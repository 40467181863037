import React from 'react';

// import HomeIcon from '@material-ui/icons/DashboardOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import KitchenIcon from '@material-ui/icons/KitchenOutlined';
import HistoryIcon from '@material-ui/icons/History';
// import ProductionIcon from '@material-ui/icons/FastfoodOutlined';
import SignOutIcon from '@material-ui/icons/ExitToAppOutlined';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SportsMotorsportsOutlinedIcon from '@material-ui/icons/SportsMotorsportsOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
// import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CollapseMenu from './collapseMenu';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';

//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import Collapse from '@mui/material/Collapse';
import {
  ItemMenu,
  StyleButton,
  StyleButtonHover,
  ListItemText
} from './styles';
import { Grid } from '@material-ui/core';

// ** This function isn't working - The idea is change de icon color when click in the button.**/
const store: string = localStorage.getItem('state');
const state = JSON.parse(store);
let is_client = false;
if (state !== null) {
  is_client = state.authentication.is_client;
}
export default (
  <>
    <StyleButton>
      <Grid container direction="column">
        {is_client === true ? (
          <Grid container direction="column">
            <ItemMenu to="/dashboard/profile">
              <StyleButtonHover>
                <ListItem button className="d-profile d-control">
                  <ListItemIcon>
                    <PermIdentityIcon
                      className="d-profile d-control-icon"
                      style={{ color: '#BBBBBB' }}
                    />
                  </ListItemIcon>
                  <ListItemText className="d-control d-profile-text">
                    Profile
                  </ListItemText>
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>
            <ItemMenu to="/dashboard/order">
              <StyleButtonHover>
                <ListItem button className="d-order d-control">
                  <ListItemIcon>
                    <KitchenIcon
                      className="d-order d-control-icon"
                      style={{ color: '#BBBBBB' }}
                    />
                  </ListItemIcon>
                  <ListItemText className="d-control d-order-text">
                    My Orders
                  </ListItemText>
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>
          </Grid>
        ) : (
          <Grid container direction="column">
            <ItemMenu to="/dashboard/manager">
              <StyleButtonHover>
                <ListItem button className="d-manager d-control">
                  <ListItemIcon>
                    <BarChartIcon
                      className="d-manager d-control-icon"
                      style={{ color: '#BBBBBB' }}
                    />
                  </ListItemIcon>
                  <ListItemText className="d-control d-manager-text">
                    Dashboard
                  </ListItemText>
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>

            <ItemMenu to="/dashboard/order">
              <StyleButtonHover>
                <ListItem button className="d-order d-control">
                  <ListItemIcon>
                    <KitchenIcon
                      className="d-order d-control-icon"
                      style={{ color: '#BBBBBB' }}
                    />
                  </ListItemIcon>
                  <ListItemText className="d-control d-order-text">
                    My Orders
                  </ListItemText>
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>

            <ItemMenu to="/dashboard/completed">
              <StyleButtonHover>
                <ListItem button className="d-completed d-control">
                  <ListItemIcon>
                    <ReceiptIcon
                      className="d-completed d-control-icon"
                      style={{ color: '#BBBBBB', width: 24, height: 24 }}
                    />
                  </ListItemIcon>
                  <ListItemText className="d-control d-completed-text">
                    Complete Orders
                  </ListItemText>
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>

            <ItemMenu to="/dashboard/delivery">
              <StyleButtonHover>
                <ListItem button className="d-delivery d-control">
                  <ListItemIcon>
                    <SportsMotorsportsOutlinedIcon
                      className="d-delivery d-control-icon"
                      style={{ color: '#BBBBBB', width: 24, height: 24 }}
                    />
                  </ListItemIcon>
                  <ListItemText className="d-control d-delivery-text">
                    Delivery
                  </ListItemText>
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>

            <ItemMenu to="/dashboard/history">
              <StyleButtonHover>
                <ListItem button className="d-history d-control">
                  <ListItemIcon>
                    <HistoryIcon
                      className="d-history d-control-icon"
                      style={{ color: '#BBBBBB' }}
                    />
                  </ListItemIcon>
                  <ListItemText className="d-control d-history-text">
                    Billing
                  </ListItemText>
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>
            <ItemMenu to="/dashboard/vouchers">
              <StyleButtonHover>
                <ListItem button className="d-vouchers d-control">
                  <ListItemIcon>
                    <LocalActivityIcon
                      className="d-vouchers d-control-icon"
                      style={{ color: '#BBBBBB' }}
                    />
                  </ListItemIcon>
                  <ListItemText className="d-control d-vouchers-text">
                    Vouchers
                  </ListItemText>
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>
            <ItemMenu to="/dashboard/customers">
              <StyleButtonHover>
                <ListItem button className="d-customers d-control">
                  <ListItemIcon>
                    <PeopleAltOutlinedIcon
                      className="d-customers d-control-icon"
                      style={{ color: '#BBBBBB', width: 24, height: 24 }}
                    />
                  </ListItemIcon>
                  <ListItemText className="d-control d-customers-text">
                    Customers
                  </ListItemText>
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>
            <CollapseMenu label="CMS">
              <ItemMenu to="/dashboard/artists">
                <StyleButtonHover>
                  <ListItem button className="d-artists d-control">
                    <ListItemIcon>
                      <PersonOutlineIcon
                        className="d-artists d-control-icon"
                        style={{ color: '#BBBBBB' }}
                      />
                    </ListItemIcon>
                    <ListItemText className="d-control d-artists-text">
                      Artists
                    </ListItemText>
                  </ListItem>
                </StyleButtonHover>
              </ItemMenu>

              <ItemMenu to="/dashboard/stock">
                <StyleButtonHover>
                  <ListItem button className="d-stock d-control">
                    <ListItemIcon>
                      <TableChartOutlinedIcon
                        className="d-stock d-control-icon"
                        style={{ color: '#BBBBBB' }}
                      />
                    </ListItemIcon>
                    <ListItemText className="d-control d-stock-text">
                      Inventory
                    </ListItemText>
                  </ListItem>
                </StyleButtonHover>
              </ItemMenu>

              <ItemMenu to="/dashboard/latest">
                <StyleButtonHover>
                  <ListItem button className="d-latest d-control">
                    <ListItemIcon>
                      <TableChartOutlinedIcon
                        className="d-latest d-control-icon"
                        style={{ color: '#BBBBBB' }}
                      />
                    </ListItemIcon>
                    <ListItemText className="d-control d-latest-text">
                      Latest
                    </ListItemText>
                  </ListItem>
                </StyleButtonHover>
              </ItemMenu>

              <ItemMenu to="/dashboard/menus">
                <StyleButtonHover>
                  <ListItem button className="d-menus d-control">
                    <ListItemIcon>
                      <TableChartOutlinedIcon
                        className="d-menus d-control-icon"
                        style={{ color: '#BBBBBB' }}
                      />
                    </ListItemIcon>
                    <ListItemText className="d-control d-menus-text">
                      Collections
                    </ListItemText>
                  </ListItem>
                </StyleButtonHover>
              </ItemMenu>
              {/*<ItemMenu to="/dashboard/media">
                <StyleButtonHover>
                  <ListItem button className="d-media d-control">
                    <ListItemIcon>
                      <PermMediaIcon
                        className="d-media d-control-icon"
                        style={{ color: '#BBBBBB' }}
                      />
                    </ListItemIcon>
                    <ListItemText className="d-control d-media-text">
                      Media
                    </ListItemText>
                  </ListItem>
                </StyleButtonHover>
              </ItemMenu> */}
              <ItemMenu to="/dashboard/home">
                <StyleButtonHover>
                  <ListItem button className="d-home-page d-control">
                    <ListItemIcon>
                      <PermMediaIcon
                        className="d-home-page d-control-icon"
                        style={{ color: '#BBBBBB' }}
                      />
                    </ListItemIcon>
                    <ListItemText className="d-control d-home-page-text">
                      Homepage
                    </ListItemText>
                  </ListItem>
                </StyleButtonHover>
              </ItemMenu>
            </CollapseMenu>

            <CollapseMenu label="Settings">
              <ItemMenu to="/dashboard/currency">
                <StyleButtonHover>
                  <ListItem button className="d-currency d-control">
                    <ListItemIcon>
                      <MonetizationOnIcon
                        className="d-currency d-control-icon"
                        style={{ color: '#BBBBBB' }}
                      />
                    </ListItemIcon>
                    <ListItemText className="d-control d-currency-text">
                      Currency
                    </ListItemText>
                  </ListItem>
                </StyleButtonHover>
              </ItemMenu>
            </CollapseMenu>
          </Grid>
        )}

        <ItemMenu to="/dashboard/logout">
          <StyleButtonHover>
            <ListItem button>
              <ListItemIcon>
                <SignOutIcon style={{ color: '#BBBBBB' }} />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </ListItem>
          </StyleButtonHover>
        </ItemMenu>
      </Grid>
    </StyleButton>
  </>
);
