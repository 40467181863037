import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  withStyles
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createTechnique } from 'redux/ducks/stock';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: (success: number) => void;
}
const GlobalCss = withStyles({
  '@global': {
    '.MuiDialog-paperWidthSm': {
      minWidth: '30vw'
    }
  }
})(() => null);

export default function ModalCreateTechnique(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');

  const dispatch = useDispatch();

  const handleClose = (success: number) => {
    onClose(success);
  };

  const submitCreate = async () => {
    if (name === '') {
      setNameError('Please enter name');
    } else {
      dispatch(createTechnique({ name })).then((result: number) => {
        if (result === 1) handleClose(1);
      });
    }
  };

  return (
    <div>
      <GlobalCss />
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        style={{ minWidth: '30vw' }}
      >
        <DialogTitle id="simple-dialog-title">Create Technique</DialogTitle>
        <Container maxWidth="md">
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
            error={nameError !== ''}
          >
            <InputLabel htmlFor="name-input">Name</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError('');
              }}
            />
            <FormHelperText>{nameError}</FormHelperText>
          </FormControl>
        </Container>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={submitCreate} color="primary">
            {' '}
            Create{' '}
          </Button>
          <Button onClick={() => handleClose(0)}> Cancel </Button>
        </div>
      </Dialog>
    </div>
  );
}
