import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import {
    Backdrop,
    Fade,
    Typography,
    CircularProgress,
    Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import { fetch } from 'utils/request';
import { EMethod } from 'enums/method';
import { Product } from 'interfaces/stock';
import useStyles from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
interface IProps {
    open: boolean;
    handleClose: () => void;
    customer: {
        name: string;
        id: number;
        wishlist: number[]
    };
}

export default function TransitionsModal(props: IProps) {
    const { open, handleClose, customer } = props;
    const classes = useStyles();
    const [wishlist, setWishlist] = useState<Product[]>([]);
    const [loading, setLoading] = useState(true)
    const getWishlist = async () => {
        setLoading(true)
        setWishlist([])
        try {
            customer?.wishlist.forEach(async product_id => {
                const response = await fetch({
                    method: EMethod.GET,
                    url: `/products/api/products/${product_id}/`,
                });
                setWishlist(wishlist => [...wishlist, response]);
            });
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }

    }
    useEffect(() => {
        if (customer.id === 0 || !customer.id) return
        getWishlist()
    }, [customer])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open}>
                <div className={classes.wishlist_paper}>
                    <Typography variant="h5" style={{ marginBottom: "5px" }}>
                        {customer.name}{customer.name.slice(-1).toLowerCase() === 's' ? "'" : "'s"} wishlist
                    </Typography>
                    {loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></div> :
                        <>
                            {wishlist.length > 0 ?
                                <>
                                    {wishlist.map((item, idx) => (
                                        <div key={"wishlist item " + idx} style={{ marginBottom: "10px" }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <Typography style={{ fontWeight: "bold" }} >{item.name}</Typography>
                                                        <Typography>{item.artist.name}, {item.year}</Typography>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <Typography>Price:</Typography>{" "}
                                                            <Typography style={{ marginLeft: "5px", color: 'rgb(254, 48, 156)' }}>{item.price.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: "USD"
                                                            })}</Typography>
                                                        </div>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div style={{
                                                        borderRadius: '9px',
                                                        width: "100%",
                                                        marginTop: "-20px"
                                                    }}>
                                                        <Typography style={{ marginBottom: "1rem" }} variant='h6'>Available editions</Typography>
                                                        <div style={{
                                                            paddingLeft: "15px"
                                                        }}>
                                                            {item.editions.map((edition, idx) => (
                                                                <>
                                                                    {edition.stock_status === "Available" &&
                                                                        <div key={"edition " + edition.id} style={{
                                                                            marginBottom: "1rem", paddingBottom: "0.5rem", borderBottom: "1px solid lightgrey", paddingLeft: "15px"
                                                                        }}>
                                                                            <Typography style={{ fontWeight: "bold" }}>{edition.name}</Typography>
                                                                            <Typography>Edition number: {edition.edition_number}</Typography>
                                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                                <Typography>Price:</Typography>{" "}
                                                                                <Typography style={{ marginLeft: "5px", color: 'rgb(254, 48, 156)' }}>{edition.base_price.toLocaleString('en-US', {
                                                                                    style: 'currency',
                                                                                    currency: "USD"
                                                                                })}</Typography>
                                                                            </div>
                                                                        </div>}
                                                                </>
                                                            ))}
                                                        </div>

                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>

                                        </div>
                                    ))}
                                </> : <Typography>This wishlist is empty</Typography>}
                        </>
                    }

                </div>
            </Fade>
        </Modal >
    );
}
