import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Latest } from 'interfaces/latest';
import { Button, Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import { CLOUDINARY_URL } from 'settings';
import ModalEditlatest from './ModalEditLatest';
import ModalCreatelatest from './ModalCreateLatest';
import ModalDeletelatest from './ModalDeleteLatest';
// import ModalDeletelatest from './ModalDeletelatest';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'black',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: '#fe309c',
      backgroundColor: 'black'
    }
  }
});

interface Props {
  latest: Latest[];
}

export default function LatestTable(props: Props) {
  const classes = useStyles();
  const { latest } = props;
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedlatest, setSelectedlatest] = React.useState(undefined);

  const handleEdit = (latest: Latest) => {
    setSelectedlatest(latest);
    setOpenEdit(true);
  };
  const handleDelete = (latest: Latest) => {
    setSelectedlatest(latest);
    setOpenDelete(true);
  };
  const handleClose = () => {
    setOpenEdit(false);
    setOpenCreate(false);
    setOpenDelete(false);
    setSelectedlatest(undefined);
  };
  const handleCreate = () => {
    setOpenCreate(true);
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button className={classes.activeButton} onClick={handleCreate}>
        Create latest
      </Button>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Date</TableCell>
            <TableCell align="center">Cover Image</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {latest ? (
            <>
              {latest.map((latest) => (
                <TableRow key={latest.id}>
                  <TableCell component="th" scope="row">
                    {latest.id}
                  </TableCell>
                  <TableCell>{latest.title}</TableCell>
                  <TableCell>{latest.author}</TableCell>
                  <TableCell>{latest.date}</TableCell>
                  <TableCell align="center">
                    {latest.title_image ? (
                      <IconButton>
                        <ImageIcon
                          onClick={() =>
                            window.open(
                              CLOUDINARY_URL + latest.title_image,
                              '_blank'
                            )
                          }
                        ></ImageIcon>
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <IconButton
                        onClick={() => handleEdit(latest)}
                        style={{ padding: 5 }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(latest)}
                        style={{ padding: 5 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : null}
        </TableBody>
      </Table>
      <ModalEditlatest
        open={openEdit}
        onClose={handleClose}
        latest={selectedlatest}
        latestArray={latest}
      />
      <ModalCreatelatest
        open={openCreate}
        onClose={handleClose}
        latest={latest}
      />
      <ModalDeletelatest
        open={openDelete}
        onClose={handleClose}
        latest={selectedlatest}
      />
    </TableContainer>
  );
}
