import React from 'react';
import { IPackage } from 'interfaces/package';
import { Grid, Card, Typography } from '@material-ui/core';

interface IProps {
  batch: IPackage;
}

export default function Panel(props: IProps): JSX.Element {
  const { batch } = props;

  return (
    <Grid item xs={12} sm={6} md={3} lg={3}>
      <Card style={{ padding: 10, height: '100%'}}>
        <Typography
          variant="subtitle2"
          align="center"
          style={{ fontWeight: 300, fontSize: 16 }}
        >
          {`PACOTE Nº ${batch.batch}`}
        </Typography>
        <hr/>
        <Typography
          align="left"
          style={{ fontWeight: 600, fontSize: 14 }}
        >
          {`PRODUCTS`}
        </Typography>
        {batch.products.map((product) => (
          <Typography
            align="left"
            style={{ fontSize: 12 }}
          >
            {`${product.quantity} x ${product.product}`}
          </Typography>

        ))}
        <hr/>
        <Typography
          align="left"
          style={{ fontWeight: 600, fontSize: 14 }}
        >
          {`INGREDIENTS`}
        </Typography>
        {batch.ingredients.map((ingredient) => (
          <Typography
            align="left"
            style={{ fontSize: 12 }}
          >
            {`${ingredient.ingredient} - ${ingredient.quantity}${ingredient.measure}`}
          </Typography>

        ))}

      </Card>
    </Grid>
  );
}
