import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Backdrop,
  Fade,
  Typography,
  CircularProgress,
  Button,
  Grid,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  TextField,
  Select,
  MenuItem,
  IconButton
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import NewAddressModal from './newAddressModal';
import { Customer } from 'interfaces/customersAndSellers';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import { patchClient, fetchClient } from 'redux/ducks/customersAndSellers';
interface IProps {
  open: boolean;
  handleClose: () => void;
  loading: boolean;
  customer: Customer;
  fetchData: () => void;
}

export default function CustomerModal(props: IProps) {
  const { open, handleClose, loading, customer, fetchData } = props;
  const classes = useStyles();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [billingId, setBillingId] = useState('');
  const [billingQuery, setBillingQuery] = useState('');
  const [deliveryId, setDeliveryId] = useState('');
  const [deliveryQuery, setDeliveryQuery] = useState('');
  const [addresses, setAddresses] = useState<any>([]);
  const [newAddressModal, setNewAddressModal] = useState(false);
  const [newAddressType, setNewAddressType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [typeOfClient, setTypeOfClient] = useState<any>('');
  const [clientData, setClientData] = useState({
    first_name: "",
    last_name: ""
  })
  const [billing, setBilling] = useState({
    city: '',
    country: { code: '', name: '' },
    postal_code: '',
    street: '',
    street_number: '',
    address_id: 0
  });
  const [delivery, setDelivery] = useState({
    city: '',
    country: { code: '', name: '' },
    postal_code: '',
    street: '',
    street_number: '',
    address_id: 0
  });
  const [billingError, setBillingError] = useState({
    city: '',
    country: '',
    postal_code: '',
    street: '',
    street_number: ''
  });
  const [deliveryError, setDeliveryError] = useState({
    city: '',
    country: '',
    postal_code: '',
    street: '',
    street_number: ''
  });
  const [errors, setErrors] = useState({
    firstName: '',
    secondName: '',
    phone: '',
    email: ''
  });
  const dispatch = useDispatch();

  const checkForm = () => {
    var entered = false;
    if (firstName === '') {
      setErrors((errors) => ({
        ...errors,
        [firstName]: 'This field is required'
      }));
      entered = true;
    }
    if (secondName === '') {
      setErrors((errors) => ({
        ...errors,
        [secondName]: 'This field is required'
      }));
      entered = true;
    }
    if (phone === '') {
      setErrors((errors) => ({
        ...errors,
        [phone]: 'This field is required'
      }));
      entered = true;
    }
    if (email === '') {
      setErrors((errors) => ({
        ...errors,
        [email]: 'This field is required'
      }));
      entered = true;
    }
    for (let index = 0; index < Object.keys(billingError).length; index++) {
      const element = Object.keys(billingError)[index];
      if (billingId[element] === '') {
        entered = true;
        setBillingError({ ...billingError, city: 'This field is mandatory' });
      }
    }
    for (let index = 0; index < Object.keys(deliveryError).length; index++) {
      const element = Object.keys(deliveryError)[index];
      if (deliveryId[element] === '') {
        entered = true;
        setDeliveryError({ ...deliveryError, city: 'This field is mandatory' });
      }
    }
    return entered;
  };
  function deepEqual(x, y) {
    return JSON.stringify(x) === JSON.stringify(y);
  }
  const submit = () => {
    try {
      if (!checkForm()) {
        var data = {
          first_name: firstName,
          last_name: secondName,
          type_of_client: typeOfClient,
          email,
          profile: {
            phone_number: phone,
            name: firstName + " " + secondName,

          },
          billing_address: billingId,
          delivery_address: deliveryId
        };
        if (customer.phone_number === phone) {
          delete data.profile.phone_number;
        }
        if (billing) {
          if (deepEqual(billing, billingId)) {
            delete data.billing_address;
          }
        }
        if (delivery) {
          if (deepEqual(delivery, deliveryId)) {
            delete data.delivery_address;
          }
        }
        dispatch(patchClient(data, customer.id)).then(() => {
          fetchData();
          onClose();
        });
      }
    } catch (error) {
      console.log('error - ', error);
    }
  };
  console.log('Billing ', deepEqual(billing, billingId), billing, billingId);
  console.log(
    'Delivery ',
    deepEqual(delivery, deliveryId),
    delivery,
    deliveryId
  );
  const fetch = async () => {
    var data = await dispatch(fetchClient(customer.id));
    if (data) {
      setClientData(data)
      setPhone(data?.profile?.phone_number);
      setFirstName(data?.first_name);
      setSecondName(data?.last_name);
      setEmail(data?.email);
      setAddresses(data.addresses);
      setBillingId(data.billing_address);
      setDeliveryId(data.delivery_address);
      setBilling(data.billing_address);
      setDelivery(data.delivery_address);
      setTypeOfClient((data?.profile?.type_of_client).toUpperCase());
    }
  };

  const onClose = () => {
    setPhone('');
    setBilling({ ...billing, city: '' });
    setBilling({ ...billing, country: { code: '', name: '' } });
    setBilling({ ...billing, street: '' });
    setBilling({ ...billing, postal_code: '' });
    setBilling({ ...billing, street: '' });
    setBilling({ ...billing, street_number: '' });

    setDelivery({ ...delivery, city: '' });
    setDelivery({ ...delivery, country: { code: '', name: '' } });
    setDelivery({ ...delivery, street: '' });
    setDelivery({ ...delivery, postal_code: '' });
    setDelivery({ ...delivery, street: '' });
    setDelivery({ ...delivery, street_number: '' });
    setFirstName('');
    setSecondName('');
    setEmail('');
    setTypeOfClient('');
    setClientData({
      first_name: "",
      last_name: ""
    })
    handleClose();
  };
  useEffect(() => {
    if (customer.id) {
      fetch();
    }
  }, [customer]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.edit_customer_modal}
      open={open}
      onClose={() => onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className={classes.edit_customer_paper}>
          {loading ? (
            <div className={classes.customer_loading_wrapper}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Typography variant="h5">{clientData?.first_name} {clientData?.last_name}</Typography>
                <Button
                  onClick={() => submit()}
                  variant="outlined"
                  color="primary"
                >
                  Save
                </Button>
              </div>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '5px',
                  marginTop: '15px'
                }}
                spacing={3}
                container
              >
                <Grid style={{ marginRight: '1rem' }} lg={5}>
                  <FormControl
                    margin={'normal'}
                    style={{ marginTop: '0px' }}
                    fullWidth
                    error={errors.firstName !== ''}
                  >
                    <InputLabel htmlFor="name-input">First name</InputLabel>
                    <Input
                      id="name-input"
                      type="text"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setErrors((errors) => ({
                          ...errors,
                          [firstName]: ''
                        }));
                      }}
                    />
                    <FormHelperText>{errors.firstName}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  style={{
                    marginBottom: '1vw',

                    width: '45%'
                  }}
                  lg={5}
                >
                  <FormControl
                    margin={'normal'}
                    style={{ marginTop: '0px' }}
                    fullWidth
                    error={errors.secondName !== ''}
                  >
                    <InputLabel htmlFor="name-input">Second name</InputLabel>
                    <Input
                      id="name-input"
                      type="text"
                      value={secondName}
                      onChange={(e) => {
                        setSecondName(e.target.value);
                        setErrors((errors) => ({
                          ...errors,
                          [secondName]: ''
                        }));
                      }}
                    />
                    <FormHelperText>{errors.secondName}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid style={{ marginRight: '1rem' }} lg={5}>
                  <FormControl
                    margin={'normal'}
                    style={{ marginTop: '0px' }}
                    fullWidth
                    error={errors.email !== ''}
                  >
                    <InputLabel htmlFor="name-input">Phone number</InputLabel>
                    <Input
                      id="name-input"
                      type="text"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setErrors((errors) => ({
                          ...errors,
                          [phone]: ''
                        }));
                      }}
                    />{' '}
                    <FormHelperText>{errors.phone}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  style={{
                    marginBottom: '1vw'
                  }}
                  lg={5}
                >
                  <FormControl
                    margin={'normal'}
                    style={{ marginTop: '0px' }}
                    fullWidth
                    error={errors.email !== ''}
                  >
                    <InputLabel htmlFor="name-input">E-mail</InputLabel>
                    <Input
                      id="name-input"
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      value={email}
                    />{' '}
                    <FormHelperText>{errors.email}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid lg={3} md={3} sm={5}>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '15px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="name-input">Type of Client</InputLabel>
                    <Select
                      labelId="label"
                      id="type-of-user-input"
                      value={typeOfClient}
                      onChange={(e) => {
                        setTypeOfClient(e.target.value);
                      }}
                    >
                      <MenuItem value="B2B">B2B</MenuItem>
                      <MenuItem value="B2C">B2C</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    marginTop: '15px'
                  }}
                >
                  <Grid
                    style={{
                      marginRight: '1rem',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                    lg={8}
                    md={12}
                    sm={12}
                  >
                    <FormControl
                      style={{
                        marginTop: '0px'
                      }}
                      fullWidth
                      margin={'normal'}
                      //  error={clientError !== ''}
                      required
                    >
                      <Autocomplete
                        value={billingId}
                        onChange={(event: any, newValue: string | null) => {
                          setBillingId(newValue);
                        }}
                        inputValue={billingQuery}
                        onInputChange={(event, newInputValue) => {
                          setBillingQuery(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={addresses}
                        getOptionLabel={(option: any) => {
                          if (!option.street && !option.city) {
                            return '';
                          }
                          var country = option.country
                            ? typeof option.country === 'object'
                              ? option.country.name
                              : option.country
                            : '';
                          return (
                            option.street +
                            ', ' +
                            option.street_number +
                            ', ' +
                            option.city +
                            ', ' +
                            country
                          );
                        }}
                        renderOption={(option) => {
                          var string = option.country
                            ? option.street +
                            ', ' +
                            option.street_number +
                            ', ' +
                            option.city +
                            ', ' +
                            option.country
                            : option.street +
                            ', ' +
                            option.street_number +
                            ', ' +
                            option.city;
                          return string;
                        }}
                        loading={addresses.length === 0}
                        loadingText={'Loading addresses'}
                        renderInput={(params) => (
                          <TextField {...params} label="Billing address" />
                        )}
                      />
                      {/* <FormHelperText>{clientError}</FormHelperText> */}
                    </FormControl>
                    <IconButton
                      onClick={() => {
                        setNewAddressModal(true);
                        setNewAddressType('billing');
                      }}
                      style={{ marginTop: '1vw' }}
                    >
                      <AddIcon></AddIcon>
                    </IconButton>
                  </Grid>
                </div>
                <br />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    marginTop: '15px'
                  }}
                >
                  <Grid
                    style={{
                      marginRight: '1rem',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                    lg={8}
                    md={12}
                    sm={12}
                  >
                    <FormControl
                      style={{
                        marginTop: '0px'
                      }}
                      fullWidth
                      margin={'normal'}
                      //  error={clientError !== ''}
                      required
                    >
                      <Autocomplete
                        value={deliveryId}
                        onChange={(event: any, newValue: string | null) => {
                          setDeliveryId(newValue);
                        }}
                        inputValue={deliveryQuery}
                        onInputChange={(event, newInputValue) => {
                          setDeliveryQuery(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={addresses}
                        getOptionLabel={(option: any) => {
                          if (!option.street && !option.city) {
                            return '';
                          }
                          var country = option.country
                            ? typeof option.country === 'object'
                              ? option.country.name
                              : option.country
                            : '';
                          return (
                            option.street +
                            ', ' +
                            option.street_number +
                            ', ' +
                            option.city +
                            ', ' +
                            country
                          );
                        }}
                        renderOption={(option) => {
                          var string = option.country
                            ? option.street +
                            ', ' +
                            option.street_number +
                            ', ' +
                            option.city +
                            ', ' +
                            option.country
                            : option.street +
                            ', ' +
                            option.street_number +
                            ', ' +
                            option.city;
                          return string;
                        }}
                        loading={addresses.length === 0}
                        loadingText={'Loading Addresses'}
                        renderInput={(params) => (
                          <TextField {...params} label="Delivery address" />
                        )}
                      />
                      {/* <FormHelperText>{clientError}</FormHelperText> */}
                    </FormControl>
                    <IconButton
                      onClick={() => {
                        setNewAddressModal(true);
                        setNewAddressType('delivery');
                      }}
                      style={{ marginTop: '1vw' }}
                    >
                      <AddIcon></AddIcon>
                    </IconButton>
                  </Grid>
                </div>
              </Grid>
            </>
          )}
          <NewAddressModal
            open={newAddressModal}
            addressType={newAddressType}
            onClose={(data) => {
              if (data !== 'canceled') {
                if (newAddressType === 'billing') {
                  setBillingId(data);
                } else {
                  setDeliveryId(data);
                }
              }
              setNewAddressType('');
              setNewAddressModal(false);
            }}
          />
        </div>
      </Fade>
    </Modal>
  );
}
