import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import {
  fetchBestBuyers,
  fetchBestBuyersWithDateRange,
  fetchLastOrder
} from 'redux/ducks/customersAndSellers';
import { EFilters } from '../../enums/customers';
import formatDate from 'date-fns/format';

import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import CustomersTable from './CustomersTable';
import DateFilter from './DateFilter';
import LastOrderModal from './LastOrderModal';
const filters = [
  {
    label: 'All',
    value: EFilters.ALL
  }
];

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const { customersAndSellers } = useSelector<IStore, IStore>((state) => state);
  const [activeFilter, setActiveFilter] = useState<EFilters>(EFilters.ALL);
  const [initialDate, setInitialDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-customers');
    d.item(0).style.color = '#fe309c';

    d.item(1).style.color = '#fe309c';
    const dText = document.querySelectorAll<HTMLElement>('.d-customers-text');
    dText.item(0).style.color = '#fe309c';
  });

  useEffect(() => {
    dispatch(fetchBestBuyers(activeFilter));
  }, [dispatch, activeFilter]);

  const handleDateRange = () => {
    dispatch(
      fetchBestBuyersWithDateRange(
        formatDate(initialDate, 'yyyy-MM-dd'),
        formatDate(endDate, 'yyyy-MM-dd')
      )
    );
  };

  const handleLastOrder = async (orderId: number) => {
    setLoading(true);
    setOpenModal(true);
    await dispatch(fetchLastOrder(orderId));
    setLoading(false);
  };

  return (
    <>
      <Loading show={customersAndSellers.fetch} />
      <LastOrderModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        orderToShow={customersAndSellers.last_order}
        loading={loading}
      />
      <DateFilter
        activeFilter={activeFilter}
        setActiveFilter={(newFilter: EFilters) => setActiveFilter(newFilter)}
        filters={filters}
        dateRange={{
          initialDate,
          endDate,
          setInitialDate,
          setEndDate
        }}
        handleDateRange={handleDateRange}
      />
      <Grid container spacing={3}>
        <CustomersTable
          fetchData={() => dispatch(fetchBestBuyers(activeFilter))}
          customers={customersAndSellers.customers}
          handleLastOrder={handleLastOrder}
        />
      </Grid>
    </>
  );
}
