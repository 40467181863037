import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Icurrency } from 'interfaces/currency';
import ModalCreateCurrency from './ModalCreateCurrency';
import ModalEditCurrency from './ModalEditCurrency';
import ModalDeleteCurrency from './ModalDeleteCurrency';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';
//import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'black',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: '#fe309c',
      backgroundColor: 'black'
    }
  }
});

interface IProps {
  currencys: Icurrency[];
  getData: () => void;
}

export default function CurrencysTable(props: IProps) {
  // const dispatch = useDispatch();
  const classes = useStyles();
  const { currencys, getData } = props;
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedCurrency, setSelectedCurrency] = React.useState(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenDelete(false);
    setSelectedCurrency(undefined);
  };

  const handleClickOpenEdit = (currency: Icurrency) => {
    setOpenEdit(true);
    setSelectedCurrency(currency);
  };

  /*
   const handleClickOpenDelete = (currency: Icurrency) => {
     setOpenDelete(true);
     setSelectedCurrency(currency);
   }; */

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button
        style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }}
        color="primary"
        onClick={handleClickOpen}
        className={classes.activeButton}
      >
        Add Currency
      </Button>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Rate</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currencys?.map((currency) => (
            <TableRow key={currency.name}>
              <TableCell component="th" scope="row">
                {currency.name}
              </TableCell>
              <TableCell>{currency.rate}</TableCell>
              <TableCell>
                <EditIcon onClick={() => handleClickOpenEdit(currency)} />{' '}
                {/*<DeleteIcon onClick={() => handleClickOpenDelete(currency)} />{' '}*/}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ModalCreateCurrency
        getData={getData}
        open={open}
        onClose={handleClose}
      />
      <ModalEditCurrency
        getData={getData}
        open={openEdit}
        onClose={handleClose}
        currency={selectedCurrency}
      />
      <ModalDeleteCurrency
        open={openDelete}
        getData={getData}
        onClose={handleClose}
        currency={selectedCurrency}
      />
    </TableContainer>
  );
}
