import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  }
});
export default function OrderNotesModal(props) {
  const { onClose, order, open } = props;
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    console.log('order 2 ', order);
  }, [order]);
  const classes = useStyles();
  return (
    <Dialog onClose={handleClose} open={open} className={classes.dialog}>
      <DialogTitle>Order notes for order: {order?.order_id} </DialogTitle>
      <DialogContent>
        <DialogContentText>{order?.final_comment}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
