import { Grid } from '@material-ui/core';
import Loading from 'components/Common/Loading';
import { Banner } from 'interfaces/banner';
import IStore from 'interfaces/store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBanners } from 'redux/ducks/banner';
import MediaTable from './MediaTable';

export default function Index() {
  const dispatch = useDispatch();
  const [banners, setBanners] = React.useState([]);
  const { banner } = useSelector<IStore, IStore>((state) => state);

  const getBannerss = async () => {
    const x: Banner[] = await dispatch(getBanners());
    setBanners(x);
  };
  useEffect(() => {
    getBannerss();
  }, []);
  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      if (c.style) {
        c.style.color = 'black';
      }
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      if (c.style) {
        c.style.color = '#BBBBBB';
      }
    });

    const d = document.querySelectorAll<HTMLElement>('.d-media');
    if (d.item(0)) {
      d.item(0).style.color = '#fe309c';

      d.item(1).style.color = '#fe309c';
      const dText = document.querySelectorAll<HTMLElement>('.d-media-text');
      dText.item(0).style.color = '#fe309c';
    }
  });

  return (
    <>
      <Loading show={banner.fetch} />

      <Grid container spacing={3}>
        <MediaTable banners={banners} reload={() => getBannerss()} />
      </Grid>
    </>
  );
}
