/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
// import { Image, Transformation } from 'cloudinary-react';
import {
  Select,
  MenuItem,
  Grid,
  Container,
  FormControl,
  Button,
  InputLabel,
  Input,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  TextField,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createProduct } from 'redux/ducks/stock';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { Editions, Ingredients, Tags } from 'interfaces/stock';
import './styles.css';
import { fetch } from './../../utils/request';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteModal from '../../components/Common/Modal/index';
import { EAlertVariant } from 'interfaces/alert';
import { sendAlert } from '../../redux/ducks/alert';
import ModalCreateTechnique from './ModalCreateTechnique';
import ModalCreateArtist from './ModalCreateArtist';
import ModalCreateClient from './ModalCreateClient';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//Overrides theme of Dropzone component
const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  ingredients: Ingredients[];
  tags: Tags[];
  reset: () => void;
}
const ckEditorToolbarConfig = [
  'Heading',
  '|',
  'bold',
  'italic',
  '|',
  'BulletedList',
  'NumberedList',
  '|',
  'Indent',
  '|',
  'BlockQuote',
  'undo',
  'redo'
];
export default function ModalCreateProduct(props: SimpleDialogProps) {
  const { onClose, open, reset } = props;
  const [openCreateTechnique, setOpenCreateTechnique] = React.useState(false);
  const [openCreateArtist, setOpenCreateArtist] = React.useState(false);
  const [openCreateClient, setOpenClient] = React.useState(false);
  const [deletedOn, setDeletedOn] = React.useState([]);
  const [active, setActive] = React.useState(false);
  const [titleOn, setTitleOn] = React.useState(true);
  const [index, setIndex] = React.useState('');
  const [page_tag, setPageTag] = React.useState(false);
  const [indexError, setIndexError] = React.useState('');
  const [artworkImagesError, setArtworkImagesError] = React.useState('');
  const [editionsOn, setEditionsOn] = React.useState(false);
  const [imageSource, setImageSource] = React.useState<File[]>([]);
  const [contentSheet, setContentSheet] = React.useState<File>();
  const [video, setVideo] = React.useState<File>();
  const [year, setYear] = React.useState('');
  const [hs_code, setHscode] = React.useState('');
  const [imagePaths, setImagePaths] = React.useState([]);
  const [selectedArtist, setSelectedArtist] = React.useState(0);
  const [template, setTemplate] = React.useState('');
  const [quote, setQuote] = React.useState('');
  const [quoteSignature, setQuoteSignature] = React.useState('');
  const [slug, setSlug] = React.useState('');
  const [slugError, setSlugError] = React.useState('');
  const [selectedArtistError, setSelectedArtistError] = React.useState('');
  const [fetchedArtists, setFetchedArtists] = React.useState([]);
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [length, setLength] = React.useState('');
  const [width, setWidth] = React.useState('');
  const [height, setHeight] = React.useState('');
  const [weight, setWeigt] = React.useState('');
  const [additionalDimensions, setAdditionalDimensions] = React.useState([
    {
      artwork: '',
      length: 0,
      width: 0,
      height: 0,
      weight: 0
    }
  ]);
  const [price, setPrice] = React.useState(0);
  const [homePageArtworks, setHomePageArtworks] = React.useState([]);
  const [techniques, setTechniques] = React.useState([]);
  const [technique, setTechnique] = React.useState({ id: 0, name: '' });
  const [inputValueTechnique, setInputValueTechnique] = React.useState('');
  const [techniqueError, setTechniqueError] = React.useState('');
  const [signatureType, setSignatureType] = React.useState('');
  const [editionSize, setEditionSize] = React.useState('');
  const [editions, setEditions] = React.useState([]);
  const [editionsError, setEditionsError] = React.useState([]);
  const [cost, setCost] = React.useState(0);
  const [description, setDescription] = React.useState('');
  const [descriptionError, setDescriptionError] = React.useState('');
  // const [value, setValue] = React.useState(0);
  const [stores, setStores] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [inputValueConsignorOwner, setInputValueConsignorOwner] =
    React.useState([]);
  const [inputValueLastExaminedBy, setInputValueLastExaminedBy] =
    React.useState([]);
  const [extras, setExtras] = React.useState([]);
  const [extrasLimit, setExtrasLimit] = React.useState([]);
  const [productExtras, setProductExtras] = React.useState([]);
  const [productExtrasPrice, setProductExtrasPrice] = React.useState([]);
  const [productExtrasQuantity, setProductExtrasQuantity] = React.useState([]);
  const [waiters, setWaiters] = React.useState([]);
  const [clicked, setClicked] = React.useState(false);
  const [youtubeVideo, setYoutubeVideo] = React.useState('');
  const [videoDescription, setVideoDescription] = React.useState('');
  const [previewVideo, setPreviewVideo] = React.useState<File>();
  const [colliNumber, setColliNumber] = React.useState(0);
  const [valueForEditionsTabs, setValueForEdidtionsTabs] = React.useState(0);
  const handleChange = () => {
    setTitleOn(!titleOn);
    setEditionsOn(!editionsOn);
  };
  const handleChangeTabsForEditions = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValueForEdidtionsTabs(newValue);
  };
  const [deleteEditionModal, setDeleteEditionModal] = React.useState(false);
  const [idDeleteEditionModal, setIdDeleteEditionModal] = React.useState(0);

  const dispatch = useDispatch();

  const _getArtist = async () => {
    try {
      let response;
      const results = [];
      let url = '/products/api/artists/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setFetchedArtists(response.results);
    } catch (error) {
      console.log('error fetching artists');
    }
  };
  const _getTechniques = async () => {
    try {
      let response;
      const results = [];
      let url = '/products/api/techniques/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setTechniques(response.results);
    } catch (error) {
      console.log('error fetching artists');
    }
  };
  const _getHomePageArtworks = async () => {
    try {
      let response;
      const results = [];
      let url = '/products/api/products/?page_tag=home';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setHomePageArtworks(response.results);
    } catch (error) {
      console.log('error fetching home page artworks');
    }
  };
  const _getStores = async () => {
    try {
      let response;
      const results = [];
      let url = '/products/api/stores/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setStores(response.results);
    } catch (error) {
      console.log('error fetching stores');
    }
  };
  const _getUsers = async () => {
    try {
      let response;
      const results = [];
      let url = '/users/api/clients/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setUsers(response.results);
      //   const x = [];
      //   response.results.forEach((element) => {
      //     x.push(element.first_name + '' + element.last_name);
      //   });
      //   setUsersOptions(x);
    } catch (error) {
      console.log('error fetching users');
    }
  };
  const _getExtras = async () => {
    try {
      let response;
      const results = [];
      let url = '/products/api/extras/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setProductExtras(response.results);
    } catch (error) {
      console.log('error fetching extras');
    }
  };
  const _getWaiters = async () => {
    try {
      let response;
      const results = [];
      let url = '/waiter/api/waiter/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setWaiters(response.results);
      //   const x = [];
      //   response.results.forEach((element) => {
      //     x.push(element.first_name + '' + element.last_name);
      //   });
      //   setUsersOptions(x);
    } catch (error) {
      console.log('error fetching users');
    }
  };

  useEffect(() => {
    if (open) {
      _getArtist();
    }
    if (stores.length === 0) _getStores();
    if (users.length === 0) _getUsers();
    if (waiters.length === 0) _getWaiters();
    if (techniques.length === 0) _getTechniques();
    if (productExtras.length === 0) _getExtras();
    if (homePageArtworks.length === 0) _getHomePageArtworks();
    setAdditionalDimensions([]);
  }, [open]);

  const handleClose = () => {
    setName('');
    setNameError('');
    setPrice(0);
    setSelectedArtist(0);
    setSelectedArtistError('');
    setTechnique({ id: 0, name: '' });
    setInputValueTechnique('');
    setTechniqueError('');
    setCost(0);
    setDescription('');
    setDescriptionError('');
    setSlug('');
    setSlugError('');
    setQuote('');
    setQuoteSignature('');
    setTemplate('');
    setSignatureType('');
    setEditionSize('');
    setProductExtras([]);
    setProductExtrasPrice([]);
    setProductExtrasQuantity([]);
    setExtrasLimit([]);
    setYear('');
    setHscode('');
    setWeigt('');
    setLength('');
    setWidth('');
    setHeight('');
    setContentSheet(null);
    setVideo(null);
    setPreviewVideo(null);
    setYoutubeVideo('');
    setImageSource([]);
    setImagePaths([]);
    setEditions([]);
    setEditionsError([]);
    setEditionsOn(false);
    setTitleOn(true);
    setAdditionalDimensions([]);
    setIndex('');
    setIndexError('');
    setPageTag(false);
    setClicked(false);
    onClose();
  };

  const handleImage = (event: any) => {
    if (event.length > 0) {
      setImageSource((imageSource) => [
        ...imageSource,
        event[event.length - 1]
      ]);
      const url = URL.createObjectURL(event[event.length - 1]);
      const x = [...imagePaths];
      x.push(url);
      setImagePaths(x);
      setArtworkImagesError('');
      setDeletedOn((deletedOn) => [...deletedOn, 0]);
    }
  };
  const handleContentSheet = (event: any) => {
    if (event.length > 0) {
      setContentSheet(event[0]);
    }
  };
  const handleVideo = (event: any) => {
    if (event.length > 0) {
      setVideo(event[0]);
    }
  };
  const handleAddDimensions = () => {
    const x = [...additionalDimensions];
    x.push({
      artwork: '',
      length: 0,
      width: 0,
      height: 0,
      weight: 0
    });
    setAdditionalDimensions(x);
  };
  const handleRemoveDimensions = (index: number) => {
    const x = [...additionalDimensions];
    x.splice(index, 1);
    setAdditionalDimensions(x);
  };
  const handleHovered = (index: any) => {
    const x = [...deletedOn];
    x[index] = 1;
    setDeletedOn(x);
  };
  const handleUnHovered = (index: any) => {
    const x = [...deletedOn];
    x[index] = 0;
    setDeletedOn(x);
  };
  const handleRemove = (index: any) => {
    let x = [...deletedOn];
    x.splice(index, 1);
    setDeletedOn(x);
    x = [...imagePaths];
    x.splice(index, 1);
    setImagePaths(x);
    x = [...imageSource];
    x.splice(index, 1);
    setImageSource(x);
  };

  const handleName = (event: any) => {
    setName(event.target.value);
    setNameError('');
    const x = [...editions];
    x.map((element, index) => {
      element.name = '';
      element.name = event.target.value;
      x[index] = element;
      setEditions(x);
    });
  };

  const checkFormError = () => {
    let entered = false;
    if (name === '') {
      setNameError('Please add title');
      entered = true;
    }
    if (description === '') {
      setDescriptionError('Please add description');
      entered = true;
    }
    if (selectedArtist === 0) {
      setSelectedArtistError('Please add artist');
      entered = true;
    }
    if (slug === '') {
      setSlugError('Please add slug');
      entered = true;
    }
    if (technique.id === 0) {
      setTechniqueError('Please add a technique');
      entered = true;
    }
    if (imagePaths.length < 1) {
      setArtworkImagesError('Please add the artwork images');
      entered = true;
    }
    if (
      (index !== '' && (parseInt(index) > 16 || parseInt(index) < 1)) ||
      (page_tag && index === '')
    ) {
      setIndexError('The index must be between 1 and 16');
      entered = true;
    }
    editions.forEach((element, index) => {
      const x = [...editionsError];
      if (element.name === '') {
        x[index][0] = 'Please add a title';
        entered = true;
      }
      if (element.slug === '') {
        x[index][1] = 'Please add a slug';
        entered = true;
      }
      if (element.store_owner.name === '') {
        x[index][2] = 'Please add a store owner';
        entered = true;
      }
      if (
        element.consignor_owner.name === '' &&
        element.stock_status === 'Consigned Out'
      ) {
        x[index][3] = 'Please add a consignor owner';
        entered = true;
      }
      setEditionsError(x);
    });
    homePageArtworks.map((element) => {
      if (index && parseInt(element.index) === parseInt(index)) {
        setIndexError(
          `This index is already associated with the artwork: ${element.name}. To associate the index with this artwork, please remove it from ${element.name}.`
        );
        entered = true;
      }
    });
    if (entered) {
      if (entered) {
        dispatch(
          sendAlert('Please check the errors below', EAlertVariant.ERROR)
        );
      }
    }
    return entered;
  };

  const submitCreation = async () => {
    if (!checkFormError()) {
      setClicked(true);
      if (editions.length <= 0) {
        dispatch(
          sendAlert('Please add at least 1 edition', EAlertVariant.ERROR)
        );
        setClicked(false);
      } else {
        const editionsToSubmit = [];
        const allFiles = new Array(editions.length).fill({
          consignment_documents: null,
          certificate: null
        });

        for (let index = 0; index < editions.length; index++) {
          const element = editions[index];

          editionsToSubmit.push({
            name: element.name,
            edition_number: element.edition_number,
            slug: element.slug,
            stock_status: element.stock_status,
            base_price: element.base_price,
            tax_status: element.tax_status,
            condition_notes: element.condition_notes,
            internal_condition_notes: element.internal_condition_notes,
            current_location: element.current_location,
            location_details: element.location_details,
            location_date: element.location_date,
            consignment_date: element.location_date,
            return_consignment_date: element.location_date,
            consignment_terms: element.consignment_terms,
            consignment_notes: element.consignment_notes,
            location_notes: element.location_notes,
            location_history: element.location_history,
            third_party_base_price: element.third_party_base_price,
            cost: element.cost,
            active: element.active,
            store_owner_id: element.store_owner.id,
            consignor_owner_id: element.consignor_owner.id,
            last_examined_by:
              element.last_examined_by !== 0 ||
                element.last_examined_by !== null
                ? element.last_examined_by.user
                : '',
            last_examined_by_id: 0,
            acquired_from: element.acquired_from,
            acquisition_date: moment(element.acquisition_date).format(
              'YYYY-MM-DD'
            )
          });
          if (element.last_examined_by !== 0) {
            editionsToSubmit[index].last_examined_by_id =
              element.last_examined_by.user;
            delete editionsToSubmit[index].last_examined_by;
          } else {
            delete editionsToSubmit[index].last_examined_by;
            delete editionsToSubmit[index].last_examined_by_id;
          }
          if (editionsToSubmit[index].consignor_owner_id === 0) {
            delete editionsToSubmit[index].consignor_owner_id;
          }
          allFiles[index].consignment_documents = element.consignment_documents;
          allFiles[index].certificate = element.certificate;
        }
        const extrasToBeSubmitted = [];
        extras.map((element, index) => {
          if (element !== null || element !== '') {
            extrasToBeSubmitted.push({
              ingredient_id: element,
              extra: {
                limit: extrasLimit[index] > 0 ? extrasLimit[index] : 1,
                measure: 'Unit',
                price:
                  productExtrasPrice[index] > 0 ? productExtrasPrice[index] : 0,
                quantity:
                  productExtrasQuantity[index] > 0
                    ? productExtrasQuantity[index]
                    : 0,
                is_sauce: false
              }
            });
          }
        });
        const pr = {
          product_images: imageSource,
          active,
          index,
          page_tag: page_tag ? 'home' : '',
          name,
          price,
          quotation: quote,
          p_quotation_signature: quoteSignature,
          year,
          hs_code,
          signature_type: signatureType,
          content_sheet: contentSheet,
          template,
          stock: 0,
          technique_id: 0,
          weight,
          length,
          width,
          height,
          video,
          youtube_video: youtubeVideo,
          video_description: videoDescription,
          preview_video: previewVideo,
          technique,
          edition_size: editionSize,
          slug,
          cost,
          description,
          artist_id: selectedArtist,
          editions: JSON.stringify(editionsToSubmit),
          extras: extrasToBeSubmitted,
          allFiles,
          dimensions_additional: additionalDimensions,
          colli_number: colliNumber
        };

        if (pr.technique.id === 0) {
          delete pr.technique;
          delete pr.technique_id;
        } else {
          pr.technique_id = pr.technique.id;
          delete pr.technique;
        }
        if (pr.content_sheet === undefined || pr.content_sheet === null) {
          delete pr.content_sheet;
        }
        if (pr.video === undefined || pr.video === null) {
          delete pr.video;
        }
        if (pr.preview_video === undefined || pr.preview_video === null) {
          delete pr.preview_video;
        }
        if (pr.index === undefined || pr.index === null || pr.index === '') {
          pr.index = '0';
        }

        dispatch(createProduct(pr)).then((result: number) => {
          if (result !== -1) {
            reset();
            handleClose();
          } else {
            setClicked(false);
          }
        });
      }
    }
  };

  const handleChangeNameEdition = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            name: value
          }
          : item
      )
    );
  };

  const handleChangeBasePriceEdition = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            base_price: value
          }
          : item
      )
    );
  };
  const handleChangeBasePriceAll = (value: any) => {
    const x = [...editions];
    x.map((element) => {
      element.base_price = value;
    });
    setEditions(x);
    setPrice(value);
  };
  const handleSignatureType = (value: any) => {
    setSignatureType(value);
  };

  const handleEditionSize = (value: any) => {
    setEditionSize(value);
  };
  const handleChangeCost = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            cost: value
          }
          : item
      )
    );
  };

  const handleChangeEditionNumberEdition = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            edition_number: value
          }
          : item
      )
    );
  };

  const handleChangeStockStatus = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            stock_status: value
          }
          : item
      )
    );
  };
  const handleChangeTaxStatus = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            tax_status: value
          }
          : item
      )
    );
  };
  const handleChangeActive = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            active: value
          }
          : item
      )
    );
  };
  const handleChangeAllActive = (value: any) => {
    editions.map((element, index) => {
      handleChangeActive(index, value);
    });
    setActive(value);
  };

  const handleChangeCurrentLocation = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            current_location: value
          }
          : item
      )
    );
  };

  const handleLocationNotes = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            location_notes: value
          }
          : item
      )
    );
  };

  const handleChangeLocationHistory = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            location_history: value
          }
          : item
      )
    );
  };

  const handleChangeLocationDate = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            location_date: value
          }
          : item
      )
    );
  };
  const handleChangeConsignmentDate = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            consignment_date: value
          }
          : item
      )
    );
  };
  const handleChangeReturnConsignmentDate = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            return_consignment_date: value
          }
          : item
      )
    );
  };
  const handleChangeConsignmentTerms = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            consignment_terms: value
          }
          : item
      )
    );
  };
  const handleChangeConsignmentDocuments = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            consignment_documents: value.target.files[0]
          }
          : item
      )
    );
  };
  const handleChangeCertificate = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            certificate: value.target.files[0]
          }
          : item
      )
    );
  };
  const handleChange3rdBasePrice = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            third_party_base_price: value
          }
          : item
      )
    );
  };
  const handleChangeConsignmentNotes = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            consignment_notes: value
          }
          : item
      )
    );
  };

  const handleChangeConditionNotes = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            condition_notes: value
          }
          : item
      )
    );
  };
  const handleChangeInternalConditionNotes = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            internal_condition_notes: value
          }
          : item
      )
    );
  };

  const handleChangeSlugEdition = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            slug: value
          }
          : item
      )
    );
    const x = [...editionsError];
    x[index][1] = '';
    setEditionsError(x);
  };
  const handleChangeAllSlugs = (value: any) => {
    const x = [...editions];
    x.map((element, index) => {
      element.slug = '';
      element.slug = value + '-' + element.edition_number;
      x[index] = element;
      setEditions(x);
    });
  };
  const handleChangeStore = (index: any, value: any) => {
    const store = { id: 0, name: '' };
    store.id = value;
    Object.keys(stores).forEach((key) => {
      if (stores[key].id === value) {
        store.name = stores[key].name;
      }
    });
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            store_owner: store
          }
          : item
      )
    );
    const x = [...editionsError];
    x[index][2] = '';
    setEditionsError(x);
  };
  const handleChangeConsignor = (index: any, value: any) => {
    if (value) {
      const consignor = { id: 0, name: '' };
      consignor.id = value.id;
      consignor.name = value.first_name + ' ' + value.last_name;
      setEditions(
        editions.map((item) =>
          item.id === index
            ? {
              ...item,
              consignor_owner: consignor
            }
            : item
        )
      );
      const x = [...editionsError];
      x[index][3] = '';
      setEditionsError(x);
    } else {
      const consignor = { id: 0, name: '' };
      setEditions(
        editions.map((item) =>
          item.id === index
            ? {
              ...item,
              consignor_owner: consignor
            }
            : item
        )
      );
    }
  };
  const handleChangeLastExamined = (index: any, value: any) => {
    setEditions(
      editions.map((item) =>
        item.id === index
          ? {
            ...item,
            last_examined_by: value
          }
          : item
      )
    );
  };

  const handleSelectedArtist = (event: any) => {
    setSelectedArtist(event.target.value);
    setSelectedArtistError('');
  };
  const handleSelectedTemplate = (event: any) => {
    const templateValue = event.target.value;
    setTemplate(templateValue);
    if (templateValue === 'SCULPTURE') {
      setHscode('9703 9000 000');
    } else if (templateValue === 'ART') {
      setHscode('9702 0000 900');
    } else {
      setHscode('');
    }
  };
  const handleSelectedTechnique = (event: any) => {
    if (event) {
      setTechnique(event);
    } else {
      setTechnique({ id: 0, name: '' });
    }
    setTechniqueError('');
  };
  const handleDeleteEditions = () => {
    const values = [...editions];
    values.splice(idDeleteEditionModal, 1);
    const errors = [...editionsError];
    errors.splice(idDeleteEditionModal, 1);

    setEditions(values);
    setEditionsError(errors);
    setValueForEdidtionsTabs(0);
    setDeleteEditionModal(false);
  };
  const handleShowDeleteEditionModal = (id: number) => {
    setDeleteEditionModal(true);
    setIdDeleteEditionModal(id);
  };
  const handleCloseDeleteEditionModal = () => {
    setIdDeleteEditionModal(0);
    setDeleteEditionModal(false);
  };

  const addExtras = () => {
    let x = [...extras];
    x.push('');
    setExtras(x);
    x = [...extrasLimit];
    x.push(0);
    setExtrasLimit(x);
    x = [...productExtrasPrice];
    x.push(0);
    setProductExtrasPrice(x);
    x = [...productExtrasQuantity];
    x.push(0);
    setProductExtrasQuantity(x);
  };

  const removeExtras = (index: number) => {
    let x = [...extras];
    x.splice(index, 1);
    setExtras(x);
    x = [...extrasLimit];
    x.splice(index, 1);
    setExtrasLimit(x);
    x = [...productExtrasPrice];
    x.splice(index, 1);
    setProductExtrasPrice(x);
    x = [...productExtrasQuantity];
    x.splice(index, 1);
    setProductExtrasQuantity(x);
  };

  const handleProductExtras = (value: any, index: number) => {
    const x = [...extras];
    x[index] = value;
    setExtras(x);
  };

  const handleProductExtrasLimit = (value: any, index: number) => {
    const x = [...extrasLimit];
    x[index] = value;
    setExtrasLimit(x);
  };
  const handleProductExtrasPrice = (value: any, index: number) => {
    const x = [...productExtrasPrice];
    x[index] = value;
    setProductExtrasPrice(x);
  };
  const handleProductExtrasQuantity = (value: any, index: number) => {
    const x = [...productExtrasQuantity];
    x[index] = value;
    setProductExtrasQuantity(x);
  };

  const duplicateEdition = (edition: Editions) => {
    const data = { ...edition };
    if (editions.length >= 1) {
      data.id = editions[editions.length - 1].id + 1;
    }
    data.edition_number += 1;
    editions.map((element) => {
      element.edition_number === data.edition_number
        ? (data.edition_number += 1)
        : (data.edition_number = data.edition_number);
    });
    if (data.is_old) {
      data.is_old = false;
    }
    const y = [...editions];
    y.push(data);
    setEditions(y);
    setValueForEdidtionsTabs(editions.length);
    let x = [...editionsError];
    x.push(['', '', '', '']);
    setEditionsError(x);
    x = [...inputValueConsignorOwner];
    x.push('');
    setInputValueConsignorOwner(x);

    x = [...inputValueLastExaminedBy];
    x.push('');
    setInputValueLastExaminedBy(x);
  };

  const handleChangeAcquiredDate = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            acquisition_date: value
          }
          : item
      )
    );
  };
  const handleChangeAcquiredBy = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            acquired_from: value
          }
          : item
      )
    );
  };

  const handleChunkIntoThreesOfButtons = () => {
    const buttons = [...editions];
    const chunked = [];
    while (buttons.length) {
      chunked.push(buttons.splice(0, 3));
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {chunked.map((element, i) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {element.map((e, j) => {
                return (
                  <Button
                    onClick={(e) => {
                      handleChangeTabsForEditions(e, 3 * i + j);
                    }}
                    variant="outlined"
                    size="small"
                    className={
                      valueForEditionsTabs === 3 * i + j
                        ? 'activeButton'
                        : 'inactiveButton'
                    }
                    style={{
                      marginLeft: (3 * i + j) % 3 === 0 ? '0vw' : '1vw',
                      marginTop: '1vw',
                      width: '7vw'
                    }}
                  >
                    {`Edition ${editions[3 * i + j].edition_number}`}
                  </Button>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const handleSlugs = (slug: string) => {
    let alphanumericSlug = '';
    if (slug) {
      alphanumericSlug = slug.replace(/\W/g, '-');
    }

    setSlug(alphanumericSlug);
    handleChangeAllSlugs(alphanumericSlug);
    setSlugError('');
    return null;
  };

  const listEditions = editions.map((item, index) => (
    // eslint-disable-next-line react/jsx-key
    <div style={{ position: 'relative' }}>
      <Grid
        item
        xs={12}
        sm={8}
        style={{
          display: valueForEditionsTabs === index ? 'flex' : 'none',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: '2vw'
        }}
      >
        <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
          <Grid item style={{ flexDirection: 'column' }}>
            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
              error={editionsError[index][0] !== ''}
            >
              <InputLabel htmlFor="name-input">Title</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={item.name}
                onChange={(e) => {
                  handleChangeNameEdition(index, e.target.value);
                }}
              />
              <FormHelperText>{editionsError[index][0]}</FormHelperText>
            </FormControl>

            <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="base-price-input">
                  Website Price ($)
                </InputLabel>
                <Input
                  id="base-price-input"
                  type="number"
                  value={item.base_price}
                  onChange={(e) => {
                    handleChangeBasePriceEdition(index, e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px',
                  marginLeft: '0.5vw'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="cost-input">Buying Price (€)</InputLabel>
                <Input
                  id="cost-input"
                  type="number"
                  value={item.cost}
                  onChange={(e) => {
                    handleChangeCost(index, e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <FormControl
                style={{
                  marginTop: '0px',
                  marginBottom: '2vw'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="internal-condition-notes-input">
                  Third Party Base Price
                </InputLabel>
                <Input
                  id="internal-condition-notes-input"
                  type="number"
                  value={item.third_party_base_price}
                  onChange={(e) => {
                    handleChange3rdBasePrice(index, e.target.value);
                  }}
                />
              </FormControl>
            </Grid>

            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="edition-number-input">
                Edition Number
              </InputLabel>
              <Input
                id="edition-number-input"
                type="string"
                value={item.edition_number}
                onChange={(e) => {
                  handleChangeEditionNumberEdition(index, e.target.value);
                }}
              />
            </FormControl>
            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="current-location-input">
                Current Location
              </InputLabel>
              <Select
                labelId="label"
                id="current-location-input"
                value={item.current_location}
                onChange={(e) => {
                  handleChangeCurrentLocation(index, e.target.value);
                }}
              >
                <MenuItem value="Warehouse">Warehouse</MenuItem>
                <MenuItem value="Consigners location">
                  Consigners location
                </MenuItem>
                <MenuItem value="3rd Party">3rd Party</MenuItem>
                <MenuItem value="Blockchain">Blockchain</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="internal-condition-notes-input">
                Sub-Location Notes
              </InputLabel>
              <Input
                id="internal-condition-notes-input"
                type="text"
                value={item.location_notes}
                onChange={(e) => {
                  handleLocationNotes(index, e.target.value);
                }}
                rows={4}
                multiline={true}
              />
            </FormControl>
            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="location-history-input">
                Location History
              </InputLabel>
              <Input
                id="location-history-input"
                type="text"
                value={item.location_history}
                onChange={(e) => {
                  handleChangeLocationHistory(index, e.target.value);
                }}
              />
            </FormControl>
            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <TextField
                id="datetime-local"
                label="Location Date"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={item.location_date}
                onChange={(e) => {
                  handleChangeLocationDate(index, e.target.value);
                }}
              />
            </FormControl>

            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="status-input">Stock Status</InputLabel>
              <Select
                labelId="label"
                id="status-input"
                value={item.stock_status}
                onChange={(e) => {
                  handleChangeStockStatus(index, e.target.value);
                }}
              >
                <MenuItem value="Available">Available</MenuItem>
                <MenuItem value="Consigned Out">Consigned out</MenuItem>
                <MenuItem value="Under offer">Under offer</MenuItem>
                <MenuItem value="Sold">Sold</MenuItem>
                <MenuItem value="Damaged">Damaged</MenuItem>
                <MenuItem value="Unavailable">Unavailable</MenuItem>
                <MenuItem value="Not for sale">Not for sale</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="status-input">Tax Status</InputLabel>
              <Select
                labelId="label"
                id="status-input"
                value={item.tax_status}
                onChange={(e) => {
                  handleChangeTaxStatus(index, e.target.value);
                }}
              >
                <MenuItem value="Exempt">Exempt</MenuItem>
                <MenuItem value="Standard Rate">Standard Rate</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="condition-notes-input">
                Website Condition Notes / Blockchain Category
              </InputLabel>
              <Input
                id="condition-notes-input"
                type="text"
                value={item.condition_notes}
                onChange={(e) => {
                  handleChangeConditionNotes(index, e.target.value);
                }}
              />
            </FormControl>
            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="internal-condition-notes-input">
                Internal Condition Notes
              </InputLabel>
              <Input
                id="internal-condition-notes-input"
                type="text"
                value={item.internal_condition_notes}
                onChange={(e) => {
                  handleChangeInternalConditionNotes(index, e.target.value);
                }}
                rows={4}
                multiline={true}
              />
            </FormControl>
            <FormControl
              style={{
                marginTop: '0px',
                marginBottom: '2vw'
              }}
              fullWidth={true}
              margin={'normal'}
              error={editionsError[index][1] !== ''}
              disabled
            >
              <InputLabel htmlFor="edition-number-input">Clean URL</InputLabel>
              <Input
                id="edition-number-input"
                type="text"
                value={item.slug}
                onChange={(e) => {
                  handleChangeSlugEdition(index, e.target.value);
                }}
              />
              <FormHelperText>{editionsError[index][1]}</FormHelperText>
            </FormControl>
            <FormControl
              style={{
                marginTop: '0px',
                marginBottom: '2vw'
              }}
              fullWidth={true}
              margin={'normal'}
              error={editionsError[index][2] !== ''}
              required
            >
              <InputLabel htmlFor="edition-number-input">
                Store Owner
              </InputLabel>
              <Select
                labelId="label"
                id="status-input"
                value={item.store_owner.id}
                onChange={(e) => {
                  handleChangeStore(index, e.target.value);
                }}
              >
                {stores.map((element) => (
                  // eslint-disable-next-line react/jsx-key
                  <MenuItem value={element.id}>{element.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{editionsError[index][2]}</FormHelperText>
            </FormControl>
            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="condition-notes-input">
                Acquired by
              </InputLabel>
              <Input
                id="acquired_from-input"
                type="text"
                value={item.acquired_from}
                onChange={(e) => {
                  handleChangeAcquiredBy(index, e.target.value);
                }}
              />
            </FormControl>
            <FormControl
              style={{
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <TextField
                id="acquisition_date"
                label="Acquired date"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={item.acquisition_date}
                onChange={(e) => {
                  handleChangeAcquiredDate(index, e.target.value);
                }}
              />
            </FormControl>
            {item.stock_status === 'Consigned Out' ? (
              <Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                    error={editionsError[index][3] !== ''}
                  >
                    <Autocomplete
                      value={item.consignor_owner}
                      onChange={(event: any, newValue: string | null) => {
                        handleChangeConsignor(index, newValue);
                      }}
                      inputValue={inputValueConsignorOwner[index]}
                      onInputChange={(event, newInputValue) => {
                        const x = [...inputValueConsignorOwner];
                        x[index] = newInputValue;
                        setInputValueConsignorOwner(x);
                      }}
                      id="controllable-states-demo"
                      options={users}
                      getOptionLabel={(option) => {
                        if (
                          option.first_name === undefined ||
                          option.last_name === undefined
                        ) {
                          return option.name;
                        } else
                          return option.first_name + ' ' + option.last_name;
                      }}
                      renderOption={(option) =>
                        option.name === undefined
                          ? option.first_name + ' ' + option.last_name
                          : option.name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Consignor/Third Party Owner"
                        />
                      )}
                    />
                    <FormHelperText>{editionsError[index][3]}</FormHelperText>
                  </FormControl>
                  <IconButton
                    onClick={() => {
                      setOpenClient(true);
                    }}
                  >
                    <AddIcon></AddIcon>
                  </IconButton>
                </Grid>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginBottom: '2vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <TextField
                    id="datetime-local"
                    label="Consignment Date"
                    type="datetime-local"
                    defaultValue={new Date()}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={item.consignment_date}
                    onChange={(e) => {
                      handleChangeConsignmentDate(index, e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginBottom: '2vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <TextField
                    id="datetime-local"
                    label="Return Consignment Date"
                    type="datetime-local"
                    defaultValue={new Date()}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={item.return_consignment_date}
                    onChange={(e) => {
                      handleChangeReturnConsignmentDate(index, e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginBottom: '2vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="artist-input">
                    Consignment Terms
                  </InputLabel>
                  <Select
                    labelId="label"
                    id="select"
                    value={item.consignment_terms}
                    onChange={(e) => {
                      handleChangeConsignmentTerms(index, e.target.value);
                    }}
                  >
                    <MenuItem value={'Percentage'}>Percentage</MenuItem>
                    <MenuItem value={'Net Value'}>Net Value</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginBottom: '2vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="internal-condition-notes-input">
                    Consignment Notes
                  </InputLabel>
                  <Input
                    id="internal-condition-notes-input"
                    type="text"
                    value={item.consignment_notes}
                    onChange={(e) => {
                      handleChangeConsignmentNotes(index, e.target.value);
                    }}
                    rows={4}
                    multiline={true}
                  />
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginBottom: '2vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="btn-upload-doc">
                    Consignment Documents
                  </InputLabel>
                  <label htmlFor="btn-upload" style={{ color: 'white' }}>
                    Select file
                  </label>

                  <Input
                    id="btn-upload-doc"
                    name="btn-upload-doc"
                    style={{ display: '' }}
                    type="file"
                    onChange={(e) => {
                      handleChangeConsignmentDocuments(index, e);
                    }}
                  />
                </FormControl>
              </Grid>
            ) : (
              ''
            )}
            <FormControl
              style={{
                marginTop: '0px',
                marginBottom: '2vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <InputLabel htmlFor="btn-upload">Certificate</InputLabel>
              <label htmlFor="btn-upload" style={{ color: 'white' }}>
                Select file
              </label>

              <Input
                id="btn-upload"
                name="btn-upload"
                style={{ display: '' }}
                type="file"
                onChange={(e) => {
                  handleChangeCertificate(index, e);
                }}
              />
            </FormControl>

            <FormControl
              style={{
                marginTop: '0px',
                marginBottom: '2vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <Autocomplete
                value={item.last_examined_by}
                onChange={(event: any, newValue: string | null) => {
                  handleChangeLastExamined(index, newValue);
                }}
                inputValue={inputValueLastExaminedBy[index]}
                onInputChange={(event, newInputValue) => {
                  const x = [...inputValueLastExaminedBy];
                  x[index] = newInputValue;
                  setInputValueLastExaminedBy(x);
                }}
                id="controllable-states-demo"
                options={waiters}
                getOptionLabel={(option) => {
                  if (
                    option.first_name === undefined ||
                    option.last_name === undefined
                  ) {
                    return option.username;
                  } else return option.first_name + ' ' + option.last_name;
                }}
                renderOption={(option) =>
                  option.username === undefined
                    ? option.first_name + ' ' + option.last_name
                    : option.username
                }
                renderInput={(params) => (
                  <TextField {...params} label="Last Examined By" />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        style={{
          display: valueForEditionsTabs === index ? 'flex' : 'none',
          flexDirection: 'row',
          position: 'absolute',
          top: 0,
          right: 0,
          marginTop: '1vw',
          marginRight: '4vw',
          height: '2.5vw'
        }}
      >
        <IconButton
          onClick={() => handleShowDeleteEditionModal(index)}
          color="secondary"
        >
          <DeleteIcon />
        </IconButton>
        <Button
          onClick={() => {
            duplicateEdition(item);
          }}
          variant="outlined"
          size="small"
          className={'activeButton'}
          style={{ marginLeft: '1vw' }}
        >
          Duplicate
        </Button>
      </Grid>
    </div>
  ));

  /**
 *   const handleChangeTagName = (index: any, value: any) => {
    setTags(
      tags.map((item) =>
        item.id === index
          ? {
              ...item,
              name: value
            }
          : item
      )
    );
  };

  const handleChangeBackGorund = (index: any, value: any) => {
    setTags(
      tags.map((item) =>
        item.id === index
          ? {
              ...item,
              background: value
            }
          : item
      )
    );
  };

  const handleChangeTagColor = (index: any, value: any) => {
    setTags(
      tags.map((item) =>
        item.id === index
          ? {
              ...item,
              color: value
            }
          : item
      )
    );
  };
  const handleDeletTag = (id: number) => {
    const values = [...tags];
    values.splice(id, 1);
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      element.id = index;
    }
    setTags(values);
  };
 */
  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <DeleteModal
          title={'Are you sure you want to delete this edition?'}
          handleConfirm={handleDeleteEditions}
          handleClose={handleCloseDeleteEditionModal}
          open={deleteEditionModal}
        />
        <ModalCreateTechnique
          open={openCreateTechnique}
          onClose={(number: number) => {
            if (number === 1) {
              _getTechniques();
            }
            setOpenCreateTechnique(false);
          }}
        />
        <ModalCreateArtist
          open={openCreateArtist}
          onClose={(number: number) => {
            if (number === 1) {
              _getArtist();
            }
            setOpenCreateArtist(false);
          }}
        />
        <ModalCreateClient
          open={openCreateClient}
          onClose={(number: number) => {
            if (number === 1) {
              _getUsers();
            }
            setOpenClient(false);
          }}
        />
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>Add Artwork</div>
            <div>
              <Button
                onClick={submitCreation}
                variant="outlined"
                color="primary"
                disabled={clicked}
              >
                Publish{' '}
              </Button>
            </div>
          </div>
        </DialogTitle>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginLeft: '2vw' }}
        >
          <Button
            onClick={() => {
              handleChange();
            }}
            variant="outlined"
            size="small"
            className={titleOn ? 'activeButton' : 'inactiveButton'}
          >
            MASTER
          </Button>
          <Button
            onClick={() => {
              handleChange();
            }}
            variant="outlined"
            size="small"
            className={editionsOn ? 'activeButton' : 'inactiveButton'}
            style={{ marginLeft: '1vw' }}
          >
            EDITIONS
          </Button>
        </div>
        <Container
          maxWidth="md"
          style={{ display: titleOn ? 'flex' : 'none', marginTop: '2vw' }}
        >
          <Grid container spacing={7}>
            {/* Start of left column */}
            <Grid item xs={12} sm={9}>
              <FormControl
                fullWidth={true}
                required={true}
                error={nameError !== ''}
              >
                <InputLabel htmlFor="name-input">Title</InputLabel>
                <Input
                  id="name-input"
                  type="string"
                  value={name}
                  onChange={handleName}
                />
                <FormHelperText>{nameError}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth={true}
                margin={'normal'}
                required={true}
                error={descriptionError !== ''}
              >
                <div
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '1.1vw',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '1vw'
                  }}
                >
                  Description
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ckEditorToolbarConfig
                  }}
                  data={description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                    setDescriptionError("")
                  }}
                />
                <FormHelperText>{descriptionError}</FormHelperText>
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="base-price-input">
                  Website Price
                </InputLabel>
                <Input
                  id="base-price-input"
                  type="number"
                  value={price}
                  onChange={(e) => {
                    handleChangeBasePriceAll(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} margin={'normal'}>
                <div
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '1.1vw',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '1vw'
                  }}
                >
                  Quote
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ckEditorToolbarConfig
                  }}
                  data={quote}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setQuote(data);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} margin={'normal'}>
                <InputLabel htmlFor="quote-signature-input">
                  Quote Signature
                </InputLabel>
                <Input
                  id="quote-signature-input"
                  type="string"
                  value={quoteSignature}
                  onChange={(e) => {
                    setQuoteSignature(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="edition-number-input">Year</InputLabel>
                <Input
                  id="edition-number-input"
                  type="text"
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                />
              </FormControl>
              <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl
                  style={{
                    marginTop: '0px'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="edition-number-input">Weight</InputLabel>
                  <Input
                    id="edition-number-input"
                    type="number"
                    value={weight}
                    onChange={(e) => {
                      setWeigt(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginLeft: '0.5vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="edition-number-input">Length</InputLabel>
                  <Input
                    id="edition-number-input"
                    type="number"
                    value={length}
                    onChange={(e) => {
                      setLength(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginLeft: '0.5vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="edition-number-input">Width</InputLabel>
                  <Input
                    id="edition-number-input"
                    type="number"
                    value={width}
                    onChange={(e) => {
                      setWidth(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginLeft: '0.5vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="edition-number-input">Height</InputLabel>
                  <Input
                    id="edition-number-input"
                    type="number"
                    value={height}
                    onChange={(e) => {
                      setHeight(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    marginRight: '1vw',
                    fontSize: '0.9vw',
                    fontWeight: 'lighter'
                  }}
                >
                  Additional dimensions
                </div>
                <IconButton onClick={handleAddDimensions}>
                  <AddIcon />
                </IconButton>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {additionalDimensions.map((element, index) => (
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '1vw'
                    }}
                    key={index}
                  >
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: '2px solid grey',
                        borderRadius: '0.3vw',
                        padding: '0.5vw'
                      }}
                    >
                      <FormControl
                        style={{
                          marginTop: '0px'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="edition-number-input">
                          Artwork
                        </InputLabel>
                        <Input
                          id="edition-number-input"
                          type="string"
                          value={element.artwork}
                          onChange={(e) => {
                            const x = [...additionalDimensions];
                            x[index].artwork = e.target.value;
                            setAdditionalDimensions(x);
                          }}
                        />
                      </FormControl>
                      <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControl
                          style={{
                            marginTop: '0px'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                        >
                          <InputLabel htmlFor="edition-number-input">
                            Weight
                          </InputLabel>
                          <Input
                            id="edition-number-input"
                            type="number"
                            value={element.weight}
                            onChange={(e) => {
                              const x = [...additionalDimensions];
                              x[index].weight = parseFloat(e.target.value);
                              setAdditionalDimensions(x);
                            }}
                          />
                        </FormControl>
                        <FormControl
                          style={{
                            marginTop: '0px',
                            marginLeft: '0.5vw'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                        >
                          <InputLabel htmlFor="edition-number-input">
                            Length
                          </InputLabel>
                          <Input
                            id="edition-number-input"
                            type="number"
                            value={element.length}
                            onChange={(e) => {
                              const x = [...additionalDimensions];
                              x[index].length = parseFloat(e.target.value);
                              setAdditionalDimensions(x);
                            }}
                          />
                        </FormControl>
                        <FormControl
                          style={{
                            marginTop: '0px',
                            marginLeft: '0.5vw'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                        >
                          <InputLabel htmlFor="edition-number-input">
                            Width
                          </InputLabel>
                          <Input
                            id="edition-number-input"
                            type="number"
                            value={element.width}
                            onChange={(e) => {
                              const x = [...additionalDimensions];
                              x[index].width = parseFloat(e.target.value);
                              setAdditionalDimensions(x);
                            }}
                          />
                        </FormControl>
                        <FormControl
                          style={{
                            marginTop: '0px',
                            marginLeft: '0.5vw'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                        >
                          <InputLabel htmlFor="edition-number-input">
                            Height
                          </InputLabel>
                          <Input
                            id="edition-number-input"
                            type="number"
                            value={element.height}
                            onChange={(e) => {
                              const x = [...additionalDimensions];
                              x[index].height = parseFloat(e.target.value);
                              setAdditionalDimensions(x);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <IconButton onClick={() => handleRemoveDimensions(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                {/* <FormLabel component="legend">Active</FormLabel> */}
                <FormControlLabel
                  control={
                    <Switch
                      value={active}
                      onChange={(e) => handleChangeAllActive(!active)}
                      name="active"
                      color="primary"
                    />
                  }
                  label="Active"
                />
              </FormControl>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <FormControl
                  fullWidth={true}
                  margin={'normal'}
                  required={true}
                  error={selectedArtistError !== ''}
                >
                  <InputLabel htmlFor="artist-input">Artist</InputLabel>
                  <Select
                    labelId="label"
                    id="select"
                    value={selectedArtist}
                    onChange={handleSelectedArtist}
                  >
                    {fetchedArtists?.map((artist) => (
                      <MenuItem value={artist.id}>{artist.name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{selectedArtistError}</FormHelperText>
                </FormControl>
                <IconButton
                  style={{ marginTop: '1vw' }}
                  onClick={() => {
                    setOpenCreateArtist(true);
                  }}
                >
                  <AddIcon></AddIcon>
                </IconButton>
              </Grid>
              <FormControl fullWidth={true} margin={'normal'}>
                <InputLabel htmlFor="artist-input">Template</InputLabel>
                <Select
                  labelId="label"
                  id="select"
                  value={template}
                  onChange={handleSelectedTemplate}
                >
                  <MenuItem value="SCULPTURE">SCULPTURE</MenuItem>
                  <MenuItem value="ART">ART</MenuItem>
                  <MenuItem value="NFT">NFT</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="edition-hs_code">HSCODE</InputLabel>
                <Input
                  id="edition-number-input"
                  type="text"
                  value={hs_code}
                  onChange={(e) => {
                    setHscode(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <div
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '1.1vw',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '1vw'
                  }}
                >
                  Signature type
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ckEditorToolbarConfig
                  }}
                  data={signatureType}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleSignatureType(data)
                  }}
                />
              </FormControl>

              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <div
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '1.1vw',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '1vw'
                  }}
                >
                  Edition Size / Blockchain Palm
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ckEditorToolbarConfig
                  }}
                  data={editionSize}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleEditionSize(data);
                  }}
                />
              </FormControl>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <FormControl
                  fullWidth={true}
                  margin={'normal'}
                  required
                  error={techniqueError !== ''}
                >
                  <Autocomplete
                    value={technique}
                    onChange={(event: any, newValue: string | null) => {
                      handleSelectedTechnique(newValue);
                    }}
                    inputValue={inputValueTechnique}
                    onInputChange={(event, newInputValue) => {
                      setInputValueTechnique(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={techniques}
                    getOptionLabel={(option) => {
                      if (option.name !== undefined) {
                        return option.name;
                      }
                    }}
                    renderOption={(option) =>
                      option.name !== undefined && option.name
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Technique *" />
                    )}
                  />
                  <FormHelperText>{techniqueError}</FormHelperText>
                </FormControl>
                <IconButton
                  style={{ marginTop: '1vw' }}
                  onClick={() => {
                    setOpenCreateTechnique(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
              <FormControl
                fullWidth={true}
                margin={'normal'}
                error={slugError !== ''}
                required
              >
                <InputLabel htmlFor="artist-input">Clean URL</InputLabel>
                <Input
                  id="edition-number-input"
                  type="text"
                  value={slug}
                  onChange={(e) => {
                    handleSlugs(e.target.value);
                  }}
                />
                <FormHelperText>{slugError}</FormHelperText>
              </FormControl>
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={page_tag}
                      onChange={() => {
                        setPageTag(!page_tag);
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Display on home page"
                />
              </Grid>
              <FormControl
                fullWidth={true}
                error={indexError !== ''}
                required={page_tag} // only required when the home tag is true
              >
                <InputLabel htmlFor="artist-input">
                  Homepage position
                </InputLabel>
                <Input
                  id="edition-number-input"
                  type="number"
                  value={index}
                  onChange={(e) => {
                    setIndex(e.target.value);
                    setIndexError('');
                  }}
                />
                <FormHelperText>{indexError}</FormHelperText>
              </FormControl>
              <Grid item xs={12} sm={9}>
                <FormControl fullWidth margin={'normal'}>
                  <InputLabel htmlFor="name-input">Colli number</InputLabel>
                  <Input
                    type="number"
                    id="name-input"
                    value={colliNumber}
                    onChange={(e) => setColliNumber(parseInt(e.target.value))}
                  />
                </FormControl>
              </Grid>
              <Grid
                style={{
                  fontWeight: 'lighter',
                  fontSize: '0.9vw',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '1vw'
                }}
              >
                <div>Add Artwork Services</div>
                <div style={{ marginLeft: '2vw' }}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => addExtras()}
                  >
                    <AddIcon></AddIcon>
                  </IconButton>
                </div>
              </Grid>
              {extras.map((element, index) => (
                <Grid
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    border: '2px solid grey',
                    borderRadius: '0.3vw',
                    padding: '1vw'
                  }}
                >
                  <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          width: '17.5vw'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="name-input">
                          Artwork Services
                        </InputLabel>
                        <Select
                          labelId="label"
                          id="current-location-input"
                          value={extras[index]}
                          onChange={(e) => {
                            handleProductExtras(e.target.value, index);
                          }}
                        >
                          {productExtras.map((element, index) => (
                            <MenuItem key={index} value={element.id}>
                              {element.ingredient}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          width: '17.5vw'
                        }}
                        fullWidth
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="name-input">Limit</InputLabel>
                        <Input
                          id="name-input"
                          type="number"
                          value={extrasLimit[index]}
                          onChange={(e) =>
                            handleProductExtrasLimit(e.target.value, index)
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          width: '17.5vw'
                        }}
                        fullWidth
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="name-input">Price</InputLabel>
                        <Input
                          id="name-input"
                          type="number"
                          value={productExtrasPrice[index]}
                          onChange={(e) =>
                            handleProductExtrasPrice(e.target.value, index)
                          }
                        />
                      </FormControl>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          width: '17.5vw'
                        }}
                        fullWidth
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="name-input">Quantity</InputLabel>
                        <Input
                          id="name-input"
                          type="number"
                          value={productExtrasQuantity[index]}
                          onChange={(e) =>
                            handleProductExtrasQuantity(e.target.value, index)
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => removeExtras(index)}
                    style={{ marginTop: '0.5vw' }}
                  >
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={'Drag and drop the website artwork images here'}
                  showPreviewsInDropzone={false}
                  onChange={(files) => handleImage(files)}
                  filesLimit={100}
                  maxFileSize={12000000}
                />
                <Typography variant="caption" style={{ color: '#fe309c' }}>
                  For better resolution insert images with 1000x1000
                </Typography>
                <Typography variant="caption" style={{ color: 'red' }}>
                  {artworkImagesError}
                </Typography>
              </ThemeProvider>
              {/* <input
                  type="file"
                  id="upload"
                  onChange={(files) => handleImage(files)}
                /> */}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {imagePaths.map((element, index) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '3vw',
                        marginLeft: index === 0 ? '0vw' : '2vw'
                      }}
                    >
                      <Tooltip
                        title="Delete"
                        onOpen={() => handleHovered(index)}
                        onClose={() => handleUnHovered(index)}
                        onClick={() => handleRemove(index)}
                      >
                        <div>
                          <img
                            src={element}
                            style={{
                              width: '3vw',
                              height: '3vw',
                              marginTop: '1vw',
                              opacity: deletedOn[index] === 1 ? '0.5' : '1',
                              cursor: deletedOn[index] === 1 ? 'pointer' : ''
                            }}
                          />
                        </div>
                      </Tooltip>
                      {deletedOn[index] === 1 ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <DeleteIcon
                            style={{
                              marginTop: '-2.8vw'
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                })}
              </div>
              {/* <div>
                  {imageSource.map((element) => {
                    <img src={element} />;
                  })}
                </div> */}
              {/* <FormControl>
                <InputLabel htmlFor="file-input">Image</InputLabel>
                <Input
                  id="file-input"
                  type="file"
                  value={image}
                  onChange={(e) => handleImage(e)}
                />
              </FormControl> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['image/*', 'application/pdf']}
                  dropzoneText={'Drag and drop the content sheet'}
                  showPreviewsInDropzone={false}
                  onChange={(files) => handleContentSheet(files)}
                  filesLimit={1}
                />
              </ThemeProvider>
              <div>{contentSheet?.name}</div>
            </Grid>{' '}
            <Grid
              item
              xs={12}
              sm={9}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['video/*']}
                  dropzoneText={'Drag and drop the website artwork video'}
                  showPreviewsInDropzone={false}
                  onChange={(files) => handleVideo(files)}
                  filesLimit={1}
                  maxFileSize={20000000}
                />
              </ThemeProvider>
              <div>{video?.name}</div>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth
                margin={'normal'}
              >
                <InputLabel htmlFor="name-input">
                  Artwork video description
                </InputLabel>
                <Input
                  multiline
                  id="name-input"
                  value={videoDescription}
                  onChange={(e) => setVideoDescription(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={9}>
              {' '}
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth
                margin={'normal'}
              >
                <InputLabel htmlFor="name-input">
                  Artwork video youtube URL
                </InputLabel>
                <Input
                  id="name-input"
                  value={youtubeVideo}
                  onChange={(e) => setYoutubeVideo(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Container>
        {editionsOn ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '2vw',
              marginLeft: '2vw',
              marginBottom: '1vw'
            }}
          >
            <Button
              onClick={() => {
                const z = editions.length + 1;
                const data = {
                  id: 0,
                  name: name !== '' ? name : '',
                  technique: {
                    id: 0,
                    name: ''
                  },
                  edition_number: editions.length + 1,
                  weight: '',
                  height: '',
                  length: '',
                  active: false,
                  width: '',
                  cost: 0,
                  slug: slug + '-' + z,
                  stock_status: 'Available',
                  base_price: price !== undefined ? price : 0,
                  tax_status: '',
                  condition_notes: '',
                  internal_condition_notes: '',
                  current_location: '',
                  location_details: '',
                  location_date: '',
                  consignment_date: '',
                  return_consignment_date: '',
                  consignment_terms: '',
                  third_party_base_price: 0,
                  consignment_documents: '',
                  certificate: '',
                  consignment_notes: '',
                  location_notes: '',
                  location_history: '',
                  store_owner: { id: 0, name: '' },
                  consignor_owner: { id: 0, name: '' },
                  last_examined_by: 0
                };
                if (editions.length >= 1) {
                  data.id = editions[editions.length - 1].id + 1;
                }
                setEditions(editions.concat(data));
                setValueForEdidtionsTabs(editions.length);
                let x = [...editionsError];
                x.push(['', '', '', '']);
                setEditionsError(x);
                x = [...inputValueConsignorOwner];
                x.push('');
                setInputValueConsignorOwner(x);

                x = [...inputValueLastExaminedBy];
                x.push('');
                setInputValueLastExaminedBy(x);
              }}
              variant="outlined"
              size="small"
              className={'activeButton'}
            >
              ADD EDITION
            </Button>
            {handleChunkIntoThreesOfButtons()}
          </div>
        ) : (
          ''
        )}
        {editionsOn ? listEditions : ''}
      </div>
    </Modal>
  );
}
