import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  IVoucherState,
  ETypesVoucher,
  IVoucherAction,
  IVoucherBase
} from 'interfaces/vouchers';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';
import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Authentication State. */
const initialState: IVoucherState = {
  fetch: false,
  next: '',
  previous: '',
  results: [],
  error: ''
};

/* Authentication Reducer. */
export default (
  state: IVoucherState = initialState,
  action: IVoucherAction
): IVoucherState => {
  switch (action.type) {
    case ETypesVoucher.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesVoucher.SUCCESS:
      return {
        ...state,
        fetch: false,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesVoucher.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    case ETypesVoucher.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchVoucher = (): IFetchAction => ({
  type: ETypesVoucher.FETCH
});

export const successVoucher = (payload: IVoucherState): ISuccessAction => ({
  type: ETypesVoucher.SUCCESS,
  payload
});

export const failureVoucher = (payload: string): IFailureAction => ({
  type: ETypesVoucher.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesVoucher.RESET
});

/* Authentication Side Effects Functions. */
export const getVouchers =
  (params: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        let url = `/orders/api/voucher_code/`;
        let response;
        let results: IVoucherBase[] = [];
        do {
          response = await fetch({
            method: EMethod.GET,
            url: url
          });
          url = response.next;
          results.push(...response.results);
        } while (response.next);
        response.results = results;
        dispatch(successVoucher(response));
      } catch (error) {
        dispatch(failureVoucher(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };

export const addVoucher =
  (data: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        let url = `/orders/api/voucher_code/`;
        let response;
        let results: IVoucherBase[] = [];
        response = await fetch({
          method: EMethod.POST,
          url: url,
          data
        });
        response.results = results;
        dispatch(sendAlert('Voucher created successfuly', EAlertVariant.SUCCESS));
        dispatch(getVouchers());

        return true;
      } catch (error) {
        dispatch(sendAlert('Something went wrong', EAlertVariant.ERROR));

        return false;
      }
    };
export const editVoucher =
  (data: {} = undefined, id: number): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        let url = `/orders/api/voucher_code/${id}/`;
        let response;
        let results: IVoucherBase[] = [];
        response = await fetch({
          method: EMethod.PATCH,
          url: url,
          data
        });
        response.results = results;
        dispatch(sendAlert('Voucher created successfuly', EAlertVariant.SUCCESS));
        dispatch(getVouchers());

        return true;
      } catch (error) {
        dispatch(sendAlert('Something went wrong', EAlertVariant.ERROR));

        return false;
      }
    };

export const deleteVoucher =
  (id: number): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        let url = `/orders/api/voucher_code/${id}/`;
        await fetch({
          method: EMethod.DELETE,
          url: url,
        });
        return true;
      } catch (error) {
        dispatch(sendAlert('Something went wrong', EAlertVariant.ERROR));

        return false;
      }
    };