import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

export const ItemMenu = styled(Link)`
  text-decoration: none;
  color: #00004c;
`;
export const StyleButton = styled.div`
  margin: 0 5px;
  color: #fff;
  border: none;
  background-color: #211f5c;
  background: #fff;
`;

export const StyleButtonHover = styled.div`
  &:hover {
    color: ${shade(0.2, '#fe309c')};
  }

  &:hover svg {
    color: ${shade(0.2, 'red')};
  }
`;

export const ExtendedMenuItem = styled.div`
  margin-left: 20px;
`;

export const ListItemText = styled.div`
  font-family: Archivo, sans-serif;
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 1rem;
  letter-spacing: 0.02em;
  color: black;
  &:hover {
    opacity: 0.8;
    color: #fe309c;
    cursor: pointer;
  }
`;
