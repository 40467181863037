import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  Container,
  DialogTitle,
  FormControl,
  // Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
// import { updateDhlShipmentDate } from 'redux/ducks/kitchen';
import { createDHLShipment } from 'redux/ducks/kitchen';
import moment from 'moment';

import { IKitchen } from 'interfaces/kitchen';
// import Print from 'components/Common/Print';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IProps {
  onClose: () => void;
  open: boolean;
  order: IKitchen;
  // createDHLShipment: () => void;
}

export default function ModalDhlCreateLabel(props: IProps) {
  const { onClose, open, order } = props;
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const handleClose = () => {
    setDate(moment(new Date()).format('YYYY-MM-DD'));

    onClose();
  };

  const [loading, setLoading] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);

  function submitCreateDHLShipment() {
    setLoading(true);

    const formatedDate = `${moment(date).format(
      'YYYY-MM-DD'
    )}T10:00:00 GMT+01:00`;

    // setShowSubmitButton(!showSubmitButton);
    setShowSubmitButton(false);
    dispatch(createDHLShipment(order.order_id, formatedDate));
    pageRefresh();
  }

  const pageRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  const DatePicker = () => {
    return (
      <>
        <div
          style={{
            color: 'red',
            marginBottom: '1rem',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography variant="caption">DHL DATE RULES:</Typography>
          <Typography variant="caption">
            - Can't be booked for previous days
          </Typography>
          <Typography variant="caption">
            - Can't be booked for the same day or weekend
          </Typography>
          <Typography variant="caption">
            - Can't be booked beyond 10 days from today
          </Typography>
        </div>
        <FormControl
          style={{
            marginTop: '0px',
            maxWidth: '30%'
          }}
          fullWidth={true}
          margin={'normal'}
        >
          <TextField
            name="date"
            id="date"
            label="Date"
            type="date"
            aria-readonly
            ready-only
            InputLabelProps={{
              shrink: true
            }}
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
        </FormControl>
      </>
    );
  };

  const verifyLabelExistence = () => {
    if (order.items[0].dhl_label) {
      return true;
    } else {
      return false;
    }
  };

  const parseBase64ToPdf = (base64Label, productName) => {
    // The Base64 string of a simple PDF file
    const b64 = base64Label;

    // Decode Base64 to binary and show some information about the PDF file (note that I skipped all checks)
    // const bin = atob(b64);
    // console.log('File Size:', Math.round(bin.length / 1024), 'KB');
    // console.log('PDF Version:', bin.match(/^.PDF-([0-9.]+)/)[1]);

    // Embed the PDF into the HTML page and show it to the user
    const obj = document.createElement('object');
    obj.type = 'application/pdf';
    obj.data = 'data:application/pdf;base64,' + b64;

    // Insert a link that allows the user to download the PDF file
    const link = document.createElement('a');
    link.style.display = 'none';
    link.innerHTML = 'Download PDF file';
    link.download = `${productName}-dhl-label.pdf`;
    link.href = 'data:application/octet-stream;base64,' + b64;
    document.body.appendChild(link);
    link.click();

    return null;
  };
  const generateLabelPdf = (base64Label: string, productName: string) => {
    return (
      <Button
        onClick={() => parseBase64ToPdf(base64Label, productName)}
        variant="outlined"
        color="primary"
      >
        Download
      </Button>
    );
  };
  const RenderLabel = () => {
    const hasLabels = verifyLabelExistence();

    return (
      <>
        {!hasLabels ? (
          <Typography variant="caption">
            No labels have been created yet.
          </Typography>
        ) : (
          order.items.map((item) => (
            <>
              <Typography variant="h6">{item.product.name}</Typography>
              {generateLabelPdf(item.dhl_label, item.edition.slug)}
            </>
          ))
        )}
      </>
    );
  };

  const tabTitle = () => {
    if (verifyLabelExistence()) {
      return 'DHL Labels';
    }
    return 'Setup the DHL planned shipping date';
  };

  const renderPanelContent = () => {
    if (loading) {
      return <Typography variant="caption">Please wait...</Typography>;
    }
    const hasLabels = verifyLabelExistence();
    if (hasLabels) {
      return <RenderLabel />;
    } else {
      return <DatePicker />;
    }
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>{tabTitle()}</div>

            {loading ? (
              <CircularProgress />
            ) : !verifyLabelExistence() ? (
              showSubmitButton ? (
                <Button
                  onClick={() => submitCreateDHLShipment()}
                  variant="outlined"
                  color="primary"
                >
                  Submit
                </Button>
              ) : null
            ) : null}
          </div>
        </DialogTitle>
        <Container maxWidth="md">{renderPanelContent()}</Container>
      </div>
    </Modal>
  );
}
