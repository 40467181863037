import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export interface Homepage {
  vc1_preview_video: string;
  vc1_youtube_video_url: string;
  vc1_artist_name: string;
  vc1_video_title: string;
  vc1_year: string;
  vc1_art_edition: string;
  vc1_presented_by: string;
  vc2_preview_video: string;
  vc2_youtube_video_url: string;
  vc2_artist_name: string;
  vc2_video_title: string;
  vc2_year: string;
  vc2_art_edition: string;
  vc2_presented_by: string;
  id: number;
  latest_image: string;
  latest_title: string;
  latest_hover_text: string;
  latest_url: string;
  featured_artist_title: string;
  featured_artist_image: string;
  featured_artist_page_url: string;
  services_image_title: string;
  services_title: string;
  services_hover_text: string;
  social_image: string;
  social_title: string;
  social_link: string;
  gifting_url: string;
  gifting_first_message: string;
  gifting_second_message: string;
  whatsapp: string;
  wechat: string;
  email: string;
  instagram: string;
  facebook: string;
  previewVideo: string;
  youtubeURL: string;
  videoartistName: string;
  videoTitle: string;
  videoYear: string;
  videoArtEdition: string;
  videoPresented: string;
}

export interface IHomepageState {
  fetch: boolean;
  count: number;
  next?: string;
  previous?: string;
  results: Homepage[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesHomepage {
  FETCH = 'HOMEPAGE/FETCH',
  SUCCESS = 'HOMEPAGE/SUCCESS',
  FIRSTLOAD = 'HOMEPAGE/FIRSTLOAD',
  FAILURE = 'HOMEPAGE/FAILURE',
  SEARCH = 'HOMEPAGE/SEARCH'
}

export interface IFetchAction extends IBaseAction<ETypesHomepage, null> {
  type: ETypesHomepage.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesHomepage, IHomepageState> {
  type: ETypesHomepage.SUCCESS;
  payload: IHomepageState;
}

export interface IFirstLoadAction
  extends IBaseAction<ETypesHomepage, IHomepageState> {
  type: ETypesHomepage.FIRSTLOAD;
  payload: IHomepageState;
}

export interface IFailureAction extends IBaseAction<ETypesHomepage, string> {
  type: ETypesHomepage.FAILURE;
  payload: string;
}

export type IHomepageAction =
  | IFetchAction
  | ISuccessAction
  | IFirstLoadAction
  | IFailureAction;

export type GetState = () => any;

export type PromiseAction = Promise<IHomepageAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IHomepageAction
    | ThunkAction
    | PromiseAction
    | Array<IHomepageAction>
    | IAlertAction
) => any;
