import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
import { Button, InputLabel, Input, IconButton } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createCurrency } from 'redux/ducks/currency';
import CloseIcon from '@material-ui/icons/Close';
import '../Stock/styles.css';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  getData: () => void;
}

export default function ModalCreateCurrency(props: SimpleDialogProps) {
  const { onClose, open, getData } = props;
  const [name, setName] = React.useState('');
  const [rate, setRate] = React.useState(0);

  const dispatch = useDispatch();

  const handleClose = () => {
    setName('');
    setRate(0);
    onClose();
  };

  const handleName = (event: any) => {
    setName(event.target.value);
  };
  const handleRate = (event: any) => {
    setRate(event.target.value);
  };
  const submitCreation = async () => {
    dispatch(
      createCurrency({
        name,
        rate
      })
    ).then((result: boolean) => {
      if (result) {
        console.log('veio aqui');

        getData();
        handleClose();
      }
    });
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="simple-dialog-title">Add Currency</DialogTitle>
        <FormControl style={{ margin: '0px 10px' }}>
          <InputLabel htmlFor="name-input">Name</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={name}
            onChange={handleName}
          />
        </FormControl>
        <FormControl style={{ margin: '0px 10px' }}>
          <InputLabel htmlFor="name-input">Rate</InputLabel>
          <Input
            id="name-input"
            type="number"
            value={rate}
            onChange={handleRate}
          />
        </FormControl>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={submitCreation} color="primary">
            {' '}
            Create{' '}
          </Button>
          <Button onClick={handleClose}> Cancel </Button>
        </div>
      </div>
    </Modal>
  );
}
