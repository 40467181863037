import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import store from './redux/';

import { APP_NAME } from 'settings';

import Routes from './components/Router';
import PrivateRoute from './components/Router/PrivateRoute';
import Authentication from './components/Authentication';
import ForgotPassword from 'components/Authentication/ForgotPassword';
import SetPassword from 'components/Authentication/SetPassword';

import Alert from './components/Global/Alert';
import { withStyles } from '@material-ui/core';

const App: React.FC = () => {
  useEffect(() => {
    document.title = APP_NAME;
  }, []);
  const GlobalCss = withStyles({
    '@global': {
      '.MuiFormLabel-root.Mui-focused': {
        color: 'black'
      }
    }
  })(() => null);
  useEffect(() => {
    if (!store.getState().authentication.authorized && window.location.pathname.includes("dashboard")) {
      window.location.pathname = "/"
    }
  }, [store.getState().authentication.authorized])
  return (
    <Provider store={store}>
      <GlobalCss />
      <Alert />
      <Router>
        <Switch>
          <Route path="/" exact component={Authentication} />
          <PrivateRoute path="/dashboard" component={Routes} />

          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/set-password" exact component={SetPassword} />
          <Route
            render={() => (
              <>
                <h3 className="text-center">Page Not Found</h3>
              </>
            )}
          />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
