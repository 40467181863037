import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const cardStyles = {
  // border: '1px dashed gray',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
  display: 'flex',
  alignItems: 'center'
};

const indexStyles = {
  padding: '0 1rem'
};

const Card = ({ id, text, index, moveCard }) => {
  const ref = useRef(null);
  const ItemTypes = {
    CARD: 'card'
  };
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const readableIndex = index + 1;

  return (
    <div
      ref={ref}
      style={{ ...cardStyles, opacity }}
      data-handler-id={handlerId}
    >
      <div style={{ ...indexStyles, opacity }}>{readableIndex}</div>
      <img
        src={text}
        style={{
          width: '5vw',
          height: '5vw'
        }}
      />
    </div>
  );
};

export default Card;
