import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@material-ui/core';
import { Customer } from 'interfaces/customersAndSellers';
import { formatCellPhone } from '../../helpers/common';
import CustomerModal from './customerOrders';
import { getCustomerOrders } from 'redux/ducks/customersAndSellers';
import CustomerEditModal from './editCustomerModal';
import WishlistModal from './WishlistModal'
const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface Props {
  customers: Customer[];
  handleLastOrder: (orderId: number) => void;
  fetchData: () => void;
}

export default function ClientsTable(props: Props) {
  const classes = useStyles();
  const { customers, handleLastOrder, fetchData } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [editModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [customerData, setCustomerData] = useState<any>({});
  const [wishlistOpen, setWishlistOpen] = useState<any>(false);
  const [wishlistCustomer, setWishlistCustomer] = useState({
    name: "",
    id: 0,
    wishlist: []
  })
  const openCustomerModal = async (customer_name) => {
    setData([]);
    setLoading(true);
    setModal(true);
    const _customer: Customer = customers.filter(
      (customer) => customer.name === customer_name
    )[0];
    setCustomerData(_customer);
    for (let index = 0; index < _customer['orders_list'].length; index++) {
      const element = _customer['orders_list'][index];
      var res = await getCustomerOrders(element);
      if (res) {
        setData((data) => [...data, res]);
      }
    }
    setLoading(false);
  };
  const openCustomerEditModal = async (customer_email) => {
    setLoading(true);
    setEditModal(true);
    const _customer: Customer = customers.filter(
      (customer) => customer.email === customer_email
    )[0];
    setCustomerData(_customer);

    setLoading(false);
  };
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Phone</TableCell>
            <TableCell align="right">Orders</TableCell>
            <TableCell align="right">Wishlist</TableCell>
            <TableCell align="right">Total (€)</TableCell>
            <TableCell align="right">To Pay (€)</TableCell>
            <TableCell align="right">Profit to date (€)</TableCell>
            <TableCell align="right">Average per Order (€)</TableCell>
            <TableCell align="right">Last Order (€)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers ? (
            <>
              {customers.map((customer) => (
                <TableRow key={customer.email + customer.phone_number}>
                  <TableCell
                    onClick={() => openCustomerEditModal(customer.email)}
                    component="th"
                    scope="row"
                    style={{ color: 'rgb(254, 48, 156)', cursor: 'pointer' }}
                  >
                    {' '}
                    {customer.name}
                  </TableCell>
                  <TableCell align="right">
                    {customer.phone_number
                      ? formatCellPhone(customer.phone_number)
                      : ''}
                  </TableCell>
                  <TableCell
                    onClick={() => openCustomerModal(customer.name)}
                    align="right"
                    style={{ color: 'rgb(254, 48, 156)', cursor: 'pointer' }}
                  >
                    <Button style={{ color: 'rgb(254, 48, 156)', cursor: 'pointer' }}>{customer.orders_placed}</Button>

                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setWishlistOpen(true)
                      setWishlistCustomer({
                        name: customer.name,
                        id: customer.id,
                        wishlist: customer.wishlist
                      })
                    }}
                    align="right"
                    style={{ color: 'rgb(254, 48, 156)', cursor: 'pointer' }}
                  >
                    {customer.wishlist ? <Button style={{ color: 'rgb(254, 48, 156)', cursor: 'pointer' }}>
                      {customer?.wishlist.length}
                    </Button> : <Button style={{ color: 'rgb(254, 48, 156)', cursor: 'pointer' }}>
                      0
                    </Button>}
                  </TableCell>
                  <TableCell align="right">
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'EUR'
                    }).format(customer.total_billed)}
                  </TableCell>
                  <TableCell align="right">
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'EUR'
                    }).format(customer.outstanding_to_pay)}
                  </TableCell>
                  <TableCell align="right">
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'EUR'
                    }).format(
                      customer.total_billed - customer.outstanding_to_pay
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'EUR'
                    }).format(customer.value_of_order_average)}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      style={{ color: 'rgb(254, 48, 156)' }}
                      onClick={() => handleLastOrder(customer.last_order.id)}
                    >
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'EUR'
                      }).format(customer.last_order.total_order)}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : null}
        </TableBody>
      </Table>
      {customerData && (
        <CustomerModal
          open={modal}
          handleClose={() => setModal(false)}
          data={data}
          loading={loading}
          customer={customerData}
        />
      )}
      {customerData && (
        <CustomerEditModal
          open={editModal}
          handleClose={() => setEditModal(false)}
          loading={loading}
          customer={customerData}
          fetchData={() => fetchData()}
        />
      )}
      <WishlistModal open={wishlistOpen} handleClose={() => { setWishlistOpen(false) }} customer={wishlistCustomer} />
    </TableContainer>
  );
}
