import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export interface Icurrency {
  name: string;
  rate: number;
  id:number;
}

export interface ICurrencyState {
  fetch?: boolean;
  results: Icurrency[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesCurrency {
  FETCH = 'CURRENCY/FETCH',
  SUCCESS = 'CURRENCY/SUCCESS',
  FAILURE = 'CURRENCY/FAILURE'
}

export interface IFetchAction extends IBaseAction<ETypesCurrency, null> {
  type: ETypesCurrency.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesCurrency, ICurrencyState> {
  type: ETypesCurrency.SUCCESS;
  payload: ICurrencyState;
}

export interface IFailureAction extends IBaseAction<ETypesCurrency, string> {
  type: ETypesCurrency.FAILURE;
  payload: string;
}

export type ICurrencyAction = IFetchAction | ISuccessAction | IFailureAction;

export type GetState = () => any;

export type PromiseAction = Promise<ICurrencyAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | ICurrencyAction
    | ThunkAction
    | PromiseAction
    | Array<ICurrencyAction>
    | IAlertAction
) => any;
