import React from 'react';
import { IBalcony } from 'interfaces/balcony';
import Grid from '@material-ui/core/Grid';

// import { v4 as uuidv4 } from 'uuid';
import { IProduction } from 'interfaces/production';
// import { useStyles } from './styles';
import Order from './Order';
import Production from './Production';
import { orderTypes } from './index';

interface IProps {
  orders: IBalcony[];
  production: IProduction[];
  ingredients: IProduction[];
  activeOrders: string;
  handleActiveOrders: Function;
  orderType: orderTypes[];
  searchID: string;
  initialDate: Date;
  finalDate: Date;
  isFiltering: Boolean;
  filter: any;
  setSearchID: any;
  setInitialDate: any;
  setFinalDate: any;
  clearFilter: any;
  message: string;
}

export default function Panel(props: IProps): JSX.Element {
  const {
    orders,
    production,
    ingredients,
    activeOrders
    // handleActiveOrders,
    // orderType,
  } = props;

  return (
    <Grid item container xs={12} sm={12} md={12} lg={12} direction="column">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: 15,
          padding: 15,
          borderRadius: 5
        }}
      >
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '1.5vw',
            fontWeight: 'bold'
          }}
        >
          {orders?.length > 0 ? '' : 'You have no orders yet'}
        </Grid>
      </div>
      {activeOrders === orderTypes.ENCOMENDAS && <Order orders={orders} />}
      {activeOrders === orderTypes.PRODUCAO && (
        <Production orders={production} />
      )}
      {activeOrders === orderTypes.COMPRAS && (
        <Production orders={ingredients} />
      )}
    </Grid>
  );
}
