import { IKitchen } from 'interfaces/kitchen';
import { EKitchenStatus, EOrderType } from 'enums/order';

import differenceInSeconds from 'date-fns/differenceInSeconds';

export function checkOrderStatus(status: string): any {
  const types = {
    [EKitchenStatus.TODO]: 'todo',
    [EKitchenStatus.DOING]: 'doing',
    [EKitchenStatus.DONE]: 'done',
    [EKitchenStatus.DEPOT]: 'depot',
    [EKitchenStatus.BAKING]: 'baking',
    [EKitchenStatus.TRANSIT]: 'transit',
    [EKitchenStatus.COMPLETED]: 'completed',
    [EKitchenStatus.CANCELLED]: 'canceled'
  };
  return types[status];
}

export function setNewOrder(order: IKitchen, orders: IKitchen[]) {
  const newOrder: IKitchen[] = [order, ...orders].sort(
    (a, b) => a.order_id - b.order_id
  );
  return newOrder;
}

export function unsetOldOrder(order: IKitchen, orders: IKitchen[]): IKitchen[] {
  const newOrder: IKitchen[] = orders;
  return newOrder
    .filter((item) => item.order_id !== order.order_id)
    .sort((a, b) => a.order_id - b.order_id);
}

export function getDateByStatus(status: string, order: IKitchen) {
  if (!status) return new Date();
  switch (status) {
    case EKitchenStatus.TODO:
      return order.date_ordered;
    case EKitchenStatus.DOING:
      return order.doing_at;
    case EKitchenStatus.DONE:
      return order.done_at;
    case EKitchenStatus.DEPOT:
      return order.depot_at;
    case EKitchenStatus.COMPLETED:
      return order.finished_at;
    case EKitchenStatus.CANCELLED:
      return order.canceled_at;
    default:
      return new Date();
  }
}

export function hideButtonByDate(order: IKitchen): boolean {
  const currentStatusDate = getDateByStatus(order.status, order);
  const diffSeconds = differenceInSeconds(
    new Date(),
    new Date(currentStatusDate)
  );
  return diffSeconds <= 30;
}

export function getDeliveryType(type: string): string {
  return EOrderType[type.split('-')[1]] || '';
}

export function getWhastappUrl(
  order: IKitchen,
  driverPhone: string,
  addressEncoded: string
) {
  const br = '%0a';
  let message = `*Order Nº*: ${order.order_code}${br}`;
  message += `*CLIENT*: ${order.owner?.name ?? order.client?.name}${br}`;
  message += `*PAYMENT TYPE*: ${order.payment}${br}`;
  message += `*VALUE OF THE ORDER*: ${order.total_order}${br}`;
  message += `*ADDRESS*: ${order.delivery_address}${br}`;

  if (order.cash_paid) {
    message += `*MONEY AMOUNT TO BE PAID*: ${order.cash_paid}${br}`;
    message += `*CHANGE*: ${order.cash_change}${br}`;
  }

  message += `*ITEMS*:${br}`;

  order.items.forEach((item) => {
    message += `${item?.quantity} x ${item?.product?.name}${br}`;
    item?.extra_orders.forEach((extra) => {
      message += `  ${extra?.quantity} x ${extra?.ingredient} Extra${br}`;
    });
  });

  message += `*Link*: https://maps.google.com/?q=${addressEncoded}${br}`;

  return `https://web.whatsapp.com/send?phone=55${driverPhone}&text=${message}&source&data`;
}
