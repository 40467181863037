import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Artist } from 'interfaces/artist';
import { deleteArtist, fetchArtists } from 'redux/ducks/artists';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  artist: Artist;
}

export default function ModalDeleteArtist(props: SimpleDialogProps) {
  const { onClose, open, artist } = props;

  const dispatch = useDispatch();

  const handleClose = (number: number) => {
    if (number === 1) {
      dispatch(fetchArtists());
    }
    onClose();
  };

  const submitDelete = async () => {
    dispatch(deleteArtist(artist?.id)).then((result: number) => {
      if (result === 1) {
        handleClose(1);
      } else {
        handleClose(0);
      }
    });
  };

  return (
    <Dialog
      onClose={() => handleClose(0)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Delete Artist {artist?.name}?
      </DialogTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={submitDelete} color="primary">
          {' '}
          Delete{' '}
        </Button>
        <Button onClick={() => handleClose(0)}> Cancel </Button>
      </div>
    </Dialog>
  );
}
