import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 10,
      position: 'fixed',
      top: theme.spacing(8.1),
      left: theme.spacing(2),
      width: '100%'
    }
  })
);
