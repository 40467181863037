/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/interface-name-prefix */
import React, { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
import { fetch } from './../../utils/request';
import { EMethod } from './../../enums/method';
import { sendAlert } from './../../redux/ducks/alert';
import CircularProgress from '@material-ui/core/CircularProgress';

// import { Image, Transformation } from 'cloudinary-react';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import { Editions } from 'interfaces/stock';
import moment from 'moment';
import { EAlertVariant } from 'interfaces/alert';

//Overrides theme of Dropzone component

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  edition: Editions;
}

export default function ModalCreateProduct(props: SimpleDialogProps) {
  const { onClose, open, edition } = props;
  const [orderModal, setorderModal] = useState(false);
  const [selectedOrderData, setSelectedOrderData] = useState(null);
  const handleClose = () => {
    onClose();
  };

  const getOrderData = async (id) => {
    await fetch({
      method: EMethod.GET,
      url: `/orders/api/orders/${id}/get_order/`
    })
      .then((response) => {
        setorderModal(true);
        setSelectedOrderData(response);
        console.log('response - ', response);
      })
      .catch((e) => {
        console.log(e);
        if (e === 'Not found') {
          e = 'Order not found';
        }
        sendAlert(e, EAlertVariant.ERROR);
      });
  };

  const isLastId = (el) => {
    var lastId = '';
    edition.location_history_logs.forEach((ed) => {
      if (ed.what === 'Order ID') {
        lastId = ed.after;
      }
    });
    return el === lastId;
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>
              {edition?.name ? edition.name + `'s` : `Edition's`} history logs
            </div>
          </div>
          <div>
            {edition?.location_history_logs.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>What</TableCell>
                    <TableCell>Before</TableCell>
                    <TableCell>After</TableCell>
                    <TableCell>Created at</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {edition?.location_history_logs.map((element, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>{element.what}</TableCell>
                        <TableCell>{element.before}</TableCell>
                        <TableCell>
                          {element.what === 'Order ID' &&
                          isLastId(element.after) ? (
                            <Button
                              onClick={() => {
                                getOrderData(element.after);
                              }}
                            >
                              {element.after}
                            </Button>
                          ) : (
                            element.after
                          )}
                        </TableCell>
                        <TableCell>
                          {element.created_at
                            ? moment(new Date(element.created_at)).format('ll')
                            : ''}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <div
                style={{
                  marginTop: '2vw',
                  marginLeft: 'auto',
                  fontSize: '1vw',
                  fontWeight: 'lighter'
                }}
              >
                There aren't any logs yet
              </div>
            )}
          </div>
        </DialogTitle>
        <Modal
          open={orderModal}
          onClose={() => setorderModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',

              width: '30vw'
            }}
          >
            {selectedOrderData ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '8px'
                }}
              >
                <Typography variant="h5">
                  {selectedOrderData?.order_code}
                </Typography>
                <Typography>{selectedOrderData?.owner?.name}</Typography>
                <Typography>
                  {selectedOrderData?.invoice_date
                    ? moment(selectedOrderData?.invoice_date).format('ll')
                    : moment(selectedOrderData?.date_created).format('ll')}
                </Typography>
                <Typography>{selectedOrderData?.payment_method}</Typography>
                <Typography>
                  {(selectedOrderData?.total_order).toLocaleString('en-IN', {
                    style: 'currency',
                    currency: selectedOrderData?.currency
                  })}
                </Typography>
                {selectedOrderData?.currency}
              </div>
            ) : (
              <CircularProgress
                style={{ height: '30px', width: '30px', marginBottom: '20px' }}
              />
            )}
          </div>
        </Modal>
      </div>
    </Modal>
  );
}
