import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { fetchMenusGeneral } from 'redux/ducks/menu';
import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import MenusTable from './MenusTable';

export default function Menus(): JSX.Element {
  const dispatch = useDispatch();
  const { menu } = useSelector<IStore, IStore>((state) => state);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    if (control) {
      control.forEach((c) => {
        c.style.color = 'black';
      });
    }

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    if (icon) {
      icon.forEach((c) => {
        c.style.color = '#BBBBBB';
      });
    }

    const d = document.querySelectorAll<HTMLElement>('.d-menus');
    if (d.item(0)) {
      d.item(0).style.color = '#fe309c';

      d.item(1).style.color = '#fe309c';
    }
    const dText = document.querySelectorAll<HTMLElement>('.d-menus-text');
    if (dText.item(0)) {
      dText.item(0).style.color = '#fe309c';
    }
  });

  useEffect(() => {
    dispatch(fetchMenusGeneral());
  }, [dispatch]);

  return (
    <>
      <Loading show={menu.fetch} />
      <Grid container spacing={3}>
        <MenusTable menus={menu.menus} />
      </Grid>
    </>
  );
}
