import {
  IHistory,
  IHistoryState,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  ISearchAction,
  IHistoryAction,
  Dispatch,
  ThunkAction,
  ETypesHistory,
  IFirstLoadAction,
  GetState
} from 'interfaces/history';

import { EAlertVariant } from 'interfaces/alert';

import { EMethod } from 'enums/method';

import { sendAlert } from './alert';
import { fetch } from 'utils/request';
import { mountQueryURL } from 'utils/query';
/* History State. */
const initialState: IHistoryState = {
  fetch: false,
  count: 0,
  results: [],
  next: '',
  previous: '',
  error: ''
};

/* History Reducer. */
export default (
  state: IHistoryState = initialState,
  action: IHistoryAction
): IHistoryState => {
  switch (action.type) {
    case ETypesHistory.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesHistory.FIRSTLOAD:
      return {
        ...state,
        fetch: false,
        count: action.payload.count,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesHistory.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesHistory.SEARCH:
      return {
        ...state,
        fetch: false,
        count: 0,
        results: [action.payload],
        next: '',
        previous: '',
        error: ''
      };
    case ETypesHistory.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* History Action Creators Functions. */
export const fetchHistory = (): IFetchAction => ({
  type: ETypesHistory.FETCH
});

export const successHistory = (payload: IHistoryState): ISuccessAction => ({
  type: ETypesHistory.SUCCESS,
  payload
});

export const firstLoadHistory = (payload: IHistoryState): IFirstLoadAction => ({
  type: ETypesHistory.FIRSTLOAD,
  payload
});

export const failureHistory = (payload: string): IFailureAction => ({
  type: ETypesHistory.FAILURE,
  payload
});

export const searchHistory = (payload: IHistory): ISearchAction => ({
  type: ETypesHistory.SEARCH,
  payload
});

/* History Side Effects Functions. */
export const getHistory =
  (params: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { history } = getState();
      let request_url;
      dispatch(fetchHistory());
      const query = params ? mountQueryURL(params) : '';
      request_url = `/waiter/api/waiter/get_orders_between_dates/${query}`;
      const response = await fetch({
        method: EMethod.GET,
        url: request_url
      });
      let results: IHistory[] = [];
      results.push(...response);

      dispatch(
        successHistory({ ...history, results: results.sort().reverse() })
      );
    } catch (error) {
      dispatch(failureHistory(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const getCustomerOrders = async (customer_id: {} = undefined) => {
  new Promise<any>(async (resolve, reject) => {
    try {
      let request_url;
      request_url = `/orders/api/orders/client_orders/`;
      const response = await fetch({
        method: EMethod.GET,
        url: request_url
      });
      let results: IHistory[] = [];
      results.push(...response);
      results = results.filter(
        (result) => result.owner.profile_id === customer_id
      );
      resolve(results);
    } catch (error) {
      reject(error);
    }
    new Promise<void>((resolve, reject) => {});
  });
};
const patchProducts = async (data) => {
  return new Promise<void>(async (resolve, reject) => {
    data['products'].map(async (element: any, index: number) => {
      if (element.changedPrice) {
        //  const product_patch_url = `products/api/editions/${element.edition_id}/`;
        var edition_data = {};
        switch (data['infos']['currency']) {
          case 'EUR':
            edition_data['price_eur'] = element.value;
            break;
          case 'USD':
            edition_data['base_price'] = element.value;
            break;
          case 'GBP':
            edition_data['price_gbp'] = element.value;
            break;
          case 'CHF':
            edition_data['price_chf'] = element.value;
            break;
          default:
            edition_data['base_price'] = element.value;
            break;
        }
        await fetch({
          headers: { 'content-type': 'application/json' },
          method: EMethod.POST,
          url: 'products/api/products/reciprocal_currency/',
          data: {
            invoice_date: data['infos']['invoice_date'],
            invoice_currency: data['infos']['currency'].toLowerCase(),
            edition_value: element.value,
            edition_id: element.edition_id
          }
        }).then(() => {
          delete data['products'][index]['value'];
          delete data['products'][index]['changedPrice'];
          if (index === data['products'].length - 1) {
            resolve();
          }
        });
      } else if (index === data['products'].length - 1) {
        resolve();
      }
    });
  });
};
export const createOrder =
  (data: {} = undefined, query: any): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState) => {
    return new Promise<void>((resolve, reject) => {
      try {
        const { history } = getState();
        dispatch(fetchHistory());
        var billingAddress = data['billing'];
        if (billingAddress) {
          delete data['billing'];
          const billing_address_post_url = `users/api/address/waiter_create_address/`;
          fetch({
            headers: { 'content-type': 'application/json' },
            method: EMethod.POST,
            url: billing_address_post_url,
            data: billingAddress
          });
        }
        patchProducts(data).then(async () => {
          data['products'].map((item, index) => {
            delete data['products'][index]['value'];
            delete data['products'][index]['changedPrice'];
          });
          if (data['infos']['type_of_order'] !== 'Collection') {
            await fetch({
              headers: { 'content-type': 'application/json' },
              method: EMethod.POST,
              url: '/users/api/address/waiter_create_delivery_address/',
              data: data['address']
            });
            delete data['address']['active'];
          }
          const request_url = `/orders/api/orders/`;
          await fetch({
            headers: { 'content-type': 'application/json' },
            method: EMethod.POST,
            url: request_url,
            data
          })
            .then((res) => {
              dispatch(
                sendAlert('Invoice created successfully', EAlertVariant.SUCCESS)
              );
              dispatch(successHistory({ ...history }));
              dispatch(getHistory(query));
              //window.location.reload();
              resolve();
            })
            .catch((e) => {
              dispatch(failureHistory(e));
              dispatch(sendAlert(e, EAlertVariant.ERROR));
              reject();
            });
        });
      } catch (error) {
        dispatch(failureHistory(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        reject();
      }
    });
  };

export const addAddress =
  (data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      const request_url = `users/api/address/waiter_create_address/`;

      await fetch({
        headers: { 'content-type': 'application/json' },
        method: EMethod.POST,
        url: request_url,
        data
      });
      dispatch(
        sendAlert('Address created successfully', EAlertVariant.SUCCESS)
      );

      return 1;
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };
export const patchOrder =
  (data: {} = undefined, order_id: number): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      const request_url = `/orders/api/orders/${order_id}/`;

      await fetch({
        headers: { 'content-type': 'application/json' },
        method: EMethod.PATCH,
        url: request_url,
        data
      });
      dispatch(
        sendAlert('Invoice created successfully', EAlertVariant.SUCCESS)
      );

      return 1;
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };
export const editOrder =
  (data: {} = undefined, orderId: number): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<number> => {
    try {
      const { history } = getState();

      dispatch(fetchHistory());
      const request_url = `/orders/api/orders/${orderId}/`;
      var response = await fetch({
        headers: { 'content-type': 'application/json' },
        method: EMethod.PATCH,
        url: request_url,
        data
      }).then((res) => {
        return res;
      });
      dispatch(
        sendAlert('The order was successfully edited', EAlertVariant.SUCCESS)
      );

      dispatch(successHistory({ ...history }));
      return response;
    } catch (error) {
      dispatch(failureHistory(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };
export const updateInvoiceReference =
  (data: {} = undefined, orderId: number): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<any> => {
    try {
      const { history } = getState();

      dispatch(fetchHistory());
      const request_url = `/orders/api/orders/${orderId}/update_invoice_reference/`;
      var response = await fetch({
        headers: { 'content-type': 'application/json' },
        method: EMethod.POST,
        url: request_url,
        data
      }).then((res) => {
        return res;
      });
      dispatch(
        sendAlert('The order was successfully edited', EAlertVariant.SUCCESS)
      );

      dispatch(successHistory({ ...history }));
      return response;
    } catch (error) {
      dispatch(failureHistory(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };
export const getOrderByID =
  (id: string): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await fetch({
        method: EMethod.GET,
        url: `/orders/api/orders/${id}/get_order/`
      });
      return response;
    } catch (error) {
      return null;
    }
  };
export const searchHistoryByID =
  (id: string): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await fetch({
        method: EMethod.GET,
        url: `orders/api/orders_History_resumed/${id}/get_order/`
      });
      dispatch(searchHistory(response));
    } catch (error) {
      dispatch(failureHistory(error));
    }
  };
