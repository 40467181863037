import React from 'react';

import { IKitchen } from 'interfaces/kitchen';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// import Receipt from 'components/Kitchen/Receipt';

interface IProps {
  order: IKitchen;
}

export default function Payment(props: IProps): JSX.Element {
  const { order } = props;

  return (
    <Grid>
      <Grid container justifyContent="space-between">
        {/* <Receipt order={order} /> */}
        {order?.is_paid && (
          <Button
            style={{
              cursor: 'default',
              border: '1px solid green',
              color: 'green',
              fontWeight: 400,
              borderRadius: 50
            }}
            size="small"
          >
            {order?.is_paid && 'PAID'}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
