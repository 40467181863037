import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'interfaces/store';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Panel from './Panel';
import Loading from 'components/Common/Loading';
import { getPackage } from 'redux/ducks/package';
import { IPackageState } from 'interfaces/package';
import { v4 as uuidv4 } from 'uuid';

export default function Package() {
  const dispatch = useDispatch();
  const { fetch, results } = useSelector<IStore, IPackageState>(
    (state) => state.package
  );


  useEffect(() => {
    dispatch(getPackage());

  }, [dispatch]);

  return (
    <>
      <Loading show={fetch} />
      {!fetch && results.length === 0 && (
        <Typography variant="h6" align="center" style={{ marginBottom: 10 }}>
          There is currently no Package.
        </Typography>
      )}
      <Grid container spacing={3}>
        {results.map((batch) => (
          <Panel
            key={uuidv4()}
            batch={batch}
          />
        ))}
      </Grid>
    </>
  );
}
