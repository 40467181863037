import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  // Typography,
  Typography
} from '@material-ui/core';
import {
  billing_address,
  cards,
  IProfileState,
  User
} from 'interfaces/profile';
import IStore from 'interfaces/store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editProfile,
  getProfile,
  addCardAPI,
  editBillingAddress,
  deleteCardAPI
} from 'redux/ducks/profile';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';

function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector<IStore, IProfileState>((state) => state.profile);
  const [user, setUser] = React.useState<User>();
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [cards, setCards] = React.useState<cards[]>([]);
  const [email, setEmail] = React.useState('');
  const [billing, setBilling] = React.useState<billing_address>({
    active: false,
    address: '',
    address_id: 1,
    city: '',
    complement: '',
    country: '',
    name: '',
    neighborhood: '',
    postal_code: '',
    state: '',
    street: '',
    street_number: '',
    update_address: false
  });
  const [phoneNumberEditable, setPhoneNumberEditable] = React.useState(false);
  const [emailEditable, setEmailEditable] = React.useState(false);
  const [billingEditable, setBillingEditable] = React.useState(false);
  const [cardsEditable, setCardsEditable] = React.useState([true]);
  const [cardsNameError, setCardsNameError] = React.useState(['']);
  const [cardsNumberError, setCardsNumberError] = React.useState(['']);
  const [cardsExpiryError, setCardsExpiryError] = React.useState(['']);
  const [cardsCVVError, setCardsCVVError] = React.useState(['']);

  const getUser = async () => {
    const x: User = await dispatch(getProfile());
    setUser(x);
    setPhoneNumber(x.profile.phone_number);
    setEmail(x.email);
    if (x.cards !== null) {
      setCards(x.cards);
      const y = new Array(x.cards.length).fill(false);
      setCardsEditable(y);
      const q = new Array(x.cards.length).fill('');
      setCardsNameError(q);
      setCardsExpiryError(q);
      setCardsNumberError(q);
      setCardsCVVError(q);
    }
    setBilling(x.billing_address);
  };
  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-profile');
    d.item(0).style.color = '#fe309c';

    d.item(1).style.color = '#fe309c';

    const dText = document.querySelectorAll<HTMLElement>('.d-profile-text');
    dText.item(0).style.color = '#fe309c';
  });
  useEffect(() => {
    getUser();
  }, [dispatch, profile]);

  const submitEdit = (data: any) => {
    dispatch(editProfile(data));
  };

  const handleEditPhone = () => {
    if (phoneNumber !== user.profile.phone_number) {
      submitEdit({ profile: { phone_number: phoneNumber } });
    }
    setPhoneNumberEditable(false);
  };
  const handleEditBilling = () => {
    if (billing !== user.billing_address) {
      dispatch(editBillingAddress(billing, billing.address_id));
    }
    setBillingEditable(false);
  };
  const handleEditEmail = () => {
    if (email !== user.email) {
      submitEdit({ email });
    }
    setEmailEditable(false);
  };
  const addCard = () => {
    const x = [...cards];
    x.push({
      id: 0,
      active: false,
      cardFlag: '',
      cardHolder: '',
      cardNumber: '',
      cpf: '',
      expirationDate: '',
      cvv: ''
    });
    setCards(x);
    if (x.length !== cardsEditable.length) {
      const y = [...cardsEditable];
      y.push(true);
      setCardsEditable(y);
      const q = [...cardsNameError];
      q.push('');
      const w = [...cardsNumberError];
      w.push('');
      const e = [...cardsExpiryError];
      e.push('');
      const r = [...cardsCVVError];
      r.push('');
      setCardsNameError(q);
      setCardsNumberError(w);
      setCardsExpiryError(e);
      setCardsCVVError(r);
    }
  };
  const deleteCard = (index: number) => {
    const x = [...cards];
    console.log(x);
    console.log(x[index]);
    if (x[index].id && x[index].id !== 0) {
      dispatch(deleteCardAPI(x[index].id));
    }
    x.splice(index, 1);
    setCards(x);
    const q = [...cardsNameError];
    q.splice(index, 1);
    setCardsNameError(q);
    const w = [...cardsExpiryError];
    w.splice(index, 1);
    setCardsExpiryError(w);
    const e = [...cardsNumberError];
    e.splice(index, 1);
    setCardsNumberError(e);
    const r = [...cardsCVVError];
    r.splice(index, 1);
    setCardsCVVError(r);
    const t = [...cardsEditable];
    t.splice(index, 1);
    setCardsEditable(t);
  };
  const checkCardFormError = (index: number) => {
    const y = [...cards];
    let entered = false;
    if (y[index].cardHolder === '') {
      const x = [...cardsNameError];
      x[index] = 'Please enter the card holder name';
      setCardsNameError(x);
      entered = true;
    }
    if (y[index].cardNumber.length !== 16) {
      const x = [...cardsNumberError];
      x[index] = 'Please enter the card number(must be 16)';
      setCardsNumberError(x);
      entered = true;
    }
    if (y[index].expirationDate.length !== 5) {
      const x = [...cardsExpiryError];
      x[index] = 'Please enter a valid expiry date';
      setCardsExpiryError(x);
      entered = true;
    }
    if (y[index].cvv === '') {
      const x = [...cardsCVVError];
      x[index] = 'Please enter the card cvv';
      setCardsCVVError(x);
      entered = true;
    }
    return entered;
  };
  const handleEditCards = (index: number) => {
    if (!checkCardFormError(index)) {
      const y = [...cards];
      if (y[index].id && y[index].id !== 0) {
        deleteCard(y[index].id);
      }
      delete y[index].id;
      dispatch(addCardAPI(y[index]));
      const x = [...cardsEditable];
      x[index] = false;
      setCardsEditable(x);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Grid
        style={{
          border: '1px solid grey',
          borderRadius: '1vw',
          padding: '2vw',
          width: '90%'
        }}
      >
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2vw'
          }}
        >
          <Typography variant="h4">Your Details</Typography>
        </Grid>

        <Grid style={{ marginBottom: '1vw' }}>
          <Typography style={{ fontWeight: 'normal' }}>Name</Typography>
          <FormControl
            style={{
              marginTop: '0px',
              width: '20vw'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            {/* <Typography htmlFor="name-input">Name</Typography> */}
            <Input
              id="name-input"
              type="text"
              disabled
              value={user?.profile.name}
            ></Input>
          </FormControl>
        </Grid>

        <Grid style={{ marginBottom: '1vw' }}>
          <Typography style={{ fontWeight: 'normal' }}>Phone Number</Typography>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <Input
                id="billing-input"
                type="text"
                disabled={!phoneNumberEditable}
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              ></Input>
            </FormControl>
            <IconButton
              style={{ marginBottom: '0.5vw', marginLeft: '0.5vw' }}
              onClick={() => {
                if (!phoneNumberEditable) {
                  setPhoneNumberEditable(true);
                } else {
                  handleEditPhone();
                }
              }}
            >
              {!phoneNumberEditable ? (
                <EditIcon></EditIcon>
              ) : (
                <DoneIcon></DoneIcon>
              )}
            </IconButton>
          </Grid>
        </Grid>
        <Grid style={{ marginBottom: '1vw' }}>
          <Typography style={{ fontWeight: 'normal' }}>E-mail</Typography>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <Input
                id="billing-input"
                type="text"
                disabled={!emailEditable}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></Input>
            </FormControl>
            <IconButton
              style={{ marginBottom: '0.5vw', marginLeft: '0.5vw' }}
              onClick={() => {
                if (!emailEditable) {
                  setEmailEditable(true);
                } else {
                  handleEditEmail();
                }
              }}
            >
              {!emailEditable ? <EditIcon></EditIcon> : <DoneIcon></DoneIcon>}
            </IconButton>
          </Grid>
        </Grid>
        <Typography style={{ fontWeight: 'normal', marginBottom: '1vw' }}>
          Billing Address
        </Typography>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Grid
            style={{
              marginBottom: '1vw',
              border: '1px solid black',
              borderRadius: '1vw',
              padding: '1vw',
              maxWidth: '95%'
            }}
          >
            <Grid
              style={{
                marginBottom: '2vw',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '20vw'
                }}
                disabled={!billingEditable}
                margin={'normal'}
                fullWidth
              >
                <Typography>First line of the address</Typography>
                <Input
                  id="name-input"
                  type="text"
                  value={billing.address}
                  onChange={(e) => {
                    const x = { ...billing };
                    x.address = e.target.value;
                    setBilling(x);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '20vw',
                  marginLeft: '10vw'
                }}
                disabled={!billingEditable}
                margin={'normal'}
                fullWidth
              >
                <Typography>Door number</Typography>
                <Input
                  id="street-input"
                  type="text"
                  value={billing.street_number}
                  onChange={(e) => {
                    const x = { ...billing };
                    x.street_number = e.target.value;
                    setBilling(x);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              style={{
                marginBottom: '2vw',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '20vw'
                }}
                disabled={!billingEditable}
                margin={'normal'}
                fullWidth
              >
                <Typography>Second line of the address</Typography>
                <Input
                  id="name-input"
                  type="text"
                  value={billing.street}
                  onChange={(e) => {
                    const x = { ...billing };
                    x.street = e.target.value;
                    setBilling(x);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '20vw',
                  marginLeft: '10vw'
                }}
                disabled={!billingEditable}
                margin={'normal'}
                fullWidth
              >
                <Typography>Neighborhood</Typography>
                <Input
                  id="name-input"
                  type="text"
                  value={billing.neighborhood}
                  onChange={(e) => {
                    const x = { ...billing };
                    x.neighborhood = e.target.value;
                    setBilling(x);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              style={{
                marginBottom: '2vw',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '20vw'
                }}
                disabled={!billingEditable}
                margin={'normal'}
                fullWidth
              >
                <Typography>City</Typography>
                <Input
                  id="street-input"
                  type="text"
                  value={billing.city}
                  onChange={(e) => {
                    const x = { ...billing };
                    x.city = e.target.value;
                    setBilling(x);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '20vw',
                  marginLeft: '10vw'
                }}
                margin={'normal'}
                fullWidth
                disabled={!billingEditable}
              >
                <Typography>Postal Code</Typography>
                <Input
                  id="postal-input"
                  type="text"
                  value={billing.postal_code}
                  onChange={(e) => {
                    const x = { ...billing };
                    x.postal_code = e.target.value;
                    setBilling(x);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              style={{
                marginBottom: '2vw',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '20vw'
                }}
                margin={'normal'}
                fullWidth
                disabled={!billingEditable}
              >
                <Typography>State</Typography>
                <Input
                  id="name-input"
                  type="text"
                  value={billing.state}
                  onChange={(e) => {
                    const x = { ...billing };
                    x.state = e.target.value;
                    setBilling(x);
                  }}
                />
              </FormControl>

              <FormControl
                style={{
                  marginTop: '0px',
                  width: '20vw',
                  marginLeft: '10vw'
                }}
                margin={'normal'}
                fullWidth
                disabled={!billingEditable}
              >
                <Typography>Country</Typography>
                <Input
                  id="street-input"
                  type="text"
                  value={billing.country}
                  onChange={(e) => {
                    const x = { ...billing };
                    x.country = e.target.value;
                    setBilling(x);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <IconButton
            style={{ marginBottom: '0.5vw', marginLeft: '0.5vw' }}
            onClick={() => {
              if (!billingEditable) {
                setBillingEditable(true);
              } else {
                handleEditBilling();
              }
            }}
          >
            {!billingEditable ? <EditIcon></EditIcon> : <DoneIcon></DoneIcon>}
          </IconButton>
        </Grid>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '1vw'
          }}
        >
          <Typography>Cards</Typography>
          <IconButton onClick={addCard}>
            <AddIcon></AddIcon>
          </IconButton>
        </Grid>
        <Grid>
          {cards !== null &&
            cards.length > 0 &&
            cards.map((element, index) => {
              return (
                <Grid
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Grid
                    style={{
                      border: '1px solid black',
                      borderRadius: '1vw',
                      padding: '1vw',
                      marginBottom: '2vw',
                      maxWidth: '95%'
                    }}
                  >
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vw'
                      }}
                    >
                      <Grid>
                        <Typography style={{ fontWeight: 'normal' }}>
                          Card Name
                        </Typography>
                        <FormControl
                          style={{
                            marginTop: '0px',
                            width: '20vw'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                          required
                          error={cardsNameError[index] !== ''}
                        >
                          <Input
                            id="cardholder-input"
                            type="text"
                            disabled={!cardsEditable[index]}
                            value={cards[index].cardHolder}
                            onChange={(e) => {
                              const x = [...cards];
                              x[index].cardHolder = e.target.value;
                              setCards(x);
                              const y = [...cardsNameError];
                              y[index] = '';
                              setCardsNameError(y);
                            }}
                          ></Input>
                          <FormHelperText>
                            {cardsNameError[index]}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid style={{ marginLeft: '10vw' }}>
                        <Typography style={{ fontWeight: 'normal' }}>
                          Card Number
                        </Typography>
                        <FormControl
                          style={{
                            marginTop: '0px',
                            width: '20vw'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                          required
                          error={cardsNumberError[index] !== ''}
                        >
                          <Input
                            id="cardNumber-input"
                            type="text"
                            disabled={!cardsEditable[index]}
                            value={cards[index].cardNumber}
                            onChange={(e) => {
                              const x = [...cards];
                              x[index].cardNumber = e.target.value;
                              setCards(x);
                              const y = [...cardsNumberError];
                              y[index] = '';
                              setCardsNumberError(y);
                            }}
                          ></Input>
                          <FormHelperText>
                            {cardsNumberError[index]}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid>
                        <Typography style={{ fontWeight: 'normal' }}>
                          Expiry Date
                        </Typography>
                        <FormControl
                          style={{
                            marginTop: '0px',
                            width: '20vw'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                          required
                          error={cardsExpiryError[index] !== ''}
                        >
                          <Input
                            id="expiry-input"
                            type="text"
                            disabled={!cardsEditable[index]}
                            value={cards[index].expirationDate}
                            onChange={(e) => {
                              const x = [...cards];
                              if (e.target.value.length < 6) {
                                if (e.target.value.length === 2) {
                                  x[index].expirationDate =
                                    e.target.value + '/';
                                } else {
                                  x[index].expirationDate = e.target.value;
                                }
                                setCards(x);
                                const y = [...cardsExpiryError];
                                y[index] = '';
                                setCardsExpiryError(y);
                              }
                            }}
                          ></Input>
                          <FormHelperText>
                            {cardsExpiryError[index]}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid style={{ marginLeft: '10vw' }}>
                        <Typography style={{ fontWeight: 'normal' }}>
                          CVV
                        </Typography>
                        <FormControl
                          style={{
                            marginTop: '0px',
                            width: '20vw'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                          required
                          error={cardsCVVError[index] !== ''}
                        >
                          <Input
                            id="cvv-input"
                            type="text"
                            disabled={!cardsEditable[index]}
                            value={cards[index].cvv}
                            onChange={(e) => {
                              const x = [...cards];
                              if (e.target.value.length < 4) {
                                x[index].cvv = e.target.value;
                                setCards(x);
                              }
                              const y = [...cardsCVVError];
                              y[index] = '';
                              setCardsCVVError(y);
                            }}
                          ></Input>
                          <FormHelperText>
                            {cardsCVVError[index]}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <IconButton
                    onClick={() => {
                      if (!cardsEditable[index]) {
                        const x = [...cardsEditable];
                        x[index] = true;
                        setCardsEditable(x);
                      } else {
                        handleEditCards(index);
                      }
                    }}
                    style={{ marginBottom: '1vw' }}
                  >
                    {!cardsEditable[index] ? (
                      <EditIcon></EditIcon>
                    ) : (
                      <DoneIcon></DoneIcon>
                    )}
                  </IconButton>
                  <IconButton
                    style={{ marginBottom: '1vw' }}
                    onClick={() => {
                      deleteCard(index);
                    }}
                  >
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </div>
  );
}

export default Profile;
