import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { IKitchen } from 'interfaces/kitchen';
import { Button, InputLabel, Input } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setCanceledStatus } from 'redux/ducks/kitchen';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  order: IKitchen;
  successFunction: () => void;
}

export default function CancelOrderModal(props: SimpleDialogProps) {
  const { onClose, open, order, successFunction } = props;
  const [pin, setPin] = React.useState(0);
  
  const dispatch = useDispatch();

  const handleClose = () => {
    setPin(0);
    onClose();
  };

  const handlePin = (event: any) => {
    setPin(event.target.value);
  };

  const submitCancel = async () => {
    dispatch(setCanceledStatus(order, { pin: pin })).then((result: boolean) => {
      if (result) {
        successFunction();
        handleClose();
      }
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Cancel order: {order?.order_code}
      </DialogTitle>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel htmlFor="my-input">Pin</InputLabel>
        <Input
          id="my-input"
          aria-describedby="my-helper-text"
          type="number"
          value={pin}
          onChange={handlePin}
        />
      </FormControl>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={submitCancel} color="primary" disabled={pin <= 0}>
          {' '}
          Cancel Order{' '}
        </Button>
        <Button onClick={handleClose}> Close </Button>
      </div>
    </Dialog>
  );
}
