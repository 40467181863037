import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import { useStyles } from './styles';

interface IProps {
  show: boolean;
  styles: any;
}

export default function CustomizableLoading(props: IProps): JSX.Element {
  const classes = useStyles();
  const { show, styles } = props;
  console.log("styles ", styles)
  return (
    show && (
      <div className={classes.root} style={{...styles}}>
        <LinearProgress />
      </div>
    )
  );
}
