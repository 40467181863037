import React from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Backdrop,
  Fade,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { IKitchen } from 'interfaces/kitchen';
import { Customer } from 'interfaces/customersAndSellers';
import useStyles from './styles';
import moment from 'moment';
interface IProps {
  open: boolean;
  handleClose: () => void;
  data: IKitchen[];
  loading: boolean;
  customer: Customer;
}

export default function CustomerModal(props: IProps) {
  const { open, handleClose, data, loading, customer } = props;
  const classes = useStyles();
  const translateStatus = (status) => {
    switch (status) {
      case 'A fazer':
        return 'Pending';
      case 'Fazendo':
        return 'Accepted';

      case 'Feito':
        return 'Preparing';

      case 'Depot':
        return 'Ready';

      case 'Transit':
        return 'In Route';

      default:
        return status;
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.customer_modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className={classes.customer_paper}>
          {loading ? (
            <div className={classes.customer_loading_wrapper}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Typography variant="h5">{customer.name}</Typography>
              {data.map((order, idx) => (
                <>
                  {order && (
                    <div
                      key={order?.order_id + idx}
                      className={classes.customer_modal_wrapper}
                    >
                      <Typography variant={'h6'}>
                        {order?.order_code}
                      </Typography>
                      <div className={classes.customer_modal_body}>
                        <div className={classes.customer_modal_field_wrapper}>
                          <Typography style={{ fontWeight: 'bold' }}>
                            Payment
                          </Typography>
                          <Typography>{order?.payment}</Typography>

                          {order?.insurance_requested ? (
                            <Typography>
                              Insurance:{' '}
                              {order?.insurance_value_cents.toLocaleString(
                                'en-US',
                                {
                                  style: 'currency',
                                  currency: order?.currency
                                }
                              )}
                            </Typography>
                          ) : null}
                          {order?.vat ? (
                            <Typography>
                              VAT:{' '}
                              {order?.vat.toLocaleString('en-US', {
                                style: 'currency',
                                currency: order?.currency
                              })}
                            </Typography>
                          ) : null}
                          {order?.dhl_price &&
                            order?.order_code.includes('O-D') ? (
                            <Typography>
                              Delivery:{' '}
                              {(order?.dhl_price * 1).toLocaleString('en-US', {
                                style: 'currency',
                                currency: order?.currency
                              })}
                            </Typography>
                          ) : null}
                          <Typography>
                            Total:{' '}
                            {order?.total_order?.toLocaleString('en-US', {
                              style: 'currency',
                              currency: order?.currency
                            })}
                          </Typography>
                        </div>
                        <div className={classes.customer_modal_field_wrapper}>
                          <Typography style={{ fontWeight: 'bold' }}>
                            Order
                          </Typography>
                          {order?.items.map((item, idx) => (
                            item.quantity > 0 && <div key={item.product.product_id + idx}>
                              <Typography>
                                {item.quantity}x {item.product.name}
                              </Typography>
                              <Typography style={{ fontStyle: 'italic' }}>
                                {item.edition.edition_number}
                              </Typography>
                              <Typography>
                                {item.product.artist.name}
                              </Typography>
                            </div>
                          ))}
                        </div>
                        <div className={classes.customer_modal_field_wrapper}>
                          <Typography style={{ fontWeight: 'bold' }}>
                            Information
                          </Typography>
                          <Typography>
                            Status: {translateStatus(order?.status)}
                          </Typography>
                          <Typography>
                            Date ordered:{' '}
                            {moment(new Date(order?.date_ordered))
                              .utcOffset(3, false)
                              .format('DD/MM/yyyy - HH:mm')}
                          </Typography>
                          {order?.delivery_address ? (
                            <Typography>
                              Delivery address:{' '}
                              {order?.delivery_address
                                .replace(/[()''_]/g, '')
                                .replace(', 0', '')}
                            </Typography>
                          ) : null}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                          marginBottom: '10px',
                          marginTop: '5px'
                        }}
                      >
                        <div
                          style={{
                            width: '90%',
                            height: '1px',
                            backgroundColor: 'lightgrey'
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              ))}
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
