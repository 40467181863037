import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { getVouchers } from 'redux/ducks/vouchers';
import IStore from 'interfaces/store';
import Loading from 'components/Common/Loading';
import Button from '@material-ui/core/Button';
import CreateVoucherModal from './modalCreateVoucher';
import VoucherTable from './vouchersTable';
import { fetchMenusGeneral } from 'redux/ducks/menu';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'black',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: '#fe309c',
      backgroundColor: 'black'
    }
  }
});

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const { voucher, menu } = useSelector<IStore, IStore>((state) => state);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-vouchers');
    d.item(0).style.color = '#fe309c';

    d.item(1).style.color = '#fe309c';
    const dText = document.querySelectorAll<HTMLElement>('.d-vouchers-text');
    dText.item(0).style.color = '#fe309c';
  });

  const fetchVouchers = () => {
    dispatch(getVouchers());
  };
  const fetchMenus = () => {
    dispatch(fetchMenusGeneral());
  };
  useEffect(() => {
    fetchVouchers();
    fetchMenus();
  }, []);
  const classes = useStyles()
  return (
    <>
      <Loading show={voucher.fetch} />
      <Grid container spacing={3}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '97%',
            marginTop: '10px'
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{
              width: 'fit-content'
            }}
            onClick={() => setModal(true)}
            className={classes.activeButton}
          >
            Add voucher
          </Button>
        </div>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <VoucherTable refresh={() => fetchVouchers()} menus={menu.menus}
            vouchers={voucher.results} />
        </Grid>
      </Grid>
      <CreateVoucherModal
        menus={menu.menus}
        open={modal}
        onClose={() => setModal(false)}
      />
    </>
  );
}
