import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Container, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  loadData: () => void;
  stores: any;
  downloadFile: () => void;
  fetching: boolean;
  csvData: any;
  handleChangeCSVdata: (value: any) => any;
}
const GlobalCss = withStyles({
  '@global': {
    '.MuiDialog-paperWidthSm': {
      minWidth: '30vw'
    }
  }
})(() => null);

export default function CsvModal(props: SimpleDialogProps) {
  const {
    onClose,
    open,
    stores,
    downloadFile,
    fetching,
    csvData,
    handleChangeCSVdata
  } = props;
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([
    { value: 0, label: 'All' }
  ]);
  const [selectedStores, setSelectedStores] = useState(['']);
  const [selectedStatus, setSelectedStatus] = useState([0]);
  const [statusOptions, setStatusOptions] = useState([]);

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    if (csvData) {
      var statuses = [];
      var statusesList = [];
      var artistsList = [];
      csvData.forEach((row) => {
        if (!statusesList.includes(row['Stock status'])) {
          statuses.push({
            value: row['Stock status'],
            label: row['Stock status']
          });
          statusesList.push(row['Stock status']);
        }
        if (!artistsList.find((el) => el.label === row['Artist'])) {
          artistsList.push({
            value: row.Artist,
            label: row.Artist
          });
        }
      });
      statuses.push({ value: 0, label: 'All' });
      setStatusOptions(statuses);
      setOptions(artistsList);
    }
  }, [csvData]);
  const handleStatusFilter = (value: number) => {
    var _selectedStatus = selectedStatus;
    if (_selectedStatus.includes(0)) {
      _selectedStatus = _selectedStatus.filter(function (value) {
        return value != 0;
      });
    }
    if (value === 0) {
      _selectedStatus = [0];
    } else {
      if (!_selectedStatus.includes(value)) {
        _selectedStatus.push(value);
      } else {
        const index = _selectedStatus.indexOf(value);
        _selectedStatus.splice(index, 1);
      }
    }
    setSelectedStatus([..._selectedStatus]);
  };
  const handleStoresFilter = (value: string) => {
    var _selectedStores = selectedStores;
    if (_selectedStores.includes('ALL')) {
      _selectedStores = _selectedStores.filter(function (value) {
        return value != 'ALL';
      });
    }
    if (value === 'ALL') {
      _selectedStores = ['ALL'];
    } else {
      if (!_selectedStores.includes(value)) {
        _selectedStores.push(value);
      } else {
        const index = _selectedStores.indexOf(value);
        _selectedStores.splice(index, 1);
      }
    }
    setSelectedStores([..._selectedStores]);
  };

  const isEditionIncluded = (label) => {
    var found = false;
    selectedOptions.forEach((option) => {
      if (option.label === label) {
        found = true;
      }
    });
    return found;
  };

  const filterData = () => {
    var filteredData = [...csvData];
    if (selectedStores[0] != 'ALL') {
      filteredData = filteredData.filter((el) => {
        return selectedStores.includes(el['Store owner']);
      });
    }
    if (selectedOptions[0].value != 0) {
      filteredData = filteredData.filter((el, idx) => {
        return isEditionIncluded(el.Artist);
      });
    }
    if (selectedStatus[0] != 0) {
      filteredData = filteredData.filter((el) => {
        return selectedStatus.includes(el['Stock status']);
      });
    }
    const csvHeaders = [
      'Artist',
      'Title',
      'Number',
      'Year',
      'Current location',
      'Acquisition history',
      'Acquisition date',
      'Store owner',
      'Internal condition notes',
      'Stock status',
      'Website price',
      'Buying price'
    ];
    const fileData = {
      data: filteredData,
      headers: csvHeaders,
      filename: 'Weng_Inventory_Report.csv'
    };
    handleChangeCSVdata(fileData);
    setTimeout(() => {
      downloadFile();
    }, 1000);
  };

  return (
    <div>
      <GlobalCss />
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        style={{ minWidth: '40vw' }}
      >
        <DialogTitle id="simple-dialog-title">CSV report filters</DialogTitle>

        <Container maxWidth="md">
          {!fetching && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '20px'
                }}
              >
                {stores.map((elmnt) => (
                  <Button
                    key={'store buttons - ' + elmnt.name}
                    onClick={() => {
                      handleStoresFilter(elmnt.name);
                    }}
                    variant="outlined"
                    size="small"
                    className={
                      selectedStores.includes(elmnt.name) ||
                      selectedStores.includes('ALL')
                        ? classes.activeButton
                        : classes.button
                    }
                  >
                    {elmnt.name}
                  </Button>
                ))}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '20px',
                  alignItems: 'center'
                }}
              >
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={options}
                  value={selectedOptions}
                  style={{ width: '28vw' }}
                  onChange={(event: any, newValue: any | null) => {
                    newValue = newValue.filter((el) => {
                      return el.value != 0;
                    });
                    setSelectedOptions(newValue);
                  }}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Artists"
                      placeholder="Artists"
                    />
                  )}
                />

                <Button
                  onClick={() => {
                    setSelectedOptions([{ value: 0, label: 'All' }]);
                  }}
                  variant="outlined"
                  size="small"
                  style={{ height: '28px' }}
                  className={
                    selectedOptions[0]?.value === 0
                      ? classes.activeButton
                      : classes.button
                  }
                >
                  All
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '20px'
                }}
              >
                {statusOptions.map((elmnt) => (
                  <Button
                    key={'status buttons - ' + elmnt.value}
                    onClick={() => {
                      handleStatusFilter(elmnt.value);
                    }}
                    variant="outlined"
                    size="small"
                    className={
                      selectedStatus.includes(elmnt.value) ||
                      selectedStatus.includes(0)
                        ? classes.activeButton
                        : classes.button
                    }
                  >
                    {elmnt.label}
                  </Button>
                ))}
              </div>
            </>
          )}
        </Container>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!fetching ? (
            <Button onClick={() => filterData()} color="primary">
              Export CSV
            </Button>
          ) : (
            <CircularProgress
              style={{ height: '30px', width: '30px', marginBottom: '20px' }}
            />
          )}{' '}
        </div>
      </Dialog>
    </div>
  );
}
