import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { IVoucherBase } from 'interfaces/vouchers';
import { Button } from '@material-ui/core';
import { deleteVoucher } from 'redux/ducks/vouchers';
import { useDispatch } from 'react-redux';

//import { setCanceledStatus } from 'redux/ducks/kitchen';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  voucher: IVoucherBase;
  refresh: () => void;
}

export default function CancelOrderModal(props: SimpleDialogProps) {
  const { onClose, open, voucher, refresh } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };
  const deleteData = () => {
    dispatch(deleteVoucher(voucher.id)).then((res) => {
      if (res) {
        refresh()
        handleClose()
      }
    })
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Delete voucher: {voucher?.name}
      </DialogTitle>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button color="primary" onClick={() => deleteData()}> Delete </Button>
        <Button onClick={handleClose}> Close </Button>
      </div>
    </Dialog>
  );
}
