import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography, CardContent } from '@material-ui/core';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '0 5px',
    color: '#211f5c',
    border: 'none',
    backgroundColor: '#fdfdfd',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'black',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: '#fe309c',
      backgroundColor: 'black'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  customer_modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '15px',
    marginBottom: '15px'
  },
  edit_customer_modal: {
    display: 'flex',
    justifyContent: 'end',
    height: '100vh'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  wishlist_paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '60vw',
    paddingTop: '30px',
    paddingBottom: '30px',
    overflowY: 'auto',
    maxHeight: '99vh'
  },
  customer_paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '60vw',
    paddingTop: '30px',
    paddingBottom: '30px',
    overflowY: 'auto',
    maxHeight: '99vh'
  },
  edit_customer_paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '60vw',
    paddingTop: '30px',
    paddingBottom: '30px',
    overflowY: 'auto',
    height: '100vh'
  },
  customer_loading_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  customer_modal_wrapper: {
    marginTop: '5px'
  },
  customer_modal_body: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },
  customer_modal_field_wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%'
  }
}));

export const CardWithValue = styled(Card)`
  background-color: #fdfdfd;
  min-width: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const CardWithGraphic = styled(Card)`
  background-color: #fdfdfd;
  min-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const CardLabel = styled(Typography)`
  margin-bottom: 0;
`;

export const GraphicCardLabel = styled(Typography)`
  width: 100%;
  padding: 10px 20px;
  color: #4c4c4c;
  border-bottom: 1px solid #d1d1d1;
`;

export const CardBody = styled(CardContent)`
  padding-bottom: 16px !important;
`;

export const ColorLabel = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: ${(props) => (props.color ? props.color : '#211F5C')};
`;

export const PlusInfo = styled.span`
  color: #3fa336;
`;

export default useStyles;
