/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/interface-name-prefix */
import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
    FormControl,
    //   FormHelperText,
    Grid,
    Input,
    InputLabel,
    FormHelperText,
    TextField,
    Typography,
    Button
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProductsGeneral } from 'redux/ducks/stock';
import IStore from 'interfaces/store';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetch } from './../../utils/request';

import COUNTRIES from 'components/Global/Countries';
import { IHistory } from 'interfaces/history';
import { sendAlert } from 'redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';
import styles from './styles'
export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    order: IHistory;
    generatePDF: (any) => void
}

export default function ModalCreateInvoice(props: SimpleDialogProps) {
    const { onClose, open, order, generatePDF } = props;
    const dispatch = useDispatch();
    const { stock } = useSelector<IStore, IStore>((state) => state);
    const classes = styles()
    const [allEditions, setAllEditions] = useState([]);

    const [postalCodeError, setpostalCodeError] = useState('');
    const [streetError, setstreetError] = useState('');
    const [streetNumberError, setstreetNumberError] = useState('');
    const [cityError, setcityError] = useState('');
    const [countryError, setcountryError] = useState('');
    const [billingPostalCodeError, setBillingpostalCodeError] = useState('');
    const [billingStreetError, setBillingstreetError] = useState('');
    const [billingStreetNumberError, setBillingstreetNumberError] = useState('');
    const [billingCityError, setBillingcityError] = useState('');
    const [billingCountryError, setBillingcountryError] = useState('');
    const [isBillingChanged, setIsBillingChanged] = useState(false);
    const [billingInputValueCountries, setBillingInputValueCountries] =
        useState('');

    const [inputValueCountries, setInputValueCountries] = useState('');
    const [userId, setUserId] = useState(0)


    const [deliveryType, setDeliveryType] = useState('');
    const [billingAddress, setBillingAddress] = useState({
        address: "",
        city: '',
        country: { code: "", name: "" },
        postal_code: '',
        state: '',
        street: '',
        street_number: '',
    });
    const [deliveryAddress, setDeliveryAddress] = useState({
        address: "",
        city: '',
        country: { code: "", name: "" },
        postal_code: '',
        state: '',
        street: '',
        street_number: '',
    });
    const [clients, setClients] = useState([]);

    const getUserData = async () => {
        try {
            setUserId(order.owner.user)
            let url = `/users/api/clients/${order.owner.user}/`;
            let response = await fetch({
                method: 'GET',
                url
            });
            if (response.billing_address) {
                var selectedCountry = COUNTRIES.find(el => { if (el.code === response.billing_address.country) return el })
                var _billing_address = {
                    address: response.billing_address.address,
                    address_id: response.billing_address.address_id,
                    city: response.billing_address.city,
                    country: selectedCountry,
                    postal_code: response.billing_address.postal_code,
                    state: response.billing_address.state,
                    street: response.billing_address.street,
                    street_number: response.billing_address.street_number,
                }
                setBillingAddress(_billing_address)
            }
            if (response.delivery_address) {
                var selectedCountry = COUNTRIES.find(el => { if (el.code === response.delivery_address.country) return el })

                var _delivery_address = {
                    address: response.delivery_address.address,
                    address_id: response.delivery_address.address_id,
                    city: response.delivery_address.city,
                    country: selectedCountry,
                    postal_code: response.delivery_address.postal_code,
                    state: response.delivery_address.state,
                    street: response.delivery_address.street,
                    street_number: response.delivery_address.street_number,
                }

                setDeliveryAddress(_delivery_address)
            }
        } catch (error) {
            console.log('error fetching client');
        }
    }
    useEffect(() => {
        setDeliveryType(order.type_of_order === "O-D" ? "Delivery" : "Collection")
        getUserData()
    }, [order])
    const checkForm = () => {
        let entered = false;
        // if (deliveryAddress === '') {
        //   setDeliveryAddressError('Please enter first line of address');
        //   entered = true;
        // }
        if (deliveryType === 'Delivery') {
            if (deliveryAddress.postal_code === '') {
                setpostalCodeError('Please enter postal code');
                entered = true;
            }
            if (deliveryAddress.street === '') {

                setstreetError('Please enter the street');
                entered = true;
            }
            if (deliveryAddress.street_number === '') {

                setstreetNumberError('Please enter door number');
                entered = true;
            }
            if (deliveryAddress.city === '') {

                setcityError('Please enter city');
                entered = true;
            }

            if (deliveryAddress.country.code === '' || deliveryAddress.country === null) {

                setcountryError('Please enter country');
                entered = true;
            }
        }
        if (billingAddress.postal_code === '') {

            setBillingpostalCodeError('Please enter postal code');
            entered = true;
        }
        if (billingAddress.street === '') {

            setBillingstreetError('Please enter the street');
            entered = true;
        }
        if (billingAddress.street_number === '') {

            setBillingstreetNumberError('Please enter door number');
            entered = true;
        }
        if (billingAddress.city === '') {

            setBillingcityError('Please enter city');
            entered = true;
        }

        if (billingAddress.country.code === '' || billingAddress.country === null) {

            setBillingcountryError('Please enter country');
            entered = true;
        }
        return entered;
    };
    const _getClients = async () => {
        try {
            let response;
            const results = [];
            let url = '/users/api/clients/';
            do {
                response = await fetch({
                    method: 'GET',
                    url
                });
                url = response.next;
                results.push(...response.results);
            } while (response.next);
            response.results = results;
            setClients(response.results);
        } catch (error) {
            console.log('error fetching clients');
        }
    };
    useEffect(() => {
        if (allEditions.length === 0) {
            dispatch(fetchProductsGeneral());
        }
        // setOrderId('');
        if (clients.length === 0) {
            _getClients();
        }
        if (stock && stock.products && stock.products.length > 0) {
            const y = [];
            stock.products.forEach((element) => {
                element.editions.forEach((edition) => {
                    const x = {
                        name: edition.name,
                        id: edition.id,
                        productId: edition.product,
                        productName: element.name,
                        value: edition.base_price,
                        extras: element.extras,
                        number: edition.edition_number,
                        stock_status: edition.stock_status,
                        price_eur: edition.price_eur,
                        price_gbp: edition.price_gbp,
                        price_chf: edition.price_chf
                    };
                    y.push(x);
                });
            });
            setAllEditions(y);
        }
    }, [stock.products]);

    const handleClose = () => {
        setpostalCodeError('');
        setstreetError('');
        setstreetNumberError('');
        setcityError('');
        setcountryError('');
        setBillingAddress({
            address: '',
            city: '',
            country: { code: "", name: "" },
            postal_code: '',
            state: '',
            street: '',
            street_number: '',
        });
        setDeliveryAddress({
            address: '',
            city: '',
            country: { code: "", name: "" },
            postal_code: '',
            state: '',
            street: '',
            street_number: '',
        });
        onClose();
    };
    const handleBillingChange = (value, prop) => {
        setBillingAddress(billingAddress => ({ ...billingAddress, [prop]: value }))
    }
    const handleDeliveryChange = (value, prop) => {
        setDeliveryAddress(billingAddress => ({ ...billingAddress, [prop]: value }))
    }
    const stringfyAddress = (address: { address: string, city: string, country: { code: string, name: string, }, postal_code: string, state: string, street: string, street_number: string, }) => {
        return address.street + " " + address.street_number + ", " + address.city + ", " + address.postal_code + ", " + address.country.code
    }
    const getPrice = (product, currency) => {
        switch (currency) {
            case 'EUR':
                return product.price_eur;
            case 'USD':
                return product.value;
            case 'GBP':
                return product.price_gbp;
            case 'CHF':
                return product.price_chf;
            default:
                return product.value;
        }
    }
    const calculateTotalValue = () => {
        let x = 0;

        let totalValue = 0;
        order.items.map((element) => {
            totalValue += getPrice(element.product, order.currency) - element.orderitem_discount;
        });


        x += totalValue;
        if (deliveryType === 'Delivery') {
            x += order.delivery_fee;
        }
        if (order?.additional_costs?.length > 0) {
            order?.additional_costs?.map((el) => {
                x += el.cost
            });
        }
        x -= order.discount;
        if (order.insurance_requested) {
            x += order.insurance_value_cents;
        }
        console.log("returning total value ", x)
        return x;
    };
    const patchOrder = async () => {
        return new Promise<IHistory>(async (resolve, reject) => {
            try {
                console.log("cehgou aqui 2")
                var orderProducts = [];
                order.items.map((item) => {
                    orderProducts.push({
                        product_id: item.product.product_id,
                        edition_id: item.edition.id,
                        quantity: 1
                    });
                });
                const formatedOrderToPatch = {
                    infos: {
                        type_of_order:
                            order.type_of_order === 'O-C' ? 'Collection' : 'Delivery',
                        store_id: 1,
                        invoice_reference: order.invoice_reference,
                        insurance_requested: order.insurance_requested,
                        invoice_date: order.invoice_date,
                        vat: deliveryAddress.country.code === "CH" ? calculateTotalValue() * 0.077 : 0

                    },
                    address: {
                        ...deliveryAddress,
                        country: billingAddress.country.code
                    },
                    delivery_address: {
                        ...deliveryAddress,
                        country: billingAddress.country.code
                    },
                    products: orderProducts,
                    client: order.client || 1
                };
                console.log("patched order ", order)
                var _order = await fetch({
                    method: 'PATCH',
                    url: `/orders/api/orders/${order.order_id}/`,
                    data: formatedOrderToPatch
                });
                console.log("order returned ", _order)
                resolve(_order)
            } catch (error) {
                console.log("error ", error)
                resolve(error)
            }
        })
    }
    const submit = async () => {
        try {
            if (!checkForm()) {
                if (isBillingChanged) {
                    await fetch({
                        method: 'POST',
                        url: `users/api/address/waiter_create_address/`,
                        data: { ...billingAddress, active: true, user_id: userId, country: billingAddress.country.code }
                    });
                }
                await fetch({
                    method: 'POST',
                    url: `/users/api/address/waiter_create_delivery_address/`,
                    data: { ...deliveryAddress, active: true, user_id: userId, country: deliveryAddress.country.code }
                });
                console.log("chegou aqui 1")
                patchOrder().then((_order) => {
                    console.log("chegou aqui 3", _order)
                    generatePDF({ ..._order, delivery_address: stringfyAddress(deliveryAddress) })
                    handleClose()
                    dispatch(sendAlert("Address successfully updated", EAlertVariant.SUCCESS))
                })

            }
        } catch (error) {
            dispatch(sendAlert("Something went wrong", EAlertVariant.ERROR))

            console.log("error")
        }
    }
    return (
        <Modal
            className="create-menu-dialog"
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="modal-create-menu-content" style={{ padding: "5px 20px" }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        // width: '20vw',
                        alignItems: 'center',
                        marginTop: "20px"
                    }}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{
                            width: 'fit-content'
                        }}
                        className={classes.activeButton}
                        onClick={() => submit()}
                    >
                        Save
                    </Button>
                </div>

                <div>
                    {' '}
                    <Grid style={{ fontWeight: 'lighter', fontSize: '1vw' }}>
                        <Typography>Billing Address</Typography>
                    </Grid>
                    <Grid
                        style={{
                            border: '1px solid grey',
                            padding: '1vw',
                            borderRadius: '1vw',
                            marginBottom: '1vw'
                        }}
                    >
                        <Grid
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <FormControl
                                style={{
                                    marginTop: '0px'
                                }}
                                margin={'normal'}
                                fullWidth
                                error={billingStreetError !== ''}
                                required
                            >
                                <InputLabel htmlFor="name-input">Street</InputLabel>
                                <Input
                                    id="name-input"
                                    type="text"
                                    value={billingAddress.street}
                                    onChange={(e) => {
                                        handleBillingChange(e.target.value, "street");
                                        setIsBillingChanged(true);

                                    }}
                                />
                                <FormHelperText>{billingStreetError}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <FormControl
                                style={{
                                    marginTop: '0px'
                                }}
                                margin={'normal'}
                                fullWidth
                                error={billingCityError !== ''}
                                required
                            >
                                <InputLabel htmlFor="street-input">City</InputLabel>
                                <Input
                                    id="street-input"
                                    type="text"
                                    value={billingAddress.city}
                                    onChange={(e) => {
                                        handleBillingChange(e.target.value, "city");
                                        setIsBillingChanged(true);

                                    }}
                                />
                                <FormHelperText>{billingCityError}</FormHelperText>
                            </FormControl>

                            <FormControl
                                style={{
                                    marginTop: '0px',
                                    width: '17.5vw',
                                    marginLeft: '2vw'
                                }}
                                margin={'normal'}
                                fullWidth
                                error={billingStreetError !== ''}
                                required
                            >
                                <InputLabel htmlFor="street-input">
                                    Door number
                                </InputLabel>
                                <Input
                                    id="street-input"
                                    type="text"
                                    value={billingAddress.street_number}
                                    onChange={(e) => {
                                        handleBillingChange(e.target.value, "street_number");
                                        setIsBillingChanged(true);

                                    }}
                                />
                                <FormHelperText>
                                    {billingStreetNumberError}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <FormControl
                                style={{
                                    marginTop: '0px'
                                }}
                                margin={'normal'}
                                fullWidth
                                error={billingPostalCodeError !== ''}
                                required
                            >
                                <InputLabel htmlFor="street-input">
                                    Postal Code
                                </InputLabel>
                                <Input
                                    id="postal-input"
                                    type="text"
                                    value={billingAddress.postal_code}
                                    onChange={(e) => {
                                        handleBillingChange(e.target.value, "postal_code");
                                        setIsBillingChanged(true);

                                    }}
                                />
                                <FormHelperText>{billingPostalCodeError}</FormHelperText>
                            </FormControl>
                            <FormControl
                                style={{
                                    marginTop: '0px',
                                    marginLeft: '2vw'
                                }}
                                margin={'normal'}
                                fullWidth
                                error={billingCountryError !== ''}
                                required
                            >
                                <Autocomplete
                                    value={billingAddress.country}
                                    onChange={(event: any, newValue: string | null) => {
                                        handleBillingChange(newValue, "country");
                                        setIsBillingChanged(true);

                                    }}
                                    inputValue={billingInputValueCountries}
                                    onInputChange={(event, newInputValue) => {
                                        setBillingInputValueCountries(newInputValue);
                                    }}
                                    id="country-autocomplete"
                                    options={COUNTRIES}
                                    getOptionLabel={(option) => {
                                        if (option.name !== undefined) {
                                            return option.name;
                                        } else return '';
                                    }}
                                    renderOption={(option) =>
                                        option.name !== undefined ? option.name : ''
                                    }
                                    loading={clients.length === 0}
                                    loadingText={'Loading countries'}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Country" />
                                    )}
                                />

                                <FormHelperText>{billingCountryError}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>


                {deliveryType === 'Delivery' && (
                    <>
                        {' '}
                        <Grid style={{ fontWeight: 'lighter', fontSize: '1vw' }}>
                            <Typography>Shipping Address</Typography>
                        </Grid>
                        <Grid
                            style={{
                                border: '1px solid grey',
                                padding: '1vw',
                                borderRadius: '1vw',
                                marginBottom: '1vw'
                            }}>

                            <Grid
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',

                                }}
                            >
                                <FormControl
                                    style={{
                                        marginTop: '0px'
                                    }}
                                    margin={'normal'}
                                    fullWidth
                                    error={streetError !== ''}
                                    required
                                >
                                    <InputLabel htmlFor="name-input">Street</InputLabel>
                                    <Input
                                        id="name-input"
                                        type="text"
                                        value={deliveryAddress.street}
                                        onChange={(e) => {
                                            handleDeliveryChange(e.target.value, "street");
                                        }}
                                    />
                                    <FormHelperText>{streetError}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <FormControl
                                    style={{
                                        marginTop: '0px'
                                    }}
                                    margin={'normal'}
                                    fullWidth
                                    error={cityError !== ''}
                                    required
                                >
                                    <InputLabel htmlFor="street-input">City</InputLabel>
                                    <Input
                                        id="street-input"
                                        type="text"
                                        value={deliveryAddress.city}
                                        onChange={(e) => {
                                            handleDeliveryChange(e.target.value, "city");
                                        }}
                                    />
                                    <FormHelperText>{cityError}</FormHelperText>
                                </FormControl>

                                <FormControl
                                    style={{
                                        marginTop: '0px',
                                        width: '17.5vw',
                                        marginLeft: '2vw'
                                    }}
                                    margin={'normal'}
                                    fullWidth
                                    error={streetNumberError !== ''}
                                    required
                                >
                                    <InputLabel htmlFor="street-input">Door number</InputLabel>
                                    <Input
                                        id="street-input"
                                        type="text"
                                        value={deliveryAddress.street_number}
                                        onChange={(e) => {
                                            handleDeliveryChange(e.target.value, "street_number");
                                        }}
                                    />
                                    <FormHelperText>{streetNumberError}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <FormControl
                                    style={{
                                        marginTop: '0px'
                                    }}
                                    margin={'normal'}
                                    fullWidth
                                    error={postalCodeError !== ''}
                                    required
                                >
                                    <InputLabel htmlFor="street-input">Postal Code</InputLabel>
                                    <Input
                                        id="postal-input"
                                        type="text"
                                        value={deliveryAddress.postal_code}
                                        onChange={(e) => {
                                            handleDeliveryChange(e.target.value, "postal_code");
                                        }}
                                    />
                                    <FormHelperText>{postalCodeError}</FormHelperText>
                                </FormControl>
                                <FormControl
                                    style={{
                                        marginTop: '0px',
                                        marginLeft: '2vw'
                                    }}
                                    margin={'normal'}
                                    fullWidth
                                    error={countryError !== ''}
                                    required
                                >
                                    <Autocomplete
                                        value={deliveryAddress.country}
                                        onChange={(event: any, newValue: string | null) => {
                                            handleDeliveryChange(newValue, "country");
                                        }}
                                        inputValue={inputValueCountries}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValueCountries(newInputValue);
                                        }}
                                        id="country-autocomplete"
                                        options={COUNTRIES}
                                        getOptionLabel={(option) => {
                                            if (option.name !== undefined) {
                                                return option.name;
                                            } else return '';
                                        }}
                                        renderOption={(option) =>
                                            option.name !== undefined ? option.name : ''
                                        }
                                        loading={clients.length === 0}
                                        loadingText={'Loading countries'}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Country" />
                                        )}
                                    />

                                    <FormHelperText>{countryError}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </>
                )}
            </div>
        </Modal>
    );
}
