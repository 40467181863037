import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import {
  fetchDashboard,
  fetchDashboardWithDateRange
} from 'redux/ducks/manager';
import formatDate from 'date-fns/format';

import { EFilters } from '../../enums/managerDashboard';
import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import DateFilter from './DateFilter';
import ExpensesCard from './ExpensesCard';
// import DateCard from './DateCard';
// import PieChart from './PieChart';
// import BarChart from './BarChart';
// import LineChart from './LineChart';
import NumberLessCard from './numberLessCard';
//import NumberCard from './NumberCard';
import TableChart from './TableChart';
//import { useStyles } from './styles';
//import { Button } from '@material-ui/core';
//import { v4 as uuidv4 } from 'uuid';
import { getStore } from 'redux/ducks/store';
import { IStoreState } from 'interfaces/store_test';

const filters = [
  // {
  //   label: 'Yesterday',
  //   value: EFilters.YESTERDAY
  // },
];

var storeFilter: { id: number; name: string }[] = [];

export default function ManagerDashboard(): JSX.Element {
  const dispatch = useDispatch();
  const [activeFilter, setActiveFilter] = useState<EFilters>(EFilters.TODAY);
  //const [activeStoreFilter, setActiveStoreFilter] = useState(0);
  const [initialDate, setInitialDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { manager } = useSelector<IStore, IStore>((state) => state);
  const revenue = Object.values(manager.balance.revenue).reduce(
    (value, sum) => value + sum,
    0
  );
  const cost =
    manager.balance.cost.bookvalue_card +
    manager.balance.cost.bookvalue_invoice +
    manager.balance.cost.dhl +
    (manager.balance.cost.insurance_card +
      manager.balance.cost.insurance_invoice) /
      100;
  const { results } = useSelector<IStore, IStoreState>((state) => state.store);

  storeFilter = [];

  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });
  storeFilter.push({ id: 0, name: 'ALL' });

  useEffect(() => {
    dispatch(getStore());
  }, [dispatch, activeFilter /*activeStoreFilter */]);

  useEffect(() => {
    dispatch(fetchDashboard(activeFilter));
  }, [dispatch, activeFilter]);

  //const classes = useStyles();

  const handleDateRange = () => {
    dispatch(
      fetchDashboardWithDateRange(
        formatDate(initialDate, 'yyyy-MM-dd'),
        formatDate(endDate, 'yyyy-MM-dd')
        // activeStoreFilter
      )
    );
  };

  /*  const handleActiveStoreFilter = (value: number) => {
    setActiveStoreFilter(value);
  };
 */

  const averageOrderValue = (): number => {
    const calc =
      (revenue - manager.balance.revenue.abandoned) /
      (manager.balance.came_from_count.waiter +
        manager.balance.came_from_count.app);

    return calc | 0;
  };

  return (
    <>
      <Loading show={manager.fetch} />

      <Grid container spacing={3}>
        {/* <Grid item container direction="row">
          <Typography
            color="primary"
            variant="overline"
            className={`${classes.toolBarTitles}`}
          >{`store: `}</Typography>
          {storeFilter.map((elmnt) => (
            <Button
              key={uuidv4()}
              onClick={() => {
                handleActiveStoreFilter(elmnt.id);
              }}
              variant="outlined"
              size="small"
              className={
                activeStoreFilter === elmnt.id
                  ? classes.activeButton
                  : classes.button
              }
            >
              {elmnt.name}
            </Button>
          ))}
        </Grid> */}
        <DateFilter
          activeFilter={activeFilter}
          setActiveFilter={(newFilter: EFilters) => setActiveFilter(newFilter)}
          filters={filters}
          dateRange={{
            initialDate,
            endDate,
            setInitialDate,
            setEndDate
          }}
          handleDateRange={handleDateRange}
        />
        <Grid container>
          <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
            Orders
          </Typography>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start">
          <ExpensesCard
            label="Revenue"
            header={
              manager.balance.came_from_count.waiter +
              manager.balance.came_from_count.app
            }
            percentage={
              ((manager.balance.came_from.waiter +
                manager.balance.came_from.app) /
                (manager.balance.came_from.waiter +
                  manager.balance.came_from.app)) *
              100
            }
            value={revenue - manager.balance.revenue.abandoned}
            marginRight
          />
          <ExpensesCard
            label="Average Order Value"
            value={averageOrderValue()}
            marginRight
          />
          <ExpensesCard
            label="Profit"
            value={revenue + cost - manager.balance.revenue.abandoned}
            marginRight
          />
          <ExpensesCard
            label="Abandoned"
            header={manager.balance.revenue_count.abandoned}
            percentage={1 + 2}
            value={manager.balance.revenue.abandoned}
            marginRight
          />

          {/* <ExpensesCard
            label="Collection"
            header={manager.balance.where_go_count.collection}
            percentage={
              (manager.balance.where_go.collection /
                (manager.balance.came_from.waiter +
                  manager.balance.came_from.app)) *
              100
            }
            value={manager.balance.where_go.collection}
          />
          <ExpensesCard
            label="Delivery"
            header={manager.balance.where_go_count.delivery}
            percentage={
              (manager.balance.where_go.delivery /
                (manager.balance.came_from.waiter +
                  manager.balance.came_from.app)) *
              100
            }
            value={manager.balance.where_go.delivery}
          /> */}
        </Grid>
        <Grid container>
          <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
            Revenues
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start">
          <ExpensesCard
            header={manager.balance.revenue_count.card}
            label="Stripe"
            percentage={
              (manager.balance.revenue.card /
                (manager.balance.came_from.waiter +
                  manager.balance.came_from.app)) *
              100
            }
            value={manager.balance.revenue.card}
            marginRight
          />
          <ExpensesCard
            label="Invoice"
            header={manager.balance.revenue_count.invoice}
            percentage={
              (manager.balance.revenue.invoice /
                (manager.balance.came_from.waiter +
                  manager.balance.came_from.app)) *
              100
            }
            value={manager.balance.revenue.invoice}
            marginRight
          />
          <ExpensesCard
            label="B2B"
            value={
              manager.balance.type_of_client.b2b_card +
              manager.balance.type_of_client.b2b_invoice
            }
            marginRight
          />
          <ExpensesCard
            label="B2C"
            value={
              manager.balance.type_of_client.b2c_card +
              manager.balance.type_of_client.b2c_invoice
            }
          />
        </Grid>

        <Grid container>
          <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
            Distribution{' '}
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start">
          <ExpensesCard
            label="Delivery"
            value={manager.balance.revenue_d_c.delivery}
            marginRight
          />
          <ExpensesCard
            label="Collection"
            value={manager.balance.revenue_d_c.collection}
            marginRight
          />
          <ExpensesCard label="NFT" value={manager.balance.revenue_d_c.nft} />
        </Grid>
        <Grid container>
          <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
            Value of Editions Sold per Store.
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start">
          <ExpensesCard
            label="ArtXX"
            value={manager.balance.store.artxx}
            marginRight
          />
          <ExpensesCard label="WFA" value={manager.balance.store.wfa} />
        </Grid>
        <Grid container>
          <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
            Costs
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start">
          <NumberLessCard label="Total Cost" value={cost} marginRight />
          <NumberLessCard
            label="DHL"
            value={manager.balance.cost.dhl}
            marginRight
          />
          <NumberLessCard
            label="Book Value Cost"
            value={
              manager.balance.cost.bookvalue_card +
              manager.balance.cost.bookvalue_invoice
            }
            marginRight
          />
          <NumberLessCard
            label="Segurio"
            value={
              (manager.balance.cost.insurance_card +
                manager.balance.cost.insurance_invoice) /
              100
            }
          />
        </Grid>
        {/* <Grid container>
          <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
            Other Statistics
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="space-between">
          <ExpensesCard
            label="Average Order Value"
            header={manager.balance.came_from_count.app}
            percentage={
              (manager.balance.came_from.app /
                (manager.balance.came_from.waiter +
                  manager.balance.came_from.app)) *
              100
            }
            value={manager.balance.came_from.app}
          />

          <ExpensesCard
            label="Total Buyers"
            header={manager.balance.where_go_count.collection}
            percentage={
              (manager.balance.where_go.collection /
                (manager.balance.came_from.waiter +
                  manager.balance.came_from.app)) *
              100
            }
            value={manager.balance.where_go.collection}
          />
          <ExpensesCard
            label="Returning Buyers"
            header={manager.balance.where_go_count.delivery}
            percentage={
              (manager.balance.where_go.delivery /
                (manager.balance.came_from.waiter +
                  manager.balance.came_from.app)) *
              100
            }
            value={manager.balance.where_go.delivery}
          />
          <ExpensesCard
            header={manager.balance.revenue_count.card}
            label="Purchase Frequency"
            percentage={
              (manager.balance.revenue.card /
                (manager.balance.came_from.waiter +
                  manager.balance.came_from.app)) *
              100
            }
            value={manager.balance.revenue.card}
          />
        </Grid>
        <Grid item container direction="row" justifyContent="space-between">
          <LineChart title="new orders" data={manager.orders.news} />
          <PieChart open={manager.orders.open} closed={manager.orders.closed} />
          <LineChart
            title="Medium value per order"
            data={manager.orders.average}
            floatNumbers
            total_average={manager.orders.total_average}
          />
        </Grid> */}
        {/* <Grid container>
          <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
            Time Averages
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="space-between">
          <DateCard
            label="Accepted - Preparing"
            time={manager.balance.timings.doing_done}
          />
          <DateCard
            label="Preparing - Ready"
            time={manager.balance.timings.doing_depot}
          />
          <DateCard
            label="Ready - On Route"
            time={manager.balance.timings.depot_transit}
          />
          <DateCard
            label="On Route - Delivered"
            time={manager.balance.timings.transit_delivered}
          />
          <DateCard
            label="Accepted - Complete"
            time={manager.balance.timings.doing_completed}
          />
        </Grid> */}
        <Grid container>
          <Typography
            variant="subtitle1"
            style={{ paddingLeft: '10px', paddingBottom: '10px' }}
          >
            Statistics
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="space-between">
          <TableChart
            label1="Artwork"
            label2="Quant."
            data={manager.ranks.products}
            total_count={manager.ranks.total_products}
            total_value={manager.ranks.total_products_value}
          />
          <TableChart
            label1="Country"
            label2="Quant."
            data={manager.ranks.countries}
            total_count={manager.ranks.total_country}
            total_value={manager.ranks.total_country_value}
          />
        </Grid>
        <Grid container>
          <Typography
            variant="subtitle1"
            style={{ paddingLeft: '10px', paddingBottom: '10px' }}
          ></Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="space-between">
          <TableChart
            label1="Artist"
            label2="Quant."
            data={manager.ranks.artists.sort((a, b) => b.amount - a.amount)}
            total_count={manager.ranks.total_artist}
            total_value={manager.ranks.total_artist_value}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
          Users{' '}
        </Typography>
      </Grid>
      <Grid item container direction="row" justifyContent="flex-start">
        <ExpensesCard label="ArtXX" value={manager.users.total} marginRight />
        <ExpensesCard label="WFA" value={manager.users.news} />
      </Grid>
    </>
  );
}
