import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { deleteProduct } from 'redux/ducks/stock';
import { Product } from 'interfaces/stock';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  product: Product;
  reset: () => void;
}

export default function ModalCreateProduct(props: SimpleDialogProps) {
  const { onClose, open, product, reset } = props;

  const dispatch = useDispatch();

  const handleClose = () => {
    reset();

    onClose();
  };

  const submitDelete = async () => {
    dispatch(deleteProduct(product?.product_id)).then((result: boolean) => {
      if (result) handleClose();
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Delete Artwork {product?.name}?
      </DialogTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={submitDelete} color="primary">
          {' '}
          Delete{' '}
        </Button>
        <Button onClick={handleClose}> Cancel </Button>
      </div>
    </Dialog>
  );
}
