import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button
} from '@material-ui/core';
import { IVoucherBase } from 'interfaces/vouchers';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EditVoucherModal from './modalEditVoucher';
import DeleteVoucherModal from './deleteVoucherModal';
import { Menu } from 'interfaces/menu';
import UsersVoucherModal from './userVouchers';
const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface Props {
  vouchers: IVoucherBase[];
  menus: Menu[];
  refresh: () => void;
}

export default function ClientsTable(props: Props) {
  const classes = useStyles();
  const { vouchers, menus, refresh } = props;
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selected, setSelected] = useState<IVoucherBase>(null);
  const [customerModal, setCustomerModal] = useState(false);
  const typeOfCoupon = (type: string) => {
    switch (type) {
      case 'percentage':
        return 'Percentage';
      case 'fixed':
        return 'Fixed amount';
      case 'free_delivery':
        return 'Free delivery';
      case 'free_delivery_insurance':
        return 'Free shipping + free insurance';
      default:
        return '';
    }
  };
  const formatDate = (date) => {
    return moment(date.split('T')[0]).format("DD/MM/YYYY")
  }
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="center">Type of coupon</TableCell>
            <TableCell align="center">Clients</TableCell>
            <TableCell align="center">Start date</TableCell>
            <TableCell align="center">End date</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vouchers.map((voucher) => (
            <TableRow key={voucher.name + voucher.id}>
              <TableCell component="th" scope="row">
                {' '}
                {voucher.name}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {' '}
                {typeOfCoupon(voucher.type_of_coupon)}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {' '}
                <Button
                  onClick={() => {
                    setSelected(voucher);
                    setCustomerModal(true);
                  }}
                >
                  {voucher.client.length}
                </Button>
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {' '}
                {formatDate(voucher.date_start)}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {' '}
                {formatDate(voucher.date_finish)}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {' '}
                {voucher.status ? 'Active' : 'Inactive'}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                <IconButton
                  onClick={() => {
                    setSelected(voucher);
                    setEditModal(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setSelected(voucher);
                    setDeleteModal(true);
                  }}
                  color="secondary"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditVoucherModal
        refresh={() => refresh()}
        open={editModal}
        onClose={() => {
          setEditModal(false);
          setSelected(null);
        }}
        voucher={selected}
        menus={menus}
      />
      <DeleteVoucherModal
        refresh={() => refresh()}
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
          setSelected(null);
        }}
        voucher={selected}
      />
      <UsersVoucherModal
        open={customerModal}
        onClose={() => {
          setCustomerModal(false);
          setSelected(null);
        }}
        voucher={selected}
      />
    </TableContainer>
  );
}
