import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { Menu } from 'interfaces/menu';
import { useDispatch } from 'react-redux';
import { deleteMenu } from 'redux/ducks/menu'

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    menu: Menu;
}

export default function ModalEditMenu(props: SimpleDialogProps) {
    const dispatch = useDispatch();
    const { onClose, open, menu } = props;

    const handleClose = () => {
        onClose();
    };

    const submitDelete = async () => {
        dispatch(deleteMenu(menu?.menu_id))
            .then((result: boolean) => {
                if (result) handleClose()
            })
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Delete Menu {menu?.name}?
            </DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={submitDelete} color="primary"> Delete </Button>
                <Button onClick={handleClose}> Close </Button>
            </div>
        </Dialog>
    );
}