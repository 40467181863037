import React, { useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Typography
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductsGeneral } from 'redux/ducks/stock';
import IStore from 'interfaces/store';
import { editBanner } from 'redux/ducks/banner';
import Validator from 'validator';
import { Banner } from 'interfaces/banner';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

interface IProps {
  onClose: () => void;
  open: boolean;
  banner: Banner;
  reload: () => void;
}

const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto',
        maxWidth: '25vw'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

export default function EditBanner(props: IProps) {
  const { onClose, banner, reload } = props;
  const dispatch = useDispatch();
  const { stock } = useSelector<IStore, IStore>((state) => state);
  const [bannerId, setBannerId] = React.useState(0);
  const [placementTitle, setPlacementTitle] = React.useState('');
  const [placementTitleError, setPlacementError] = React.useState('');
  const [placementDesc, setPlacementDesc] = React.useState('');
  const [placementDescError, setPlacementDescError] = React.useState('');
  const [allEditions, setAllEditions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(['']);
  const [slidesError, setSlidesError] = React.useState([['', '', '', '', '']]);
  const [imagePaths, setImagePaths] = React.useState([]);
  const [previewImage, setPreviewImage] = React.useState([]);
  const [imagePathsPortrait, setImagePathsPortrait] = React.useState([]);
  const [previewImagePortrait, setPreviewImagePortrait] = React.useState([]);
  const [previewVideo, setPreviewVideo] = React.useState([]);
  const [clicked, setClicked] = React.useState(false);

  const [slides, setSlides] = React.useState([
    {
      id: 0,
      image: '',
      video: '',
      external_url: '',
      description: '',
      hide_description: false,
      image_portrait: '',
      sort_key: 0
    }
  ]);
  const [deletedSlides, setDeletedSlides] = React.useState([
    {
      id: 0,
      image: '',
      video: '',
      external_url: '',
      description: '',
      hide_description: false,
      image_portrait: '',
      sort_key: 0
    }
  ]);
  const [productError, setProductError] = React.useState(['']);
  const [product, setProduct] = React.useState([
    {
      id: 0,
      productName: '',
      value: 0
    }
  ]);

  useEffect(() => {
    try {
      if (allEditions.length === 0) {
        dispatch(fetchProductsGeneral());
      }
      if (stock && stock.products.length > 0) {
        const y = [];
        stock.products.forEach((element) => {
          const x = {
            id: element.product_id,
            productName: element.name,
            value: element.price
          };
          y.push(x);
        });
        setAllEditions(y);
      }
      if (banner && placementTitle === '') {
        setBannerId(banner.id);
        setPlacementDesc(banner.placement_description);
        setPlacementTitle(banner.placement_title);
        if (banner.banner_slides) {
          const x = new Array(banner.banner_slides.length).fill([
            '',
            '',
            '',
            '',
            ''
          ]);
          setSlidesError(x);
          const q = new Array(banner.banner_slides.length).fill('');
          const z = new Array(banner.banner_slides.length).fill(false);
          const qz = new Array(banner.banner_slides.length).fill('');
          const zx = new Array(banner.banner_slides.length).fill('');
          const y = [];
          banner.banner_slides.map((element, index) => {
            y.push({
              id: element?.internal_product?.product_id,
              productName: element?.internal_product?.name,
              value: element?.internal_product?.price
            });
            zx[index] = element?.internal_product?.name;
            q[index] = element.image;
            qz[index] = element.image_portrait;
          });
          setProduct(y);
          setImagePaths(q);
          setPreviewImage(z);
          setImagePathsPortrait(qz);
          setPreviewImagePortrait(z);
          setPreviewVideo(z);
          setInputValue(zx);
        }
        setSlides(banner.banner_slides);
      }
    } catch (e) {
      console.log(e);
    }
  }, [stock.products, banner]);

  const checkFormError = () => {
    let entered = false;
    if (placementTitle === '') {
      setPlacementError('Please enter placement title');
      entered = true;
    }
    if (placementDesc === '') {
      setPlacementDescError('Please enter placement description');
      entered = true;
    }
    slides.map((element, index) => {
      if (element.description === '') {
        const x = [...slidesError];
        x[index][0] = 'Please enter description';
        setSlidesError(x);
        entered = true;
      }
      if (element.image === '') {
        const x = [...slidesError];
        x[index][1] = 'Please upload image';
        setSlidesError(x);
        entered = true;
      }
      if (element.image_portrait === '') {
        const x = [...slidesError];
        x[index][4] = 'Please upload image';
        setSlidesError(x);
        entered = true;
      }
      if (
        element.external_url &&
        element.external_url !== '' &&
        !Validator.isURL(element.external_url)
      ) {
        const x = [...slidesError];
        x[index][3] =
          'Please enter a valid external url (http(s)://something.something)';
        setSlidesError(x);
        entered = true;
      }
      if (
        product[index].productName !== null &&
        product[index].productName === '' &&
        element.external_url === ''
      ) {
        const x = [...productError];
        x[index] = 'Please enter a product';
        setProductError(x);
        entered = true;
      }
    });
    return entered;
  };
  const handleClose = () => {
    setClicked(false);
    setPlacementTitle('');
    setPlacementDesc('');
    setSlides([
      {
        id: 0,
        image: '',
        video: '',
        external_url: '',
        description: '',
        hide_description: false,
        image_portrait: '',
        sort_key: 0
      }
    ]);
    setProduct([
      {
        id: 0,
        productName: '',
        value: 0
      }
    ]);
    setPlacementError('');
    setPlacementDescError('');
    setSlidesError([['', '', '', '', '']]);
    setProductError(['']);
    onClose();
  };

  const handleSubmit = () => {
    if (!checkFormError()) {
      setClicked(true);
      const banner = {
        placement_title: placementTitle,
        placement_description: placementDesc
      };
      const newBannerSlides = [];
      const oldBannerSlides = [];
      const deletedSlidess = [];
      slides.map((element, index) => {
        if (element.id === 0) {
          const elementSubmitted = {
            image: element.image,
            image_portrait: element.image_portrait,
            video: element.video,
            external_url:
              element.external_url !== null ? element.external_url : '',
            description: element.description,
            hide_description: element.hide_description,
            internal_product: product[index].id,
            sort_key: element.sort_key
          };
          try {
            if (
              elementSubmitted.image.includes('https://res.cloudinary.com/')
            ) {
              delete elementSubmitted.image;
            }
            if (
              elementSubmitted.image_portrait.includes(
                'https://res.cloudinary.com/'
              )
            ) {
              delete elementSubmitted.image_portrait;
            }
          } catch (e) {
            console.log(e);
          }
          if (element.external_url !== null && element.external_url !== '') {
            delete elementSubmitted.internal_product;
          }
          newBannerSlides.push(elementSubmitted);
        } else {
          const elementSubmitted = {
            id: element.id,
            image: element.image,
            image_portrait: element.image_portrait,
            video: element.video,
            external_url:
              element.external_url !== null ? element.external_url : '',
            description: element.description,
            hide_description: element.hide_description,
            internal_product: product[index].id,
            sort_key: element.sort_key
          };
          try {
            if (
              elementSubmitted.image.includes('https://res.cloudinary.com/')
            ) {
              delete elementSubmitted.image;
            }
            if (
              elementSubmitted.image_portrait.includes(
                'https://res.cloudinary.com/'
              )
            ) {
              delete elementSubmitted.image_portrait;
            }
          } catch (e) {
            console.log(e);
          }
          if (element.external_url !== null && element.external_url !== '') {
            delete elementSubmitted.internal_product;
          }
          oldBannerSlides.push(elementSubmitted);
        }
      });
      deletedSlides.map((element) => {
        if (element.id !== 0) {
          deletedSlidess.push({
            id: element.id
          });
        }
      });

      dispatch(
        editBanner(
          bannerId,
          banner,
          newBannerSlides,
          oldBannerSlides,
          deletedSlidess
        )
      ).then((result: number) => {
        if (result === 0) {
          reload();
          handleClose();
        } else {
          setClicked(false);
        }
      });
    }
  };

  const handlePlacementTitle = (value: any) => {
    setPlacementTitle(value);
    setPlacementError('');
  };
  const handlePlacementDesc = (value: any) => {
    setPlacementDesc(value);
    setPlacementDescError('');
  };
  const handleSlideDesc = (value: any, index: number) => {
    const x = [...slides];
    x[index].description = value;
    setSlides(x);
    const z = [...slidesError];
    z[index][0] = '';
    setSlidesError(z);
  };
  const handleSlideExternalURL = (value: any, index: number) => {
    const x = [...slides];
    x[index].external_url = value;
    setSlides(x);
    const z = [...slidesError];
    z[index][3] = '';
    setSlidesError(z);
  };
  const handleHideDescription = (index: number) => {
    const x = [...slides];
    x[index].hide_description = !x[index].hide_description;
    setSlides(x);
  };

  const addSlide = () => {
    const x = [...slides];
    x.push({
      id: 0,
      image: '',
      video: '',
      external_url: '',
      description: '',
      hide_description: false,
      image_portrait: '',
      sort_key: 0
    });
    setSlides(x);
    const y = [...product];
    y.push({
      id: 0,
      productName: '',
      value: 0
    });
    setProduct(y);
    const w = [...productError];
    w.push('');
    setProductError(w);
    const z = [...slidesError];
    z.push(['', '', '', '']);
    setSlidesError(z);
  };

  const deleteSlide = (index: number) => {
    const x = [...slides];
    const z = [...deletedSlides];
    z.push(x[index]);
    setDeletedSlides(z);
    x.splice(index, 1);
    setSlides(x);
    const y = [...product];
    y.splice(index, 1);
    setProduct(y);
  };

  const handleImage = (event: any, index) => {
    if (event.length > 0) {
      const x = [...slides];
      x[index].image = event[0];
      setSlides(x);
      const z = [...slidesError];
      z[index][1] = '';
      setSlidesError(z);
      const w = [...imagePaths];
      const url = URL.createObjectURL(event[0]);
      w[index] = url;
      setImagePaths(w);
      const q = [...previewImage];
      q[index] = true;
      setPreviewImage(q);
    }
  };
  const handleImagePortrait = (event: any, index) => {
    if (event.length > 0) {
      const x = [...slides];
      x[index].image_portrait = event[0];
      setSlides(x);
      const z = [...slidesError];
      z[index][4] = '';
      setSlidesError(z);
      const w = [...imagePathsPortrait];
      const url = URL.createObjectURL(event[0]);
      w[index] = url;
      setImagePathsPortrait(w);
      const q = [...previewImagePortrait];
      q[index] = true;
      setPreviewImagePortrait(q);
    }
  };

  const handleChangeProduct = (value: any, index: number) => {
    if (value !== null) {
      const y = [...productError];
      y[index] = '';
      setProductError(y);
      const x = [...product];
      x[index] = value;
      setProduct(x);
    } else {
      const x = [...product];
      x[index] = {
        id: 0,
        productName: '',
        value: 0
      };
      setProduct(x);
    }
  };

  const handleVideo = (event: any, index) => {
    if (event.length > 0) {
      const x = [...slides];
      x[index].video = event[0];
      setSlides(x);
      const z = [...slidesError];
      z[index][2] = '';
      setSlidesError(z);
      const q = [...previewVideo];
      q[index] = true;
      setPreviewVideo(q);
    }
  };

  const [orderedSlides, setOrderedSlides] = React.useState([]);

  useEffect(() => {
    const tempOrderedSlides = orderedSlides;
    banner?.banner_slides.map((slide) => {
      tempOrderedSlides.push({
        sortKey: slide.sort_key
      });
    });
    setOrderedSlides(tempOrderedSlides);
  }, [banner]);

  const handleOrderedSlides = (inputValue: number, inputIndex: number) => {
    const tempOrderedSlides = [];
    orderedSlides?.map((slide, index) => {
      index === inputIndex
        ? tempOrderedSlides.push({
            sortKey: inputValue
          })
        : tempOrderedSlides.push({
            sortKey: slide.sortKey
          });
    });
    banner.banner_slides[inputIndex].sort_key = inputValue;
    setOrderedSlides(tempOrderedSlides);

    return null;
  };

  const getSlideSortKey = (index: number) => {
    let sortKey = 1;
    if (orderedSlides.length > 0) {
      if (orderedSlides[index]) {
        sortKey = orderedSlides[index].sortKey;
      }
      return sortKey;
    }
    return null;
  };

  const disableOrderSelect = (index: number) => {
    console.log(index);
    const incrementedIndex = index + 1;
    if (incrementedIndex > banner?.banner_slides.length) {
      return true;
    }
    return false;
  };

  return (
    <div style={{ width: '100%' }}>
      <DialogTitle id="simple-dialog-title">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div>Edit Banner Container</div>
          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
              disabled={clicked}
            >
              Save
            </Button>
          </div>
        </div>
      </DialogTitle>
      <div style={{ padding: '2vw' }}>
        <Grid>
          <FormControl
            style={{
              marginTop: '0px',
              width: '20vw'
            }}
            fullWidth={true}
            margin={'normal'}
            error={placementTitleError !== ''}
          >
            <InputLabel htmlFor="name-input">Placement Title</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={placementTitle}
              onChange={(e) => {
                handlePlacementTitle(e.target.value);
              }}
            />
            <FormHelperText>{placementTitleError}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid>
          <FormControl
            style={{
              marginTop: '0px',
              width: '20vw'
            }}
            fullWidth={true}
            margin={'normal'}
            error={placementDescError !== ''}
          >
            <InputLabel htmlFor="name-input">Placement Description</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={placementDesc}
              onChange={(e) => {
                handlePlacementDesc(e.target.value);
              }}
              multiline
              rows={3}
            />
            <FormHelperText>{placementDescError}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid
          style={{
            fontSize: '0.9vw',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div>Slides</div>
          <IconButton onClick={addSlide} style={{ marginLeft: '1vw' }}>
            <AddIcon></AddIcon>
          </IconButton>
        </Grid>
        {slides.map((element, index) => (
          <Grid
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Grid
              style={{
                border: '1px solid black',
                borderRadius: '1vw',
                width: 'fit-content',
                padding: '1vw',
                marginTop: '1vw'
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <FormControl
                  style={{
                    marginTop: '0px',
                    width: '20vw'
                  }}
                  // fullWidth={true}
                  margin={'normal'}
                  error={slidesError[index][0] !== ''}
                >
                  <InputLabel htmlFor="name-input">
                    Slide Description
                  </InputLabel>
                  <Input
                    id="name-input"
                    type="text"
                    value={element.description}
                    onChange={(e) => {
                      handleSlideDesc(e.target.value, index);
                    }}
                    multiline
                    rows={3}
                  />
                  <FormHelperText>{slidesError[index][0]}</FormHelperText>
                </FormControl>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end'
                  }}
                >
                  <FormControl
                    style={{
                      marginTop: '0px',
                      width: '7vw',
                      paddingTop: '1vw'
                    }}
                    // fullWidth={true}
                    margin={'normal'}
                    error={false}
                  >
                    <InputLabel id="orderSelect-label">Order</InputLabel>
                    <Select
                      labelId="orderSelect-label"
                      id="orderSelect"
                      value={getSlideSortKey(index)}
                      label="Order"
                      onChange={(e) =>
                        handleOrderedSlides(Number(e.target.value), index)
                      }
                      variant="standard"
                      disabled={disableOrderSelect(index)}
                    >
                      {banner?.banner_slides?.map((p, index) => (
                        <MenuItem value={index + 1}>{index + 1}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography variant="caption" style={{ color: 'red' }}>
                    {disableOrderSelect(index)
                      ? 'Please save before change this order'
                      : ''}
                  </Typography>
                </div>
              </Grid>
              <Grid>
                <FormControlLabel
                  style={{
                    marginLeft: 'unset'
                  }}
                  control={
                    <Checkbox
                      checked={element.hide_description}
                      onChange={(e) => {
                        handleHideDescription(index);
                      }}
                      name="hideDescriptionCheckbox"
                      color="primary"
                    />
                  }
                  label="Hide Description"
                />
              </Grid>
              <Grid>
                <FormControl
                  style={{
                    marginTop: '25px',
                    width: '20vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                  error={slidesError[index][3] !== ''}
                >
                  <InputLabel htmlFor="name-input">External URL</InputLabel>
                  <Input
                    id="name-input"
                    type="text"
                    value={element.external_url}
                    onChange={(e) => {
                      handleSlideExternalURL(e.target.value, index);
                    }}
                  />
                  <FormHelperText>{slidesError[index][3]}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginBottom: '2vw',
                    width: '20vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                  error={productError[index] !== ''}
                  required
                >
                  <Autocomplete
                    value={product[index]}
                    onChange={(event: any, newValue: string | null) => {
                      handleChangeProduct(newValue, index);
                    }}
                    inputValue={inputValue[index]}
                    onInputChange={(event, newInputValue) => {
                      const x = [...inputValue];
                      x[index] = newInputValue;
                      setInputValue(x);
                    }}
                    options={allEditions}
                    getOptionLabel={(option) => {
                      if (option.productName !== undefined && option.id !== 0) {
                        return option.productName;
                      } else return '';
                    }}
                    renderOption={(option) =>
                      option.productName !== undefined ? option.productName : ''
                    }
                    loading={allEditions.length === 0}
                    loadingText={'Loading products'}
                    renderInput={(params) => (
                      <TextField {...params} label="Artwork" />
                    )}
                  />
                  <FormHelperText>{productError[index]}</FormHelperText>
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginLeft: '2vw',
                    width: '17.5vw'
                  }}
                  fullWidth
                  margin={'normal'}
                >
                  <InputLabel htmlFor="name-input">Value</InputLabel>
                  <Input
                    id="name-input"
                    type="text"
                    value={product[index].value}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1vw',
                  flexDirection: 'column'
                }}
              >
                <ThemeProvider theme={theme}>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={'Drag and drop the Slide image here'}
                    showPreviewsInDropzone={true}
                    onChange={(files) => handleImage(files, index)}
                    filesLimit={0}
                  />
                  <Typography variant="caption" style={{ color: '#fe309c' }}>
                    For better resolution insert images with 2500x1250
                  </Typography>
                  <br />
                </ThemeProvider>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      display: previewImage[index] ? 'none' : 'flex',
                      flexDirection: 'column',
                      maxWidth: '3vw'
                    }}
                  >
                    <div>
                      <img
                        src={imagePaths[index]}
                        style={{
                          width: '3vw',
                          height: '3vw',
                          marginTop: '1vw'
                        }}
                      />
                    </div>
                  </div>
                </div>
                <FormHelperText style={{ color: 'red' }}>
                  {slidesError[index][1]}
                </FormHelperText>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1vw',
                  flexDirection: 'column'
                }}
              >
                <ThemeProvider theme={theme}>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={'Drag and drop the Slide image portrait here'}
                    showPreviewsInDropzone={true}
                    onChange={(files) => handleImagePortrait(files, index)}
                    filesLimit={0}
                  />
                  <Typography variant="caption" style={{ color: '#fe309c' }}>
                    For better resolution insert images with 1080x1920
                  </Typography>
                  <br />
                </ThemeProvider>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      display: previewImagePortrait[index] ? 'none' : 'flex',
                      flexDirection: 'column',
                      maxWidth: '3vw'
                    }}
                  >
                    <div>
                      <img
                        src={imagePathsPortrait[index]}
                        style={{
                          width: '3vw',
                          height: '3vw',
                          marginTop: '1vw'
                        }}
                      />
                    </div>
                  </div>
                </div>
                <FormHelperText style={{ color: 'red' }}>
                  {slidesError[index][5]}
                </FormHelperText>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1vw',
                  flexDirection: 'column'
                }}
              >
                <ThemeProvider theme={theme}>
                  <DropzoneArea
                    acceptedFiles={['video/*']}
                    dropzoneText={'Drag and drop the Slide video here'}
                    showPreviewsInDropzone={true}
                    onChange={(files) => handleVideo(files, index)}
                    filesLimit={0}
                  />
                </ThemeProvider>
                <div
                  style={{
                    display: slides[index].video !== '' ? 'flex' : 'none',
                    flexDirection: 'row'
                  }}
                >
                  <div
                    style={{
                      display: previewVideo[index] ? 'none' : 'flex',
                      flexDirection: 'column',
                      maxWidth: '3vw'
                    }}
                  >
                    <div>
                      <IconButton>
                        <VideoCallIcon
                          onClick={() =>
                            window.open(slides[index].video, '_blank')
                          }
                        ></VideoCallIcon>
                      </IconButton>
                    </div>
                  </div>
                </div>
                <FormHelperText style={{ color: 'red' }}>
                  {slidesError[index][2]}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid>
              <IconButton
                disabled={index === 0}
                onClick={() => {
                  deleteSlide(index);
                }}
              >
                <DeleteIcon></DeleteIcon>
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </div>
    </div>
  );
}
