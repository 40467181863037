/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Product, Ingredients, Tags, Editions } from 'interfaces/stock';
import ModalCreateProduct from './ModalCreateProduct';
import ModalEditProduct from './ModalEditProduct';
import ModalDeleteProduct from './ModalDeleteProduct';
import ModalEditionHistory from './ModalEditionHistory';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputBase,
  Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/History';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ModalEditEdition from './ModalEditEdition';
import ModalDeleteEdition from './ModalDeleteEdition';
import SearchIcon from '@material-ui/icons/Search';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { fetchAllProducts } from 'redux/ducks/stock';
import { formatCurrencyValues } from '../../utils/format-functions/formatCurrencyValues';
import CsvModal from './csvModal';
const useStyles = makeStyles({
  root: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    flex: 1
  },
  input__search_container: {
    margin: '10px 10px 0 10px',
    '& .MuiInputBase-input': {
      marginLeft: 10
    }
  },
  button__export_csv_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  iconButton: {
    padding: 10
  },
  table: {
    minWidth: 10
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'black',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: '#fe309c',
      backgroundColor: 'black'
    }
  }
});

interface IProps {
  products: Product[];
  ingredients: Ingredients[];
  tags: Tags[];
  more: () => void;
  hasMore: boolean;
  reset: () => void;
  searchData: (data: string) => void;
  stores: any;
}

export default function ProductsTable(props: IProps) {
  const classes = useStyles();
  const { products, hasMore, more, reset, searchData, stores } = props;
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openEditEdition, setOpenEditEdition] = React.useState(false);
  const [openEditionHistory, setOpenEditionHistory] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openDeleteEdition, setOpenDeleteEdition] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(undefined);
  const [selectedEdition, setSelectedEdition] = React.useState(undefined);
  const [openEditions, setOpenEditions] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [fetching, setFetching] = React.useState(false);
  const [csvModal, setCsvModal] = React.useState(false);
  const [csvData, setCsvData] = React.useState({
    data: [],
    headers: [],
    filename: 'Weng_Inventory_Report.csv'
  });
  const [CsvDownloadData, setCsvDownloadData] = React.useState({
    data: [],
    headers: [],
    filename: 'Weng_Inventory_Report.csv'
  });
  // const [productsFiltered, setProductsFiltered] = React.useState<Product>([]);

  useEffect(() => {
    if (products) {
      const x = new Array(products.length).fill(false);
      setOpenEditions(x);
    }
  }, [props]);

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenEditEdition(false);
    setOpenEditionHistory(false);
    setOpenDelete(false);
    setOpenDeleteEdition(false);
    setSelectedProduct(undefined);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenEdit = (product: Product) => {
    setOpenEdit(true);
    setSelectedProduct(product);
  };

  const handleClickOpenDelete = (product: Product) => {
    setOpenDelete(true);
    setSelectedProduct(product);
  };

  const handleClickOpenEditionHistory = (edition: Editions) => {
    setOpenEditionHistory(true);
    setSelectedEdition(edition);
  };

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };
  // const handleEditions = (value: any) => {
  //   setEditionsSelected(value);
  // };
  const handleEdtionsExpand = (index: number) => {
    const x = [...openEditions];
    x[index] = x[index] ? false : true;
    setOpenEditions(x);
  };
  const handleCsvModal = () => {
    if (!fetching || csvData.data.length > 0) {
      loadCSV();
    }
    setCsvModal(true);
  };

  const downloadFile = () => {
    document.getElementById('csv-export-link').click();
  };
  const loadCSV = async () => {
    setFetching(true);
    const csvEditions = [];
    const csvHeaders = [
      'Artist',
      'Title',
      'Number',
      'Year',
      'Current location',
      'Acquisition history',
      'Acquisition date',
      'Store owner',
      'Internal condition notes',
      'Stock status',
      'Website price',
      'Buying price'
    ];
    const allProducts = await fetchAllProducts();
    const csvRows = [];
    //Save only editions in array
    allProducts?.forEach((product) => {
      product.editions.forEach((edition) => {
        csvEditions.push({
          Artist: product.artist.name,
          Title: edition.name,
          Number: edition.edition_number,
          Year: product.year,
          'Current location': edition.current_location,
          'Acquisition history': edition.acquired_from,
          'Acquisition date': edition.acquisition_date
            ? moment(edition.acquisition_date).format('DD/MM/YYYY')
            : '',
          'Store owner': edition.store_owner.name,
          'Internal condition notes': edition.internal_condition_notes,
          'Stock status': edition.stock_status,
          'Website price':
            '$ ' + formatCurrencyValues(edition.base_price, 'USD'),
          'Buying price': '€ ' + formatCurrencyValues(edition.cost, 'EUR')
        });
      });
    });

    csvEditions.forEach((edition) => {
      csvRows.push(edition);
    });

    setCsvData({
      data: csvRows,
      headers: csvHeaders,
      filename: 'Weng_Inventory_Report.csv'
    });

    setFetching(false);
  };

  return (
    <>
      <div className={classes.button__export_csv_container}>
        <div style={{ display: 'none' }}>
          <CSVLink {...CsvDownloadData} id={'csv-export-link'}></CSVLink>
        </div>
        <Button
          style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }}
          color="primary"
          onClick={handleCsvModal}
          className={classes.activeButton}
        >
          Export CSV{' '}
        </Button>
      </div>
      <CsvModal
        open={csvModal}
        loadData={() => loadCSV()}
        onClose={() => setCsvModal(false)}
        stores={stores}
        downloadFile={() => downloadFile()}
        fetching={fetching}
        csvData={csvData?.data}
        handleChangeCSVdata={(value: any) => setCsvDownloadData(value)}
      />
      <TableContainer className={classes.tableContainer} component={Paper}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Button
            style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }}
            color="primary"
            onClick={handleClickOpen}
            className={classes.activeButton}
          >
            Add Artwork
          </Button>

          <Paper className={classes.input__search_container}>
            <InputBase
              className={classes.input}
              placeholder="Search Artworks"
              inputProps={{ 'aria-label': 'search google maps' }}
              value={search}
              onChange={handleSearch}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  searchData(search);
                }
              }}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={() => {
                searchData(search);
              }}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <Table className={classes.table} aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: '5px', height: '6vh' }} />
              <TableCell style={{ padding: '5px', height: '6vh' }}>
                Artist
              </TableCell>
              <TableCell
                style={{ padding: '5px', height: '6vh', maxWidth: '9vw' }}
              >
                Title
              </TableCell>
              <TableCell
                style={{ padding: '5px', height: '6vh', maxWidth: '9vw' }}
              >
                No. of Editions
              </TableCell>
              <TableCell
                style={{ padding: '5px', height: '6vh' }}
                align="center"
              >
                Year
              </TableCell>
              <TableCell
                align="right"
                style={{ padding: '5px', height: '6vh' }}
              >
                Sold
              </TableCell>

              <TableCell
                align="right"
                style={{ padding: '5px', height: '6vh' }}
              >
                Retail Value
              </TableCell>
              <TableCell
                align="right"
                style={{ padding: '5px', height: '6vh' }}
              >
                Book Value
              </TableCell>
              <TableCell
                align="right"
                style={{ padding: '5px', height: '6vh' }}
              >
                Net Profit %
              </TableCell>
              <TableCell
                align="right"
                style={{ padding: '5px', height: '6vh' }}
              >
                Profit %
              </TableCell>
              <TableCell
                align="right"
                style={{ padding: '5px', height: '6vh' }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products?.map((product, index) => (
              <React.Fragment key={product.product_id}>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      height: '7vh',
                      padding: '5px'
                    }}
                  >
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        handleEdtionsExpand(index);
                      }}
                    >
                      {openEditions[index] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ padding: '5px', height: '7vh' }}>
                    {product.artist
                      ? product.artist.name?.split(' ')[
                          product.artist.name?.split(' ').length - 1
                        ]
                      : ''}
                  </TableCell>
                  <TableCell
                    style={{ padding: '5px', height: '7vh', maxWidth: '9vw' }}
                  >
                    {product.name ? product.name : ''}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ padding: '5px', height: '7vh', maxWidth: '9vw' }}
                  >
                    {
                      products[index]?.editions.filter(
                        (item) => item.stock_status !== 'Sold'
                      ).length
                    }
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ padding: '5px', height: '7vh', maxWidth: '9vw' }}
                  >
                    {product.year ? product.year : ''}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ padding: '5px', height: '7vh' }}
                  >
                    {product.sold ? product.sold : ''}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ padding: '5px', height: '7vh' }}
                  >
                    {product.price
                      ? product.price.toLocaleString(undefined, {
                          maximumFractionDigits: 2
                        })
                      : ''}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ padding: '5px', height: '7vh' }}
                  >
                    {product.cost
                      ? product.cost.toLocaleString(undefined, {
                          maximumFractionDigits: 2
                        })
                      : ''}
                  </TableCell>
                  {/* <TableCell
                  align="right"
                  style={{ padding: '5px', height: '7vh' }}
                >
                  {(
                    ((product.price - product.cost) / product.price) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell> */}
                  <TableCell
                    align="right"
                    style={{ padding: '5px', height: '7vh' }}
                  >
                    {product.price && product.cost
                      ? (
                          ((product.price - product.cost) / product.price) *
                          100
                        ).toFixed(2)
                      : 0.0}
                    %
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ padding: '5px', height: '7vh' }}
                  >
                    {product.price && product.cost
                      ? (
                          ((product.price - product.cost) / product.cost) *
                          100
                        ).toFixed(2)
                      : 0.0}
                    %
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ padding: '5px', height: '7vh' }}
                  >
                    <IconButton
                      style={{ padding: 1 }}
                      onClick={() => handleClickOpenEdit(product)}
                    >
                      <EditIcon />{' '}
                    </IconButton>
                    <IconButton
                      style={{ padding: 1 }}
                      onClick={() => handleClickOpenDelete(product)}
                    >
                      <DeleteIcon />
                    </IconButton>{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={10}
                  >
                    <Collapse
                      in={openEditions[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box margin={1}>
                        <Typography
                          gutterBottom
                          component="div"
                          style={{ fontSize: '1.0rem' }}
                        >
                          Editions
                        </Typography>
                        <Table
                          style={{ display: openEditions[index] ? '' : 'none' }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ padding: '5px', height: '6vh' }}
                              >
                                Title
                              </TableCell>
                              <TableCell
                                style={{ padding: '5px', height: '6vh' }}
                              >
                                Number
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ padding: '5px', height: '6vh' }}
                              >
                                Web URL
                              </TableCell>

                              <TableCell
                                align="right"
                                style={{ padding: '5px', height: '6vh' }}
                              >
                                Retail Price
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ padding: '5px', height: '6vh' }}
                              >
                                VAT 7.7%
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ padding: '5px', height: '6vh' }}
                              >
                                Book Value
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ padding: '5px', height: '6vh' }}
                              >
                                Stock Status
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ padding: '5px', height: '6vh' }}
                              >
                                Stock Owner
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ padding: '5px', height: '6vh' }}
                              >
                                Con/3rd party
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ padding: '5px', height: '6vh' }}
                              >
                                History
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {products[index].editions.map((edition) => (
                              <TableRow key={edition.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ padding: '5px', height: '7vh' }}
                                >
                                  {edition.name ? edition.name : ''}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ padding: '5px', height: '7vh' }}
                                >
                                  {edition.edition_number
                                    ? edition.edition_number
                                    : ''}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ padding: '5px', height: '7vh' }}
                                >
                                  {edition.slug ? edition.slug : ''}
                                </TableCell>

                                <TableCell
                                  align="right"
                                  style={{ padding: '5px', height: '7vh' }}
                                >
                                  {edition.base_price
                                    ? edition.base_price.toLocaleString(
                                        undefined,
                                        {
                                          maximumFractionDigits: 2
                                        }
                                      )
                                    : ''}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ padding: '5px', height: '7vh' }}
                                >
                                  {edition.base_price
                                    ? (
                                        edition.base_price * 1.077
                                      ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2
                                      })
                                    : ''}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ padding: '5px', height: '7vh' }}
                                >
                                  {edition.cost
                                    ? edition.cost.toLocaleString(undefined, {
                                        maximumFractionDigits: 2
                                      })
                                    : ''}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ padding: '5px', height: '7vh' }}
                                >
                                  {edition.stock_status
                                    ? edition.stock_status
                                    : ''}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ padding: '5px', height: '7vh' }}
                                >
                                  {edition.store_owner
                                    ? edition.store_owner.name
                                    : ''}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ padding: '5px', height: '7vh' }}
                                >
                                  {edition.consignor_owner
                                    ? edition.consignor_owner.name
                                    : ''}
                                </TableCell>

                                <TableCell
                                  align="right"
                                  style={{ padding: '5px', height: '7vh' }}
                                >
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                  >
                                    <HistoryIcon
                                      onClick={() => {
                                        handleClickOpenEditionHistory(edition);
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <ModalCreateProduct
          ingredients={props.ingredients}
          open={open}
          onClose={handleClose}
          tags={props.tags}
          reset={reset}
        />
        <ModalEditProduct
          ingredients={props.ingredients}
          open={openEdit}
          product={selectedProduct}
          onClose={handleClose}
          tags={props.tags}
          reset={reset}
        />
        <ModalDeleteProduct
          open={openDelete}
          product={selectedProduct}
          onClose={handleClose}
          reset={reset}
        />
        <ModalDeleteEdition
          open={openDeleteEdition}
          edition={selectedEdition}
          onClose={handleClose}
        />
        <ModalEditEdition
          open={openEditEdition}
          onClose={handleClose}
          edition={selectedEdition}
        />
        <ModalEditionHistory
          open={openEditionHistory}
          onClose={handleClose}
          edition={selectedEdition}
        />
        {hasMore && products.length > 0 && (
          <div
            style={{
              color: 'grey',
              fontSize: '1.2vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
            onClick={() => {
              if (hasMore) {
                more();
              }
            }}
          >
            Load More
          </div>
        )}
      </TableContainer>
    </>
  );
}
