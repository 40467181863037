import { Banner } from 'interfaces/banner';
import React from 'react';
import CreateBanner from './CreateBanner';

import ModalDeleteBanner from './ModalDeleteBanner';
import EditBanner from './EditBanner';

interface IProps {
  banners: Banner[];
  reload: () => void;
}

export default function MediaTable(props: IProps) {
  const { banners, reload } = props;
  const [open, setOpen] = React.useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = React.useState(false);
  const [modalEditOpen, setModalEditOpen] = React.useState(false);
  const [editedBanner, setEditedBanner] = React.useState<Banner>();
  const [deletedBanner, setDeletedBanner] = React.useState<Banner>();

  const createBannerCloseModal = () => {
    setOpen(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <CreateBanner
        open={open}
        onClose={() => createBannerCloseModal()}
        reload={reload}
      />
      <ModalDeleteBanner
        onClose={() => {
          setDeletedBanner(undefined);
          setModalDeleteOpen(false);
        }}
        open={modalDeleteOpen}
        banner={deletedBanner}
        reload={reload}
      />
      <EditBanner
        open={modalEditOpen}
        onClose={() => {
          setModalEditOpen(false);
          setEditedBanner(undefined);
        }}
        banner={banners.length > 0 ? banners[0] : editedBanner}
        reload={reload}
      />
    </div>
  );
}
