import { IAlertAction } from './alert';
import IBaseAction from './redux';

export interface IVoucherBase {
  id: number;
  created_date: string;
  name: string;
  description: string;
  type_of_coupon: string;
  discount_percentage: number;
  discount_fixed_amount: number;
  free_delivery: boolean;
  date_start: string;
  date_finish: string;
  status: boolean;
  collection: number[];
  client: {
    id: number;
    client: {
      profile_id: number;
      name: string;
      phone_number: string;
      cpf: string;
      total_billed: number;
      outstanding_to_pay: number;
      orders_placed: number;
      value_of_order_average: number;
      is_waiter: boolean;
      is_client: boolean;
      email: string;
      type_of_client: string;
      stripe_customer_id: string;
    };
  }[];
  all_products: boolean;
}

export interface IVoucherState {
  fetch: boolean;
  next: string;
  previous: string;
  results: IVoucherBase[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesVoucher {
  FETCH = 'VOUCHER/FETCH',
  SUCCESS = 'VOUCHER/SUCCESS',
  FAILURE = 'VOUCHER/FAILURE',
  RESET = 'VOUCHER/RESET'
}

export interface IFetchAction extends IBaseAction<ETypesVoucher, null> {
  type: ETypesVoucher.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesVoucher, IVoucherState> {
  type: ETypesVoucher.SUCCESS;
  payload: IVoucherState;
}

export interface IFailureAction extends IBaseAction<ETypesVoucher, string> {
  type: ETypesVoucher.FAILURE;
  payload: string;
}

export interface IResetAction extends IBaseAction<ETypesVoucher, null> {
  type: ETypesVoucher.RESET;
}

export type IVoucherAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction
  | IResetAction;

export type GetState = () => any;

export type PromiseAction = Promise<IVoucherAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IVoucherAction
    | ThunkAction
    | PromiseAction
    | Array<IVoucherAction>
    | IAlertAction
) => any;
