import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export interface IProduction {
  id: number;
  order_code: string;
  owner: number;
  status: string;
  last_status: string;
  order_type: string;
  batch: number;
  item: {
    name: string,
    ingredients: [{
      name: string,
      quantity: number,
      measure: string
    }]
  };
  quantity: number;
  measure: string;
  date_ordered: string;
  doing_at: string;
  done_at: string;
  finished_at: string;
}

export interface IProductionState {
  fetch: boolean;
  count: number;
  next?: string;
  previous?: string;
  currentOrderID: number;
  results: IProduction[];
  todo: IProduction[];
  doing: IProduction[];
  done: IProduction[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesProduction {
  FETCH = 'PRODUCTION/FETCH',
  SUCCESS = 'PRODUCTION/SUCCESS',
  FAILURE = 'PRODUCTION/FAILURE',
  SET = 'PRODUCTION/SET',
  UNSET = 'PRODUCTION/UNSET',
  CURRENT_ORDER_ID = 'PRODUCTION/CURRENT_ORDER_ID'
}

export interface IFetchAction extends IBaseAction<ETypesProduction, null> {
  type: ETypesProduction.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesProduction, IProductionState> {
  type: ETypesProduction.SUCCESS;
  payload: IProductionState;
}

export interface ICurrentOrderIDAction
  extends IBaseAction<ETypesProduction, number> {
  type: ETypesProduction.CURRENT_ORDER_ID;
  payload: number;
}

export interface ISetAction extends IBaseAction<ETypesProduction, IProduction> {
  type: ETypesProduction.SET;
  payload: IProduction;
}

export interface IUnsetAction
  extends IBaseAction<ETypesProduction, IProduction> {
  type: ETypesProduction.UNSET;
  payload: IProduction;
}

export interface IFailureAction extends IBaseAction<ETypesProduction, string> {
  type: ETypesProduction.FAILURE;
  payload: string;
}

export type IProductionAction =
  | IFetchAction
  | ISuccessAction
  | ICurrentOrderIDAction
  | ISetAction
  | IUnsetAction
  | IFailureAction;

export type GetState = () => any;

export type PromiseAction = Promise<IProductionAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IProductionAction
    | ThunkAction
    | PromiseAction
    | Array<IProductionAction>
    | IAlertAction
) => any;
