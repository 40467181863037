import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SOCKET_NOTIFICATION } from 'settings';
import { connect } from 'utils/socket';

import { getKitchen, updateKitchen } from 'redux/ducks/kitchen';
import { getStore } from 'redux/ducks/store';

import IStore from 'interfaces/store';
import { IStoreState } from 'interfaces/store_test';

import { IKitchen, IKitchenState } from 'interfaces/kitchen';

import { ESocketStatus } from 'enums/socket';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

import Panel from './Panel';
import Loading from 'components/Common/Loading';
import { Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

enum EDateFilter {
  HOJE = 'TODAY',
  SEMANA = 'WEEK',
  TODOS = 'ALL'
}

const dateFilter = [EDateFilter.HOJE, EDateFilter.SEMANA, EDateFilter.TODOS];

var storeFilter: { id: number; name: string }[] = [];

export default function Kitchen() {
  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-order');
    d.item(0).style.color = '#fe309c';

    d.item(1).style.color = '#fe309c';
    const dText = document.querySelectorAll<HTMLElement>('.d-order-text');
    dText.item(0).style.color = '#fe309c';
  });

  const dispatch = useDispatch();
  const { fetch, todo, doing, done, depot, baking } = useSelector<
    IStore,
    IKitchenState
  >((state) => state.kitchen);

  const { results } = useSelector<IStore, IStoreState>((state) => state.store);

  storeFilter = [];

  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });
  storeFilter.push({ id: 0, name: 'ALL' });

  const classes = useStyles();

  const [online, setOnline] = useState(false);
  const [filteredTodo, setFilteredTodo] = useState(todo);
  const [filteredDoing, setFilteredDoing] = useState(doing);
  const [filteredDone, setFilteredDone] = useState(done);
  const [filteredDepot, setFilteredDepot] = useState(depot);
  const [activeDateFilter, setActiveDateFilter] = useState('ALL');
  const [activeStoreFilter, setActiveStoreFilter] = useState(0);

  const handleActiveDateFilter = (value: string) => {
    setActiveDateFilter(value);
  };

  const handleActiveStoreFilter = (value: number) => {
    setActiveStoreFilter(value);
  };

  const getWeekDates = () => {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const numDay = now.getDate();

    const start = new Date(now);
    start.setDate(numDay - dayOfWeek + 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(now);
    end.setDate(numDay + (7 - dayOfWeek));
    end.setHours(23, 59, 59, 59);

    return [start, end];
  };

  const getTodayDates = () => {
    const now = new Date();

    const start = new Date(now);
    start.setHours(0, 0, 0, 0);

    const end = new Date(now);
    end.setHours(23, 59, 59, 59);

    return [start, end];
  };

  useEffect(() => {
    dispatch(getStore());
  }, [dispatch, activeDateFilter, activeStoreFilter]);

  useEffect(() => {
    dispatch(
      getKitchen({
        date_delivery: activeDateFilter,
        a_fazer: true,
        fazendo: true,
        feito: true,
        depot: true,
        baking: true,
        store: activeStoreFilter
      })
    );
  }, [dispatch, activeDateFilter, activeStoreFilter]);

  const filterData = {
    dateDelivery: activeDateFilter,
    store: activeStoreFilter
  };

  useEffect(() => {
    let kitchenSocket = connect(SOCKET_NOTIFICATION);

    kitchenSocket.onopen = () => {
      setOnline(true);
    };

    kitchenSocket.onmessage = (event) => {
      try {
        let new_data = JSON.parse(event.data);
        if (new_data.message.order_id) {
          if (new_data && new_data.store_id === activeStoreFilter) {
            dispatch(updateKitchen(JSON.parse(event.data)));
          } else if (new_data && activeStoreFilter === 0) {
            dispatch(updateKitchen(JSON.parse(event.data)));
          }
        }
      } catch (error) {
        console.log('error ', error);
      }
    };

    kitchenSocket.onerror = (e) => {
      console.log('error ', e);
      kitchenSocket.close();
    };

    kitchenSocket.onclose = () => kitchenSocket.close();

    const heartbeat = setInterval(() => {
      if (kitchenSocket.readyState === ESocketStatus.OPEN) setOnline(true);

      if (kitchenSocket.readyState !== ESocketStatus.OPEN) setOnline(false);

      if (!online) kitchenSocket = connect(SOCKET_NOTIFICATION);
    }, 5000);

    return () => {
      kitchenSocket.close();
      clearInterval(heartbeat);
    };
  }, [online, dispatch]);

  useEffect(() => {
    const [start, end] =
      activeDateFilter === EDateFilter.HOJE
        ? getTodayDates()
        : activeDateFilter === EDateFilter.SEMANA
        ? getWeekDates()
        : [null, null];

    function filterDates(order: IKitchen) {
      if (start && end) {
        if (
          new Date(order.date_ordered) >= new Date(start) &&
          new Date(order.date_ordered) <= new Date(end)
        ) {
          return true;
        }
        if (
          new Date(order.date_delivery) >= new Date(start) &&
          new Date(order.date_delivery) <= new Date(end)
        ) {
          return true;
        }
        return false;
      } else {
        return order;
      }
    }

    const toDofilter = todo.filter((order) => filterDates(order));
    setFilteredTodo(toDofilter);

    const doingfilter = doing.filter((order) => filterDates(order));
    setFilteredDoing(doingfilter);

    const donefilter = done.filter((order) => filterDates(order));
    setFilteredDone(donefilter);

    const depotfilter = depot.filter((order) => filterDates(order));
    setFilteredDepot(depotfilter);
  }, [activeDateFilter, todo, doing, done, depot]);

  const panels = [
    {
      key: 1,
      headerStyle: classes.cardHeaderTodo,
      headerTitle: 'PENDING',
      bodyStyle: classes.cardBodyTodo,
      orders: filteredTodo,
      showBackBtn: false,
      isFirstStage: true,
      showIsPaid: true,
      showPrintBtn: true
    },
    {
      key: 2,
      headerStyle: classes.cardHeaderDoing,
      headerTitle: 'ACCEPTED',
      bodyStyle: classes.cardBodyDoing,
      orders: filteredDoing,
      showIsPaid: true,
      showPrintBtn: true
    },
    {
      key: 3,
      headerStyle: classes.cardHeaderDone,
      headerTitle: 'PREPARING',
      bodyStyle: classes.cardBodyDone,
      orders: filteredDone,
      showOKBtn: true,
      showBackBtn: true,
      showIsPaid: true,
      showPrintBtn: true
    },
    {
      key: 4,
      headerStyle: classes.cardHeaderDepot,
      headerTitle: 'READY',
      bodyStyle: classes.cardBodyDepot,
      orders: filteredDepot,
      showOKBtn: true,
      showBackBtn: true,
      showIsPaid: true,
      showExtraInfo: true,
      showPrintBtn: true
    }
  ];

  return (
    <>
      <Loading show={fetch} />
      <Grid style={{ marginBottom: 10 }}>
        <Typography
          color="primary"
          variant="overline"
          className={`${classes.toolBarTitles}`}
        >{`store: `}</Typography>
        {storeFilter.map((elmnt) => (
          <Button
            key={uuidv4()}
            onClick={() => {
              handleActiveStoreFilter(elmnt.id);
            }}
            size="small"
            className={
              activeStoreFilter === elmnt.id
                ? classes.activeButton
                : classes.inactiveButton
            }
          >
            {elmnt.name}
          </Button>
        ))}
      </Grid>
      <Grid style={{ marginBottom: 10 }}>
        <Typography
          color="primary"
          variant="overline"
          className={`${classes.toolBarTitles}`}
        >{`for: `}</Typography>
        {dateFilter.map((elmnt) => (
          <Button
            key={uuidv4()}
            onClick={() => {
              handleActiveDateFilter(elmnt);
            }}
            variant="outlined"
            size="small"
            className={
              activeDateFilter === elmnt
                ? classes.activeButton
                : classes.inactiveButton
            }
          >
            {elmnt}
          </Button>
        ))}
      </Grid>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="space-between"
      >
        {panels.map((panel) => (
          <Panel
            key={panel.key}
            headerStyle={panel.headerStyle}
            headerTitle={panel.headerTitle}
            bodyStyle={panel.bodyStyle}
            orders={panel.orders}
            showOKBtn={panel.showOKBtn}
            showBackBtn={panel.showBackBtn}
            showIsPaid={panel.showIsPaid}
            showPrintBtn={panel.showPrintBtn}
            isFirstStage={panel.isFirstStage}
            showExtraInfo={panel.showExtraInfo}
            filterData={filterData}
          />
        ))}
      </Grid>
      {!fetch &&
        todo.length === 0 &&
        doing.length === 0 &&
        done.length === 0 &&
        depot.length === 0 &&
        baking.length === 0 && (
          <Typography variant="h6" align="center" style={{ marginBottom: 10 }}>
            No orders yet
          </Typography>
        )}
    </>
  );
}
