import React from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Backdrop,
  Fade,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { LastOrder } from 'interfaces/customersAndSellers';
import { getPaymentType } from 'helpers/common';
import Text from './Text';
import formatDate from 'date-fns/format';
import { pt } from 'date-fns/locale';
import useStyles from './styles';
interface IProps {
  open: boolean;
  handleClose: () => void;
  orderToShow: LastOrder;
  loading: boolean;
}

export default function TransitionsModal(props: IProps) {
  const { open, handleClose, orderToShow, loading } = props;
  const classes = useStyles();
  const dateOrdered = orderToShow?.date_ordered
    ? new Date(orderToShow?.date_ordered)
    : new Date();
  const dateDelivery = orderToShow?.date_delivery
    ? new Date(orderToShow?.date_delivery)
    : new Date();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {orderToShow && (
                <>
                  {' '}
                  <Typography variant="h4">
                    {orderToShow?.order_code}
                  </Typography>
                  <Typography variant="h6">Payment</Typography>
                  <Text paragraph>{getPaymentType(orderToShow?.payment)}</Text>
                  {orderToShow.insurance_requested ? (
                    <Typography>
                      Insurance:{' '}
                      {orderToShow?.insurance_value_cents?.toLocaleString(
                        'en-US',
                        {
                          style: 'currency',
                          currency: orderToShow.currency
                        }
                      )}
                    </Typography>
                  ) : null}
                  {orderToShow.vat ? (
                    <Typography>
                      VAT:{' '}
                      {orderToShow.vat.toLocaleString('en-US', {
                        style: 'currency',
                        currency: orderToShow.currency
                      })}
                    </Typography>
                  ) : null}
                  {orderToShow.dhl_price ? (
                    <Typography>
                      Delivery:{' '}
                      {parseFloat(orderToShow.dhl_price).toLocaleString(
                        'en-US',
                        {
                          style: 'currency',
                          currency: orderToShow.currency
                        }
                      )}
                    </Typography>
                  ) : null}
                  <Text paragraph>
                    Total: € {orderToShow?.total_order.toFixed(2)}
                  </Text>
                  <Typography variant="h6">Ordered</Typography>
                  {orderToShow?.items.map((item) => (
                    <>
                      <Text paragraph key={item.product.name}>
                        {item.quantity}x - {item.product.name}
                      </Text>
                      <Typography style={{ fontStyle: 'italic' }}>
                        {item.edition.edition_number}
                      </Typography>
                      <Typography>{item.product.artist.name}</Typography>
                    </>
                  ))}
                  <Typography variant="h6">Information</Typography>
                  <Text paragraph>
                    Order date:{' '}
                    {formatDate(dateOrdered, 'dd/MM/yy - HH:mm', {
                      locale: pt
                    })}
                  </Text>
                  {orderToShow?.date_delivery && (
                    <Text paragraph>
                      Delivery date:{' '}
                      {formatDate(dateDelivery, 'dd/MM/yy - HH:mm', {
                        locale: pt
                      })}
                    </Text>
                  )}
                  {orderToShow.delivery_address ? (
                    <Typography>
                      Delivery address:{' '}
                      {orderToShow.delivery_address
                        ?.replace(/[()''_]/g, '')
                        .replace(', 0', '')}
                    </Typography>
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
