import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export enum EFilters {
  PRODUCTS = 'products',
  INGREDIENTS = 'ingredients'
}

export enum ETypesCustomersAndSellers {
  FETCH = 'STOCK/FETCH',
  SUCCESS = 'STOCK/SUCCESS',
  FAILURE = 'STOCK/FAILURE',
  RESET = 'STOCK/RESET'
}

export interface ICustomersAndSellersState {
  fetch?: boolean;
  customers: Customer[];
  sellers: Seller[];
  last_order: LastOrder;
}

export interface Customer {
  name: string;
  id: number;
  phone_number: string;
  email: string;
  orders_placed: number;
  total_billed: number;
  value_of_order_average: number;
  outstanding_to_pay: number;
  last_order: {
    id: number;
    total_order: number;
  };
  orders_list: number[];
  wishlist: number[];
}

export interface Seller {
  id: number;
  username: string;
  profile: {
    total_billed: number;
    outstanding_to_pay: number;
    orders_placed: number;
    value_of_order_average: number;
  };
}

export interface LastOrder {
  order_code: string;
  payment: string;
  total_order: number;
  delivery_fee: number;
  cash_change: null | number;
  cash_paid: null | number;
  discount: null | number;
  date_delivery: string;
  date_ordered: string;
  final_comment: string;
  insurance_requested: boolean;
  insurance_value_cents: number;
  insurance_premium_amount_cents: number;
  currency: string;
  vat: number;
  dhl_price: string;
  delivery_address: string;
  items: Array<{
    quantity: number;
    product: {
      name: string;
      price: number;
      artist: {
        name: string;
      };
    };
    edition: {
      name: string;
      edition_number: string;
    };
  }>;
}
export interface IFetchAction
  extends IBaseAction<ETypesCustomersAndSellers, null> {
  type: ETypesCustomersAndSellers.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesCustomersAndSellers, ICustomersAndSellersState> {
  type: ETypesCustomersAndSellers.SUCCESS;
  payload: ICustomersAndSellersState;
}

export interface IFailureAction
  extends IBaseAction<ETypesCustomersAndSellers, string> {
  type: ETypesCustomersAndSellers.FAILURE;
  payload: string;
}

export interface IResetAction
  extends IBaseAction<ETypesCustomersAndSellers, null> {
  type: ETypesCustomersAndSellers.RESET;
}

export type IManagerAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction
  | IResetAction;

export type GetState = () => any;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action: IManagerAction | ThunkAction | Array<IManagerAction> | IAlertAction
) => any;
