import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrders } from 'redux/ducks/balcony';

import IStore from 'interfaces/store';
import { IBalconyState } from 'interfaces/balcony';
import { EBalconyStatus } from 'enums/order';

import Grid from '@material-ui/core/Grid';

import Panel from './Panel';
import Loading from 'components/Common/Loading';
import moment from 'moment';

export default function Balcony(): JSX.Element {
  const dispatch = useDispatch();
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(new Date());
  const [isFiltering, setIsFiltering] = useState(false);
  const [message, setMessage] = useState('Showing all orders.');
  const orders = useSelector<IStore, IBalconyState>((state) => state.balcony);

  const clearFilter = () => {
    setIsFiltering(false);
    setInitialDate(null);
    setFinalDate(new Date());
    setMessage('Showing all orders.');
  };

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-completed');
    d.item(0).style.color = '#fe309c';

    d.item(1).style.color = '#fe309c';
    const dText = document.querySelectorAll<HTMLElement>('.d-completed-text');
    dText.item(0).style.color = '#fe309c';
  });

  useEffect(() => {
    dispatch(getOrders());
  }, []);

  useEffect(() => {
    filter();
  }, [orders?.fetch]);

  const [filteredOrders, setFilteredOrders] = useState(orders.results);

  const filter = () => {
    const init = new Date(initialDate).getTime();
    const end = new Date(finalDate).getTime();

    const filtered = [];

    const completedOrders = orders.results.filter(
      (order) => order.status === EBalconyStatus.COMPLETED
    );

    completedOrders?.map((order) => {
      if (
        new Date(order.date_ordered).getTime() >= init &&
        new Date(order.date_ordered).getTime() <= end
      ) {
        filtered.push(order);
      }
    });

    setFilteredOrders(filtered);

    if (initialDate) {
      const friendlyInit = moment(init).format('YYYY-MM-DD');
      const friendlyEnd = moment(end).format('YYYY-MM-DD');

      const message = `Showing orders from ${friendlyInit} to ${friendlyEnd}`;
      setMessage(message);
    }
  };

  return (
    <Grid container>
      <Loading show={orders.fetch} />
      {orders && (
        <Panel
          initialDate={initialDate}
          setInitialDate={setInitialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
          isFiltering={isFiltering}
          filter={filter}
          clearFilter={clearFilter}
          message={message}
          orders={filteredOrders}
        />
      )}
    </Grid>
  );
}
