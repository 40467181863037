/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/interface-name-prefix */
import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  IconButton,
  Grid,
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormHelperText,
  FormControlLabel
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { addVoucher } from 'redux/ducks/vouchers';
import { useDispatch } from 'react-redux';
import { Menu } from 'interfaces/menu';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  menus: Menu[];
}

export default function ModalCreateInvoice(props: SimpleDialogProps) {
  const { onClose, open, menus } = props;
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [typeOfCoupon, setTypeOfCoupon] = useState<any>('');
  const [discountPercentage, setDiscountPercentage] = useState<any>(0);
  const [discountFixedAmount, setDiscountFixedAmount] = useState<any>(0);
  const [dateStart, setDateStart] = useState('');
  const [dateFinish, setDateFinish] = useState('');
  const [status, setStatus] = useState<any>(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [errors, setErrors] = useState({
    name: '',
    description: '',
    typeOfCoupon: '',
    discountPercentage: '',
    discountFixedAmount: '',
    dateStart: '',
    dateFinish: '',
    status: ''
  });

  //const [collection, setCollection] = useState([]);
  const dispatch = useDispatch();
  const checkForm = () => {
    console.log(typeOfCoupon);
    var entered = false;
    if (name === '') {
      console.log(1);
      entered = true;
      setErrors({ ...errors, name: 'This field is required' });
    }
    if (description === '') {
      entered = true;
      console.log(2);

      setErrors({ ...errors, description: 'This field is required' });
    }
    if (typeOfCoupon === '') {
      entered = true;
      console.log(3);

      setErrors({ ...errors, typeOfCoupon: 'This field is required' });
    }
    if (typeOfCoupon === 'percentage') {
      if (discountPercentage === '') {
        entered = true;
        setErrors({ ...errors, discountPercentage: 'This field is required' });
      }
      setDiscountFixedAmount(0);
    }
    if (typeOfCoupon === 'fixed') {
      if (discountFixedAmount === 0) {
        entered = true;
        setErrors({ ...errors, discountFixedAmount: 'This field is required' });
      }
    }
    if (dateStart === '') {
      entered = true;
      console.log(6);

      setErrors({ ...errors, dateStart: 'This field is required' });
    }
    if (dateFinish === '') {
      entered = true;
      console.log(7);

      setErrors({ ...errors, dateFinish: 'This field is required' });
    }

    return entered;
  };
  const submit = () => {
    if (!checkForm()) {
      const data = {
        name: name,
        description: description,
        type_of_coupon: typeOfCoupon,
        discount_percentage:
          typeOfCoupon === 'percentage' ? discountPercentage : 0,
        discount_fixed_amount:
          typeOfCoupon === 'fixed' ? discountFixedAmount : 0,
        free_delivery: typeOfCoupon === 'free_delivery' ? true : false,
        date_start: dateStart,
        date_finish: dateFinish,
        status: status === 0 ? false : true,
        collection: selectedMenus,
        all_products: selectAll

      };
      dispatch(addVoucher(data)).then((res) => {
        if (res) {
          handleClose();
          onClose();
        }
      });
    }
  };
  const handleClose = () => {
    setName('');
    setDescription('');
    setDateStart('');
    setDateFinish('');
    setTypeOfCoupon('');
    setDiscountFixedAmount(0);
    setDiscountPercentage(0);
    setStatus('');
    setTypeOfCoupon('');
  };
  const handleChangeMenu = (value: any) => {
    var value = value;
    if (value[value?.length - 1] === 'all') {
      setSelectedMenus(setSelectedMenus.length === menus.length ? [] : menus);
      return;
    }
    setSelectedMenus(value);
  };

  const isSelected = (id) => {
    if (id && selectedMenus) {
      return selectedMenus.includes(id);
    }
    return false;
  };

  const typeOfCouponInput = () => {
    switch (typeOfCoupon) {
      case 'percentage':
        return (
          <Grid style={{ marginBottom: '1vw', marginRight: '15px' }}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '17.5vw'
              }}
              required
              margin={'normal'}
              error={errors.discountPercentage !== ''}
            >
              <InputLabel htmlFor="name-input">Discount percentage</InputLabel>
              <Input
                id="name-input"
                type="number"
                multiline
                required
                value={discountPercentage}
                onChange={(e) => {
                  setDiscountPercentage(e.target.value);
                }}
              />{' '}
              <FormHelperText>{errors.discountPercentage}</FormHelperText>
            </FormControl>
          </Grid>
        );
      case 'fixed':
        return (
          <Grid style={{ marginBottom: '1vw', marginRight: '15px' }}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '17.5vw'
              }}
              required
              margin={'normal'}
              error={errors.discountFixedAmount !== ''}
            >
              <InputLabel htmlFor="name-input">
                Discount fixed amount
              </InputLabel>
              <Input
                id="name-input"
                type="number"
                multiline
                required
                value={discountFixedAmount}
                onChange={(e) => {
                  setDiscountFixedAmount(e.target.value);
                }}
              />{' '}
              <FormHelperText>{errors.discountFixedAmount}</FormHelperText>
            </FormControl>
          </Grid>
        );
      case 'free_delivery':
        return null;
      default:
        return null;
    }
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={(e) => {
        handleClose();
        onClose();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={{ padding: '15px' }} className="modal-create-menu-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => {
              handleClose();
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{
              width: 'fit-content'
            }}
            onClick={() => submit()}
          >
            Save
          </Button>
        </div>
        <Typography variant="h5">Create voucher</Typography>
        <Grid container>
          <Grid style={{ marginBottom: '1vw', marginRight: '15px' }}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '17.5vw'
              }}
              required
              margin={'normal'}
              error={errors.name !== ''}
            >
              <InputLabel htmlFor="name-input">Name</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={name}
                required
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <FormHelperText>{errors.name}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid style={{ marginBottom: '1vw', marginRight: '15px' }}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '35vw'
              }}
              required
              margin={'normal'}
              error={errors.description !== ''}
            >
              <InputLabel htmlFor="name-input">Description</InputLabel>
              <Input
                id="name-input"
                type="text"
                multiline
                required
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />{' '}
              <FormHelperText>{errors.description}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid style={{ marginBottom: '1vw', marginRight: '15px' }}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '17.5vw'
              }}
              fullWidth={true}
              margin={'normal'}
              required
              error={errors.typeOfCoupon !== ''}
            >
              <InputLabel htmlFor="name-input">Type of coupon</InputLabel>
              <Select
                labelId="label"
                id="currency-input"
                value={typeOfCoupon}
                onChange={(e) => {
                  setTypeOfCoupon(e.target.value);
                }}
              >
                <MenuItem value="percentage">Percentage</MenuItem>
                <MenuItem value="fixed">Fixed amount</MenuItem>
                <MenuItem value="free_delivery">Free delivery</MenuItem>
                <MenuItem value="free_delivery_insurance">
                  Free shipping + free insurance
                </MenuItem>
              </Select>{' '}
              <FormHelperText>{errors.typeOfCoupon}</FormHelperText>
            </FormControl>
          </Grid>
          {typeOfCouponInput()}

          <Grid style={{ marginBottom: '1vw', marginRight: '15px' }}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '17.5vw'
              }}
              fullWidth
              required
              margin={'normal'}
              error={errors.dateStart !== ''}
            >
              <TextField
                id="datetime-local"
                label="Start date"
                required
                type="date"
                //  error={invoiceDateError !== ''}
                InputLabelProps={{
                  shrink: true
                }}
                value={dateStart}
                onChange={(e) => {
                  setDateStart(e.target.value);
                }}
              />
              <FormHelperText>{errors.dateStart}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid style={{ marginBottom: '1vw', marginRight: '15px' }}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '17.5vw'
              }}
              fullWidth
              margin={'normal'}
              error={errors.dateFinish !== ''}
            >
              <TextField
                id="datetime-local"
                label="Finish date"
                required
                type="date"
                //  error={invoiceDateError !== ''}
                InputLabelProps={{
                  shrink: true
                }}
                value={dateFinish}
                onChange={(e) => {
                  setDateFinish(e.target.value);
                }}
              />
              <FormHelperText>{errors.dateFinish}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid
            style={{
              marginBottom: '1vw',
              marginRight: '15px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'end'
            }}
          >
            <FormControl
              style={{
                marginTop: '0px',
                width: '17.5vw'
              }}
            >
              <InputLabel id="mutiple-select-label">
                Select collections
              </InputLabel>
              <Select
                labelId="mutiple-select-label"
                multiple
                disabled={selectAll}
                value={selectedMenus}
                onChange={(event) => {
                  handleChangeMenu(event.target.value);
                }}
                renderValue={(selected) =>
                  selectedMenus.length > 0 ? selectedMenus.join(', ') : ''
                }
              >
                {menus.map((option) => (
                  <MenuItem key={option.menu_id} value={option.menu_id}>
                    <ListItemIcon>
                      <Checkbox checked={isSelected(option.menu_id)} />
                    </ListItemIcon>
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ marginLeft: '8px', marginRight: '8px' }}>
              <FormControlLabel
                label="Select all products"
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={(e) => {
                      setSelectAll(e.target.checked);
                      setSelectedMenus([]);
                    }}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid style={{ marginBottom: '1vw', marginRight: '15px' }}>
            <FormControl
              style={{
                marginTop: '0px',
                width: '17.5vw'
              }}
              fullWidth={true}
              margin={'normal'}
              required
              error={errors.status !== ''}
            >
              <InputLabel htmlFor="name-input">Status</InputLabel>
              <Select
                labelId="label"
                id="currency-input"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <MenuItem value={0}>Inactive</MenuItem>
                <MenuItem value={1}>Active</MenuItem>
              </Select>{' '}
              <FormHelperText>{errors.status}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
