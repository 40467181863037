import {
  ILatestState,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  ILatestAction,
  Dispatch,
  ThunkAction,
  ETypesLatest,
  IFirstLoadAction,
  GetState,
  blocks
} from 'interfaces/latest';

import { EAlertVariant } from 'interfaces/alert';

import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Latest State. */
const initialState: ILatestState = {
  fetch: false,
  count: 0,
  results: [],
  next: '',
  previous: '',
  error: ''
};

/* Latest Reducer. */
export default (
  state: ILatestState = initialState,
  action: ILatestAction
): ILatestState => {
  switch (action.type) {
    case ETypesLatest.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesLatest.FIRSTLOAD:
      return {
        ...state,
        fetch: false,
        count: action.payload.count,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesLatest.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesLatest.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* Latest Action Creators Functions. */
export const fetchLatest = (): IFetchAction => ({
  type: ETypesLatest.FETCH
});

export const successLatest = (payload: ILatestState): ISuccessAction => ({
  type: ETypesLatest.SUCCESS,
  payload
});

export const firstLoadLatest = (payload: ILatestState): IFirstLoadAction => ({
  type: ETypesLatest.FIRSTLOAD,
  payload
});

export const failureLatest = (payload: string): IFailureAction => ({
  type: ETypesLatest.FAILURE,
  payload
});

/* Latest Side Effects Functions. */
export const getLatest = (): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { latest } = getState();
    let request_url;
    dispatch(fetchLatest());
    let results = [];
    request_url = `/products/api/latest/`;
    const response = await fetch({
      method: EMethod.GET,
      url: request_url
    });
    results = response.results;
    dispatch(successLatest({ ...latest, results }));
  } catch (error) {
    console.log(error);
    dispatch(failureLatest(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};
export const deleteLatest = (id: number): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<number> => {
  try {
    let request_url;
    request_url = `/products/api/latest/${id}/`;
    await fetch({
      method: EMethod.DELETE,
      url: request_url
    });
    dispatch(sendAlert('Deleted successfuly', EAlertVariant.SUCCESS));
    return 1;
  } catch (error) {
    console.log(error);
    dispatch(failureLatest(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return 0;
  }
};
export const createLatest = (data: {}, blocks: blocks[]): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<number> => {
  try {
    let request_url;
    const body = new FormData();
    Object.keys(data).forEach((key) => {
      body.append(key, data[key]);
    });
    request_url = `/products/api/latest/`;
    const response = await fetch({
      method: EMethod.POST,
      url: request_url,
      data: body
    });
    request_url = `/products/api/latest/${response.id}/add_block_to_latest/`;
    await Promise.all(
      blocks.map(async (block) => {
        const bodyForBlock = new FormData();
        Object.keys(block).forEach((key) => {
          bodyForBlock.append(key, block[key]);
        });
        await fetch({
          method: EMethod.POST,
          url: request_url,
          data: bodyForBlock
        });
      })
    );
    return 1;
  } catch (error) {
    console.log(error);
    dispatch(failureLatest(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return 0;
  }
};
export const editLatest = (
  id: number,
  data: {},
  blocks: blocks[],
  editedBlocks: blocks[],
  deletedBlocks: number[]
): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<number> => {
  try {
    let request_url;
    const body = new FormData();
    Object.keys(data).forEach((key) => {
      body.append(key, data[key]);
    });
    request_url = `/products/api/latest/${id}/`;
    await fetch({
      method: EMethod.PATCH,
      url: request_url,
      data: body
    });
    request_url = `/products/api/latest/${id}/add_block_to_latest/`;
    await Promise.all(
      blocks.map(async (block) => {
        const bodyForBlock = new FormData();
        Object.keys(block).forEach((key) => {
          bodyForBlock.append(key, block[key]);
        });
        await fetch({
          method: EMethod.POST,
          url: request_url,
          data: bodyForBlock
        });
      })
    );
    await Promise.all(
      editedBlocks.map(async (block) => {
        const bodyForBlock = new FormData();
        Object.keys(block).forEach((key) => {
          bodyForBlock.append(key, block[key]);
        });
        request_url = `/products/api/block/${block.block_id}/`;
        await fetch({
          method: EMethod.PATCH,
          url: request_url,
          data: bodyForBlock
        });
      })
    );
    await Promise.all(
      deletedBlocks.map(async (block) => {
        request_url = `/products/api/block/${block}/`;
        await fetch({
          method: EMethod.DELETE,
          url: request_url
        });
      })
    );
    return 1;
  } catch (error) {
    console.log(error);
    dispatch(failureLatest(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return 0;
  }
};
