import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
import { DropzoneArea } from 'material-ui-dropzone';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  InputLabel,
  Input,
  Select,
  MenuItem,
  IconButton,
  Typography
} from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createMenu } from 'redux/ducks/menu';
import CloseIcon from '@material-ui/icons/Close';
import '../Stock/styles.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import Typography from '@material-ui/core/Typography';

const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});
const ckEditorToolbarConfig = [
  'Heading',
  '|',
  'bold',
  'italic',
  '|',
  'BulletedList',
  'NumberedList',
  '|',
  'Indent',
  '|',
  'BlockQuote',
  'undo',
  'redo'
];
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function ModalCreateMenu(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const [bannerSource, setBannerSource] = React.useState<File>();
  const [name, setName] = React.useState('');
  const [collectionPageTitle, setCollectionPageTitle] = React.useState('');
  const [slug, setSlug] = React.useState('');
  const [typeOfMenu, setTypeOfMenu] = React.useState('Website');
  const [description, setDescription] = React.useState('');
  const [active, setActive] = React.useState(true);

  const dispatch = useDispatch();

  const handleClose = () => {
    setName('');
    setActive(true);
    setDescription('');
    onClose();
  };

  const handleBanner = (event: any) => {
    if (event.length > 0) {
      setBannerSource(event[0]);
    }
  };

  const handleName = (event: any) => {
    setName(event.target.value);
  };
  const handleSlug = (event: any) => {
    setSlug(event.target.value);
  };
  const handleCollectionPageTitle = (event: any) => {
    setCollectionPageTitle(event.target.value);
  };

  const handleTypeOfMenu = (event: any) => {
    setTypeOfMenu(event.target.value);
  };

  const submitCreation = async () => {
    dispatch(
      createMenu({
        banner: bannerSource,
        name,
        collection_page_title: collectionPageTitle,
        type_of_menu: typeOfMenu,
        description,
        active,
        slug
      })
    ).then((result: boolean) => {
      if (result) handleClose();
    });
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="simple-dialog-title">Add Collection</DialogTitle>
        <Grid item xs={12} sm={6}>
          <ThemeProvider theme={theme}>
            <DropzoneArea
              acceptedFiles={['image/*']}
              dropzoneText={'Drag and drop an image here or click'}
              onChange={(files) => handleBanner(files)}
              filesLimit={1}
            />
            <Typography variant="caption" style={{ color: '#fe309c' }}>
              For better resolution insert images with 700×892
            </Typography>
            <br />
          </ThemeProvider>
        </Grid>
        <FormControl style={{ margin: '0px 10px' }}>
          <InputLabel htmlFor="name-input">Name</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={name}
            onChange={handleName}
          />
        </FormControl>
        <FormControl style={{ margin: '0px 10px' }}>
          <InputLabel htmlFor="name-input">Collection Page Title</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={collectionPageTitle}
            onChange={handleCollectionPageTitle}
          />
        </FormControl>
        <FormControl style={{ margin: '0px 10px' }}>
          <InputLabel htmlFor="name-input">Slug</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={slug}
            onChange={handleSlug}
          />
        </FormControl>
        <FormControl style={{ margin: '0px 10px' }}>
          <InputLabel htmlFor="price-input">Type of Collection</InputLabel>
          <Select
            labelId="label"
            id="select"
            value={typeOfMenu}
            onChange={handleTypeOfMenu}
          >
            <MenuItem value="Website">Website</MenuItem>
            <MenuItem value="App">App</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ margin: '0px 10px' }}>
          <div
            style={{
              fontWeight: 'lighter',
              fontSize: '1.1vw',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '1vw'
            }}
          >
            Description
          </div>
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: ckEditorToolbarConfig
            }}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
            }}
          />
        </FormControl>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={submitCreation} color="primary">
            {' '}
            Create{' '}
          </Button>
          <Button onClick={handleClose}> Cancel </Button>
        </div>
      </div>
    </Modal>
  );
}
