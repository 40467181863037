export const formatCurrencyValues = (value: number, currency: string) => {
  const formatedValue = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
    currencyDisplay: 'code'
  })
    .format(value)
    .replace(currency, '')
    .trim();
  return formatedValue;
};
