import React from 'react';

import { IKitchen } from 'interfaces/kitchen';
import { getPaymentType } from 'helpers/common';
import { getDeliveryType } from 'helpers/kitchen';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getWhastappUrl } from 'helpers/balcony';
import formatDate from 'date-fns/format';

interface IProps {
  order: IKitchen;
}

export default function Client(props: IProps): JSX.Element {
  const { order } = props;

  const phone =
    order?.owner?.phone_number?.replace(/\D+/g, '') ??
    order?.client?.phone?.replace(/\D+/g, '');

  return (
    <Grid>
      <Typography variant="overline" style={{ fontWeight: 'bold' }}>
        ORDER DATA
      </Typography>
      <br />
      <Typography variant="overline">
        CLIENT: {order?.owner?.name ?? order?.client?.name}
      </Typography>
      <br />
      <Typography variant="overline">
        CLIENT NUMBER:
        <a
          href={getWhastappUrl(order, phone)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {order?.owner?.phone_number ?? order?.client?.phone}
        </a>
      </Typography>
      <Grid container direction="column" justifyContent="space-between">
        <Typography variant="overline">
          PAYMENT: {`${getPaymentType(order.payment)}`}
        </Typography>
        <Typography variant="overline">
          TYPE: {`${getDeliveryType(order.type_of_order)}`}
        </Typography>
        {['Canceled', 'Canceld and Refunded'].includes(order.status) && (
          <Typography variant="overline" style={{ color: 'red' }}>
            CANCELED
          </Typography>
        )}
        <br />
      </Grid>
      {order?.delivery_address && (
        <>
          <Typography variant="overline" style={{ fontWeight: 'bold' }}>
            DELIVERY DETAILS
          </Typography>
          {order.date_delivery && (
            <>
              <br />
              <Typography variant="overline">{`DATA: ${formatDate(
                new Date(order.date_delivery),
                'dd/MM/yyyy - HH:mm'
              )}`}</Typography>
            </>
          )}
          <br />
          <Typography variant="overline">{`${order.delivery_address}`}</Typography>
          <br />
          <Typography variant="overline">
            DELIVERYMAN:{' '}
            {`${
              order?.gomotoboy_driver_name
                ? order?.gomotoboy_driver_name
                : 'Undefined'
            }`}
          </Typography>
        </>
      )}
      <br />
      <Typography variant="overline" style={{ fontWeight: 'bold' }}>
        PAYMENT DETAILS
      </Typography>
      <br />
      {order?.delivery_address && (
        <>
          <Typography variant="overline">
            Subtotal: €:{' '}
            {(
              Number(order?.total_order?.toFixed(2)) -
              Number(order?.delivery_fee)
            ).toFixed(2)}
          </Typography>
          <br />
          <Typography variant="overline">
            Delivery fee: € {order?.delivery_fee?.toFixed(2)}
          </Typography>
          <br />
        </>
      )}
      {order?.discount && (
        <>
          <Typography variant="overline">
            Discount: €: {Number(order?.discount?.toFixed(2))}
          </Typography>
          <br />
          <Typography variant="overline">
            Delivery fee: € {order?.delivery_fee?.toFixed(2)}
          </Typography>
          <br />
        </>
      )}
      <Typography variant="overline">
        TOTAL: € {order?.total_order?.toFixed(2)}
      </Typography>
      <br />
      {order?.cash_change && (
        <Typography variant="overline">
          Change: € {Number(order?.cash_change).toFixed(2)}
        </Typography>
      )}
    </Grid>
  );
}
