import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';
import { Product } from './stock';

export interface User {
  id: number;
  first_name: string;
  username: string;
  last_name: string;
  email: string;
  profile: Profile;
  addresses: string;
  billing_address: billing_address;
  cards: cards[];
  subscriptions: string;
  is_superuser: boolean;
  password: string;
}
export interface Profile {
  name: string;
  phone_number: string;
  cpf: string;
  total_billed: string;
  outstanding_to_pay: string;
  orders_placed: string;
  value_of_order_average: string;
  is_waiter: boolean;
  wishlist: Product[];
  is_client: boolean;
}

export interface cards {
  id: number;
  active: boolean;
  cardFlag: string;
  cardHolder: string;
  cardNumber: string;
  cpf: string;
  expirationDate: string;
  cvv: string;
}

export interface billing_address {
  active: boolean;
  address: string;
  address_id: number;
  city: string;
  complement: string;
  country: string;
  name: string;
  neighborhood: string;
  postal_code: string;
  state: string;
  street: string;
  street_number: string;
  update_address: boolean;
}

export interface IProfileState {
  fetch: boolean;
  count: number;
  next?: string;
  previous?: string;
  results: User[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesProfile {
  FETCH = 'PROFILE/FETCH',
  SUCCESS = 'PROFILE/SUCCESS',
  FIRSTLOAD = 'PROFILE/FIRSTLOAD',
  FAILURE = 'PROFILE/FAILURE',
  SEARCH = 'PROFILE/SEARCH'
}

export interface IFetchAction extends IBaseAction<ETypesProfile, null> {
  type: ETypesProfile.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesProfile, IProfileState> {
  type: ETypesProfile.SUCCESS;
  payload: IProfileState;
}

export interface IFirstLoadAction
  extends IBaseAction<ETypesProfile, IProfileState> {
  type: ETypesProfile.FIRSTLOAD;
  payload: IProfileState;
}

export interface IFailureAction extends IBaseAction<ETypesProfile, string> {
  type: ETypesProfile.FAILURE;
  payload: string;
}

export type IProfileAction =
  | IFetchAction
  | ISuccessAction
  | IFirstLoadAction
  | IFailureAction;

export type GetState = () => any;

export type PromiseAction = Promise<IProfileAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IProfileAction
    | ThunkAction
    | PromiseAction
    | Array<IProfileAction>
    | IAlertAction
) => any;
