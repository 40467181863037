import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import Print from 'components/Common/Print';
import moment from 'moment';
import { fetch } from 'utils/request';

interface IProps {
  name: string;
  date: Date;
  phone: string;
  email: string;
  products: {
    edition_number: string;
    product_id: number;
    artist: string;
    name: string;
    width: number;
    height: number;
    length: number;
    weight: number;
    quantity: number;
    price: number;
  }[];
  subtotal: number;
  vat: number;
  shipping: number;
  address: string;
  order_no: string;
  total: number;
  ownerId: number;
  currency: string;
}

export default function Receipt(props: IProps): JSX.Element {
  const {
    name,
    date,
    email,
    products,
    subtotal,
    shipping,
    order_no,
    total,
    phone,
    address,
    vat,
    currency
  } = props;

  const [technique, setTechnique] = React.useState('');
  const [template, setTemplate] = React.useState('');
  const [year, setYear] = React.useState('');

  const getData = async () => {
    try {
      if (products) {
        await Promise.all(
          products.map(async (product) => {
            const response = await fetch({
              url: `/products/api/products/${product.product_id}/`,
              method: 'GET'
            });
            setTechnique(response.technique.name);
            setTemplate(response.template);
            setYear(response.year);
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const right = () => {
    return (
      <Grid
        style={{
          width: '30%',
          paddingTop: '20px'
        }}
      >
        <Grid
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.5rem',
            paddingRight: '3rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '13px', fontFamily: 'Archivo, sans-serif' }}
          >
            ArtXX AG <br />
            Sankt-Oswalds-Gasse 1 <br />
            6300 Zug <br />
            Switzerland
          </Typography>
        </Grid>
        <Grid
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.5rem',
            paddingRight: '3rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            VAT NUMBER:
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '13px', fontFamily: 'Archivo, sans-serif' }}
          >
            CH-391.058.957 MWST
          </Typography>
        </Grid>
        <Grid
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.5rem',
            paddingRight: '3rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            CONTACTS:
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '13px', fontFamily: 'Archivo, sans-serif' }}
          >
            info@wengcontemporary.com <br />
            +41 (0)43 229 31 28
          </Typography>
        </Grid>
        <Grid
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.3rem',
            marginBottom: '0.3rem'
          }}
        ></Grid>
        <Grid
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.5rem',
            paddingRight: '3rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            GENERAL TERMS & CONDITIONS
          </Typography>
          <Typography
            variant="h5"
            style={{
              fontSize: '11px',
              fontFamily: 'Archivo, sans-serif',
              marginTop: '0.3rem'
            }}
          >
            The title of ownership of the work is transferred from ArtXX AG up
            on complete payment of the work. All sales are final up on receipt
            of payment. Unless indicated above, any applicable shipping, packing
            and/or insurance charges will be billed separately. The payment of
            any applicable duties, tax, customs, and other fees are the
            responsibility of the customer.
          </Typography>
        </Grid>
        <Grid
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.3rem',
            marginBottom: '0.3rem'
          }}
        ></Grid>
        <Grid
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.5rem',
            paddingRight: '3rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            WIRE TRANSFER INSTRUCTION
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            (IN {currency})
          </Typography>
        </Grid>
        <Grid
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.5rem',
            paddingRight: '3rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            ACCOUNT NAME:
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            ArtXX AG
          </Typography>
        </Grid>
        <Grid
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.5rem',
            paddingRight: '3rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            BANK DETAILS:
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            {currency === 'USD'
              ? 'Commerzbank Branch Zurich'
              : currency === 'EUR'
                ? 'Deutsche Bank Krefeld'
                : 'Commerzbank Branch Zurich'}
          </Typography>
        </Grid>
        <Grid
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.5rem',
            paddingRight: '3rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            IBAN:
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            {currency === 'USD'
              ? 'CH03 0883 6261 1275 0000 1'
              : currency === 'EUR'
                ? 'DE95320700800080039100'
                : currency === 'CHF'
                  ? 'CH62 0883 6261 1275 0000 6'
                  : 'CH19 0883 6261 1275 0000 4'}
          </Typography>
        </Grid>
        <Grid
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.5rem',
            paddingRight: '3rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            BIC/SWIFT CODE:
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '11px', fontFamily: 'Archivo, sans-serif' }}
          >
            {currency === 'USD'
              ? 'COBA CH ZH XXX'
              : currency === 'EUR'
                ? 'DEUTDEDD320'
                : currency === 'CHF'
                  ? 'COBA CH ZH XXX'
                  : 'COBA CH ZH XXX'}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const left = () => {
    return (
      <Grid
        style={{
          width: '50%',
          marginLeft: '20%',
          position: 'relative',
          paddingTop: '100px'
        }}
      >
        <Grid
          style={{
            marginBottom: '8vh',
            maxWidth: '50%',
            paddingLeft: '0.3rem'
          }}
        >
          <Typography
            variant="h5"
            style={{
              fontSize: '1rem',
              fontFamily: 'EB Garamond, serif'
            }}
          >
            {name} <br />
            {address} <br />
            {email}
            <br />
            {phone}
          </Typography>
        </Grid>
        <Grid
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.3rem',
            marginBottom: '0.3rem'
          }}
        ></Grid>
        <Grid style={{ paddingLeft: '0.3rem', paddingBottom: '0.5rem' }}>
          <Typography
            variant="h5"
            style={{ fontSize: '1rem', fontFamily: 'EB Garamond, serif' }}
          >
            {moment(date).format('Do MMMM YYYY')}
          </Typography>
        </Grid>
        <Grid
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.3rem',
            marginBottom: '0.3rem'
          }}
        ></Grid>
        {products.map((product, index) => (
          <Grid key={index} style={{ marginTop: index !== 0 ? '1vw' : '' }}>
            <Grid style={{ paddingLeft: '0.3rem' }}>
              <Grid
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingRight: '0.5rem'
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'EB Garamond, serif'
                  }}
                >
                  {product.artist}
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'EB Garamond, serif'
                  }}
                >
                  {product.price
                    ? product.price.toLocaleString(undefined, {
                      maximumFractionDigits: 2
                    })
                    : 0}{' '}
                  {currency}
                </Typography>
              </Grid>
              <Typography
                variant="h5"
                style={{
                  fontSize: '1rem',
                  fontFamily: 'EB Garamond, serif',
                  maxWidth: '50%'
                }}
              >
                <span style={{ fontStyle: 'italic' }}>{product.name}</span>,
                <span>, {year}</span>
              </Typography>
              <Typography
                variant="h5"
                style={{
                  fontSize: '1rem',
                  fontFamily: 'EB Garamond, serif',
                  maxWidth: '50%'
                }}
              >
                {technique}
              </Typography>
              <Typography
                variant="h5"
                style={{
                  fontSize: '1rem',
                  fontFamily: 'EB Garamond, serif',
                  maxWidth: '50%'
                }}
              >
                Edition of {product.edition_number}
              </Typography>
              <Typography
                variant="h5"
                style={{ fontSize: '1rem', fontFamily: 'EB Garamond, serif' }}
              >
                {template === 'SCULPTURE'
                  ? `${product?.width?.toFixed(2)} x ${product?.height?.toFixed(
                    2
                  )} x ${product?.length?.toFixed(2)} cm (${(
                    product?.width * 0.393701
                  ).toFixed(2)} x ${(product?.height * 0.393701).toFixed(
                    2
                  )} x ${(product?.length * 0.393701).toFixed(2)} in.)`
                  : `${product?.height?.toFixed(2)} x ${product?.length?.toFixed(
                    2
                  )} cm (${(product?.height * 0.393701).toFixed(2)} x ${(
                    product?.length * 0.393701
                  ).toFixed(2)} in.)`}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.8rem',
            marginBottom: '0.3rem'
          }}
        ></Grid>
        <Grid
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: '0.5rem',
            paddingLeft: '0.3rem',
            paddingBottom: '0.5rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '1rem', fontFamily: 'EB Garamond, serif' }}
          >
            Subtotal:
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '1rem', fontFamily: 'EB Garamond, serif' }}
          >
            {subtotal
              ? subtotal.toLocaleString(undefined, {
                maximumFractionDigits: 2
              })
              : 0}{' '}
            {currency}
          </Typography>
        </Grid>
        <Grid
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.3rem',
            marginBottom: '0.3rem'
          }}
        ></Grid>
        <Grid
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: '0.5rem',
            paddingLeft: '0.3rem',
            paddingBottom: '0.5rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '1rem', fontFamily: 'EB Garamond, serif' }}
          >
            Shipping:
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '1rem', fontFamily: 'EB Garamond, serif' }}
          >
            {shipping
              ? shipping.toLocaleString(undefined, {
                maximumFractionDigits: 2
              })
              : 0}{' '}
            {currency}
          </Typography>
        </Grid>
        <Grid
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.3rem',
            marginBottom: '0.3rem'
          }}
        ></Grid>
        <Grid
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: '0.5rem',
            paddingLeft: '0.3rem',
            paddingBottom: '0.5rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '1rem', fontFamily: 'EB Garamond, serif' }}
          >
            VAT:
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '1rem', fontFamily: 'EB Garamond, serif' }}
          >
            {vat} {currency}
          </Typography>
        </Grid>
        <Grid
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.3rem',
            marginBottom: '0.3rem'
          }}
        ></Grid>
        <Grid
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: '0.5rem',
            paddingLeft: '0.3rem',
            paddingBottom: '0.5rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: '1rem', fontFamily: 'EB Garamond, serif' }}
          >
            Total:
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: '1rem', fontFamily: 'EB Garamond, serif' }}
          >
            {total.toLocaleString(undefined, {
              maximumFractionDigits: 2
            })}{' '}
            {currency}
          </Typography>
        </Grid>
        <Grid
          style={{
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            color: '#fe309c'
          }}
        >
          <Typography
            variant="h5"
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              color: '#fe309c',
              marginTop: "2cm"

            }}
          >
            Thank you
          </Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <div>
      <Print
        trigger={
          <Button size="medium">
            <PrintIcon />
          </Button>
        }
        onBeforeGetContent={getData}
        fonts={[
          {
            family: 'EB Garamond',
            source:
              'https://fonts.gstatic.com/s/ebgaramond/v15/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RkCY95WamXgHlIbvw.woff'
          },
          {
            family: 'Archivo',
            source:
              'https://fonts.googleapis.com/css2?family=Archivo&display=swap'
          }
        ]}
        documentTitle={name + ' ' + order_no?.slice(1)}
      >
        <>
          <Grid style={{ position: 'relative' }}>
            <Grid
              style={{
                height: '7.5vh',
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '30%',
                width: 'fit-content',
                alignItems: 'center'
              }}
            >
              <Typography
                style={{
                  zIndex: 10,
                  fontFamily: 'Archivo, sans-serif',
                  fontStretch: '103%',
                  fontWeight: 700,
                  letterSpacing: '0.15em',
                  color: '#222',
                  fontSize: '11px',
                  textTransform: 'uppercase',
                  textDecoration: 'none',
                  width: '500px',
                  textAlign: 'center'
                }}
              >
                WENG CONTEMPORARY.COM
              </Typography>
              <Typography
                style={{
                  zIndex: 10,
                  fontFamily: 'Archivo, sans-serif',
                  fontStretch: '103%',
                  fontWeight: 'normal',
                  letterSpacing: '0.15em',
                  color: '#222',
                  fontSize: '11px',
                  textTransform: 'uppercase',
                  textDecoration: 'none',
                  width: '100%',
                  textAlign: 'center',
                  paddingTop: '3px',
                  marginLeft: '-5px'
                }}
              >
                Invoice Nr. {order_no}
              </Typography>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: '92.5vh'
              }}
            >
              {left()}
              <Grid
                style={{
                  width: '0.1vw',
                  height: '100%',
                  backgroundColor: 'black'
                }}
              ></Grid>
              {right()}
            </Grid>
          </Grid>
        </>
      </Print>
    </div>
  );
}

Receipt.defaultProps = {
  disabled: false
};
