import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Artist } from 'interfaces/artist';
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch
} from '@material-ui/core';
import { fetch } from 'utils/request';
import { EMethod } from 'enums/method';
import { sendAlert } from 'redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';
import { useDispatch } from 'react-redux';
import { fetchArtists } from 'redux/ducks/artists';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalEditArtist from './ModalEditArtist';
import ModalCreateArtist from './ModalCreateArtist';
import ModalDeleteArtist from './ModalDeleteArtist';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'black',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: '#fe309c',
      backgroundColor: 'black'
    }
  }
});

interface Props {
  artists: Artist[];
}

export default function ArtistsTable(props: Props) {
  const classes = useStyles();
  const { artists } = props;
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedArtist, setSelectedArtist] = React.useState(undefined);
  const dispatch = useDispatch();

  const handleEdit = (artist: Artist) => {
    setSelectedArtist(artist);
    setOpenEdit(true);
  };
  const handleDelete = (artist: Artist) => {
    setSelectedArtist(artist);
    setOpenDelete(true);
  };
  const handleClose = () => {
    setOpenEdit(false);
    setOpenCreate(false);
    setOpenDelete(false);
    setSelectedArtist(undefined);
  };
  const handleCreate = () => {
    setOpenCreate(true);
  };
  const handleChangeActive = (id: number, active: boolean) => {
    try {
      fetch({
        url: 'products/api/artists/' + id + '/',
        method: EMethod.PATCH,
        data: {
          artist_active: active
        }
      });
      dispatch(
        sendAlert(
          `Artist ${active ? 'activated' : 'de-activated'} successfuly`,
          EAlertVariant.SUCCESS
        )
      );
      dispatch(fetchArtists());
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button className={classes.activeButton} onClick={handleCreate}>
        Create Artist
      </Button>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Short Description</TableCell>
            <TableCell align="right">Slug</TableCell>
            <TableCell align="right">Active</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {artists ? (
            <>
              {artists.map((artist) => (
                <TableRow key={artist.id}>
                  <TableCell component="th" scope="row">
                    {artist.name}
                  </TableCell>
                  <TableCell dangerouslySetInnerHTML={{ __html: artist.short_description }}></TableCell>
                  <TableCell align="right">{artist.slug}</TableCell>
                  <TableCell align="right" style={{ padding: 0 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={artist.artist_active}
                          onChange={(e) =>
                            handleChangeActive(artist.id, !artist.artist_active)
                          }
                          color="primary"
                        />
                      }
                      label=""
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <IconButton
                        onClick={() => handleEdit(artist)}
                        style={{ padding: 5 }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(artist)}
                        style={{ padding: 5 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : null}
        </TableBody>
      </Table>
      <ModalEditArtist
        open={openEdit}
        onClose={handleClose}
        artist={selectedArtist}
      />
      <ModalCreateArtist open={openCreate} onClose={handleClose} />
      <ModalDeleteArtist
        open={openDelete}
        onClose={handleClose}
        artist={selectedArtist}
      />
    </TableContainer >
  );
}
