import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { setKitchenStatus, setKitchenRevertStatus } from 'redux/ducks/kitchen';

import { IKitchen, IKitchenState } from 'interfaces/kitchen';
import IStore from 'interfaces/store';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import Animate from 'components/Common/Animate';
import Order from './Order';

interface IProps {
  headerTitle: string;
  headerStyle: string;
  bodyStyle: string;
  orders: IKitchen[];
  showOKBtn?: boolean;
  showBackBtn?: boolean;
  showIsPaid?: boolean;
  showPrintBtn?: boolean;
  showExtraInfo?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

export default function Panel(props: IProps): JSX.Element {
  const classes = useStyles();
  const [openQRCode, setOpenQRCode] = useState<boolean>(false);
  const [orderToShow, setOrderToShow] = useState<IKitchen>(null);
  const {
    headerTitle,
    headerStyle,
    bodyStyle,
    orders,
    showOKBtn,
    showBackBtn,
    showIsPaid,
    showPrintBtn,
    showExtraInfo
  } = props;
  const dispatch = useDispatch();
  const { currentOrderID } = useSelector<IStore, IKitchenState>(
    (state) => state.kitchen
  );

  const onClickOrder = (order: IKitchen) => {
    setOrderToShow(order);
    setOpenQRCode(true);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openQRCode}
        onClose={() => setOpenQRCode(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openQRCode}>
          <div>
            {orderToShow && (
              <img
                alt="QR Code"
                src={orderToShow.set_driver_qr}
                width="200"
                height="200"
              />
            )}
          </div>
        </Fade>
      </Modal>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <Animate container={false}>
          <Card className={headerStyle}>
            <Typography
              variant="subtitle2"
              align="center"
              style={{ fontWeight: 300, fontSize: 16 }}
            >
              {headerTitle}
            </Typography>
          </Card>
        </Animate>
        {orders.length > 0 &&
          orders.map((order) => (
            <Order
              key={order.order_id}
              bodyStyle={bodyStyle}
              order={order}
              showBackBtn={showBackBtn}
              showOKBtn={showOKBtn}
              showIsPaid={showIsPaid}
              showPrintBtn={showPrintBtn}
              showExtraInfo={showExtraInfo}
              currentOrderID={currentOrderID}
              setKitchenStatus={() => dispatch(setKitchenStatus(order))}
              setRevertKitchenStatus={() =>
                dispatch(setKitchenRevertStatus(order))
              }
              onClick={() => onClickOrder(order)}
            />
          ))}
      </Grid>
    </>
  );
}

Panel.defaultProps = {
  showOKBtn: true,
  showBackBtn: true,
  showIsPaid: false,
  showPrintBtn: false,
  showExtraInfo: false
};
