import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Content from 'components/Dashboard/Content';
import Logout from 'components/Authentication/Logout';

import Home from 'components/Home_Page';
import Kitchen from 'components/Kitchen';
import Balcony from 'components/Balcony';
import Production from 'components/Production';
import Package from 'components/Package';
import IngredientProduction from 'components/IngredientProduction';
import Delivery from 'components/Delivery';
import ManagerDashboard from 'components/ManagerDashboard';
import Stock from 'components/Stock';
import Customers from 'components/Customers';
import Sellers from 'components/Sellers';
import Product from 'components/Product';
import Menus from 'components/Menus';
import History from 'components/History';
// import ControlPanel from 'components/ControlPanel';
import Profile from 'components/Profile';
import MyOrders from 'components/MyOrder';
import Media from 'components/Media';
import Artists from 'components/Artists';
import Latest from 'components/Latest';
import Currency from 'components/Currency';
import Voucher from 'components/Vouchers';
import store from './../../redux/index';

export default function Routes() {
  const [is_client, set_is_client] = useState({})
  useEffect(() => {
    set_is_client(store.getState().authentication.is_client)
  }, [store])
  return (
    <Content>
      {!is_client ? (
        <Switch>
          <Route path="/dashboard/home" component={Home} />
          <Route path="/dashboard/manager" component={ManagerDashboard} />
          <Route path="/dashboard/stock" component={Stock} />
          <Route path="/dashboard/customers" component={Customers} />
          <Route path="/dashboard/sellers" component={Sellers} />
          <Route path="/dashboard/order" component={Kitchen} />
          <Route path="/dashboard/production/product" component={Production} />
          <Route path="/dashboard/vouchers" component={Voucher} />

          <Route
            path="/dashboard/production/ingredient"
            component={IngredientProduction}
          />
          <Route path="/dashboard/package" component={Package} />
          <Route path="/dashboard/completed" component={Balcony} />
          <Route path="/dashboard/logout" component={Logout} />
          <Route path="/dashboard/delivery" component={Delivery} />
          <Route path="/dashboard/products" component={Product} />
          <Route path="/dashboard/menus" component={Menus} />
          <Route path="/dashboard/history" component={History} />
          {/* <Route path="/dashboard/notifications" component={ControlPanel} /> */}
          <Route path="/dashboard/media" component={Media} />
          <Route path="/dashboard/artists" component={Artists} />
          <Route path="/dashboard/latest" component={Latest} />
          <Route path="/dashboard/currency" component={Currency} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/dashboard/profile" component={Profile} />
          <Route path="/dashboard/order" component={MyOrders} />
          <Route path="/dashboard/logout" component={Logout} />
        </Switch>
      )}
    </Content>
  );
}
