import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Seller } from 'interfaces/customersAndSellers';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface Props {
  sellers: Seller[];
}

export default function SellersTable(props: Props) {
  const classes = useStyles();
  const { sellers } = props;

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell align="right">Orders</TableCell>
            <TableCell align="right">Total (€)</TableCell>
            <TableCell align="right">To Pay (€)</TableCell>
            <TableCell align="right">Profit to date (€)</TableCell>
            <TableCell align="right">Average per Order (€)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sellers ? (
            <>
              {sellers.map((seller) => (
                <TableRow key={seller.id}>
                  <TableCell component="th" scope="row">
                    {seller.username}
                  </TableCell>
                  <TableCell align="right">
                    {seller.profile.orders_placed}
                  </TableCell>
                  <TableCell align="right">
                    {seller.profile.total_billed.toFixed(2)}
                  </TableCell>
                  <TableCell align="right">
                    {seller.profile.outstanding_to_pay.toFixed(2)}
                  </TableCell>
                  <TableCell align="right">
                    {(
                      seller.profile.total_billed -
                      seller.profile.outstanding_to_pay
                    ).toFixed(2)}
                  </TableCell>
                  <TableCell align="right">
                    {seller.profile.value_of_order_average?.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
