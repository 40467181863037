/* eslint-disable max-lines */
import React, { useState } from 'react';
import { IKitchen, IKitchenState, IOrderItems } from 'interfaces/kitchen';
import formatDate from 'date-fns/format';
import { enUS } from 'date-fns/locale';
import { v4 as uuidv4 } from 'uuid';

import { getDateByStatus } from 'helpers/kitchen';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';

import { getPaymentLogo, getPaymentType } from 'helpers/common';
import { getDeliveryType } from 'helpers/kitchen';
import { useStyles } from './styles';

import LeftIcon from '@material-ui/icons/ArrowBackIosOutlined';
import RightIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Animate from 'components/Common/Animate';

import DhlLogo from '../../assets/img/dhl-icon.jpg';
import CancelOrderModal from './CancelOrderModal';
import { useDispatch, useSelector } from 'react-redux';
import { getKitchen, setOrderIsPaid, sendPDF } from 'redux/ducks/kitchen';
import ModalDhlCreateLabel from './ModalDhlCreateLabel';
import ModalChooseProductEdition from './ModalChooseProductEdition';
import { formatCurrencyValues } from 'utils/format-functions/formatCurrencyValues';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { editOrder } from 'redux/ducks/history';
import { CircularProgress } from '@material-ui/core';
import IStore from 'interfaces/store';
import { pdf } from '@react-pdf/renderer';
import Pdf from './pdf';
import { API_ENDPOINT } from 'settings';
import { fetch as _fetch } from 'utils/request';
import { EMethod } from 'enums/method';
import moment from 'moment'
interface IProps {
  bodyStyle: string;
  order: IKitchen;
  showOKBtn?: boolean;
  showBackBtn?: boolean;
  showIsPaid?: boolean;
  showPrintBtn?: boolean;
  showExtraInfo?: boolean;
  isFirstStage?: boolean;
  currentOrderID: number;
  setKitchenStatus: () => void;
  setRevertKitchenStatus: () => void;
  skipAll: () => void;
  createInsuranceContract: () => void;
  headerTitle: string;
  filterData?: {
    dateDelivery: string;
    store: number;
  };
}

const Order: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    bodyStyle,
    order,
    showOKBtn,
    showBackBtn,
    showIsPaid,
    showExtraInfo,
    isFirstStage,
    // currentOrderID,
    setKitchenStatus,
    setRevertKitchenStatus,
    createInsuranceContract,
    headerTitle,
    filterData,
    skipAll
  } = props;

  const classes = useStyles();
  // const [disableButtons, setDisableButtons] = useState(hideButtonByDate(order));
  // const [pagarMeURL, setPagarMeURL] = useState('');
  const [open, setOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState(undefined);
  const dispatch = useDispatch();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const handleDatePickerOpen = () => setShowDatePicker(true);
  const handleDatePickerClose = () => setShowDatePicker(false);

  const { fetch } = useSelector<IStore, IKitchenState>(
    (state) => state.kitchen
  );

  const [showEditionsModal, setShowEditionsModal] = useState(false);
  const [showingEditionsOfProduct, setShowingEditionsOfProduct] =
    useState<IOrderItems>();

  const handleEditionsModal = (item: IOrderItems) => {
    setShowingEditionsOfProduct(item);
    setShowEditionsModal(true);
  };

  const handleClickOpen = (order: IKitchen) => {
    setSelectedOrder(order);
    setOpen(true);
  };

  const handlePaid = (order: IKitchen) => {
    dispatch(setOrderIsPaid(order, { infos: { is_paid: true } }));
    document.getElementById(`payBtn-${order.order_id}`).remove();
    const container = document.getElementById(`payBtnDiv-${order.order_id}`);
    container.append('PAID');
    container.style.color = 'green';
    container.style.marginTop = '1rem';
    container.style.fontSize = '1rem';
  };
  const editionPrice = (
    edition: {
      price_eur: number;
      base_price: number;
      price_gbp: number;
      price_chf: number;
      price_usd: number
    },
    currency,
    operation
  ) => {
    console.log("edition ", edition)
    var value;

    if (currency === 'EUR') {
      value = edition?.price_eur;
    }
    if (currency === 'USD') {
      value = edition?.price_usd;
    }
    if (currency === 'GBP') {
      value = edition?.price_gbp;
    }
    if (currency === 'CHF') {
      value = edition?.price_chf;
    }
    return value;
  };
  const calcCartTotal = () => {
    const items = order?.items;
    const cartTotal = items.reduce(
      (a, b) => a + editionPrice(b?.edition, order?.currency, 'cartTotal') - b.orderitem_discount,
      0
    );
    return formatCurrencyValues(cartTotal, order?.currency);
  };

  const submitPDF = (file) => {
    dispatch(sendPDF(file, order?.order_id));
  };
  const getBillingAddress = async (email) => {
    return new Promise<string>((resolve, reject) => {
      _fetch({
        method: EMethod.GET,
        url: `users/api/clients/?email=${email}`
      }).then((response) => {
        response = response.results[0].billing_address
        resolve(response.street + " " + response.street_number + ", " + response.city + ", " + response.postal_code + ", " + response.country)
      })
    })

  }
  const generatePDF = async () => {
    var _names = []

    const replaceAt = (index: any, replacement: string, str: string) => {
      return str.substring(0, index) + replacement + str.substring(index + replacement.length);
    }

    function includesSpecialChars(str: string, idx) {
      const chars = ["ć", "č", "đ"]
      const utfChars = {
        ć: "c",
        č: "c",
        đ: "d"
      }
      var selectedChar = ""
      if (chars.some(v => {
        if (str.includes(v)) {
          selectedChar = v
        }
        return str.includes(v)
      })) {
        includesSpecialChars(replaceAt(str.indexOf(selectedChar), utfChars[selectedChar], str,), idx)
      } else {

        _names.length = order.items.length
        _names[idx] = str
      }
    }
    const _response = await _fetch({
      url: `/orders/api/orders/${order.order_id}/get_order/`,
      method: 'GET'
    });
    const fillNames = () => {
      order.items.forEach((product, idx) => {
        includesSpecialChars(product.product.artist.name, idx)
      })
    }
    fillNames()
    getBillingAddress(order.owner.email).then(async (response) => {
      const doc = (
        <Pdf
          type_of_order={order.type_of_order}
          additional_cost={_response.additional_costs}
          order_notes={order.final_comment}
          reference={order.invoice_reference}
          billingAddress={response}
          name={order?.owner?.name ?? order?.client?.name}
          phone={order?.owner?.phone_number}
          currency={order?.currency}
          email={order?.owner?.email}
          voucher_code={order?.voucher_code}
          discount={formatCurrencyValues(
            order?.discount,
            order?.currency
          )}
          ownerId={order?.owner?.profile_id}
          address={order.type_of_order === "O-D" ? order?.delivery_address
            ?.substring(
              order?.delivery_address.indexOf("'") + 1,
              order?.delivery_address.lastIndexOf("'")
            ) : response}
          date={handleDateOrdered(order)}
          products={order.items.map((item, idx) => {
            return {
              product_id: item.product?.product_id,
              artist: _names[idx],
              name: item.product.name,
              hs_code: item.product?.hs_code,
              width: item.product.width,
              height: item.product.height,
              weight: item.product.weight,
              length: item.product.length,
              quantity: item.quantity,
              edition_number: item.edition?.edition_number,
              price: formatCurrencyValues(
                editionPrice(item.edition, order?.currency, 'cartTotal'),
                order?.currency
              ),
              orderitem_discount: formatCurrencyValues(item.orderitem_discount, order?.currency),
              year: item.product.year,
              edition: item.edition.edition_number
            };
          })}
          cartTotal={calcCartTotal()}
          vat={formatCurrencyValues(order?.vat, order?.currency)}
          total={formatCurrencyValues(order?.total_order, order?.currency)}
          shippxng={formatCurrencyValues(
            order?.delivery_fee || order?.delivery_fee == 0 ? order?.delivery_fee : order?.dhl_price,
            order?.currency
          )}
          insuranceFee={
            order?.insurance_requested
              ? formatCurrencyValues(
                order?.insurance_value_cents,
                order?.currency
              )
              : ''
          }
          order_no={order?.order_code}
        />
      );

      await pdf(doc)
        .toBlob()
        .then((blob) => {
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            submitPDF(base64data);
          };
        })
        .catch((e) => {
          console.log(e);
        });
    })

  };

  const handleClose = () => {
    setSelectedOrder(undefined);
    setOpen(false);
  };
  const handleDateOrdered = (order) => {
    if (order.direct_ecomm === "direct") {
      return order.invoice_date
        ? moment(order.invoice_date.substring(0, 10)).format('ll')
        : '';
    } else {
      return order.date_ordered
        ? moment(order.date_ordered.substring(0, 10)).format('ll')
        : '';
    }
  };
  let found = null;
  if (order.delivery_address !== null) {
    const hyphenSplitted = order.delivery_address.split('-');
    if (hyphenSplitted.length > 0) {
      found = hyphenSplitted[2]?.split(' ')[1];
    }
  }
  const bairro = found != null ? found : null;

  const background_type = (type: string) => {
    const values = {
      D: '#ccf6c8',
      C: '#f6d6ad',
      M: '#a6f6f1'
    };

    return values[type];
  };

  const [insuredOrder, setInsuredOrder] = useState(false);
  const submitInsuranceContractCreation = async () => {
    dispatch(createInsuranceContract).then(() => {
      setInsuredOrder(true);
    });
  };

  const [orderInsuredUpdate, setOrderInsuredUpdate] = useState(false);
  const [requestInsuranceLoading, setRequestInsuranceLoading] = useState(false);

  const requestInsurance = (): void => {
    setRequestInsuranceLoading(true);

    let itemsTotal = 0;
    order.items.map((item) => {
      itemsTotal += item.edition.base_price;
    });

    const fixedInsuranceFee = (itemsTotal * 0.01).toFixed(0);

    const orderProducts = [];
    order.items.map((item) => {
      orderProducts.push({
        product_id: item.product.product_id,
        edition_id: item.edition.id,
        quantity: 1
      });
    });

    const formatedOrderToPatch = {
      infos: {
        type_of_order:
          order.type_of_order === 'O-C' ? 'Collection' : 'Delivery',
        store_id: 1,
        insurance_requested: true,
        insurance_product_id: 1,
        insurance_value_cents: fixedInsuranceFee,
        insurance_premium_type: 'yearly',
        insurance_premium_amount_cents: fixedInsuranceFee
      },
      products: orderProducts,
      client: order.client || 1
    };

    dispatch(editOrder(formatedOrderToPatch, order.order_id)).then((res) => {
      if (res) {
        setOrderInsuredUpdate(true);

        dispatch(
          getKitchen({
            date_delivery: filterData.dateDelivery,
            a_fazer: true,
            fazendo: true,
            feito: true,
            depot: true,
            baking: true,
            store: filterData.store
          })
        );
      }
      setRequestInsuranceLoading(false);
    });
  };

  const InsuranceContract = () => {
    if (order.insurance_contract_id || insuredOrder) {
      return (
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          Insured
        </Grid>
      );
    }
    return (
      <Grid style={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          size="medium"
          onClick={submitInsuranceContractCreation}
          style={{ minWidth: '0px', color: '#000' }}
        >
          <DescriptionOutlinedIcon />
        </Button>
      </Grid>
    );
  };

  const confirmPaymentButton = (order: any) => {
    if (order?.payment === '') {
      return (
        <Typography
          style={{
            color: 'red',
            marginTop: '1rem'
          }}
        >
          ABANDONED CART
        </Typography>
      );
    }
    if (order?.is_paid) {
      return (
        <Typography
          style={{
            color: 'green',
            marginTop: '1rem'
          }}
        >
          PAID
        </Typography>
      );
    }
    if (order?.payment !== 'card' && headerTitle !== 'PENDING') {
      return (
        <div id={`payBtnDiv-${order.order_id}`}>
          <Button
            id={`payBtn-${order.order_id}`}
            onClick={() => {
              handlePaid(order);
            }}
            style={{ backgroundColor: '#ff6961', marginTop: '1vw' }}
          >
            Confirm payment
          </Button>
        </div>
      );
    }
    return null;
  };

  const getPDF = async () => {
    try {
      var pdfURL = order.invoice_pdf_decoded.substring(1);
      window.open(`${API_ENDPOINT}${pdfURL}`);
    } catch (error) {
      console.log('error ', error);
    }
  };

  const InsuranceButton = () => {
    if (order?.insurance_requested && headerTitle !== 'PENDING') {
      return <InsuranceContract />;
    }
    return null;
  };

  const DhlButton = () => {
    if (order?.dhl_price && headerTitle !== 'PENDING' && order.type_of_order === "O-D") {
      return (
        <>
          <Button
            size="medium"
            onClick={handleDatePickerOpen}
            style={{ minWidth: '0px', color: '#000' }}
          >
            {order?.items[0].dhl_label && (
              <CheckCircleIcon
                style={{
                  fill: 'green',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  height: '20px'
                }}
              />
            )}
            <img src={DhlLogo} width="25" height="25" alt="DHL" />
          </Button>
          <ModalDhlCreateLabel
            open={showDatePicker}
            onClose={handleDatePickerClose}
            order={order}
          />
        </>
      );
    }
    return null;
  };

  if (!fetch) {
    return (
      <>
        <ModalChooseProductEdition
          open={showEditionsModal}
          onClose={() => setShowEditionsModal(false)}
          order={order}
          orderItem={showingEditionsOfProduct}
        />
        <Animate key={order.order_id}>
          <Card className={bodyStyle}>
            <CardContent style={{ padding: '5px' }}>
              <Grid
                container
                justifyContent="space-between"

              >
                <Typography // Order Code
                  variant="overline"
                  className={classes.overlineLineHeight}
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    lineHeight: 1,
                    backgroundColor: background_type(
                      order.type_of_order.split('-')[1] || ''
                    )
                  }}
                >
                  {order?.order_code}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography // Date
                    className={`${classes.pos} ${classes.overlineLineHeight}`}
                    variant="overline"
                    style={{
                      margin: 0,
                      lineHeight: 1,
                      marginBottom: '5px'
                    }}
                  >
                    {formatDate(
                      new Date(getDateByStatus(order.status, order)),
                      'EEE - dd/MM/yy - HH:mm',
                      { locale: enUS }
                    )}
                  </Typography>
                  {order.date_delivery && (
                    <Typography // Date Delivery
                      className={`${classes.pos} ${classes.overlineLineHeight}`}
                      variant="overline"
                      style={{
                        margin: 0,
                        lineHeight: 1,
                        marginBottom: '5px'
                      }}
                    >
                      {formatDate(
                        new Date(order.date_delivery),
                        'EEE - dd/MM/yy - HH:mm',
                        { locale: enUS }
                      )}
                    </Typography>
                  )}
                </div>
              </Grid>
              <div style={{ marginBottom: '20px' }}>
                <Typography
                  className={`${classes.pos} ${classes.overlineLineHeight}`}
                  variant="overline"
                  style={{
                    margin: 0,
                    lineHeight: 1,
                    marginBottom: '5px'
                  }}
                >
                  {order.direct_ecomm === "direct" ? "Portal" : "Website"}
                </Typography>
              </div>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginBottom: '20px' }}
              >
                <Grid>
                  <Typography // Order Client
                    style={{
                      lineHeight: 1,
                      fontWeight: 'bold'
                    }}
                  >
                    {order?.client?.name
                      ? order?.client?.name
                      : order.owner
                        ? order.owner.name
                        : ''}
                  </Typography>
                  {showIsPaid && confirmPaymentButton(order)}
                </Grid>
                {showIsPaid && (
                  <Grid>
                    {order?.payment === 'card' ? (
                      <img
                        src={getPaymentLogo(order?.payment)}
                        alt="Logo"
                        width="50"
                        height="50"
                      />
                    ) : null}
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {console.log("order?.items ", order?.items)}
                {order?.items?.map((item) => (
                  <>{item.quantity > 0 && <Grid
                    key={uuidv4()}
                    className={classes.productNameGrid}
                    onClick={() => handleEditionsModal(item)}
                  >
                    {console.log("item amount ", item.quantity)}
                    <Typography // Product Name
                      variant="h6"
                      className={classes.productInfo}
                    >
                      {`${item?.quantity + 'x' ?? ''} ${item?.product?.name}`}
                    </Typography>
                    <Typography // Product Name
                      variant="h6"
                      className={classes.productInfoEdition}
                    >
                      {`• Edition: ${item?.edition?.edition_number}`}
                    </Typography>

                    {item?.product?.double &&
                      item?.flavors?.length > 0 &&
                      item?.flavors?.map((flavor, index) => (
                        <Typography
                          key={index}
                          variant="overline"
                          className={classes.overlineLineHeight}
                          paragraph
                          style={{ marginBottom: -5 }}
                        >
                          Stack {index + 1}: {flavor.name}
                        </Typography>
                      ))}
                    {item?.extra_orders?.length > 0 && (
                      <Typography
                        paragraph
                        variant="overline"
                        className={classes.overlineLineHeight}
                        style={{
                          fontWeight: 'bold',
                          marginBottom: -3,
                          marginTop: -10
                        }}
                      >
                        Services
                      </Typography>
                    )}
                    {item?.extra_orders?.length > 0 &&
                      item?.extra_orders?.map((extra) => (
                        <Typography
                          key={extra.id}
                          variant="overline"
                          className={classes.overlineLineHeight}
                          paragraph
                          style={{ marginBottom: -5 }}
                        >
                          {`${extra?.quantity} x ${extra?.measure_quantity}${extra?.measure} ${extra?.ingredient}`}
                        </Typography>
                      ))}
                    {item.comment && (
                      <Grid className={classes.comment}>
                        <Typography
                          variant="overline"
                          className={classes.overlineLineHeight}
                          style={{ fontWeight: 'bold' }}
                        >
                          Observations:
                        </Typography>
                        <Typography
                          paragraph
                          variant="overline"
                          className={classes.overlineLineHeight}
                        >
                          {item.comment}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>}</>
                ))}
              </Grid>
              {order.delivery_address && bairro && (
                <Grid>
                  <Typography
                    variant="overline"
                    className={classes.overlineLineHeight}
                  >
                    DISTRICT:{' '}
                  </Typography>
                  <Typography
                    variant="overline"
                    className={classes.overlineLineHeight}
                    style={{ fontWeight: 'bold' }}
                  >
                    {bairro}
                  </Typography>
                </Grid>
              )}
              {order?.currency && (
                <Grid>
                  <Typography
                    variant="overline"
                    className={classes.overlineLineHeight}
                  >
                    CURRENCY:{' '}
                  </Typography>
                  <Typography
                    variant="overline"
                    className={classes.overlineLineHeight}
                    style={{ fontWeight: 'bold' }}
                  >
                    {order?.currency}
                  </Typography>
                </Grid>
              )}
              {order?.payment && (
                <Grid>
                  <Typography
                    variant="overline"
                    className={classes.overlineLineHeight}
                  >
                    PAYMENT:{' '}
                  </Typography>
                  <Typography
                    variant="overline"
                    className={classes.overlineLineHeight}
                    style={{ fontWeight: 'bold' }}
                  >
                    {order?.payment && getPaymentType(order?.payment)}
                  </Typography>
                </Grid>
              )}
              {order?.type_of_order === 'O-D' && (
                <Grid>
                  {order?.insurance_requested ||
                    orderInsuredUpdate ||
                    headerTitle !== 'PENDING' ? (
                    <>
                      <Typography
                        variant="overline"
                        className={classes.overlineLineHeight}
                      >
                        INSURANCE:{' '}
                      </Typography>
                      <Typography
                        variant="overline"
                        className={classes.overlineLineHeight}
                        style={{ fontWeight: 'bold' }}
                      >
                        {order?.insurance_requested || orderInsuredUpdate
                          ? 'Insured'
                          : 'None'}
                      </Typography>
                    </>
                  ) : null}

                  {!order?.insurance_requested &&
                    !orderInsuredUpdate &&
                    headerTitle === 'PENDING' ? (
                    !requestInsuranceLoading ? (
                      <Typography
                        variant="overline"
                        className={classes.overlineLineHeight}
                        onClick={() => requestInsurance()}
                        style={{
                          color: 'rgb(254, 48, 156)',
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                      >
                        Request Insurance
                      </Typography>
                    ) : (
                      <CircularProgress
                        style={{
                          marginLeft: '20%',
                          width: '20px',
                          height: '20px'
                        }}
                      />
                    )
                  ) : null}
                </Grid>
              )}

              {order.final_comment && (
                <>
                  <Grid>
                    <Typography
                      variant="overline"
                      className={classes.overlineLineHeight}

                    >
                      COMMENT:
                    </Typography>
                  </Grid>
                  <Typography
                    variant="overline"
                    style={{ fontWeight: 'bold' }}
                    className={classes.overlineLineHeight}
                  >
                    {`${order.final_comment}`}
                  </Typography>
                </>
              )}
              {showExtraInfo && (
                <>
                  <Grid>
                    <Typography
                      variant="overline"
                      className={classes.overlineLineHeight}
                    >
                      Type:{' '}
                    </Typography>
                    <Typography
                      variant="overline"
                      className={classes.overlineLineHeight}
                      style={{ fontWeight: 'bold' }}
                    >
                      {`${getDeliveryType(order?.type_of_order)}`}
                    </Typography>
                  </Grid>
                  {`${getDeliveryType(order?.type_of_order)}` ===
                    'Delivery' && (
                      <>
                        <Grid>
                          <Typography
                            variant="overline"
                            className={classes.overlineLineHeight}
                          >
                            Driver:{' '}
                          </Typography>
                          <Typography
                            variant="overline"
                            className={classes.overlineLineHeight}
                            style={{ fontWeight: 'bold' }}
                          >
                            {`${order?.gomotoboy_driver_name
                              ? order?.gomotoboy_driver_name
                              : 'NOT ALLOCATED'
                              }`}
                          </Typography>
                        </Grid>
                      </>
                    )}
                </>
              )}
            </CardContent>

            <CardActions>
              <Grid container justifyContent="space-between">
                {showBackBtn ? (
                  <Button
                    size="small"
                    onClick={setRevertKitchenStatus}
                    style={{ minWidth: '0px' }}
                  >
                    <LeftIcon />
                  </Button>
                ) : null}
                {order.invoice_pdf_decoded && (
                  <Button onClick={() => getPDF()}>
                    {' '}
                    <InfoIcon />
                  </Button>
                )}

                {/* <FullfilmentButton /> */}
                <InsuranceButton />
                <DhlButton />
              </Grid>
            </CardActions>
            <CardActions>
              <Grid container justifyContent="space-between">
                <Button
                  size="small"
                  onClick={() => handleClickOpen(order)}
                  style={{ minWidth: '0px', color: '#a84551' }}
                >
                  Cancel
                </Button>
                {!order.invoice_pdf ? (
                  <Button
                    size="small"
                    onClick={generatePDF}
                    style={{ minWidth: '0px' }}
                  >
                    Save invoice
                  </Button>
                ) : (
                  <Button
                    size="small"
                    onClick={generatePDF}
                    style={{ minWidth: '0px' }}
                  >
                    Update invoice
                  </Button>
                )}
                {showOKBtn ? (
                  isFirstStage && order.invoice_pdf ? (
                    <>
                      <Button
                        size="small"
                        onClick={skipAll}
                        style={{ minWidth: '0px' }}
                      >
                        Skip all
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setKitchenStatus();
                        }}
                        style={{ minWidth: '0px', color: '#23ba23' }}
                      >
                        Accept
                      </Button>

                    </>
                  ) : order.invoice_pdf ? (
                    <>
                      <Button
                        size="small"
                        onClick={skipAll}
                        style={{ minWidth: '0px' }}
                      >
                        Skip all
                      </Button>
                      <Button
                        size="small"
                        onClick={setKitchenStatus}
                        style={{ minWidth: '0px' }}
                      >
                        <RightIcon />
                      </Button>
                    </>
                  ) : null
                ) : (
                  <Button disabled>{''}</Button>
                )}
              </Grid>
            </CardActions>
            <CancelOrderModal
              open={open}
              onClose={handleClose}
              order={selectedOrder}
            />
          </Card>
        </Animate>
      </>
    );
  } else {
    return null;
  }
};

export default Order;
