import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  IManagerState,
  ETypesManager,
  IManagerAction
} from 'interfaces/managerDashboard';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Authentication State. */
const initialState: IManagerState = {
  fetch: false,
  balance: {
    revenue_d_c: {
      delivery: 0,
      collection: 0,
      nft: 0,
    },
    revenue: {
      card: 0,
      invoice: 0,
      abandoned: 0
    },
    came_from: {
      waiter: 0,
      app: 0
    },
    type_of_client: {
      b2b_card: 0,
      b2b_invoice: 0,
      b2c_card: 0,
      b2c_invoice: 0,
      undeclared: 0,
    },
    revenue_count: {
      invoice: 0,
      card: 0,
      abandoned: 0
    },
    came_from_count: {
      waiter: 0,
      app: 0
    },
    where_go: {
      delivery: 0,
      collection: 0
    },
    where_go_count: {
      delivery: 0,
      collection: 0
    },
    cost: {
      bills: 0,
      drivers: 0,
      general: 0,
      discount: 0,
      dhl: 0,
      bookvalue_card: 0,
      bookvalue_invoice: 0,
      insurance_card: 0,
      insurance_invoice: 0
    },
    store: {
      artxx: 0,
      wfa: 0
    },
    timings: {
      doing_done: '',
      doing_depot: '',
      depot_transit: '',
      transit_delivered: '',
      doing_completed: ''
    }
  },
  orders: {
    open: 0,
    closed: 0,
    total_pizzas: 0,
    news: [],
    average: [],
    total_average: 0
  },
  users: {
    total: 0,
    news: 0
  },
  ranks: {
    products: [],
    total_products: 0,
    total_products_value: 0,
    countries: [],
    total_country: 0,
    total_country_value: 0,
    artists: [],
    total_artist: 0,
    total_artist_value: 0
  }
};

/* Authentication Reducer. */
export default (
  state: IManagerState = initialState,
  action: IManagerAction
): IManagerState => {
  switch (action.type) {
    case ETypesManager.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesManager.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesManager.FAILURE:
      return {
        ...state,
        fetch: false
      };
    case ETypesManager.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchManagerDashboard = (): IFetchAction => ({
  type: ETypesManager.FETCH
});

export const successManagerDashboard = (
  payload: IManagerState
): ISuccessAction => ({
  type: ETypesManager.SUCCESS,
  payload
});

export const failureManagerDashboard = (payload: string): IFailureAction => ({
  type: ETypesManager.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesManager.RESET
});

/* Authentication Side Effects Functions. */
export const fetchDashboard =
  (filter: string,): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchManagerDashboard());
      const response = await fetch({
        method: EMethod.GET,
        url: `/calculations/calculate_all/`
        // url: `/calculations/calculate_all/?period=${filter}&store=${store}`
      });
      dispatch(successManagerDashboard(response));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const fetchDashboardWithDateRange =
  (initial: string, end: string): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchManagerDashboard());
      const response = await fetch({
        method: EMethod.GET,
        url: `/calculations/calculate_all/?starts=${initial}&ends=${end}`
      });
      dispatch(successManagerDashboard(response));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };
