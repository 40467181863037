import {
  IBannerState,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IBannerAction,
  Dispatch,
  ThunkAction,
  ETypesBanner,
  IFirstLoadAction,
  GetState,
  Banner,
  BannerSlides
} from 'interfaces/banner';

import { EAlertVariant } from 'interfaces/alert';

import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Banner State. */
const initialState: IBannerState = {
  fetch: false,
  count: 0,
  results: [],
  next: '',
  previous: '',
  error: ''
};

/* Banner Reducer. */
export default (
  state: IBannerState = initialState,
  action: IBannerAction
): IBannerState => {
  switch (action.type) {
    case ETypesBanner.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesBanner.FIRSTLOAD:
      return {
        ...state,
        fetch: false,
        count: action.payload.count,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesBanner.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesBanner.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* Banner Action Creators Functions. */
export const fetchBanner = (): IFetchAction => ({
  type: ETypesBanner.FETCH
});

export const successBanner = (payload: IBannerState): ISuccessAction => ({
  type: ETypesBanner.SUCCESS,
  payload
});

export const firstLoadBanner = (payload: IBannerState): IFirstLoadAction => ({
  type: ETypesBanner.FIRSTLOAD,
  payload
});

export const failureBanner = (payload: string): IFailureAction => ({
  type: ETypesBanner.FAILURE,
  payload
});

/* Banner Side Effects Functions. */

export const createBanner =
  (data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<number> => {
    try {
      const { banner } = getState();
      let banner_slides = [];
      Object.keys(data).forEach((key) => {
        if (key === 'banner_slides') {
          banner_slides = data[key];
          delete data[key];
        }
      });
      let request_url = `/products/api/bannercontainers/`;
      let id;
      await fetch({
        method: EMethod.POST,
        url: request_url,
        data
      }).then((res) => {
        id = res.id;
      });
      request_url = `/products/api/bannercontainers/${id}/add_slide_to_banner/`;
      await Promise.all(
        banner_slides.map(async (element) => {
          const bodyForSlides = new FormData();
          Object.keys(element).forEach((key) => {
            bodyForSlides.append(key, element[key]);
          });
          await fetch({
            headers: { 'content-type': 'multipart/form-data' },
            method: EMethod.POST,
            url: request_url,
            data: bodyForSlides
          })
        })
      );

      dispatch(sendAlert('Banner created successfully', EAlertVariant.SUCCESS));

      dispatch(successBanner({ ...banner }));
      return 1;
    } catch (error) {
      console.log(error);
      dispatch(failureBanner(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };

export const getBanners =
  (): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<Banner[]> => {
    try {
      const { banner } = getState();
      dispatch(fetchBanner());
      let request_url;
      request_url = `/products/api/bannercontainers/`;
      const response = await fetch({
        method: EMethod.GET,
        url: request_url
      });
      let results: Banner[] = [];
      results.push(...response.results);
      dispatch(successBanner({ ...banner, results }));
      return results;
    } catch (error) {
      console.log(error);
      dispatch(failureBanner(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return [];
    }
  };
export const deleteBanner =
  (id: number): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      let request_url;
      request_url = `/products/api/bannercontainers/${id}/`;
       await fetch({
        method: EMethod.DELETE,
        url: request_url
      });
      dispatch(sendAlert('Banner deleted successfully', EAlertVariant.SUCCESS));
      return 0;
    } catch (error) {
      dispatch(failureBanner(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return -1;
    }
  };

export const editBanner =
  (
    id: number,
    banner: any,
    newBannerSlides: BannerSlides[],
    oldBannerSlides: BannerSlides[],
    deletedSlides: BannerSlides[]
  ): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      let request_url;
      request_url = `/products/api/bannercontainers/${id}/`;
       await fetch({
        method: EMethod.PUT,
        url: request_url,
        data: banner
      });

      await Promise.all(
        oldBannerSlides.map(async (element) => {
          request_url = `/products/api/bannerslides/${element.id}/`;
          const body = new FormData();
          Object.keys(element).forEach((key) => {
            body.append(key, element[key]);
          });
          await fetch({
            headers: { 'content-type': 'multipart/form-data' },
            method: EMethod.PATCH,
            url: request_url,
            data: body
          })
        })
      );
      request_url = `/products/api/bannercontainers/${id}/add_slide_to_banner/`;

      await Promise.all(
        newBannerSlides.map(async (element) => {
          const body = new FormData();
          Object.keys(element).forEach((key) => {
            body.append(key, element[key]);
          });
          await fetch({
            headers: { 'content-type': 'multipart/form-data' },
            method: EMethod.POST,
            url: request_url,
            data: body
          });
        })
      );

      await Promise.all(
        deletedSlides.map(async (element) => {
          request_url = `/products/api/bannerslides/${element.id}/`;
          await fetch({
            headers: { 'content-type': 'multipart/form-data' },
            method: EMethod.DELETE,
            url: request_url
          })
        })
      );
      dispatch(sendAlert('Banner edited successfully', EAlertVariant.SUCCESS));
      return 0;
    } catch (error) {
      dispatch(failureBanner(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return -1;
    }
  };
