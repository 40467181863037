import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';
import { Product } from 'interfaces/stock';

export enum ETypesMenu {
  FETCH = 'MENU/FETCH',
  SUCCESS = 'MENU/SUCCESS',
  FAILURE = 'MENU/FAILURE',
  CREATE_MENU = 'MENU/CREATE_MENU',
  UPDATE_MENU = 'MENU/UPDATE_MENU',
  DELETE_MENU = 'MENU/DELETE_MENU',
  RESET = 'MENU/RESET'
}

export interface IMenuState {
  fetch?: boolean;
  menus: Menu[];
}

export interface Menu {
  name: string;
  slug: string;
  collection_page_title: string;
  menu_id: number;
  type_of_menu: string;
  banner: string;
  icon: string;
  description: string;
  products: Product[];
  menu_number: number;
  active: boolean;
}

export interface IFetchAction extends IBaseAction<ETypesMenu, null> {
  type: ETypesMenu.FETCH;
}

export interface ISuccessAction extends IBaseAction<ETypesMenu, IMenuState> {
  type: ETypesMenu.SUCCESS;
  payload: IMenuState;
}

export interface IFailureAction extends IBaseAction<ETypesMenu, string> {
  type: ETypesMenu.FAILURE;
  payload: string;
}

export interface IResetAction extends IBaseAction<ETypesMenu, null> {
  type: ETypesMenu.RESET;
}

export interface ICreateAction extends IBaseAction<ETypesMenu, Menu> {
  type: ETypesMenu.CREATE_MENU;
  payload: Menu;
}

export interface IUpdateAction extends IBaseAction<ETypesMenu, Menu> {
  type: ETypesMenu.UPDATE_MENU;
  payload: Menu;
}

export interface IDeleteAction extends IBaseAction<ETypesMenu, number> {
  type: ETypesMenu.DELETE_MENU;
  payload: number;
}

export type IManagerAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction
  | IResetAction
  | ICreateAction
  | IUpdateAction
  | IDeleteAction;

export type GetState = () => any;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action: IManagerAction | ThunkAction | Array<IManagerAction> | IAlertAction
) => any;
