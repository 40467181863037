import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { fetchIngredients, fetchProductsPagination } from 'redux/ducks/stock';

import { EFilters } from '../../enums/stock';
import IStore, { Iingredients, Itags } from 'interfaces/store';

import Loading from 'components/Common/Loading';
import ProductFilter from './ProductFilter';
import IngredientsTable from './IngredientsTable';
import ProductsTable from './ProductsTable';
import { getStore } from 'redux/ducks/store';
import { IStoreState } from 'interfaces/store_test';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import useStyles from './styles';
import { fetch } from './../../utils/request';

const filters = [
  {
    label: 'Artworks',
    value: EFilters.PRODUCTS
  },
  {
    label: 'Services',
    value: EFilters.INGREDIENTS
  }
];

var storeFilter: { id: number; name: string }[] = [];

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const [activeFilter, setActiveFilter] = useState<EFilters>(EFilters.PRODUCTS);
  const { stock } = useSelector<IStore, IStore>((state) => state);
  const [activeStoreFilter, setActiveStoreFilter] = useState(0);
  const classes = useStyles();
  const [fetchedIngredients, setFetchedIngredients] = useState<Iingredients[]>(
    []
  );
  const [fetchedTags, setFetchedTags] = useState<Itags[]>([]);
  const { results } = useSelector<IStore, IStoreState>((state) => state.store);
  const [page, setPage] = React.useState(1);
  const [previousPage, setPreviousPage] = React.useState(0);
  const [pageReset, setPageReset] = React.useState(true);
  const [search, setSearch] = React.useState('');
  storeFilter = [];

  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });
  storeFilter.push({ id: 0, name: 'ALL' });

  const handleActiveStoreFilter = (value: number) => {
    setActiveStoreFilter(value);
    setPageReset(true);
    setPage(1);
    setPreviousPage(0);
  };

  const getIngredients = async () => {
    try {
      const response = await fetch({
        method: 'GET',
        url: 'products/api/ingredients/'
      });
      setFetchedIngredients(response.results);
    } catch (error) {
      console.log('error fetching ingredients');
    }
  };

  const getTags = async () => {
    try {
      const response = await fetch({
        method: 'GET',
        url: '/products/api/tags/'
      });
      setFetchedTags(response.results);
    } catch (error) {
      console.log('error fetching ingredients');
    }
  };

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document?.querySelectorAll<HTMLElement>('.d-stock');
    if (d.item(0)) {
      d.item(0).style.color = '#fe309c';
    }

    if (d.item(1)) {
      d.item(1).style.color = '#fe309c';
      const dText = document.querySelectorAll<HTMLElement>('.d-stock-text');
      dText.item(0).style.color = '#fe309c';
    }
  });

  const getProducts = (data: string) => {
    dispatch(
      fetchProductsPagination(
        {
          store: activeStoreFilter,
          page: 1,
          ordering: 'artist__surname_orderonly',
          search: data
        },
        true
      )
    );
    setPage(1);
    setPreviousPage(page);
    setPageReset(false);
  };
  const getProductsReset = () => {
    dispatch(
      fetchProductsPagination(
        {
          store: activeStoreFilter,
          page: 1,
          ordering: 'artist__surname_orderonly',
          search
        },
        true
      )
    );
    setPage(1);
    setPreviousPage(1);
    setPageReset(false);
  };

  useEffect(() => {
    if (activeFilter === EFilters.PRODUCTS) {
      if (page !== 0 && previousPage !== page) {
        dispatch(
          fetchProductsPagination(
            {
              store: activeStoreFilter,
              page,
              ordering: 'artist__surname_orderonly',
              search
            },
            pageReset
          )
        );
        setPreviousPage(page);
      }
      setPageReset(false);
      getIngredients();
      getTags();
    } else {
      dispatch(fetchIngredients());
    }
    dispatch(getStore());
  }, [dispatch, activeFilter, activeStoreFilter, page]);
  return (
    <>
      <Grid style={{ position: 'relative' }}>
        <Loading show={stock.fetch} />

        <Grid container spacing={3}>
          <Grid style={{ marginBottom: 10, marginLeft: 15 }}>
            <Typography
              color="primary"
              variant="overline"
              className={`${classes.toolBarTitles}`}
            >{`store: `}</Typography>
            {storeFilter.map((elmnt) => (
              <Button
                key={uuidv4()}
                onClick={() => {
                  handleActiveStoreFilter(elmnt.id);
                }}
                variant="outlined"
                size="small"
                className={
                  activeStoreFilter === elmnt.id
                    ? classes.activeButton
                    : classes.button
                }
              >
                {elmnt.name}
              </Button>
            ))}
          </Grid>
          <ProductFilter
            activeFilter={activeFilter}
            setActiveFilter={(newFilter: EFilters) =>
              setActiveFilter(newFilter)
            }
            filters={filters}
          />
          {activeFilter === EFilters.PRODUCTS ? (
            <ProductsTable
              tags={fetchedTags}
              stores={storeFilter}
              ingredients={fetchedIngredients}
              products={stock.productsForStockPage}
              hasMore={stock.hasMore}
              more={() => {
                setPreviousPage(page);
                setPage(page + 1);
              }}
              reset={() => {
                getProductsReset();
              }}
              searchData={(data: any) => {
                setSearch(data);
                getProducts(data);
              }}
            />
          ) : (
            <IngredientsTable ingredients={stock.ingredients} />
          )}
        </Grid>
      </Grid>
    </>
  );
}
