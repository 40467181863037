import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Ingredients } from 'interfaces/stock';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface Props {
  ingredients: Ingredients[];
}

export default function IngredientsTable(props: Props) {
  const classes = useStyles();
  const { ingredients } = props;

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Stock</TableCell>
            <TableCell align="right">Measure</TableCell>
            <TableCell align="right">Cost (€)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ingredients?.map((ingredient) => (
            <TableRow key={ingredient.ingredient_id}>
              <TableCell component="th" scope="row">
                {ingredient.name}
              </TableCell>
              <TableCell align="right">{ingredient.quantity}</TableCell>
              <TableCell align="right">{ingredient.measure}</TableCell>
              <TableCell align="right">{ingredient.cost}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
