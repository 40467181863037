import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import ArtistsTable from './ArtistsTable';
import { fetchArtists } from 'redux/ducks/artists';

export default function Artists(): JSX.Element {
  const dispatch = useDispatch();
  const { artists } = useSelector<IStore, IStore>((state) => state);
  console.log(artists);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });
    const d = document.querySelectorAll<HTMLElement>('.d-artists');

    if (d.item(0)) {
      d.item(0).style.color = '#fe309c';

      d.item(1).style.color = '#fe309c';
      const dText = document.querySelectorAll<HTMLElement>('.d-artists-text');
      dText.item(0).style.color = '#fe309c';
    }
  });

  useEffect(() => {
    dispatch(fetchArtists());
  }, [dispatch]);

  return (
    <>
      <Loading show={artists.fetch} />
      <Grid container spacing={3}>
        <ArtistsTable artists={artists.artists} />
      </Grid>
    </>
  );
}
