import {
  IProduction,
  IProductionState,
  IFetchAction,
  ISuccessAction,
  ICurrentOrderIDAction,
  ISetAction,
  IUnsetAction,
  IFailureAction,
  IProductionAction,
  Dispatch,
  ThunkAction,
  ETypesProduction
} from 'interfaces/production';

import { EAlertVariant } from 'interfaces/alert';

import { EProductionStatus, EKitchenStatus } from 'enums/order';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import {
  checkOrderStatus,
  unsetOldOrder,
  setNewOrder
} from 'helpers/production';
import { mountQueryURL } from 'utils/query';

/* Production State. */
const initialState: IProductionState = {
  fetch: false,
  count: 0,
  currentOrderID: 0,
  results: [],
  todo: [],
  doing: [],
  done: [],
  next: '',
  previous: '',
  error: ''
};

/* Production Reducer. */
export default (
  state: IProductionState = initialState,
  action: IProductionAction
): IProductionState => {
  switch (action.type) {
    case ETypesProduction.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesProduction.SUCCESS:
      return {
        ...state,
        fetch: false,
        count: action.payload.count,
        results: action.payload.results,
        todo: action.payload.results.filter(
          (order) => order.status === EProductionStatus.TODO
        ),
        doing: action.payload.results.filter(
          (order) => order.status === EProductionStatus.DOING
        ),
        done: action.payload.results.filter(
          (order) => order.status === EProductionStatus.DONE
        ),
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesProduction.CURRENT_ORDER_ID:
      return {
        ...state,
        currentOrderID: action.payload
      };
    case ETypesProduction.SET:
      return {
        ...state,
        currentOrderID: 0,
        [checkOrderStatus(action.payload.status)]: setNewOrder(
          action.payload,
          state[checkOrderStatus(action.payload.status)]
        )
      };
    case ETypesProduction.UNSET:
      return {
        ...state,
        [checkOrderStatus(action.payload.last_status)]: unsetOldOrder(
          action.payload,
          state[checkOrderStatus(action.payload.last_status)]
        )
      };
    case ETypesProduction.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* Production Action Creators Functions. */
export const fetchProduction = (): IFetchAction => ({
  type: ETypesProduction.FETCH
});

export const successProduction = (
  payload: IProductionState
): ISuccessAction => ({
  type: ETypesProduction.SUCCESS,
  payload
});

export const currentOrderIDKitchen = (
  payload: number
): ICurrentOrderIDAction => ({
  type: ETypesProduction.CURRENT_ORDER_ID,
  payload
});

export const setProduction = (payload: IProduction): ISetAction => ({
  type: ETypesProduction.SET,
  payload
});

export const unsetProduction = (payload: IProduction): IUnsetAction => ({
  type: ETypesProduction.UNSET,
  payload
});

export const failureProduction = (payload: string): IFailureAction => ({
  type: ETypesProduction.FAILURE,
  payload
});

/* Production Side Effects Functions. */
export const getProduction =
  (params: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      // dispatch(fetchProduction());
      const query = mountQueryURL(params);
      let url = `/orders/api/production_orders_resumed/${query}`;
      const response = await fetch({
        method: EMethod.GET,
        url: url
      });
      dispatch(successProduction(response));
    } catch (error) {
      dispatch(failureProduction(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const setProductionStatus =
  (order: IProduction): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(currentOrderIDKitchen(order.id));
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/production_orders/${order.id}/change_status/`
      });
    } catch (error) {
      dispatch(failureProduction(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const setProductionRevertStatus =
  (order: IProduction): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(currentOrderIDKitchen(order.id));
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/production_orders/${order.id}/revert_status/`
      });
    } catch (error) {
      dispatch(failureProduction(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const updateProduction =
  (socket: any): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const { message } = socket;
      const order: IProduction = message;
      if (order.last_status) dispatch(unsetProduction(order));
      if (order.status === EKitchenStatus.COMPLETED) return null;
      dispatch(setProduction(order));
    } catch (error) {
      dispatch(failureProduction(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };
