import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  IUpdateAction,
  IDeleteAction,
  ICreateAction,
  Menu,
  IMenuState,
  ETypesMenu,
  IManagerAction,
  GetState
} from 'interfaces/menu';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Authentication State. */
const initialState: IMenuState = {
  fetch: false,
  menus: []
};

/* Authentication Reducer. */
export default (
  state: IMenuState = initialState,
  action: IManagerAction
): IMenuState => {
  switch (action.type) {
    case ETypesMenu.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesMenu.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesMenu.CREATE_MENU:
      const createdResults = state.menus.map((menu) => {
        if (menu.menu_id === action.payload.menu_id) {
          return action.payload;
        }
        return menu;
      });
      createdResults.push(action.payload);
      return {
        ...state,
        menus: createdResults
      };
    case ETypesMenu.UPDATE_MENU:
      const updatedResults = state.menus.map((menu) => {
        if (menu.menu_id === action.payload.menu_id) {
          return action.payload;
        }
        return menu;
      });
      return {
        ...state,
        menus: updatedResults
      };
    case ETypesMenu.DELETE_MENU:
      const deletedResults = state.menus.filter(
        (menu) => menu.menu_id !== action.payload
      );
      return {
        ...state,
        menus: deletedResults
      };
    case ETypesMenu.FAILURE:
      return {
        ...state,
        fetch: false
      };
    case ETypesMenu.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchMenu = (): IFetchAction => ({
  type: ETypesMenu.FETCH
});

export const successFetchMenu = (payload: IMenuState): ISuccessAction => ({
  type: ETypesMenu.SUCCESS,
  payload
});

export const updateMenuOnMenus = (payload: Menu): IUpdateAction => ({
  type: ETypesMenu.UPDATE_MENU,
  payload
});

export const createMenuOnMenus = (payload: Menu): ICreateAction => ({
  type: ETypesMenu.CREATE_MENU,
  payload
});

export const deleteMenuOnMenus = (payload: number): IDeleteAction => ({
  type: ETypesMenu.DELETE_MENU,
  payload
});

export const failureManagerDashboard = (payload: string): IFailureAction => ({
  type: ETypesMenu.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesMenu.RESET
});

/* Authentication Side Effects Functions. */

export const fetchMenusGeneral =
  (): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { menu } = getState();
      dispatch(fetchMenu());
      let url = `products/api/menus/?query={menu_id, name, collection_page_title, type_of_menu, icon, banner, description, products, active, slug}`;
      let response;
      let results = [];
      do {
        response = await fetch({
          method: EMethod.GET,
          url: url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      dispatch(successFetchMenu({ ...menu, menus: response.results }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const createMenu =
  (data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      let response;
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === 'banner') {
          if (data[key]) {
            body.append(key, data[key]);
          } else {
            return null;
          }
        } else {
          body.append(key, data[key]);
        }
      });
      response = await fetch({
        method: EMethod.POST,
        headers: { 'content-type': 'multipart/form-data' },
        url: 'products/api/menus/',
        data: body
      });
      dispatch(createMenuOnMenus(response));
      dispatch(sendAlert('Menu created successfully', EAlertVariant.SUCCESS));
      return true;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      if (Object.keys(error).includes('slug')) {
        dispatch(
          sendAlert(
            'This slug already exists in our database',
            EAlertVariant.ERROR
          )
        );
      } else {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }

      return false;
    }
  };

export const editMenu =
  (menu_id: number = undefined, data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      console.log('data ', data);
      let response;
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === 'banner') {
          if (data[key]) {
            body.append(key, data[key]);
          } else {
            return null;
          }
        } else {
          body.append(key, data[key]);
        }
      });
      response = await fetch({
        method: EMethod.PATCH,
        headers: { 'content-type': 'multipart/form-data' },
        url: `products/api/menus/${menu_id}/`,
        data: body
      });
      dispatch(updateMenuOnMenus(response));
      dispatch(sendAlert('Menu edited successfully', EAlertVariant.SUCCESS));
      return true;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      if (Object.keys(error).includes('slug')) {
        dispatch(
          sendAlert(
            'This slug already exists in our database',
            EAlertVariant.ERROR
          )
        );
      } else {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
      return false;
    }
  };

export const handleActiveMenu =
  (menu_id: number = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      let response;
      response = await fetch({
        method: EMethod.POST,
        url: `products/api/menus/${menu_id}/handle_activation/`
      });
      dispatch(updateMenuOnMenus(response));
      dispatch(
        sendAlert(
          'Menu active/inactive edited successfully',
          EAlertVariant.SUCCESS
        )
      );
      return true;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const editMenuProducts =
  (menu_id: number = undefined, data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      let response;
      response = await fetch({
        method: EMethod.POST,
        url: `products/api/menus/${menu_id}/add_products/`,
        data: data
      });
      dispatch(updateMenuOnMenus(response));
      dispatch(
        sendAlert('Menu products edited successfully', EAlertVariant.SUCCESS)
      );
      return true;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const deleteMenu =
  (menu_id: number = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      await fetch({
        method: EMethod.DELETE,
        url: `products/api/menus/${menu_id}/`
      });
      dispatch(deleteMenuOnMenus(menu_id));
      dispatch(
        sendAlert('Menu deleted edited successfully', EAlertVariant.SUCCESS)
      );
      return true;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };
