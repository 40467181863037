import React, { useEffect, useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
import {
  Select,
  MenuItem,
  Grid,
  Container,
  FormControl,
  Button,
  InputLabel,
  Input,
  IconButton,
  FormHelperText,
  TextField,
  FormControlLabel,
  Switch,
  Checkbox,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { editProduct } from 'redux/ducks/stock';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Product, Ingredients, Tags, Editions } from 'interfaces/stock';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import AddIcon from '@material-ui/icons/Add';
import './styles.css';
import { fetch } from './../../utils/request';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteModal from '../../components/Common/Modal/index';
import { EAlertVariant } from 'interfaces/alert';
import { sendAlert } from '../../redux/ducks/alert';
import ModalCreateTechnique from './ModalCreateTechnique';
import ModalCreateArtist from './ModalCreateArtist';
import ModalCreateClient from './ModalCreateClient';
import { CLOUDINARY_URL } from 'settings';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ProductImagesContainer from './ProductImagesContainer';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//Overrides theme of Dropzone component
const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});
const ckEditorToolbarConfig = [
  'Heading',
  '|',
  'bold',
  'italic',
  '|',
  'BulletedList',
  'NumberedList',
  '|',
  'Indent',
  '|',
  'BlockQuote',
  'undo',
  'redo'
];
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  product: Product;
  ingredients: Ingredients[];
  tags: Tags[];
  reset: () => void;
}

export default function ModalEditProduct(props: SimpleDialogProps) {
  const { onClose, open, product, reset } = props;
  const [productImages, setProductImages] = React.useState([]);
  const [openCreateTechnique, setOpenCreateTechnique] = React.useState(false);
  const [openCreateArtist, setOpenCreateArtist] = React.useState(false);
  const [openCreateClient, setOpenClient] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [index, setIndex] = React.useState('');
  const [pageTag, setPageTag] = React.useState(false);
  const [indexError, setIndexError] = React.useState('');

  // const [imageSource, setImageSource] = React.useState<File[]>([]);
  // const [imageIds, setImageIds] = React.useState([]);
  // const [deletedImages, setDeletedImages] = React.useState([]);
  // const [deletedOn, setDeletedOn] = React.useState([]);
  // const [imagePaths, setImagePaths] = React.useState([]);

  const [selectedArtist, setSelectedArtist] = React.useState(0);
  const [contentSheet, setContentSheet] = React.useState<File>();
  const [video, setVideo] = React.useState<File>();
  const [previewVideo, setPreviewVideo] = React.useState<File>();
  const [fetchedArtists, setFetchedArtists] = React.useState([]);
  const [name, setName] = React.useState('');
  const [year, setYear] = React.useState('');
  const [hs_code, setHscode] = React.useState('');
  const [price, setPrice] = React.useState(0);
  const [titleOn, setTitleOn] = React.useState(true);
  const [valueForEditionsTabs, setValueForEdidtionsTabs] = React.useState(0);
  const [editionsError, setEditionsError] = React.useState([]);
  const [slug, setSlug] = React.useState('');
  const [slugError, setSlugError] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [quote, setQuote] = React.useState('');
  const [quoteSignature, setQuoteSignature] = React.useState('');
  const [descriptionError, setDescriptionError] = React.useState('');
  const [selectedArtistError, setSelectedArtistError] = React.useState('');

  const [editionsOn, setEditionsOn] = React.useState(false);
  const [techniques, setTechniques] = React.useState([]);
  const [template, setTemplate] = React.useState('');
  const [technique, setTechnique] = React.useState({ id: 0, name: '' });
  const [inputValueTechnique, setInputValueTechnique] = React.useState('');
  const [techniqueError, setTechniqueError] = React.useState('');
  const [signatureType, setSignatureType] = React.useState('');
  const [editionSize, setEditionSize] = React.useState('');
  const [editions, setEditions] = React.useState([]);
  const [cost, setCost] = React.useState(0);
  const [description, setDescription] = React.useState('');
  const [artworkImagesError, setArtworkImagesError] = React.useState('');
  const [inputValueConsignorOwner, setInputValueConsignorOwner] =
    React.useState([]);

  const [inputValueLastExaminedBy, setInputValueLastExaminedBy] =
    React.useState([]);
  const handleChange = () => {
    setTitleOn(!titleOn);
    setEditionsOn(!editionsOn);
    handleEditionsSlug();
  };
  const [showImagesTab, setShowImagesTab] = useState(false);
  const handleChangeArtworkImages = () => {
    setTitleOn(false);
    setEditionsOn(false);
    setShowImagesTab(true);
  };
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [weight, setWeigt] = useState(0);
  const [extras, setExtras] = useState([]);
  const [extrasOld, setExtrasOld] = useState([]);

  const [extrasLimit, setExtrasLimit] = useState([]);
  const [productExtras, setProductExtras] = useState([]);
  const [productExtrasPrice, setProductExtrasPrice] = useState([]);
  const [productExtrasQuantity, setProductExtrasQuantity] = useState([]);
  const [stores, setStores] = useState([]);
  const [users, setUsers] = useState([]);
  const [waiters, setWaiters] = useState([]);
  const [deleteEditionModal, setDeleteEditionModal] = useState(false);
  const [idDeleteEditionModal, setIdDeleteEditionModal] = useState(0);
  const [deletedEditions, setDeletedEditions] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [colliNumber, setColliNumber] = useState(0);
  const [additionalDimensions, setAdditionalDimensions] = useState([
    {
      id: 0,
      artwork: '',
      length: 0,
      width: 0,
      height: 0,
      weight: 0
    }
  ]);
  const [homePageArtworks, setHomePageArtworks] = React.useState([]);
  const [additionalDimensionsToBeDeleted, setAdditionalDimensionsToBeDeleted] =
    React.useState([]);
  const [videoDescription, setVideoDescription] = React.useState('');
  const [youtubeVideoURL, setYoutubeVideoURL] = React.useState('');

  const handleChangeTabsForEditions = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValueForEdidtionsTabs(newValue);
  };

  const dispatch = useDispatch();

  const handleClose = () => {
    setName('');
    setNameError('');
    setPrice(0);
    setSelectedArtist(0);
    setSelectedArtistError('');
    setTechnique({ id: 0, name: '' });
    setInputValueTechnique('');
    setTechniqueError('');
    setCost(0);
    setDescription('');
    setDescriptionError('');
    setSlug('');
    setSlugError('');
    setQuote('');
    setQuoteSignature('');
    setTemplate('');
    setSignatureType('');
    setEditionSize('');
    setProductExtras([]);
    setProductExtrasPrice([]);
    setProductExtrasQuantity([]);
    setExtrasLimit([]);
    setEditions([]);
    setEditionsError([]);
    setEditionsOn(false);
    setTitleOn(true);
    setIndex('');
    setIndexError('');
    setVideo(null);
    setPreviewVideo(null);
    setClicked(false);
    setAdditionalDimensionsToBeDeleted([]);
    setPageTag(false);
    setYoutubeVideoURL('');
    setVideoDescription('');
    setArtworkImagesError('');

    onClose();
  };
  const _getArtist = async () => {
    try {
      let response;
      const results = [];
      let url = '/products/api/artists/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setFetchedArtists(response.results);
    } catch (error) {
      console.log('error fetching ingredients');
    }
  };
  const _getExtras = async () => {
    try {
      let response;
      const results = [];
      let url = '/products/api/extras/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setProductExtras(response.results);
    } catch (error) {
      console.log('error fetching extras');
    }
  };
  const _getTechniques = async () => {
    try {
      let response;
      const results = [];
      let url = '/products/api/techniques/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setTechniques(response.results);
    } catch (error) {
      console.log('error fetching artists');
    }
  };

  const handleMaps = () => {
    const newEditions = [];
    const x = new Array(product.editions.length).fill(['', '', '', '']);
    setEditionsError(x);
    for (let index = 0; index < product.editions.length; index++) {
      const element = product.editions[index];
      if (element.last_examined_by.id) {
        element.last_examined_by.username = element.last_examined_by.name;
        delete element.last_examined_by.name;
      }
      newEditions.push({
        base_id: index,
        id: element.id,
        name: element.name,
        cost: element.cost,
        edition_number: element.edition_number,
        slug: createEditionSlug(String(element.edition_number)),
        stock_status: element.stock_status,
        base_price: element.base_price,
        tax_status: element.tax_status,
        condition_notes: element.condition_notes,
        internal_condition_notes: element.internal_condition_notes,
        current_location: element.current_location,
        location_details: element.location_details,
        location_date: element.location_date,
        location_notes: element.location_notes,
        location_history: element.location_history,
        store_owner: element.store_owner,
        consignor_owner: element.consignor_owner,
        last_examined_by: element.last_examined_by,
        third_party_base_price: element.third_party_base_price,
        consignment_date: element.location_date,
        return_consignment_date: element.location_date,
        consignment_terms: element.consignment_terms,
        consignment_notes: element.consignment_notes,
        consignment_documents: element.consignment_documents,
        certificate: element.certificate,
        is_old: true,
        acquisition_date: element.acquisition_date,
        acquired_from: element.acquired_from
      });
    }
    setEditions(newEditions);
  };
  const _getUsers = async () => {
    try {
      let response;
      const results = [];
      let url = '/users/api/clients/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setUsers(response.results);
      //   const x = [];
      //   response.results.forEach((element) => {
      //     x.push(element.first_name + '' + element.last_name);
      //   });
      //   setUsersOptions(x);
    } catch (error) {
      console.log('error fetching users');
    }
  };
  const _getStores = async () => {
    try {
      let response;
      const results = [];
      let url = '/products/api/stores/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setStores(response.results);
    } catch (error) {
      console.log('error fetching stores');
    }
  };
  const _getWaiters = async () => {
    try {
      let response;
      const results = [];
      let url = '/waiter/api/waiter/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setWaiters(response.results);
      //   const x = [];
      //   response.results.forEach((element) => {
      //     x.push(element.first_name + '' + element.last_name);
      //   });
      //   setUsersOptions(x);
    } catch (error) {
      console.log('error fetching users');
    }
  };
  const _getHomePageArtworks = async () => {
    try {
      let response;
      const results = [];
      let url = '/products/api/products/?page_tag=home';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setHomePageArtworks(response.results);
    } catch (error) {
      console.log('error fetching home page artworks');
    }
  };
  useEffect(() => {
    setAdditionalDimensions([]);
    if (product) {
      setName(product.name);
      setPrice(product.price);
      setCost(product.cost);
      setActive(product.active);
      setProductImages(product.product_images);

      if (product.year) {
        setYear(product.year);
      }
      // console.log("product.hs_code", product)
      if (product.hs_code) {
        setHscode(product.hs_code);
      }
      if (product.index) {
        setIndex(product.index);
      }
      if (product.page_tag) {
        setPageTag(product.page_tag === 'home');
      }
      setQuote(product.quotation);
      setQuoteSignature(product.p_quotation_signature);
      setDescription(product.description);
      setSelectedArtist(product.artist.id);
      if (product.technique && product.technique.id) {
        setTechnique(product.technique);
      }
      if (product.dimensions_additional) {
        setAdditionalDimensions(product.dimensions_additional);
      }
      setTemplate(product.template);
      setSignatureType(product.signature_type);
      setEditionSize(product.edition_size);
      setExtras(product.extras);
      setContentSheet(product.content_sheet);
      setYoutubeVideoURL(product?.youtube_video);
      setVideoDescription(product?.description_video);
      setColliNumber(product?.colli_number);
      // setImageIds(
      //   imagesSorted.map((element) => {
      //     return element.id;
      //   })
      // );
      if (product.video) {
        setVideo(product.video);
      }
      if (product.preview_video) {
        setPreviewVideo(product.preview_video);
      }

      const x = new Array(product.extras.length);
      const y = new Array(product.extras.length);
      const z = new Array(product.extras.length);
      const w = new Array(product.extras.length);
      const a = new Array(product.extras.length);
      product.extras.map((element, index) => {
        x[index] = element.id;
        y[index] = element.quantity;
        z[index] = element.price;
        w[index] = element.limit;
        a[index] = 'old';
      });
      setExtras(x);
      setProductExtrasPrice(z);
      setProductExtrasQuantity(y);
      setExtrasLimit(w);
      setExtrasOld(a);
      setWidth(product.width !== null ? product.width : 0);
      setHeight(product.height !== null ? product.height : 0);
      setLength(product.length !== null ? product.length : 0);
      setWeigt(product.weight !== null ? product.weight : 0);
      if (product.slug !== null) {
        setSlug(product.slug);
      }
      handleMaps();
      _getArtist();
      _getExtras();
      _getUsers();
      _getStores();
      _getWaiters();
      _getTechniques();
      _getHomePageArtworks();
    }
  }, [product]);

  const handleImage = (event: any) => {
    if (event.length > 0) {
      // const url = URL.createObjectURL(event[event.length - 1]);

      addNewImageToProduct(event[event.length - 1]);
      setArtworkImagesError('');

      // setImageSource((imageSource) => [
      //   ...imageSource,
      //   event[event.length - 1]
      // ]);

      // setDeletedOn((deletedOn) => [...deletedOn, 0]);
    }
  };

  const [orderedImages, setOrderedImages] = useState([]);
  const handleOrderedImages = (images: []) => {
    // console.log('images ', images);
    setOrderedImages(images);
    setArtworkImagesError('');
    return null;
  };

  const addNewImageToProduct = async (image: string) => {
    try {
      const body = new FormData();
      body.append('sort_key', '0');
      body.append('image', image);
      const response = await fetch({
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        url: `/products/api/products/${product.product_id}/add_image_to_product/`,
        data: body
      });
      // console.log('response ', response);
      setProductImages(response.product_images);
      setArtworkImagesError('');
    } catch (error) {
      console.log('error adding new image to product');
    }
  };

  const handleRemoveImage = async (image) => {
    setIsDeleting(true);
    const productImageToBeRemoved = productImages.filter(
      (product) => product.image === image
    )[0];
    if (productImages.length < 2) {
      dispatch(
        sendAlert(
          `The artwork needs to have at least one image`,
          EAlertVariant.ERROR
        )
      );
      setIsDeleting(false);
      return false;
    }

    try {
      await fetch({
        method: 'DELETE',
        headers: { 'content-type': 'multipart/form-data' },
        url: `/products/api/productimages/${productImageToBeRemoved.id}`
      }).then((response) => {
        removeImageIntoProductImages(productImageToBeRemoved.id);
      });
    } catch (error) {
      console.log('error deleting image to product');
    }
    setIsDeleting(false);
    return true;
  };

  const removeImageIntoProductImages = (imageId: number) => {
    setProductImages(productImages.filter((product) => product.id != imageId));
    return null;
  };

  const returnFromImageTab = async () => {
    setTitleOn(true);
    setShowImagesTab(false);
    const formatedData = [];
    orderedImages.map((image) => {
      formatedData.push({ id: image.id, sort_key: image.sort_key });
    });

    const formatedObjectToSend = {
      images: formatedData
    };
    try {
      await fetch({
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        url: `/products/api/productimages/sort_images/`,
        data: formatedObjectToSend
      });
      // console.log('response ', response);
      // const productImages = response.product_images;
      // const lastImage = productImages[productImages.length - 1];

      // setImagePaths((imagePaths) => [...imagePaths, lastImage.image]);
      // setAddDragableCard(true);
    } catch (error) {
      console.log('error updating product images');
    }
  };

  const handleAddDimensions = () => {
    const x = [...additionalDimensions];
    x.push({
      id: 0,
      artwork: '',
      length: 0,
      width: 0,
      height: 0,
      weight: 0
    });
    setAdditionalDimensions(x);
  };
  const handleRemoveDimensions = (index: number) => {
    if (additionalDimensions[index].id) {
      const x = [...additionalDimensionsToBeDeleted];
      x.push(additionalDimensions[index].id);
      setAdditionalDimensionsToBeDeleted(x);
    }
    const x = [...additionalDimensions];
    x.splice(index, 1);
    setAdditionalDimensions(x);
  };

  const handleName = (event: any) => {
    setName(event.target.value);
    setNameError('');
    const x = [...editions];
    x.map((element, index) => {
      element.name = '';
      element.name = event.target.value;
      x[index] = element;
      setEditions(x);
    });
  };

  // const handleRemove = (index: any) => {
  //   if (imageIds[index]) {
  //     const x = [...deletedImages];
  //     x.push(imageIds[index]);
  //     setDeletedImages(x);
  //     const y = [...imageIds];
  //     y.splice(index, 1);
  //     setImageIds(y);
  //   }
  //   let x = [...deletedOn];
  //   x.splice(index, 1);
  //   setDeletedOn(x);

  //   x = [...imageSource];
  //   x.splice(index, 1);
  //   setImageSource(x);
  // };


  const handleChangeBasePriceEdition = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            base_price: value
          }
          : item
      )
    );
  };
  const handleChangeBasePriceAll = (value: any) => {
    const x = [...editions];
    x.map((element) => {
      element.base_price = value;
    });
    setEditions(x);
    setPrice(value);
  };
  const checkFormError = () => {
    let entered = false;
    if (name === '') {
      setNameError('Please add title');
      entered = true;
    }

    if (description === '') {
      setDescriptionError('Please add description');
      entered = true;
    }
    if (slug === '') {
      setSlugError('Please add slug');
      entered = true;
    }
    if (selectedArtist === 0) {
      setSelectedArtistError('Please add artist');
      entered = true;
    }
    if (productImages.length < 1) {
      setArtworkImagesError('Please add the artwork images');
      entered = true;
    }
    if (technique.id === 0) {
      setTechniqueError('Please add a technique');
      entered = true;
    }
    if (
      (index !== '' && (parseInt(index) > 16 || parseInt(index) < 1)) ||
      (pageTag && index === '')
    ) {
      setIndexError('The homepage position must be between 1 and 16');
      entered = true;
    }
    homePageArtworks.map((element) => {
      if (
        index &&
        parseInt(element.index) === parseInt(index) &&
        element.product_id !== product.product_id
      ) {
        setIndexError(
          `This homepage position is already associated with the artwork: ${element.name}. To associate the homepage position with this artwork, please remove it from ${element.name}.`
        );
        entered = true;
      }
    });

    editions.forEach((element, index) => {
      const x = [...editionsError];
      if (element.name === '') {
        x[index][0] = 'Please add a title';
        entered = true;
      }
      if (element.slug === '') {
        x[index][1] = 'Please add a slug';
        entered = true;
      }
      if (element.store_owner.name === '') {
        x[index][2] = 'Please add a store owner';
        entered = true;
      }
      if (
        element.consignor_owner.name === '' &&
        element.stock_status === 'Consigned Out'
      ) {
        x[index][3] = 'Please add a consignor owner';
        entered = true;
      }
      setEditionsError(x);
    });
    if (entered) {
      dispatch(sendAlert('Please check the errors below', EAlertVariant.ERROR));
    }
    if (editions.length <= 0) {
      entered = true;

      dispatch(sendAlert('Please add at least 1 edition', EAlertVariant.ERROR));
    }
    return entered;
  };

  const submitCreation = async () => {
    if (!checkFormError()) {
      setClicked(true);

      const oldEditionsToSubmit = [];
      const newEditionsToSubmit = [];
      const editionIdsOld = [];
      const editionIdsNew = [];
      let deleteContentSheet = false;

      const allFilesOld = new Array(editions.length).fill({
        consignment_documents: null,
        certificate: null
      });
      const allFilesNew = new Array(editions.length).fill({
        consignment_documents: null,
        certificate: null
      });
      const additionalDimensionsToBeUpdated = additionalDimensions.filter(
        (x) => x.id !== 0
      );
      for (let index = 0; index < editions.length; index++) {
        const element = editions[index];

        if (element.is_old) {
          const x = {
            id: element.id,
            name: element.name,
            edition_number: element.edition_number,
            third_party_base_price: element.third_party_base_price,
            slug: element.slug,
            stock_status: element.stock_status,
            cost: element.cost,
            base_price: element.base_price,
            tax_status: element.tax_status,
            condition_notes: element.condition_notes,
            internal_condition_notes: element.internal_condition_notes,
            current_location: element.current_location,
            location_details: element.location_details,
            location_date: element.location_date,
            consignment_date: element.location_date,
            return_consignment_date: element.location_date,
            location_notes: element.location_notes,
            location_history: element.location_history,
            store_owner_id: element.store_owner.id,
            acquired_from: element.acquired_from,
            acquisition_date: element.acquisition_date,
            consignor_owner_id: element.consignor_owner.id,
            last_examined_by:
              element.last_examined_by !== 0 ||
                element.last_examined_by !== null
                ? element.last_examined_by.user
                : '',
            last_examined_by_id: 0
          };
          if (element.last_examined_by !== 0) {
            x.last_examined_by_id = element.last_examined_by.user;
            delete x.last_examined_by;
          } else {
            delete x.last_examined_by;
            delete x.last_examined_by_id;
          }
          if (element.consignor_owner.id === 0) {
            delete x.consignor_owner_id;
          }
          if (element.tax_status === '') {
            delete x.tax_status;
          }
          allFilesOld[index].consignment_documents =
            element.consignment_documents;
          allFilesOld[index].certificate = element.certificate;
          oldEditionsToSubmit.push(x);
          editionIdsOld.push(element.id);
        } else {
          const x = {
            name: element.name,
            edition_number: element.edition_number,

            slug: element.slug,
            stock_status: element.stock_status,
            base_price: element.base_price,
            cost: element.cost,
            acquired_from: element.acquired_from,
            acquisition_date: element.acquisition_date,
            tax_status: element.tax_status,
            condition_notes: element.condition_notes,
            internal_condition_notes: element.internal_condition_notes,
            current_location: element.current_location,
            location_details: element.location_details,
            location_date: element.location_date,
            location_notes: element.location_notes,
            location_history: element.location_history,
            store_owner_id: element.store_owner.id,
            consignor_owner_id: element.consignor_owner.id,
            consignment_date: element.location_date,
            return_consignment_date: element.location_date,
            consignment_terms: element.consignment_terms,
            consignment_documents: element.consignment_documents,
            certificate: element.certificate,
            consignment_notes: element.consignment_notes,
            third_party_base_price: element.third_party_base_price,
            last_examined_by:
              element.last_examined_by !== 0 ||
                element.last_examined_by !== null
                ? element.last_examined_by.user
                : '',
            last_examined_by_id: 0
          };
          if (element.last_examined_by !== 0) {
            x.last_examined_by_id = element.last_examined_by.user;
            delete x.last_examined_by;
          } else {
            delete x.last_examined_by;
            delete x.last_examined_by_id;
          }
          if (element.consignor_owner.id === 0) {
            delete x.consignor_owner_id;
          }
          if (element.tax_status === '') {
            delete x.tax_status;
          }
          allFilesNew[index].consignment_documents =
            element.consignment_documents;
          allFilesNew[index].certificate = element.certificate;
          editionIdsNew.push(element.id);
          newEditionsToSubmit.push(x);
        }
      }
      const extrasToBeSubmitted = [];
      const oldExtrasToBeSubmitted = [];
      extras.map((element, index) => {
        if (
          extrasOld[index] === 'new' &&
          (element !== null || element !== '')
        ) {
          extrasToBeSubmitted.push({
            ingredient_id: element,
            extra: {
              limit: extrasLimit[index] > 0 ? extrasLimit[index] : 1,
              measure: 'Unit',
              price:
                productExtrasPrice[index] > 0 ? productExtrasPrice[index] : 0,
              quantity:
                productExtrasQuantity[index] > 0
                  ? productExtrasQuantity[index]
                  : 0,
              is_sauce: false
            }
          });
        } else if (element !== null || element !== '') {
          oldExtrasToBeSubmitted.push({
            ingredient_id: element,
            extra: {
              limit: extrasLimit[index] > 0 ? extrasLimit[index] : 1,
              measure: 'Unit',
              price:
                productExtrasPrice[index] > 0 ? productExtrasPrice[index] : 0,
              quantity:
                productExtrasQuantity[index] > 0
                  ? productExtrasQuantity[index]
                  : 0,
              is_sauce: false
            }
          });
        }
      });
      const pr = {
        name,
        active,
        year,
        hs_code,
        index,
        page_tag: pageTag ? 'home' : '',
        // deletedImages,
        quotation: quote,
        p_quotation_signature: quoteSignature,
        imagesToBeDeleted: name,
        price,
        signature_type: signatureType,
        content_sheet: contentSheet,
        template,
        stock: 0,
        technique_id: 0,
        weight,
        length,
        width,
        height,
        technique,
        edition_size: editionSize,
        slug,
        cost,
        description,
        artist_id: selectedArtist,
        oldEditions: oldEditionsToSubmit,
        newEditions: JSON.stringify(newEditionsToSubmit),
        extrasToBeSubmitted,
        oldExtrasToBeSubmitted,
        allFilesNew,
        allFilesOld,
        editionIdsOld,
        deletedEditions,
        video,
        youtube_video: youtubeVideoURL,
        preview_video: previewVideo,
        additionalDimensionsToBeUpdated,
        additionalDimensionsToBeDeleted,
        description_video: videoDescription,
        colli_number: colliNumber
      };
      if (pr.technique.id === 0) {
        delete pr.technique;
        delete pr.technique_id;
      } else {
        pr.technique_id = pr.technique.id;
        delete pr.technique;
      }
      if (pr.content_sheet === undefined) {
        deleteContentSheet = true;
      }
      if (!(pr.content_sheet instanceof File)) {
        delete pr.content_sheet;
      }
      if (pr.index === undefined || pr.index === null || pr.index === '') {
        pr.index = '0';
      }
      if (pr.video === undefined) {
        delete pr.video;
      }
      if (pr.preview_video === undefined || pr.preview_video === null) {
        delete pr.preview_video;
      }

      dispatch(
        editProduct(
          product.product_id,
          pr,
          orderedImages,
          additionalDimensions.filter((item) => item.id === 0),
          deleteContentSheet
        )
      ).then((result: boolean) => {
        reset();
        if (result) {
          handleClose();
        } else {
          setClicked(false);
        }
      });
    }
  };

  const handleChangeNameEdition = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            name: value
          }
          : item
      )
    );
  };

  const handleChangeEditionNumberEdition = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            edition_number: value,
            slug: createEditionSlug(value)
          }
          : item
      )
    );
  };

  const handleChangeStockStatus = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            stock_status: value
          }
          : item
      )
    );
  };

  const handleChangeCurrentLocation = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            current_location: value
          }
          : item
      )
    );
  };

  const handleChangeLocationHistory = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            location_history: value
          }
          : item
      )
    );
  };

  const handleChangeLocationDate = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            location_date: value
          }
          : item
      )
    );
  };
  const handleChangeAcquiredDate = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            acquisition_date: value
          }
          : item
      )
    );
  };
  const handleChangeAcquiredBy = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            acquired_from: value
          }
          : item
      )
    );
  };
  const handleChangeConditionNotes = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            condition_notes: value
          }
          : item
      )
    );
  };

  const newEditionNumber = () => {
    const lastNumber = editions.length + 1;
    return `${lastNumber}/${lastNumber}`;
  };

  const sanitizeSlug = (slug: string) => {
    let alphanumericSlug = '';
    if (slug) {
      alphanumericSlug = slug.replace(/\W/g, '-');
    }
    return alphanumericSlug;
  };

  const createEditionSlug = (editionNumber: string) => {
    return product?.slug + '-' + sanitizeSlug(editionNumber);
  };

  const handleEditionsSlug = () => {
    setEditions(
      editions?.map((edition) =>
        edition.slug
          ? {
            ...edition,
            slug: product?.slug + '-' + sanitizeSlug(edition.edition_number)
          }
          : {
            ...edition,
            slug: product?.slug
          }
      )
    );
    return null;
  };

  const handleChangeSlugEdition = (index: any, value: any) => {
    const alphanumericSlug = sanitizeSlug(value);

    const y = [...editions];
    y[index].slug = alphanumericSlug;
    setEditions(y);
    const x = [...editionsError];
    x[index][1] = '';
    setEditionsError(x);
  };

  const handleChangeAllSlugs = (value: any) => {
    const x = [...editions];
    x.map((element, index) => {
      element.slug = '';
      element.slug = value + '-' + element.edition_number;
      x[index] = element;
      setEditions(x);
    });
  };
  const handleSelectedTemplate = (event: any) => {
    const templateValue = event.target.value;
    setTemplate(templateValue);
    if (templateValue === 'SCULPTURE') {
      setHscode('9703 9000 000');
    } else if (templateValue === 'ART') {
      setHscode('9702 0000 900');

    } else {
      setHscode('');
    }
  };
  const handleSelectedTechnique = (event: any) => {
    if (event) {
      setTechnique(event);
    } else {
      setTechnique({ id: 0, name: '' });
    }
    setTechniqueError('');
  };
  const handleLength = (value: any) => {
    setLength(value);
  };
  const handleWeigt = (value: any) => {
    setWeigt(value);
  };
  const handleWidth = (value: any) => {
    setWidth(value);
  };
  const handleHeight = (value: any) => {
    setHeight(value);
  };
  const handleSignatureType = (value: any) => {
    setSignatureType(value);
  };

  const handleEditionSize = (value: any) => {
    setEditionSize(value);
  };
  const handleChangeLastExamined = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            last_examined_by: value
          }
          : item
      )
    );
  };
  const handleContentSheet = (event: any) => {
    if (event.length > 0) {
      setContentSheet(event[0]);
    }
  };
  const handleVideo = (event: any) => {
    if (event.length > 0) {
      setVideo(event[0]);
    }
  };

  const handleChangeConsignmentDate = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            consignment_date: value
          }
          : item
      )
    );
  };
  const handleChangeTaxStatus = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            tax_status: value
          }
          : item
      )
    );
  };
  const handleChangeReturnConsignmentDate = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            return_consignment_date: value
          }
          : item
      )
    );
  };
  const handleChangeConsignmentTerms = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            consignment_terms: value
          }
          : item
      )
    );
  };
  const handleChangeConsignmentDocuments = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            consignment_documents: value.target.files[0]
          }
          : item
      )
    );
  };
  const handleChangeCertificate = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            certificate: value.target.files[0]
          }
          : item
      )
    );
  };
  const handleRemoveCertificate = (index: number) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            certificate: null
          }
          : item
      )
    );
  };
  const handleRemoveConsignmentDocuments = (index: number) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            consignment_documents: null
          }
          : item
      )
    );
  };
  const handleChange3rdBasePrice = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            third_party_base_price: value
          }
          : item
      )
    );
  };
  const handleChangeConsignmentNotes = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            consignment_notes: value
          }
          : item
      )
    );
  };
  const addExtras = () => {
    let x = [...extras];
    x.push('');
    setExtras(x);
    x = [...extrasOld];
    x.push('new');
    setExtrasOld(x);
    x = [...extrasLimit];
    x.push(0);
    setExtrasLimit(x);
    x = [...productExtrasPrice];
    x.push(0);
    setProductExtrasPrice(x);
    x = [...productExtrasQuantity];
    x.push(0);
    setProductExtrasQuantity(x);
  };

  const removeExtras = (index: number) => {
    let x = [...extras];
    x.splice(index, 1);
    setExtras(x);
    x = [...extrasOld];
    x.splice(index, 1);
    setExtrasOld(x);
    x = [...extrasLimit];
    x.splice(index, 1);
    setExtrasLimit(x);
    x = [...productExtrasPrice];
    x.splice(index, 1);
    setProductExtrasPrice(x);
    x = [...productExtrasQuantity];
    x.splice(index, 1);
    setProductExtrasQuantity(x);
  };

  const handleProductExtras = (value: any, index: number) => {
    const x = [...extras];
    x[index] = value;
    setExtras(x);
  };

  const handleProductExtrasLimit = (value: any, index: number) => {
    const x = [...extrasLimit];
    x[index] = value;
    setExtrasLimit(x);
  };
  const handleProductExtrasPrice = (value: any, index: number) => {
    const x = [...productExtrasPrice];
    x[index] = value;
    setProductExtrasPrice(x);
  };
  const handleProductExtrasQuantity = (value: any, index: number) => {
    const x = [...productExtrasQuantity];
    x[index] = value;
    setProductExtrasQuantity(x);
  };

  const handleSelectedArtist = (event: any) => {
    setSelectedArtist(event.target.value);
  };
  const handleDeleteEditions = () => {
    const values = [...editions];
    if (values[idDeleteEditionModal].id > 0) {
      const x = [...deletedEditions];
      x.push(values[idDeleteEditionModal].id);
      setDeletedEditions(x);
    }
    values.splice(idDeleteEditionModal, 1);
    const errors = [...editionsError];
    errors.splice(idDeleteEditionModal, 1);

    setEditionsError(errors);
    setEditions(values);
    setValueForEdidtionsTabs(0);
    setDeleteEditionModal(false);
  };
  const handleCloseDeleteEditionModal = () => {
    setIdDeleteEditionModal(0);
    setDeleteEditionModal(false);
  };
  const handleShowDeleteEditionModal = (id: number) => {
    setDeleteEditionModal(true);
    setIdDeleteEditionModal(id);
  };
  const handleChangeConsignor = (index: any, value: any) => {
    const consignor = { id: 0, name: '' };
    consignor.id = value.id;
    consignor.name = value.first_name + ' ' + value.last_name;
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            consignor_owner: consignor
          }
          : item
      )
    );
    const x = [...editionsError];
    x[index][3] = '';
    setEditionsError(x);
  };
  const handleChangeStore = (index: any, value: any) => {
    const store = { id: 0, name: '' };
    store.id = value;
    Object.keys(stores).forEach((key) => {
      if (stores[key].id === value) {
        store.name = stores[key].name;
      }
    });
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            store_owner: store
          }
          : item
      )
    );
    const x = [...editionsError];
    x[index][2] = '';
    setEditionsError(x);
  };

  const handleChangeInternalConditionNotes = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            internal_condition_notes: value
          }
          : item
      )
    );
  };
  const handleChangeActive = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            active: value
          }
          : item
      )
    );
  };
  const handleChangeAllActive = (value: any) => {
    editions.map((element, index) => {
      handleChangeActive(index, value);
    });
    setActive(value);
  };
  const handleChangeCost = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            cost: value
          }
          : item
      )
    );
  };

  const handleLocationNotes = (index: any, value: any) => {
    setEditions(
      editions.map((item, i) =>
        i === index
          ? {
            ...item,
            location_notes: value
          }
          : item
      )
    );
  };

  const duplicateEdition = (edition: Editions) => {
    const data = { ...edition };
    if (editions.length >= 1) {
      data.id = editions[editions.length - 1].id + 1;
    }
    data.edition_number = newEditionNumber();
    data.slug = createEditionSlug(newEditionNumber());
    // editions.map((element) => {
    //   element.edition_number === data.edition_number
    //     ? (data.edition_number += 1)
    //     : (data.edition_number = data.edition_number);
    // });
    if (data.is_old) {
      data.is_old = false;
    }
    const y = [...editions];
    y.push(data);
    setEditions(y);
    setValueForEdidtionsTabs(editions.length);
    let x = [...editionsError];
    x.push(['', '', '', '']);
    setEditionsError(x);
    x = [...inputValueConsignorOwner];
    x.push('');
    setInputValueConsignorOwner(x);
    x = [...inputValueLastExaminedBy];
    x.push('');
    setInputValueLastExaminedBy(x);
  };

  const handleSlugs = (slug: string) => {
    let alphanumericSlug = '';
    if (slug) {
      alphanumericSlug = slug.replace(/\W/g, '-');
    }

    setSlug(alphanumericSlug);
    handleChangeAllSlugs(alphanumericSlug);
    setSlugError('');
    return null;
  };

  const handleChunkIntoThreesOfButtons = () => {
    const buttons = [...editions];
    const chunked = [];
    while (buttons.length) {
      chunked.push(buttons.splice(0, 3));
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {chunked.map((element, i) => {
          return (
            <div
              style={{ display: 'flex', flexDirection: 'row' }}
              key={`chunck-${i}`}
            >
              {element.map((e, j) => {
                return (
                  <Button
                    key={`changeTabsBtn-${j}`}
                    onClick={(e) => {
                      handleChangeTabsForEditions(e, 3 * i + j);
                    }}
                    variant="outlined"
                    size="small"
                    className={
                      valueForEditionsTabs === 3 * i + j
                        ? 'activeButton'
                        : 'inactiveButton'
                    }
                    style={{
                      marginLeft: (3 * i + j) % 3 === 0 ? '0vw' : '1vw',
                      marginTop: '1vw',
                      width: '7vw'
                    }}
                  >
                    {`Edition ${editions[3 * i + j].edition_number}`}
                  </Button>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const listEditions =
    editions.length === editionsError.length &&
    editions.map((item, index) => (
      // eslint-disable-next-line react/jsx-key
      <div style={{ position: 'relative' }}>
        <Grid
          item
          xs={12}
          sm={8}
          style={{
            display: valueForEditionsTabs === index ? 'flex' : 'none',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: '2vw'
          }}
        >
          <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
            <Grid item style={{ flexDirection: 'column' }}>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
                error={editionsError[index][0] !== ''}
              >
                <InputLabel htmlFor="name-input">Title</InputLabel>
                <Input
                  id="name-input"
                  type="text"
                  value={item.name}
                  onChange={(e) => {
                    handleChangeNameEdition(index, e.target.value);
                  }}
                />
                <FormHelperText>{editionsError[index][0]}</FormHelperText>
              </FormControl>
              <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl
                  style={{
                    marginTop: '0px'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="base-price-input">
                    Website Price ($)
                  </InputLabel>
                  <Input
                    id="base-price-input"
                    type="number"
                    value={item.base_price}
                    onChange={(e) => {
                      handleChangeBasePriceEdition(index, e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginLeft: '0.5vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="cost-input">Buying Price (€)</InputLabel>
                  <Input
                    id="cost-input"
                    type="number"
                    value={item.cost}
                    onChange={(e) => {
                      handleChangeCost(index, e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <FormControl
                style={{
                  marginTop: '0px',
                  marginBottom: '2vw'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="internal-condition-notes-input">
                  Third Party Base Price
                </InputLabel>
                <Input
                  id="internal-condition-notes-input"
                  type="number"
                  value={item.third_party_base_price}
                  onChange={(e) => {
                    handleChange3rdBasePrice(index, e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="edition-number-input">
                  Edition Number
                </InputLabel>
                <Input
                  id="edition-number-input"
                  type="string"
                  value={item.edition_number}
                  onChange={(e) => {
                    handleChangeEditionNumberEdition(index, e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="current-location-input">
                  Current Location
                </InputLabel>
                <Select
                  labelId="label"
                  id="current-location-input"
                  value={item.current_location}
                  onChange={(e) => {
                    handleChangeCurrentLocation(index, e.target.value);
                  }}
                >
                  <MenuItem value="Warehouse">Warehouse</MenuItem>
                  <MenuItem value="Consigners location">
                    Consigners location
                  </MenuItem>
                  <MenuItem value="3rd Party">3rd Party</MenuItem>
                  <MenuItem value="Blockchain">Blockchain</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="internal-condition-notes-input">
                  Sub-Location Notes
                </InputLabel>
                <Input
                  id="internal-condition-notes-input"
                  type="text"
                  value={item.location_notes}
                  onChange={(e) => {
                    handleLocationNotes(index, e.target.value);
                  }}
                  rows={4}
                  multiline={true}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="location-history-input">
                  Location History
                </InputLabel>
                <Input
                  id="location-history-input"
                  type="text"
                  value={item.location_history}
                  onChange={(e) => {
                    handleChangeLocationHistory(index, e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <TextField
                  id="date"
                  label="Location Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={item.location_date}
                  onChange={(e) => {
                    handleChangeLocationDate(index, e.target.value);
                  }}
                />
              </FormControl>

              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="status-input">Stock Status</InputLabel>
                <Select
                  labelId="label"
                  id="status-input"
                  value={item.stock_status}
                  onChange={(e) => {
                    handleChangeStockStatus(index, e.target.value);
                  }}
                >
                  <MenuItem value="Available">Available</MenuItem>
                  <MenuItem value="Consigned Out">Consigned out</MenuItem>
                  <MenuItem value="Under offer">Under offer</MenuItem>
                  <MenuItem value="Sold">Sold</MenuItem>
                  <MenuItem value="Damaged">Damaged</MenuItem>
                  <MenuItem value="Unavailable">Unavailable</MenuItem>
                  <MenuItem value="Not for sale">Not for sale</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="status-input">Tax Status</InputLabel>
                <Select
                  labelId="label"
                  id="status-input"
                  value={item.tax_status}
                  onChange={(e) => {
                    handleChangeTaxStatus(index, e.target.value);
                  }}
                >
                  <MenuItem value="Exempt">Exempt</MenuItem>
                  <MenuItem value="Standard Rate">Standard Rate</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="condition-notes-input">
                  Website Condition Notes / Blockchain Category
                </InputLabel>
                <Input
                  id="condition-notes-input"
                  type="text"
                  value={item.condition_notes}
                  onChange={(e) => {
                    handleChangeConditionNotes(index, e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="internal-condition-notes-input">
                  Internal Condition Notes
                </InputLabel>
                <Input
                  id="internal-condition-notes-input"
                  type="text"
                  value={item.internal_condition_notes}
                  onChange={(e) => {
                    handleChangeInternalConditionNotes(index, e.target.value);
                  }}
                  rows={4}
                  multiline={true}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px',
                  marginBottom: '2vw'
                }}
                fullWidth={true}
                margin={'normal'}
                error={editionsError[index][1] !== ''}
              >
                <InputLabel htmlFor="edition-number-input">
                  Clean URL
                </InputLabel>
                <Input
                  id="edition-number-input"
                  type="text"
                  value={item.slug}
                  onChange={(e) => {
                    handleChangeSlugEdition(index, e.target.value);
                  }}
                />
                <FormHelperText>{editionsError[index][1]}</FormHelperText>
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px',
                  marginBottom: '2vw'
                }}
                fullWidth={true}
                margin={'normal'}
                error={editionsError[index][2] !== ''}
                required
              >
                <InputLabel htmlFor="edition-number-input">
                  Store Owner
                </InputLabel>
                <Select
                  labelId="label"
                  id="status-input"
                  value={item.store_owner.id}
                  onChange={(e) => {
                    handleChangeStore(index, e.target.value);
                  }}
                >
                  {stores.map((element) => (
                    // eslint-disable-next-line react/jsx-key
                    <MenuItem value={element.id}>{element.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{editionsError[index][2]}</FormHelperText>
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="condition-notes-input">
                  Acquired by
                </InputLabel>
                <Input
                  id="acquired_from-input"
                  type="text"
                  value={item.acquired_from}
                  onChange={(e) => {
                    handleChangeAcquiredBy(index, e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <TextField
                  id="acquisition_date"
                  label="Acquired date"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={item.acquisition_date}
                  onChange={(e) => {
                    handleChangeAcquiredDate(index, e.target.value);
                  }}
                />
              </FormControl>
              {item.stock_status === 'Consigned Out' && (
                <Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <FormControl
                      style={{
                        marginTop: '0px',
                        marginBottom: '2vw'
                      }}
                      fullWidth={true}
                      margin={'normal'}
                      error={editionsError[index][3] !== ''}
                    >
                      <Autocomplete
                        value={item.consignor_owner}
                        onChange={(event: any, newValue: string | null) => {
                          handleChangeConsignor(index, newValue);
                        }}
                        inputValue={inputValueConsignorOwner[index]}
                        onInputChange={(event, newInputValue) => {
                          const x = [...inputValueConsignorOwner];
                          x[index] = newInputValue;
                          setInputValueConsignorOwner(x);
                        }}
                        id="controllable-states-demo"
                        options={users}
                        getOptionLabel={(option) => {
                          if (
                            option.first_name === undefined ||
                            option.last_name === undefined
                          ) {
                            return option.name;
                          } else
                            return option.first_name + ' ' + option.last_name;
                        }}
                        renderOption={(option) =>
                          option.name === undefined
                            ? option.first_name + ' ' + option.last_name
                            : option.name
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Consignor/Third Party Owner"
                          />
                        )}
                      />
                      <FormHelperText>{editionsError[index][3]}</FormHelperText>
                    </FormControl>
                    <IconButton
                      onClick={() => {
                        setOpenClient(true);
                      }}
                    >
                      <AddIcon></AddIcon>
                    </IconButton>
                  </Grid>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <TextField
                      id="date"
                      label="Consignment Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={item.consignment_date}
                      onChange={(e) => {
                        handleChangeConsignmentDate(index, e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <TextField
                      id="date"
                      label="Return Consignment Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={item.return_consignment_date}
                      onChange={(e) => {
                        handleChangeReturnConsignmentDate(
                          index,
                          e.target.value
                        );
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="artist-input">
                      Consignment Terms
                    </InputLabel>
                    <Select
                      labelId="label"
                      id="select"
                      value={item.consignment_terms}
                      onChange={(e) => {
                        handleChangeConsignmentTerms(index, e.target.value);
                      }}
                    >
                      <MenuItem value={'Percentage'}>Percentage</MenuItem>
                      <MenuItem value={'Net Value'}>Net Value</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="internal-condition-notes-input">
                      Consignment Notes
                    </InputLabel>
                    <Input
                      id="internal-condition-notes-input"
                      type="text"
                      value={item.consignment_notes}
                      onChange={(e) => {
                        handleChangeConsignmentNotes(index, e.target.value);
                      }}
                      rows={4}
                      multiline={true}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="btn-upload-doc">
                      Consignment Documents
                    </InputLabel>
                    <label htmlFor="btn-upload" style={{ color: 'white' }}>
                      Select file
                    </label>

                    <Input
                      id="btn-upload-doc"
                      name="btn-upload-doc"
                      style={{ display: '' }}
                      type="file"
                      onChange={(e) => {
                        handleChangeConsignmentDocuments(index, e);
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      {item.certificate
                        ? !(item.consignment_documents instanceof File)
                          ? item.consignment_documents
                          : ''
                        : ''}
                      <IconButton
                        style={{
                          display:
                            // item.consignment_documents &&
                            // !(item.consignment_documents instanceof File)
                            //   ? ''
                            //   :
                            'none'
                        }}
                        onClick={() => {
                          handleRemoveConsignmentDocuments(index);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </FormControl>
                </Grid>
              )}
              <FormControl
                style={{
                  marginTop: '0px',
                  marginBottom: '2vw'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="btn-upload">Certificate</InputLabel>
                <label htmlFor="btn-upload" style={{ color: 'white' }}>
                  Select file
                </label>

                <Input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: '' }}
                  type="file"
                  onChange={(e) => {
                    handleChangeCertificate(index, e);
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  {item.certificate
                    ? !(item.certificate instanceof File)
                      ? item.certificate
                      : ''
                    : ''}
                  <IconButton
                    style={{
                      display:
                        // item.certificate && !(item.certificate instanceof File)
                        //   ? ''
                        //   :
                        'none'
                    }}
                    onClick={() => {
                      handleRemoveCertificate(index);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </FormControl>

              <FormControl
                style={{
                  marginTop: '0px',
                  marginBottom: '2vw'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <Autocomplete
                  value={item.last_examined_by}
                  onChange={(event: any, newValue: string | null) => {
                    handleChangeLastExamined(index, newValue);
                  }}
                  inputValue={inputValueLastExaminedBy[index]}
                  onInputChange={(event, newInputValue) => {
                    const x = [...inputValueLastExaminedBy];
                    x[index] = newInputValue;
                    setInputValueLastExaminedBy(x);
                  }}
                  id="controllable-states-demo"
                  options={waiters}
                  getOptionLabel={(option) => {
                    if (
                      option.first_name === undefined ||
                      option.last_name === undefined
                    ) {
                      return option.username;
                    } else return option.first_name + ' ' + option.last_name;
                  }}
                  renderOption={(option) =>
                    option.username === undefined
                      ? option.first_name + ' ' + option.last_name
                      : option.username
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Last Examined By" />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: valueForEditionsTabs === index ? 'flex' : 'none',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            right: 0,
            marginTop: '1vw',
            marginRight: '4vw',
            height: '2.5vw'
          }}
        >
          <IconButton
            onClick={() => handleShowDeleteEditionModal(index)}
            color="secondary"
          >
            <DeleteIcon />
          </IconButton>
          <Button
            onClick={() => {
              duplicateEdition(item);
            }}
            variant="outlined"
            size="small"
            className={'activeButton'}
            style={{ marginLeft: '1vw' }}
          >
            Duplicate
          </Button>
        </Grid>
      </div>
    ));

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="simple-dialog-title">
          <DeleteModal
            title={'Are you sure you want to delete this edition?'}
            handleConfirm={handleDeleteEditions}
            handleClose={handleCloseDeleteEditionModal}
            open={deleteEditionModal}
          />
          <ModalCreateTechnique
            open={openCreateTechnique}
            onClose={(number: number) => {
              if (number === 1) {
                _getTechniques();
              }
              setOpenCreateTechnique(false);
            }}
          />
          <ModalCreateArtist
            open={openCreateArtist}
            onClose={(number: number) => {
              if (number === 1) {
                _getArtist();
              }
              setOpenCreateArtist(false);
            }}
          />
          <ModalCreateClient
            open={openCreateClient}
            onClose={(number: number) => {
              if (number === 1) {
                _getUsers();
              }
              setOpenClient(false);
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            {showImagesTab && !titleOn ? (
              <>
                <div>Edit Artwork Images</div>
                <Button
                  onClick={returnFromImageTab}
                  variant="outlined"
                  color="primary"
                >
                  Return & Save
                </Button>
              </>
            ) : (
              <>
                <div>Edit Artwork</div>
                <Button
                  onClick={submitCreation}
                  variant="outlined"
                  color="primary"
                  disabled={clicked}
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </DialogTitle>
        {!showImagesTab ? (
          <div
            style={{ display: 'flex', flexDirection: 'row', marginLeft: '2vw' }}
          >
            <Button
              onClick={() => {
                handleChange();
              }}
              variant="outlined"
              size="small"
              className={titleOn ? 'activeButton' : 'inactiveButton'}
            >
              MASTER
            </Button>
            <Button
              onClick={() => {
                handleChange();
              }}
              variant="outlined"
              size="small"
              className={editionsOn ? 'activeButton' : 'inactiveButton'}
              style={{ marginLeft: '1vw' }}
            >
              EDITIONS
            </Button>
          </div>
        ) : null}
        <Container
          maxWidth="md"
          style={{ display: titleOn ? 'flex' : 'none', marginTop: '2vw' }}
        >
          <Grid container spacing={7}>
            {/* Start of left column */}
            <Grid item xs={12} sm={9}>
              <FormControl
                fullWidth={true}
                required={true}
                error={nameError !== ''}
              >
                <InputLabel htmlFor="name-input">Title</InputLabel>
                <Input
                  id="name-input"
                  type="string"
                  value={name}
                  onChange={handleName}
                />
                <FormHelperText>{nameError}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth={true}
                margin={'normal'}
                required={true}
                error={descriptionError !== ''}
              >
                <div
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '1.1vw',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '1vw'
                  }}
                >
                  Description
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ckEditorToolbarConfig
                  }}
                  data={description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                    setDescriptionError("")
                  }}
                />
                <FormHelperText>{descriptionError}</FormHelperText>
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="base-price-input">
                  Website Price
                </InputLabel>
                <Input
                  id="base-price-input"
                  type="number"
                  value={price}
                  onChange={(e) => {
                    handleChangeBasePriceAll(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} margin={'normal'}>
                <div
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '1.1vw',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '1vw'
                  }}
                >
                  Quote
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ckEditorToolbarConfig
                  }}
                  data={quote}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setQuote(data);
                  }}
                />
              </FormControl>
              <FormControl fullWidth={true} margin={'normal'}>
                <InputLabel htmlFor="quote-signature-input">
                  Quote Signature
                </InputLabel>
                <Input
                  id="quote-signature-input"
                  type="string"
                  value={quoteSignature}
                  onChange={(e) => {
                    setQuoteSignature(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="edition-number-input">Year</InputLabel>
                <Input
                  id="edition-number-input"
                  type="text"
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                />
              </FormControl>
              <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl
                  style={{
                    marginTop: '0px'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="edition-number-input">Weight</InputLabel>
                  <Input
                    id="edition-number-input"
                    type="number"
                    value={weight}
                    onChange={(e) => {
                      handleWeigt(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginLeft: '0.5vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="edition-number-input">Length</InputLabel>
                  <Input
                    id="edition-number-input"
                    type="number"
                    value={length}
                    onChange={(e) => {
                      handleLength(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginLeft: '0.5vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="edition-number-input">Width</InputLabel>
                  <Input
                    id="edition-number-input"
                    type="number"
                    value={width}
                    onChange={(e) => {
                      handleWidth(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  style={{
                    marginTop: '0px',
                    marginLeft: '0.5vw'
                  }}
                  fullWidth={true}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="edition-number-input">Height</InputLabel>
                  <Input
                    id="edition-number-input"
                    type="number"
                    value={height}
                    onChange={(e) => {
                      handleHeight(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    marginRight: '1vw',
                    fontSize: '0.9vw',
                    fontWeight: 'lighter'
                  }}
                >
                  Additional dimensions
                </div>
                <IconButton onClick={handleAddDimensions}>
                  <AddIcon />
                </IconButton>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {additionalDimensions.map((element, index) => (
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '1vw'
                    }}
                    key={index}
                  >
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: '2px solid grey',
                        borderRadius: '0.3vw',
                        padding: '0.5vw'
                      }}
                    >
                      <FormControl
                        style={{
                          marginTop: '0px'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="edition-number-input">
                          Artwork
                        </InputLabel>
                        <Input
                          id="edition-number-input"
                          type="string"
                          value={element.artwork}
                          onChange={(e) => {
                            const x = [...additionalDimensions];
                            x[index].artwork = e.target.value;
                            setAdditionalDimensions(x);
                          }}
                        />
                      </FormControl>
                      <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControl
                          style={{
                            marginTop: '0px'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                        >
                          <InputLabel htmlFor="edition-number-input">
                            Weight
                          </InputLabel>
                          <Input
                            id="edition-number-input"
                            type="number"
                            value={element.weight}
                            onChange={(e) => {
                              const x = [...additionalDimensions];
                              x[index].weight = parseFloat(e.target.value);
                              setAdditionalDimensions(x);
                            }}
                          />
                        </FormControl>
                        <FormControl
                          style={{
                            marginTop: '0px',
                            marginLeft: '0.5vw'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                        >
                          <InputLabel htmlFor="edition-number-input">
                            Length
                          </InputLabel>
                          <Input
                            id="edition-number-input"
                            type="number"
                            value={element.length}
                            onChange={(e) => {
                              const x = [...additionalDimensions];
                              x[index].length = parseFloat(e.target.value);
                              setAdditionalDimensions(x);
                            }}
                          />
                        </FormControl>
                        <FormControl
                          style={{
                            marginTop: '0px',
                            marginLeft: '0.5vw'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                        >
                          <InputLabel htmlFor="edition-number-input">
                            Width
                          </InputLabel>
                          <Input
                            id="edition-number-input"
                            type="number"
                            value={element.width}
                            onChange={(e) => {
                              const x = [...additionalDimensions];
                              x[index].width = parseFloat(e.target.value);
                              setAdditionalDimensions(x);
                            }}
                          />
                        </FormControl>
                        <FormControl
                          style={{
                            marginTop: '0px',
                            marginLeft: '0.5vw'
                          }}
                          fullWidth={true}
                          margin={'normal'}
                        >
                          <InputLabel htmlFor="edition-number-input">
                            Height
                          </InputLabel>
                          <Input
                            id="edition-number-input"
                            type="number"
                            value={element.height}
                            onChange={(e) => {
                              const x = [...additionalDimensions];
                              x[index].height = parseFloat(e.target.value);
                              setAdditionalDimensions(x);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <IconButton onClick={() => handleRemoveDimensions(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <FormControlLabel
                  control={
                    <Switch
                      value={active}
                      checked={active}
                      onChange={(e) => handleChangeAllActive(!active)}
                      name="active"
                      color="primary"
                    />
                  }
                  label="Active"
                />
              </FormControl>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <FormControl
                  fullWidth={true}
                  margin={'normal'}
                  required={true}
                  error={selectedArtistError !== ''}
                >
                  <InputLabel htmlFor="artist-input">Artist</InputLabel>
                  <Select
                    labelId="label"
                    id="select"
                    value={selectedArtist}
                    onChange={handleSelectedArtist}
                  >
                    {fetchedArtists?.map((artist, indexx) => (
                      <MenuItem key={indexx} value={artist.id}>
                        {artist.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{selectedArtistError}</FormHelperText>
                </FormControl>
                <IconButton
                  style={{ marginTop: '1vw' }}
                  onClick={() => setOpenCreateArtist(true)}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
              <FormControl fullWidth={true} margin={'normal'}>
                <InputLabel htmlFor="artist-input">Template</InputLabel>
                <Select
                  labelId="label"
                  id="select"
                  value={template}
                  onChange={handleSelectedTemplate}
                >
                  <MenuItem value="SCULPTURE">SCULPTURE</MenuItem>
                  <MenuItem value="ART">ART</MenuItem>
                  <MenuItem value="NFT">NFT</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <InputLabel htmlFor="edition-hs_code">HSCODE</InputLabel>

                <Input
                  id="edition-number-input"
                  type="text"
                  value={hs_code}
                  onChange={(e) => {
                    setHscode(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <div
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '1.1vw',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '1vw'
                  }}
                >
                  Signature type
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ckEditorToolbarConfig
                  }}
                  data={signatureType}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleSignatureType(data)
                  }}
                />
              </FormControl>

              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                margin={'normal'}
              >
                <div
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '1.1vw',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '1vw'
                  }}
                >
                  Edition Size / Blockchain Palm
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ckEditorToolbarConfig
                  }}
                  data={editionSize}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleEditionSize(data);
                  }}
                />
              </FormControl>
              <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl
                  fullWidth={true}
                  margin={'normal'}
                  required
                  error={techniqueError !== ''}
                >
                  <Autocomplete
                    value={technique}
                    onChange={(event: any, newValue: string | null) => {
                      handleSelectedTechnique(newValue);
                    }}
                    inputValue={inputValueTechnique}
                    onInputChange={(event, newInputValue) => {
                      setInputValueTechnique(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={techniques}
                    getOptionLabel={(option) => {
                      if (option.name !== undefined) {
                        return option.name;
                      }
                    }}
                    renderOption={(option) =>
                      option.name !== undefined && option.name
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Technique" />
                    )}
                  />
                  <FormHelperText>{techniqueError}</FormHelperText>
                </FormControl>
                <IconButton
                  style={{ marginTop: '1vw' }}
                  onClick={() => setOpenCreateTechnique(true)}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
              <FormControl
                fullWidth={true}
                margin={'normal'}
                error={slugError !== ''}
                required
              >
                <InputLabel htmlFor="artist-input">Clean URL</InputLabel>
                <Input
                  id="edition-number-input"
                  type="text"
                  value={slug}
                  onChange={(e) => {
                    handleSlugs(e.target.value);
                  }}
                />
                <FormHelperText>{slugError}</FormHelperText>
              </FormControl>
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pageTag}
                      onChange={() => {
                        setPageTag(!pageTag);
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Display on home page"
                />
              </Grid>
              <FormControl
                fullWidth={true}
                margin={'normal'}
                error={indexError !== ''}
                required={pageTag} // only required when the home tag is true
              >
                <InputLabel htmlFor="artist-input">
                  Homepage position
                </InputLabel>
                <Input
                  id="edition-number-input"
                  type="number"
                  value={index}
                  onChange={(e) => {
                    setIndex(e.target.value);
                    setIndexError('');
                  }}
                />
                <FormHelperText>{indexError}</FormHelperText>
              </FormControl>
              <Grid item xs={12} sm={9}>
                <FormControl fullWidth margin={'normal'}>
                  <InputLabel htmlFor="name-input">Colli number</InputLabel>
                  <Input
                    type="number"
                    id="name-input"
                    value={colliNumber}
                    onChange={(e) => setColliNumber(parseInt(e.target.value))}
                  />
                </FormControl>
              </Grid>
              <Grid
                style={{
                  fontWeight: 'lighter',
                  fontSize: '0.9vw',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <div>Add Artwork Services</div>
                <div style={{ marginLeft: '2vw' }}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => addExtras()}
                  >
                    <AddIcon></AddIcon>
                  </IconButton>
                </div>
              </Grid>
              {extras.map((element, index) => (
                <Grid
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    border: '2px solid grey',
                    borderRadius: '0.3vw',
                    padding: '1vw'
                  }}
                >
                  <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          width: '17.5vw'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="name-input">
                          Artwork Services
                        </InputLabel>
                        <Select
                          labelId="label"
                          id="current-location-input"
                          value={extras[index]}
                          onChange={(e) => {
                            handleProductExtras(e.target.value, index);
                          }}
                        >
                          {productExtras.map((element, index) => (
                            <MenuItem key={index} value={element.id}>
                              {element.ingredient}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          width: '17.5vw'
                        }}
                        fullWidth
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="name-input">Limit</InputLabel>
                        <Input
                          id="name-input"
                          type="number"
                          value={extrasLimit[index]}
                          onChange={(e) =>
                            handleProductExtrasLimit(e.target.value, index)
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          width: '17.5vw'
                        }}
                        fullWidth
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="name-input">Price</InputLabel>
                        <Input
                          id="name-input"
                          type="number"
                          value={productExtrasPrice[index]}
                          onChange={(e) =>
                            handleProductExtrasPrice(e.target.value, index)
                          }
                        />
                      </FormControl>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          width: '17.5vw'
                        }}
                        fullWidth
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="name-input">Quantity</InputLabel>
                        <Input
                          id="name-input"
                          type="number"
                          value={productExtrasQuantity[index]}
                          onChange={(e) =>
                            handleProductExtrasQuantity(e.target.value, index)
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => removeExtras(index)}
                    style={{ marginTop: '0.5vw' }}
                  >
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                </Grid>
              ))}
            </Grid>

            <Grid
              item
              xs={12}
              sm={9}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Button
                onClick={() => {
                  handleChangeArtworkImages();
                }}
                variant="outlined"
                size="small"
                className={'inactiveButton'}
              >
                ARTWORK IMAGES
              </Button>
              <Typography variant="caption" style={{ color: 'red' }}>
                {artworkImagesError}
              </Typography>
              {/* <div>
                  {imageSource.map((element) => {
                    <img src={element} />;
                  })}
                </div> */}
              {/* <FormControl>
                <InputLabel htmlFor="file-input">Image</InputLabel>
                <Input
                  id="file-input"
                  type="file"
                  value={image}
                  onChange={(e) => handleImage(e)}
                />
              </FormControl> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['image/*', 'application/pdf']}
                  dropzoneText={'Drag and drop the content sheet'}
                  showPreviewsInDropzone={false}
                  onChange={(files) => handleContentSheet(files)}
                  filesLimit={1}
                />
                <Typography variant="caption" style={{ color: '#fe309c' }}>
                  For better resolution insert images with 1000x1000
                </Typography>
              </ThemeProvider>
              <div>
                {contentSheet ? (
                  contentSheet instanceof File ? (
                    contentSheet.name
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <div>{contentSheet}</div>
                      <IconButton onClick={() => setContentSheet(undefined)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  )
                ) : (
                  ''
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['video/*']}
                  dropzoneText={'Drag and drop the website artwork video'}
                  showPreviewsInDropzone={false}
                  onChange={(files) => handleVideo(files)}
                  filesLimit={1}
                  maxFileSize={20000000}
                />
              </ThemeProvider>
              <div>
                {video ? (
                  video instanceof File ? (
                    video.name
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <IconButton
                        onClick={() =>
                          window.open(CLOUDINARY_URL + video, '_blank')
                        }
                      >
                        <VideoCallIcon />
                      </IconButton>
                      <IconButton onClick={() => setVideo(undefined)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  )
                ) : (
                  ''
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={9}>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth
                margin={'normal'}
              >
                <InputLabel htmlFor="name-input">
                  Artwork video description
                </InputLabel>
                <Input
                  multiline
                  id="name-input"
                  value={videoDescription}
                  onChange={(e) => setVideoDescription(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={9}>
              {' '}
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth
                margin={'normal'}
              >
                <InputLabel htmlFor="name-input">
                  Artwork video youtube URL
                </InputLabel>
                <Input
                  id="name-input"
                  value={youtubeVideoURL}
                  onChange={(e) => setYoutubeVideoURL(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Container>

        {editionsOn ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '2vw',
              marginLeft: '2vw',
              marginBottom: '1vw'
            }}
          >
            <Button
              onClick={() => {
                const data = {
                  id: 0,
                  name: name !== '' ? name : '',
                  technique: {
                    id: 0,
                    name: ''
                  },
                  edition_number: newEditionNumber(),
                  weight: '',
                  height: '',
                  length: '',
                  active: false,
                  width: '',
                  cost: 0,
                  slug: createEditionSlug(newEditionNumber()),
                  stock_status: 'Available',
                  base_price: price !== undefined ? price : 0,
                  tax_status: '',
                  condition_notes: '',
                  internal_condition_notes: '',
                  current_location: '',
                  location_details: '',
                  location_date: '',
                  location_notes: '',
                  location_history: '',
                  store_owner: { id: 0, name: '' },
                  consignor_owner: { id: 0, name: '' },
                  consignment_date: '',
                  return_consignment_date: '',
                  consignment_terms: '',
                  third_party_base_price: 0,
                  consignment_documents: '',
                  certificate: '',
                  consignment_notes: '',
                  last_examined_by: 0,
                  is_old: false
                };
                if (editions.length >= 1) {
                  data.id = editions[editions.length - 1].id + 1;
                }
                setEditions(editions.concat(data));
                setValueForEdidtionsTabs(editions.length);
                let x = [...editionsError];
                x.push(['', '', '', '']);
                setEditionsError(x);
                x = [...inputValueConsignorOwner];
                x.push('');
                setInputValueConsignorOwner(x);

                x = [...inputValueLastExaminedBy];
                x.push('');
                setInputValueLastExaminedBy(x);
              }}
              variant="outlined"
              size="small"
              className={'activeButton'}
            >
              ADD EDITION
            </Button>
            {handleChunkIntoThreesOfButtons()}
          </div>
        ) : (
          ''
        )}
        {editionsOn ? listEditions : ''}

        {showImagesTab ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '2vw',
              marginLeft: '2vw',
              marginBottom: '1vw'
            }}
          >
            <ThemeProvider theme={theme}>
              <DropzoneArea
                acceptedFiles={['image/*']}
                dropzoneText={'Drag and drop a new image here'}
                showPreviewsInDropzone={false}
                onChange={(files) => handleImage(files)}
                filesLimit={100}
                getFileAddedMessage={() => {
                  return 'Cloudinary is processing your upload(s) come back in a minute to arrange them in order.';
                }}
              />
            </ThemeProvider>
            <br />
            <DndProvider backend={HTML5Backend}>
              <ProductImagesContainer
                productId={product ? product.product_id : null}
                productImages={productImages}
                isDeleting={isDeleting}
                handleRemoveImage={handleRemoveImage}
                handleOrderedImages={handleOrderedImages}
              />
            </DndProvider>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}
