import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';
import { Product } from './stock';

export interface blocks {
  block_id: number;
  type_of_block: string;
  image: string;
  image_2: string;
  image_title: string;
  image_title_2: string;
  text: string;
  text_2: string;
  quotation_text: string;
  quotation_signature: string;
  index: number;
}

export interface Latest {
  id: number;
  nav_image: string;
  title: string;
  author: string;
  title_image: string;
  homepage_image_title: string;
  date: string;
  product: Product;
  external_url: string;
  external_slug: string;
  external: boolean;
  blocks: blocks[];
}

export interface ILatestState {
  fetch: boolean;
  count: number;
  next?: string;
  previous?: string;
  results: Latest[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesLatest {
  FETCH = 'LATEST/FETCH',
  SUCCESS = 'LATEST/SUCCESS',
  FIRSTLOAD = 'LATEST/FIRSTLOAD',
  FAILURE = 'LATEST/FAILURE',
  SEARCH = 'LATEST/SEARCH'
}

export interface IFetchAction extends IBaseAction<ETypesLatest, null> {
  type: ETypesLatest.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesLatest, ILatestState> {
  type: ETypesLatest.SUCCESS;
  payload: ILatestState;
}

export interface IFirstLoadAction
  extends IBaseAction<ETypesLatest, ILatestState> {
  type: ETypesLatest.FIRSTLOAD;
  payload: ILatestState;
}

export interface IFailureAction extends IBaseAction<ETypesLatest, string> {
  type: ETypesLatest.FAILURE;
  payload: string;
}

export type ILatestAction =
  | IFetchAction
  | ISuccessAction
  | IFirstLoadAction
  | IFailureAction;

export type GetState = () => any;

export type PromiseAction = Promise<ILatestAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | ILatestAction
    | ThunkAction
    | PromiseAction
    | Array<ILatestAction>
    | IAlertAction
) => any;
