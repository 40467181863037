import React, { useState, useCallback, useEffect } from 'react';
import Card from './ProductImagesCard';
import update from 'immutability-helper';
import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const containerStyle = {
  width: '50%'
};
const wrapperStyle = {
  display: 'flex',
  alignItems: 'center'
};
const deleteIconStyle = {
  color: 'rgba(0, 0, 0, 0.54)',
  cursor: 'pointer'
};
const ProductImagesContainer = ({
  productId,
  productImages,
  handleRemoveImage,
  handleOrderedImages,
  isDeleting
}) => {
  {
    const [cards, setCards] = useState([]);
    let tempImages = [];

    const orderImages = () => {
      const tempOrderedImages = [];
      let tempProductImage;
      if (cards.length > 0) {
        cards.map((card, index) => {
          tempProductImage = productImages.filter(
            (object) => object.image === card.text
          )[0];
          tempOrderedImages.push({
            id: tempProductImage ? tempProductImage.id : null,
            image: card.text,
            product: productId,
            sort_key: index + 1
          });
        });
      }
      handleOrderedImages(tempOrderedImages);
    };

    useEffect(() => {
      orderImages();
    }, [cards]);

    const updateCards = () => {
      tempImages = [];
      setCards([]);
      productImages.map((product, index) => {
        tempImages.push({
          id: index,
          text: product.image
        });
      });
      setCards(tempImages);
    };

    const removeCard = (card) => {
      handleRemoveImage(card.text);
      if (productImages.length > 1) {
        productImages = productImages.filter(
          (product) => product.image != card.text
        );
        updateCards();
      }
    };

    useEffect(() => {
      updateCards();
      orderImages();
    }, [productImages]);

    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        setCards(
          update(cards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard]
            ]
          })
        );
      },
      [cards]
    );
    const renderCards = () => {
      return cards.map((card, index) => (
        <div style={wrapperStyle} key={`cardWrapper-${index}`}>
          <Card
            key={card.id}
            index={index}
            id={card.id}
            text={card.text}
            moveCard={moveCard}
          />
          <Tooltip
            style={deleteIconStyle}
            title="Delete"
            onClick={() => {
              if (!isDeleting) removeCard(card);
            }}
          >
            <DeleteIcon />
          </Tooltip>
        </div>
      ));
    };
    return (
      <>
        <div style={containerStyle}>{renderCards()}</div>
      </>
    );
  }
};

export default ProductImagesContainer;
