import React, { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  ThemeProvider,
  withStyles
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createArtist } from 'redux/ducks/artists';
import { createTheme } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: (success: number) => void;
}
const GlobalCss = withStyles({
  '@global': {
    '.MuiDialog-paperWidthSm': {
      minWidth: '30vw'
    }
  }
})(() => null);

export default function ModalCreateArtist(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [quote, setQuote] = useState('');
  const [slug, setSlug] = useState('');
  const [slugError, setSlugError] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [longDesc, setLongDesc] = useState('');
  const [shortName, setShortName] = useState('');
  const [firstName, setFirstName] = useState(false);
  const [active, setActive] = useState(false);
  const [imageSource, setImageSource] = useState<File>();
  const [surnameOrderOnly, setSurnameOrderOnly] = useState<string>('');

  const dispatch = useDispatch();

  const handleClose = (success: number) => {
    onClose(success);
  };

  const submitCreate = async () => {
    if (name === '') {
      setNameError('Please enter name');
    } else if (slug === '') {
      setSlugError('Please enter web url');
    } else {
      dispatch(
        createArtist({
          name,
          short_name: shortName,
          short_description: shortDesc,
          image: imageSource,
          slug,
          first_name: firstName,
          long_description: longDesc,
          artist_active: active,
          quote,
          surname_orderonly: surnameOrderOnly
        })
      ).then((result: number) => {
        if (result === 1) handleClose(1);
      });
    }
  };

  const handleImage = (event: any) => {
    if (event.length > 0) {
      setImageSource(event[0]);
    }
  };

  return (
    <div>
      <GlobalCss />
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        style={{ minWidth: '30vw' }}
      >
        <DialogTitle id="simple-dialog-title">Create Artist</DialogTitle>
        <Container maxWidth="md">
          <Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={() => {
                    setActive(!active);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
            error={nameError !== ''}
          >
            <InputLabel htmlFor="name-input">Name</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError('');
              }}
            />
            <FormHelperText>{nameError}</FormHelperText>
          </FormControl>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            <InputLabel htmlFor="name-input">Short Name</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={shortName}
              onChange={(e) => {
                setShortName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            <InputLabel htmlFor="name-input">Surname Orderonly</InputLabel>
            <Input
              id="surnameOrderOnly-input"
              type="text"
              value={surnameOrderOnly}
              onChange={(e) => {
                setSurnameOrderOnly(e.target.value);
              }}
            />
          </FormControl>
          <Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={firstName}
                  onChange={() => {
                    setFirstName(!firstName);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="First Name"
            />
          </Grid>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            <InputLabel htmlFor="name-input">Short Description</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={shortDesc}
              onChange={(e) => {
                setShortDesc(e.target.value);
              }}
            />
          </FormControl>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            <InputLabel htmlFor="name-input">Long Description</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={longDesc}
              onChange={(e) => {
                setLongDesc(e.target.value);
              }}
              multiline
              rows={3}
            />
          </FormControl>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
            error={slugError !== ''}
          >
            <InputLabel htmlFor="name-input">Web URL</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={slug}
              onChange={(e) => {
                setSlug(e.target.value);
                setSlugError('');
              }}
            />
            <FormHelperText>{slugError}</FormHelperText>
          </FormControl>{' '}
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            <InputLabel htmlFor="name-input">Quote</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={quote}
              onChange={(e) => {
                setQuote(e.target.value);
              }}
            />
          </FormControl>
          <ThemeProvider theme={theme}>
            <DropzoneArea
              acceptedFiles={['image/*']}
              dropzoneText={'Drag and drop the artist image here'}
              onChange={(files) => handleImage(files)}
              filesLimit={1}
            />
          </ThemeProvider>
        </Container>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={submitCreate} color="primary">
            {' '}
            Create{' '}
          </Button>
          <Button onClick={() => handleClose(0)}> Cancel </Button>
        </div>
      </Dialog>
    </div>
  );
}
