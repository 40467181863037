import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import IStore from 'interfaces/store';
import Loading from 'components/Common/Loading';
import { getLatest } from 'redux/ducks/latest';
import LatestTable from './LatestTable';

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const { latest } = useSelector<IStore, IStore>((state) => state);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-latest');
    d.item(0).style.color = '#fe309c';

    d.item(1).style.color = '#fe309c';
    const dText = document.querySelectorAll<HTMLElement>('.d-latest-text');
    dText.item(0).style.color = '#fe309c';
  });

  useEffect(() => {
    dispatch(getLatest());
  }, [dispatch]);

  return (
    <>
      <Loading show={latest.fetch} />
      <Grid container spacing={3}>
        <LatestTable latest={latest.results} />
      </Grid>
    </>
  );
}
