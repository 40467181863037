import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  ICustomersAndSellersState,
  ETypesCustomersAndSellers,
  IManagerAction,
  GetState
} from 'interfaces/customersAndSellers';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Authentication State. */
const initialState: ICustomersAndSellersState = {
  fetch: false,
  customers: [],
  sellers: [],
  last_order: {
    order_code: '',
    payment: '',
    total_order: 0,
    delivery_fee: 0,
    cash_change: 0,
    cash_paid: 0,
    discount: 0,
    date_delivery: '',
    date_ordered: '',
    final_comment: '',
    insurance_requested: false,
    insurance_value_cents: 0,
    insurance_premium_amount_cents: 0,
    currency: '',
    vat: 0,
    dhl_price: '',
    delivery_address: '',
    items: []
  }
};

/* Authentication Reducer. */
export default (
  state: ICustomersAndSellersState = initialState,
  action: IManagerAction
): ICustomersAndSellersState => {
  switch (action.type) {
    case ETypesCustomersAndSellers.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesCustomersAndSellers.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesCustomersAndSellers.FAILURE:
      return {
        ...state,
        fetch: false
      };
    case ETypesCustomersAndSellers.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchStock = (): IFetchAction => ({
  type: ETypesCustomersAndSellers.FETCH
});

export const successFetch = (
  payload: ICustomersAndSellersState
): ISuccessAction => ({
  type: ETypesCustomersAndSellers.SUCCESS,
  payload
});

export const failureManagerDashboard = (payload: string): IFailureAction => ({
  type: ETypesCustomersAndSellers.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesCustomersAndSellers.RESET
});

/* Authentication Side Effects Functions. */
export const fetchBestBuyers =
  (filter: string): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { customersAndSellers } = getState();
      dispatch(fetchStock());
      const response = await fetch({
        method: EMethod.GET,
        url:
          filter === 'All'
            ? `users/api/clients/best_buyers/?period=All`
            : `users/api/clients/best_buyers/?period=${filter}`
      });
      dispatch(successFetch({ ...customersAndSellers, customers: response }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };
export const getCustomerOrders = async (order_id: {} = undefined) => {
  try {
    let request_url;
    request_url = `/orders/api/orders/${order_id}/get_order/`;
    const response = await fetch({
      method: EMethod.GET,
      url: request_url
    });

    return response;
  } catch (error) {
    return null;
  }
};
export const createClient =
  (data: {}): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<number> => {
    try {
      if (data['email'] && data['telephone']) {
        var clientSearch = await fetch({
          method: EMethod.GET,
          url: `users/api/clients/?email=${data['email']}`
        });
        if (clientSearch.count < 1) {
          await fetch({
            method: EMethod.POST,
            url: `users/api/clients/register/`,
            data
          });
          dispatch(
            sendAlert('Client created successfuly', EAlertVariant.SUCCESS)
          );
          return 1;
        } else {
          dispatch(
            sendAlert(
              'This client already exists in the database',
              EAlertVariant.ERROR
            )
          );
          return 0;
        }
      } else {
        await fetch({
          method: EMethod.POST,
          url: `users/api/clients/guest_user/`,
          data
        });
        dispatch(
          sendAlert('Client created successfuly', EAlertVariant.SUCCESS)
        );
        return 1;
      }
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      console.log('error ', JSON.stringify(error), Object.keys(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };

export const fetchBestBuyersWithDateRange =
  (initial: string, end: string): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { customersAndSellers } = getState();
      dispatch(fetchStock());
      const response = await fetch({
        method: EMethod.GET,
        url: `users/api/clients/best_buyers/?starts=${initial}&ends=${end}`
      });
      dispatch(successFetch({ ...customersAndSellers, customers: response }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const fetchBestSellers =
  (): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { customersAndSellers } = getState();
      dispatch(fetchStock());
      const response = await fetch({
        method: EMethod.GET,
        url: 'users/api/clients/top_5_sellers/'
      });
      dispatch(successFetch({ ...customersAndSellers, sellers: response }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const fetchLastOrder =
  (orderId: number): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { customersAndSellers } = getState();
      dispatch(fetchStock());
      const response = await fetch({
        method: EMethod.GET,
        url: `/orders/api/orders/${orderId}/get_order/`
      });
      dispatch(successFetch({ ...customersAndSellers, last_order: response }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const patchClient =
  (data: any, id: number): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<boolean> => {
    try {
      if (data['billing_address']) {
        var billing = data['billing_address'];
        delete data['billing_address'];
      }
      if (data['delivery_address']) {
        var delivery = data['delivery_address'];
        delete data['delivery_address'];
      }

      const patchURL = `users/api/clients/${id}/`;
      var response = await fetch({
        method: EMethod.PATCH,
        url: patchURL,
        data
      });
      if (billing) {
        billing.user_id = response.id;
        if (billing.created) {
          delete billing.created;
          dispatch(createBillingAddress(billing, id));
        } else {
          dispatch(activateAddress(billing.address_id, id, 'Billing'));
        }
      }
      if (delivery) {
        delivery.user_id = response.id;
        if (delivery.created) {
          delete delivery.created;

          dispatch(createDeliveryAddress(delivery, id));
        } else {
          dispatch(activateAddress(delivery.address_id, id, 'Delivery'));
        }
      }
      dispatch(sendAlert('User successfuly edited', EAlertVariant.SUCCESS));
      return true;
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const activateAddress =
  (address: any, customer: number, name: string): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<boolean> => {
    try {
      const postBillingURL = `/api/users/activate_address/`;
      await fetch({
        method: EMethod.PATCH,
        url: postBillingURL,
        data: {
          user_id: customer,
          address_id: address,
          name: name
        }
      });

      return true;
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const createBillingAddress =
  (data: any, customer: number): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<boolean> => {
    try {
      data.country = data.country.code;
      data.active = true;
      delete data.address_id;

      const postBillingURL = `/users/api/address/waiter_create_address/`;
      var response = await fetch({
        method: EMethod.POST,
        url: postBillingURL,
        data: data
      });
      data.address_id = response.address_id;
      await fetch({
        method: EMethod.PATCH,
        url: `users/api/clients/${customer}/`,
        data: { billing_address: data }
      });
      return true;
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const createDeliveryAddress =
  (data: any, customer: number): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<boolean> => {
    try {
      data.country = data.country.code;
      data.active = true;
      delete data.address_id;
      const postBillingURL = `/users/api/address/waiter_create_delivery_address/`;
      var response = await fetch({
        method: EMethod.POST,
        url: postBillingURL,
        data: data
      });
      data.address_id = response.address_id;

      await fetch({
        method: EMethod.PATCH,
        url: `users/api/clients/${customer}/`,
        data: { delivery_address: data }
      });
      return true;
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };
export const fetchClient =
  (id: number): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<any> => {
    try {
      const patchURL = `users/api/clients/${id}/?language=pt`;
      var data = await fetch({
        method: EMethod.GET,
        url: patchURL
      });
      return data;
    } catch (error) {
      return null;
    }
  };
