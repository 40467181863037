import React, { useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  Checkbox,
  Container,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CloseIcon from '@material-ui/icons/Close';
import { DropzoneArea } from 'material-ui-dropzone';
import { createTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createLatest, getLatest } from 'redux/ducks/latest';
import { fetchProductsGeneral } from 'redux/ducks/stock';
import IStore from 'interfaces/store';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { sendAlert } from 'redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';
import { Latest } from 'interfaces/latest';

interface IProps {
  onClose: () => void;
  open: boolean;
  latest: Latest[];
}

const ckEditorToolbarConfig = [
  'Heading',
  '|',
  'bold',
  'italic',
  '|',
  'BulletedList',
  'NumberedList',
  '|',
  'Indent',
  '|',
  'BlockQuote',
  'undo',
  'redo'
];

const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto',
        marginTop: '1vw'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

export default function ModalCreatelatest(props: IProps) {
  const { onClose, open, latest } = props;
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState('');
  const [titleError, setTitleError] = React.useState('');
  const [titleImageTitle, setTitleImageTitle] = React.useState('');
  const [externalUrl, setExternalUrl] = React.useState('');
  const [externalSlug, setExternalSlug] = React.useState('');
  const [externalUrlError, setExternalUrlError] = React.useState('');
  const [externalSlugError, setExternalSlugError] = React.useState('');
  const [internalProduct, setInternalProduct] = React.useState(false);
  const [author, setAuthor] = React.useState('');
  const [authorError, setAuthorError] = React.useState('');
  const [date, setDate] = React.useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [imageSource, setImageSource] = React.useState<File>();
  const [imageSourceNav, setImageSourceNav] = React.useState<File>();
  const [blocks, setBlocks] = React.useState([
    {
      block_id: 0,
      type_of_block: 'quotation',
      image: '',
      image_title: '',
      image_2: '',
      image_title_2: '',
      text: '',
      text_2: '',
      quotation_text: '',
      quotation_signature: '',
      index: 1
    },
    {
      block_id: 0,
      type_of_block: 'scroller_text',
      image: '',
      image_title: '',
      image_2: '',
      image_title_2: '',
      text: '',
      text_2: '',
      quotation_text: '',
      quotation_signature: '',
      index: 2
    },
    {
      block_id: 0,
      type_of_block: 'full_image',
      image: '',
      image_title: '',
      image_2: '',
      image_title_2: '',
      text: '',
      text_2: '',
      quotation_text: '',
      quotation_signature: '',
      index: 3
    },
    {
      block_id: 0,
      type_of_block: 'half_image',
      image: '',
      image_title: '',
      image_2: '',
      image_title_2: '',
      text: '',
      text_2: '',
      quotation_text: '',
      quotation_signature: '',
      index: 4
    },
    {
      block_id: 0,
      type_of_block: 'scroller_text',
      image: '',
      image_title: '',
      image_2: '',
      image_title_2: '',
      text: '',
      text_2: '',
      quotation_text: '',
      quotation_signature: '',
      index: 5
    },
    {
      block_id: 0,
      type_of_block: 'full_image',
      image: '',
      image_title: '',
      image_2: '',
      image_title_2: '',
      text: '',
      text_2: '',
      quotation_text: '',
      quotation_signature: '',
      index: 6
    },
    {
      block_id: 0,
      type_of_block: 'quotation',
      image: '',
      image_title: '',
      image_2: '',
      image_title_2: '',
      text: '',
      text_2: '',
      quotation_text: '',
      quotation_signature: '',
      index: 7
    },
    {
      block_id: 0,
      type_of_block: 'scroller_text',
      image: '',
      image_title: '',
      image_2: '',
      image_title_2: '',
      text: '',
      text_2: '',
      quotation_text: '',
      quotation_signature: '',
      index: 8
    }
  ]);
  const [clicked, setClicked] = React.useState(false);

  const [product, setProduct] = React.useState({
    id: 0,
    name: '',
    productId: 0,
    productName: '',
    value: 0,
    extras: []
  });
  const [productError, setProductError] = React.useState('');
  const [allProducts, setAllProducts] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const { stock } = useSelector<IStore, IStore>((state) => state);

  useEffect(() => {
    if (allProducts.length === 0) {
      dispatch(fetchProductsGeneral());
    }
    if (stock && stock.products && stock.products.length > 0) {
      const y = [];
      stock.products.forEach((element) => {
        const x = {
          productId: element.product_id,
          productName: element.name
        };
        y.push(x);
      });
      setAllProducts(y);
    }
  }, [stock.products]);

  const handleClose = (number: number) => {
    if (number === 1) {
      dispatch(getLatest());
    }
    setTitle('');
    setTitleImageTitle('');
    setTitleError('');
    setAuthor('');
    setAuthorError('');
    setDate(moment(new Date()).format('YYYY-MM-DD'));
    setImageSourceNav(null);
    setBlocks([
      {
        block_id: 0,
        type_of_block: 'quotation',
        image: '',
        image_title: '',
        image_2: '',
        image_title_2: '',
        text: '',
        text_2: '',
        quotation_text: '',
        quotation_signature: '',
        index: 1
      },
      {
        block_id: 0,
        type_of_block: 'scroller_text',
        image: '',
        image_title: '',
        image_2: '',
        image_title_2: '',
        text: '',
        text_2: '',
        quotation_text: '',
        quotation_signature: '',
        index: 2
      },
      {
        block_id: 0,
        type_of_block: 'full_image',
        image: '',
        image_title: '',
        image_2: '',
        image_title_2: '',
        text: '',
        text_2: '',
        quotation_text: '',
        quotation_signature: '',
        index: 3
      },
      {
        block_id: 0,
        type_of_block: 'half_image',
        image: '',
        image_title: '',
        image_2: '',
        image_title_2: '',
        text: '',
        text_2: '',
        quotation_text: '',
        quotation_signature: '',
        index: 4
      },
      {
        block_id: 0,
        type_of_block: 'scroller_text',
        image: '',
        image_title: '',
        image_2: '',
        image_title_2: '',
        text: '',
        text_2: '',
        quotation_text: '',
        quotation_signature: '',
        index: 5
      },
      {
        block_id: 0,
        type_of_block: 'full_image',
        image: '',
        image_title: '',
        image_2: '',
        image_title_2: '',
        text: '',
        text_2: '',
        quotation_text: '',
        quotation_signature: '',
        index: 6
      },
      {
        block_id: 0,
        type_of_block: 'quotation',
        image: '',
        image_title: '',
        image_2: '',
        image_title_2: '',
        text: '',
        text_2: '',
        quotation_text: '',
        quotation_signature: '',
        index: 7
      },
      {
        block_id: 0,
        type_of_block: 'scroller_text',
        image: '',
        image_title: '',
        image_2: '',
        image_title_2: '',
        text: '',
        text_2: '',
        quotation_text: '',
        quotation_signature: '',
        index: 8
      }
    ]);
    setProduct({
      id: 0,
      name: '',
      productId: 0,
      productName: '',
      value: 0,
      extras: []
    });
    setProductError('');
    setInputValue('');
    setImageSource(null);
    setExternalUrl('');
    setExternalUrlError('');
    setExternalSlug('');
    setExternalSlugError('');
    setInternalProduct(false);
    setClicked(false);
    onClose();
  };

  const handleImage = (event: any) => {
    if (event.length > 0) {
      setImageSource(event[0]);
    }
  };
  const handleImageNav = (event: any) => {
    if (event.length > 0) {
      setImageSourceNav(event[0]);
    }
  };
  const handleBlockImage = (index: number, event: any) => {
    if (event.length > 0) {
      const x = [...blocks];
      x[index].image = event[0];
      setBlocks(x);
    }
  };
  const handleBlockImage2 = (index: number, event: any) => {
    if (event.length > 0) {
      const x = [...blocks];
      x[index].image_2 = event[0];
      setBlocks(x);
    }
  };
  const handleText = (event: any, index: number) => {
    const x = [...blocks];
    x[index].text = event;
    setBlocks(x);
  };
  const handleText2 = (event: any, index: number) => {
    const x = [...blocks];
    x[index].text_2 = event;
    setBlocks(x);
  };
  const handleImageTitle = (event: any, index: number) => {
    const x = [...blocks];
    x[index].image_title = event;
    setBlocks(x);
  };
  const handleImageTitle2 = (event: any, index: number) => {
    const x = [...blocks];
    x[index].image_title_2 = event;
    setBlocks(x);
  };
  const handleQuoText = (event: any, index: number) => {
    const x = [...blocks];
    x[index].quotation_text = event;
    setBlocks(x);
  };
  const handleQuoSignature = (event: any, index: number) => {
    const x = [...blocks];
    x[index].quotation_signature = event;
    setBlocks(x);
  };
  const handleType = (event: any, index: number) => {
    const x = [...blocks];
    x[index].type_of_block = event;
    setBlocks(x);
  };

  const handleChangeProduct = (value: any) => {
    setProduct(value);
    setProductError('');
  };

  const checkForm = () => {
    let entered = false;
    if (title === '') {
      setTitleError('Please enter title');
      entered = true;
    }
    if (author === '') {
      setAuthorError('Please enter author');
      entered = true;
    }
    if (
      (internalProduct &&
        product &&
        (product.productId === 0 || product.productId === undefined)) ||
      !product
    ) {
      setProductError('Please select a product');
      entered = true;
    }
    if (externalSlug === '') {
      setExternalSlugError('Please enter clean url');
      entered = true;
    }
    latest.map((element) => {
      if (element.external_slug === externalSlug) {
        setExternalSlugError(
          'This slug belongs to "' +
            element.title +
            '". Please remove it from this latest and try again.'
        );
        entered = true;
      }
    });
    if (!internalProduct && externalUrl === '') {
      setExternalUrlError('Please enter external url');
      entered = true;
    }
    if (entered) {
      dispatch(sendAlert('Please check the errors below', EAlertVariant.ERROR));
    }
    return entered;
  };

  const handleExternalSlug = (event: any) => {
    setExternalSlug(event);
    latest.map((element) => {
      if (element.external_slug === event) {
        setExternalSlugError(
          'This slug belongs to "' +
            element.title +
            '". Please remove it from this latest and try again.'
        );
      } else {
        setExternalSlugError('');
      }
    });
  };

  const submitCreation = () => {
    if (!checkForm()) {
      setClicked(true);
      const data = {
        title,
        author,
        title_image: imageSource,
        homepage_image_title: titleImageTitle,
        nav_image: imageSourceNav,
        date: date
          ? moment(date).format('YYYY-MM-DD')
          : moment(new Date()).format('YYYY-MM-DD'),
        product: product.productId,
        external_url: externalUrl,
        external_slug: externalSlug,
        external: !internalProduct
      };
      const blocksToBeSent = [];
      if (data.title_image === undefined || data.title_image === null) {
        delete data.title_image;
      }
      if (data.nav_image === undefined || data.nav_image === null) {
        delete data.nav_image;
      }
      if (internalProduct) {
        delete data.external_url;
      }
      if (!internalProduct) {
        delete data.product;
      }
      blocks.map((element) => {
        const x = element;
        if (x.image === undefined || x.image === null || x.image === '') {
          delete x.image;
        }
        if (x.image_2 === undefined || x.image_2 === null || x.image_2 === '') {
          delete x.image_2;
        }
        blocksToBeSent.push(x);
      });
      dispatch(createLatest(data, blocksToBeSent)).then((result: number) => {
        if (result === 1) {
          handleClose(1);
        } else {
          setClicked(false);
        }
      });
    }
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={() => handleClose(0)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => handleClose(0)}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>Create Latest</div>
            <div>
              <Button
                onClick={submitCreation}
                variant="outlined"
                color="primary"
                disabled={clicked}
              >
                Save
              </Button>
            </div>
          </div>
        </DialogTitle>
        <Container maxWidth="md">
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
            error={titleError !== ''}
          >
            <InputLabel htmlFor="name-input">Title</InputLabel>
            <Input
              id="title-input"
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleError('');
              }}
            />
            <FormHelperText>{titleError}</FormHelperText>
          </FormControl>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
            error={authorError !== ''}
          >
            <InputLabel htmlFor="name-input">Author</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={author}
              onChange={(e) => {
                setAuthor(e.target.value);
                setAuthorError('');
              }}
            />
            <FormHelperText>{authorError}</FormHelperText>
          </FormControl>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            <TextField
              name="date"
              id="date"
              label="Date of Article"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
          </FormControl>
          <ThemeProvider theme={theme}>
            <DropzoneArea
              acceptedFiles={['image/*']}
              dropzoneText={'Drag and drop the title image here'}
              onChange={(files) => handleImage(files)}
              filesLimit={1}
            />
            <Typography variant="caption" style={{ color: '#fe309c' }}>
              For better resolution insert images with 1392x1080
            </Typography>
          </ThemeProvider>
          <FormControl fullWidth={true} margin={'normal'}>
            <InputLabel htmlFor="name-input">Image title</InputLabel>
            <Input
              id="title-image_title"
              type="text"
              value={titleImageTitle}
              onChange={(e) => {
                setTitleImageTitle(e.target.value);
              }}
            />
          </FormControl>
          <ThemeProvider theme={theme}>
            <DropzoneArea
              acceptedFiles={['image/*']}
              dropzoneText={'Drag and drop the nav image here'}
              onChange={(files) => handleImageNav(files)}
              filesLimit={1}
            />
            <Typography variant="caption" style={{ color: '#fe309c' }}>
              For better resolution insert images with 532×143
            </Typography>
          </ThemeProvider>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={internalProduct}
                onChange={() => {
                  setInternalProduct(!internalProduct);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="This latest is related to an internal Product"
          />
          {!internalProduct && (
            <Grid>
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth={true}
                error={externalUrlError !== ''}
                margin={'normal'}
              >
                <InputLabel htmlFor="name-input">External Url</InputLabel>
                <Input
                  id="name-input"
                  type="text"
                  value={externalUrl}
                  onChange={(e) => {
                    setExternalUrl(e.target.value);
                    setExternalUrlError('');
                  }}
                />
                <FormHelperText>{externalUrlError}</FormHelperText>
              </FormControl>
            </Grid>
          )}
          {internalProduct && (
            <FormControl
              style={{
                marginTop: '0px',
                marginBottom: '1vw',
                width: '100%'
              }}
              fullWidth={true}
              margin={'normal'}
              error={productError !== ''}
              required
            >
              <Autocomplete
                value={product}
                onChange={(event: any, newValue: string | null) => {
                  handleChangeProduct(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="auto-latest"
                options={allProducts}
                getOptionLabel={(option) => {
                  if (
                    option.productName !== '' &&
                    option.productName !== undefined
                  ) {
                    return option.productName;
                  } else return '';
                }}
                renderOption={(option) =>
                  option.productName !== '' || option.productName !== undefined
                    ? option.productName
                    : ''
                }
                loading={allProducts.length === 0}
                loadingText={'Loading artworks'}
                renderInput={(params) => (
                  <TextField {...params} label="Artwork" />
                )}
              />
              <FormHelperText>{productError}</FormHelperText>
            </FormControl>
          )}
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
            error={externalSlugError !== ''}
          >
            <InputLabel htmlFor="name-input">Clean URL</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={externalSlug}
              onChange={(e) => {
                handleExternalSlug(e.target.value);
              }}
            />
            <FormHelperText>{externalSlugError}</FormHelperText>
          </FormControl>
          <Grid
            style={{
              fontWeight: 'lighter',
              fontSize: '1.1vw',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '1vw'
            }}
          >
            <div>Blocks</div>
          </Grid>
          {blocks.map((block, index) => (
            <Grid
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                border: '2px solid grey',
                borderRadius: '0.3vw',
                padding: '1vw',
                marginTop: '1vw'
              }}
            >
              <Grid style={{ width: '100%' }}>
                <div>Block {block.index}</div>
                <Grid
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <FormControl fullWidth={true} margin={'normal'}>
                    <InputLabel htmlFor="name-input">Block Type</InputLabel>
                    <Select
                      labelId="label"
                      id="type-input"
                      value={block.type_of_block}
                      onChange={(e) => {
                        handleType(e.target.value, index);
                      }}
                      disabled
                    >
                      <MenuItem value="half_image">Half image</MenuItem>
                      <MenuItem value="full_image">Full image</MenuItem>
                      <MenuItem value="quotation">Quotation</MenuItem>
                      <MenuItem value="scroller_text">Scroller text</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {block.type_of_block === 'scroller_text' && (
                  <Grid
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 'lighter',
                        fontSize: '1.1vw',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: '1vw'
                      }}
                    >
                      Text
                    </div>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: ckEditorToolbarConfig
                      }}
                      data={block.text}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleText(data, index);
                      }}
                    />
                    <div
                      style={{
                        fontWeight: 'lighter',
                        fontSize: '1.1vw',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: '1vw'
                      }}
                    >
                      Text 2
                    </div>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: ckEditorToolbarConfig
                      }}
                      data={block.text_2}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleText2(data, index);
                      }}
                    />
                  </Grid>
                )}
                {block.type_of_block === 'quotation' && (
                  <Grid
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <FormControl fullWidth={true} margin={'normal'}>
                      <InputLabel htmlFor="name-input">
                        Quotation Text
                      </InputLabel>
                      <Input
                        id="name-input"
                        type="text"
                        value={block.quotation_text}
                        onChange={(e) => {
                          handleQuoText(e.target.value, index);
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth={true} margin={'normal'}>
                      <InputLabel htmlFor="name-input">
                        Quotation Signature
                      </InputLabel>
                      <Input
                        id="name-input"
                        type="text"
                        value={block.quotation_signature}
                        onChange={(e) => {
                          handleQuoSignature(e.target.value, index);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                {block.type_of_block !== '' &&
                  block.type_of_block !== 'quotation' &&
                  block.type_of_block !== 'scroller_text' && (
                    <FormControl fullWidth={true} margin={'normal'}>
                      <InputLabel htmlFor="name-input">Image title</InputLabel>
                      <Input
                        id="name-input"
                        type="text"
                        value={block.image_title}
                        onChange={(e) => {
                          handleImageTitle(e.target.value, index);
                        }}
                      />
                    </FormControl>
                  )}
                {block.type_of_block !== '' &&
                  block.type_of_block !== 'quotation' &&
                  block.type_of_block !== 'scroller_text' && (
                    <div
                      style={{
                        width: '100%',
                        marginTop: '1vw'
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <DropzoneArea
                          acceptedFiles={['image/*']}
                          dropzoneText={'Drag and drop the block image here'}
                          onChange={(files) => handleBlockImage(index, files)}
                          filesLimit={1}
                        />
                        {block.type_of_block !== 'scroller_text' ? (
                          <Typography
                            variant="caption"
                            style={{ color: '#fe309c' }}
                          >
                            For better resolution insert images with{' '}
                            {block.type_of_block === 'half_image'
                              ? '1392x1080'
                              : '2800x1080'}
                          </Typography>
                        ) : null}
                      </ThemeProvider>
                    </div>
                  )}
                {block.type_of_block !== '' &&
                  block.type_of_block !== 'quotation' &&
                  block.type_of_block !== 'half_image' &&
                  block.type_of_block !== 'scroller_text' &&
                  block.type_of_block !== 'full_image' && (
                    <FormControl fullWidth={true} margin={'normal'}>
                      <InputLabel htmlFor="name-input">
                        Image title 2
                      </InputLabel>
                      <Input
                        id="name-input"
                        type="text"
                        value={block.image_title_2}
                        onChange={(e) => {
                          handleImageTitle2(e.target.value, index);
                        }}
                      />
                    </FormControl>
                  )}
                {block.type_of_block !== '' &&
                  block.type_of_block !== 'quotation' &&
                  block.type_of_block !== 'half_image' &&
                  block.type_of_block !== 'scroller_text' &&
                  block.type_of_block !== 'full_image' && (
                    <div
                      style={{
                        width: '100%',
                        marginTop: '1vw'
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <DropzoneArea
                          acceptedFiles={['image/*']}
                          dropzoneText={'Drag and drop the block image here'}
                          onChange={(files) => handleBlockImage2(index, files)}
                          filesLimit={1}
                        />
                      </ThemeProvider>
                    </div>
                  )}
              </Grid>
            </Grid>
          ))}

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1vw'
            }}
          >
            <Button
              onClick={submitCreation}
              variant="outlined"
              color="primary"
              disabled={clicked}
            >
              Save
            </Button>
          </div>
        </Container>
      </div>
    </Modal>
  );
}
