import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { checkAuthentication } from 'redux/ducks/authentication';

import IStore from 'interfaces/store';
import { IAuthenticationState, ICredentials } from 'interfaces/authentication';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import { useStyles } from './styles';

import FormValidation from '@react-form-fields/material-ui/components/FormValidation';
import FormControl from '@material-ui/core/FormControl';

import FieldText from '@react-form-fields/material-ui/components/Text';
import Link from '@material-ui/core/Link';

export default function Authentication(): JSX.Element {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const dispatch = useDispatch();
  const authenticationState = useSelector<IStore, IAuthenticationState>(
    (state) => state.authentication
  );
  const { fetch, authorized } = authenticationState;

  const classes = useStyles();

  async function authenticate(isValid: boolean): Promise<void> {
    if (!isValid) return;

    const credentials: ICredentials = { email, password };
    dispatch(checkAuthentication(credentials));
  }

  if (authorized) return <Redirect to="/dashboard/order" />;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <h1 className={classes.brand}>weng contemporary</h1>
        <FormValidation onSubmit={(isValid) => authenticate(isValid)}>
          <FormControl variant="outlined" className={classes.formControl}>

            <FieldText
              inputProps={{ 'data-testid': 'email' }}
              variant="outlined"
              margin="normal"
              validation="required|email"
              type="email"
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              value={email}
              onChange={(email) => setEmail(email)}
              autoFocus
            />

            <FieldText
              inputProps={{ 'data-testid': 'password' }}
              variant="outlined"
              margin="normal"
              validation="required"
              fullWidth
              name="password"
              value={password}
              label="Password"
              type="password"
              id="password"
              onChange={(password) => setPassword(password)}
              autoComplete="current-password"
            />
          </FormControl >

          <Button
            data-testid="submit"
            type="submit"
            fullWidth
            disabled={fetch}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {!fetch ? 'Sign in' : 'Loading...'}
          </Button>

          <span className={classes.forgotPasswordLink}>
            <Link href="/forgot-password" onClick={() => { }}>
              Forgot the  password
            </Link>
          </span>
        </FormValidation>
      </div>
    </Container>
  );
}
