import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { StyleButtonHover, ListItemText, ExtendedMenuItem } from './styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
//import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

export default function CollapseMenu({ children, label }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <StyleButtonHover onClick={() => setExpanded(!expanded)}>
        <ListItem button className=" d-control">
          <ListItemIcon>
            <KeyboardArrowDownIcon
              className="d-control-icon"
              style={{
                color: '#BBBBBB',
                rotate: expanded ? 'none' : '-90deg',
                transition: 'rotate 0.2s ease'
              }}
            />
          </ListItemIcon>
          <ListItemText className="d-control ">{label}</ListItemText>
        </ListItem>
      </StyleButtonHover>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ExtendedMenuItem>{children}</ExtendedMenuItem>
      </Collapse>
    </>
  );
}
