import { APP_NAME } from 'settings';

import PzzzaLogo from 'assets/img/pzzza_logo.jpg';
import FarinaLogo from 'assets/img/farina_logo.jpg';
import ShnoncoLogo from 'assets/img/shnonco_logo.jpeg';
import CashLogo from 'assets/img/cash.png';
import PicpayLogo from 'assets/img/picpay.png';
import NubankLogo from 'assets/img/nubank.png';
import CardLogo from 'assets/img/card.png';
import DhlLogo from 'assets/img/dhl-icon.jpg';
import TransferLogo from 'assets/img/transfer.jpg';
import PagarMeLogo from 'assets/img/pagarme.jpg';
import MachineLogo from 'assets/img/machine.png';
import ArtxxLogo from 'assets/img/weng_icon.png';
import Uninformed from 'assets/img/uninformedPayment.png';
import { IKitchen } from 'interfaces/kitchen';

export function getLogo() {
  const logo = {
    'Pzzza - MPOSGO Realtime': PzzzaLogo,
    'Farina - MPOS Realtime': FarinaLogo,
    'Shnonco - MPOS Realtime': ShnoncoLogo,
    'Artxx - MPOS Realtime': ArtxxLogo
  };

  return logo[APP_NAME];
}

export function getPaymentLogo(value: string) {
  const logo = {
    cash: CashLogo,
    nubank: NubankLogo,
    picpay: PicpayLogo,
    card: CardLogo,
    transfer: TransferLogo,
    pagarme: PagarMeLogo,
    machine: MachineLogo,
    invoice: Uninformed
  };

  return logo[value];
}

export function getDhlLogo() {
  return DhlLogo;
}

export function getPaymentType(value: string) {
  const logo = {
    cash: 'Cash',
    nubank: 'Nubank',
    picpay: 'Picpay',
    card: 'Card',
    transfer: 'Transfer',
    pagarme: 'Pagar Me',
    machine: 'Card Machine'
  };

  if (!(value in logo)) {
    return 'Invoice';
  }

  return logo[value];
}

export function getUrlName(pathname: string): string {
  const urlName = {
    home: 'Homepage',
    order: 'My Orders',
    delivery: 'Delivery',
    completed: 'Complete Orders',
    production: 'Production',
    product: 'Production',
    ingredient: 'Purchases',
    package: 'Packages',
    manager: 'Data Dashboard',
    stock: 'Inventory',
    menus: 'Collections',
    customers: 'Customers',
    sellers: 'Sellers',
    products: 'Products',
    notifications: 'Notifications',
    history: 'Transactions',
    Profile: 'Profile',
    media: 'Media',
    artists: 'Artists'
  };

  if (pathname.split('/')[2] === 'production') {
    return urlName[pathname.split('/')[3]] || '';
  }

  return urlName[pathname.split('/')[2]] || '';
}

export function getWhatsappWithPicPayMessage(order: IKitchen) {
  const phone = order.client
    ? order.client.phone.replace(/\D/g, '')
    : order.owner.phone_number.replace(/\D/g, '');
  const br = '%0a';
  let message = `ORDER Nº: *${order.order_code}*${br}Your order is already being prepared.${br}`;

  message += `${br}*Payment type*: ${getPaymentType(order.payment)}.`;
  message += `${br}*Total*: €: ${order?.total_order?.toFixed(2)}`;
  message += `${br}To make the payment, access the link: https://app.picpay.com/payment?type=store%26sellerId=141411%26value=${order?.total_order.toFixed(
    2
  )}`;
  message += `${br}${br}Obrigado pela Thank you for your preference.`;

  return `https://web.whatsapp.com/send?phone=55${phone}&text=${message}&source&data`;
}

export function getWhatsappWithPagarmeMessage(
  order: IKitchen,
  pagarMeURL: string
) {
  const phone = order.client
    ? order.client.phone.replace(/\D/g, '')
    : order.owner.phone_number.replace(/\D/g, '');
  const br = '%0a';
  let message = `ORDER Nº: *${order.order_code}*${br}Your order is already being prepared.${br}`;

  message += `${br}*Payment type*: ${getPaymentType(order.payment)}.`;
  message += `${br}*Total*: €: ${order?.total_order?.toFixed(2)}`;
  message += `${br}To make the payment, access the link: ${pagarMeURL}`;
  message += `${br}${br}Thank you for your preference.`;

  return `https://web.whatsapp.com/send?phone=55${phone}&text=${message}&source&data`;
}

export const formatCellPhone = (phoneNumber: string) => {
  const phoneWithoutCharacter = phoneNumber.replace(/\D/g, '');
  if (phoneWithoutCharacter[0] === '5' && phoneWithoutCharacter[1] === '5') {
    const countryCode = phoneWithoutCharacter.slice(0, 2);
    const stateCode = phoneWithoutCharacter.slice(2, 4);
    const phoneNumberPartOne = phoneWithoutCharacter.slice(4, 9);
    const phoneNumberPartTwo = phoneWithoutCharacter.slice(9, 13);

    return `+${countryCode}  ${stateCode}  ${phoneNumberPartOne}${phoneNumberPartTwo}`;
  }
  const stateCode = phoneWithoutCharacter.slice(0, 2);
  const phoneNumberPartOne = phoneWithoutCharacter.slice(2, 7);
  const phoneNumberPartTwo = phoneWithoutCharacter.slice(7, 11);

  return `+${stateCode} ${phoneNumberPartOne}${phoneNumberPartTwo}`;
};
