import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useStyles } from './styles';

import FormControl from '@material-ui/core/FormControl';

import FieldText from '@react-form-fields/material-ui/components/Text';

import { EMethod } from 'enums/method';
import { FormHelperText } from '@material-ui/core';
import Validator from 'validatorjs';
import { API_ENDPOINT } from 'settings';
import Axios from 'axios';

export default function ForgotPassword(): JSX.Element {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const classes = useStyles();

  const handleSendEmail = async (email: any) => {
    setLoading(true);
    const rules = {
      email: 'required|email'
    };
    const data = { email };
    const validation = new Validator(data, rules);
    if (validation.passes()) {
      const request_url = `${API_ENDPOINT}/users/api/clients/forgot_password/`;
      await Axios({
        method: EMethod.POST,
        headers: {
          Authorization: ''
        },
        data: {
          email: email
        },
        url: request_url
      })
        .then((response) => {
          setLoading(false);
          setMessage(
            'You have successfully requested to reset your password, please check your email, inbox and spam folder.'
          );
          setSuccess(true);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setMessage('This email does not exist');
          setSuccess(false);
        });
    } else {
      setLoading(false);

      setSuccess(false);
      setMessage('Email must be in the correct format');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <h1 className={classes.brand}>weng contemporary</h1>
        <FormControl variant="outlined" className={classes.formControl}>
          <FieldText
            inputProps={{ 'data-testid': 'email' }}
            variant="outlined"
            margin="normal"
            type="email"
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            value={email}
            onChange={(email) => setEmail(email)}
            autoFocus
          />
        </FormControl>
        <FormHelperText style={{ color: success ? 'green' : 'red' }}>
          {message}
        </FormHelperText>

        <Button
          data-testid="submit"
          type="submit"
          fullWidth
          disabled={false}
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => {
            handleSendEmail(email);
          }}
        >
          Send
        </Button>
        <div style={{ width: '10vw' }}>{loading ? <LinearProgress /> : ''}</div>
      </div>
    </Container>
  );
}
