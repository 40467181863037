import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { getCurrencys } from 'redux/ducks/currency';
import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import CurrencyTable from './currencyTable';

export default function Currencys(): JSX.Element {
  const dispatch = useDispatch();
  const { currency } = useSelector<IStore, IStore>((state) => state);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-currency');
    if (d.item(0)) {
      d.item(0).style.color = '#fe309c';
      d.item(1).style.color = '#fe309c';
    }

    const dText = document.querySelectorAll<HTMLElement>('.d-currency-text');
    if (dText.item(0)) {
      dText.item(0).style.color = '#fe309c';
    }
  });
  const getData = () => {
    dispatch(getCurrencys());
  };
  useEffect(() => {
    getData();
  }, [dispatch]);

  return (
    <>
      <Loading show={currency.fetch} />
      <Grid container spacing={3}>
        <CurrencyTable getData={() => getData()} currencys={currency.results} />
      </Grid>
    </>
  );
}
