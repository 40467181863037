import React, { useRef } from 'react';
import Content from './content';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import { Button } from '@material-ui/core';
interface IProps {
  trigger: React.ReactElement;
  children: React.ReactElement;
  onBeforeGetContent?: () => void | Promise<any>;
  fonts: { family: string; source: string }[];
  documentTitle: string | undefined;
}

export default function Print(props: IProps): JSX.Element {
  const { trigger, children, onBeforeGetContent, fonts, documentTitle } = props;
  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current
  });
  const handleBeforeGetContent = () => {
    return new Promise<void>((resolve, reject) => {
      onBeforeGetContent();
      resolve();
    });
  };
  return (
    <>
      <ReactToPrint
        // trigger={triggerFunction}
        content={() => ref}
        onBeforeGetContent={handleBeforeGetContent}
        suppressErrors={true}
        fonts={fonts}
        onBeforePrint={() => {
          console.log('called');
        }}
        documentTitle={documentTitle || 'Artxx'}
      />
      <Button onClick={() => handlePrint()}> {trigger} </Button>
      <div style={{ display: 'none' }}>
        <Content ref={ref}>{children}</Content>
        <div style={{ display: 'none' }}>
          <div ref={ref}>{children}</div>
        </div>
      </div>
    </>
  );
}
