import {
  ICurrencyState,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  Dispatch,
  ThunkAction,
  ICurrencyAction,
  ETypesCurrency,
  GetState,
  Icurrency
} from 'interfaces/currency';

import { EAlertVariant } from 'interfaces/alert';

import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Currency State. */
const initialState: ICurrencyState = {
  fetch: false,
  results: [],
  error: ''
};

/* Currency Reducer. */
export default (
  state: ICurrencyState = initialState,
  action: ICurrencyAction
): ICurrencyState => {
  switch (action.type) {
    case ETypesCurrency.FETCH:
      return {
        ...state,
        fetch: true
      };

    case ETypesCurrency.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesCurrency.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* Currency Action Creators Functions. */
export const fetchCurrency = (): IFetchAction => ({
  type: ETypesCurrency.FETCH
});

export const successCurrency = (payload: ICurrencyState): ISuccessAction => ({
  type: ETypesCurrency.SUCCESS,
  payload
});

export const failureCurrency = (payload: string): IFailureAction => ({
  type: ETypesCurrency.FAILURE,
  payload
});

/* Currency Side Effects Functions. */

export const createCurrency =
  (data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<number> => {
    try {
      const { currency } = getState();
      console.log(data);
      let request_url = `/products/api/currency/`;
      //let id;
      await fetch({
        method: EMethod.POST,
        url: request_url,
        data
      }).then((res) => {
        console.log(res);
        // id = res.id;
      });

      dispatch(
        sendAlert('Currency created successfully', EAlertVariant.SUCCESS)
      );

      dispatch(successCurrency({ ...currency }));
      getCurrencys();
      return 1;
    } catch (error) {
      console.log(error);
      dispatch(failureCurrency(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };

export const getCurrencys =
  (): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<Icurrency[]> => {
    try {
      const { Currency } = getState();
      dispatch(fetchCurrency());
      let request_url;
      request_url = `/products/api/currency/`;
      const response = await fetch({
        method: EMethod.GET,
        url: request_url
      });
      let results: Icurrency[] = [];
      results.push(...response.results);
      dispatch(successCurrency({ ...Currency, results }));
      return results;
    } catch (error) {
      console.log(error);
      dispatch(failureCurrency(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return [];
    }
  };
export const deleteCurrency =
  (id: number): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      let request_url;
      request_url = `/products/api/currency/${id}/`;
      await fetch({
        method: EMethod.DELETE,
        url: request_url
      });
      dispatch(
        sendAlert('Currency deleted successfully', EAlertVariant.SUCCESS)
      );
      return 1;
    } catch (error) {
      dispatch(failureCurrency(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return -1;
    }
  };

export const editCurrency =
  (id: number, Currency: any): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      let request_url;
      request_url = `/products/api/currency/${id}/`;
      await fetch({
        method: EMethod.PUT,
        url: request_url,
        data: Currency
      });
      dispatch(
        sendAlert('Currency edited successfully', EAlertVariant.SUCCESS)
      );
      /**await Promise.all(
        oldCurrencySlides.map(async (element) => {
          request_url = `/products/api/Currencyslides/${element.id}/`;
          const body = new FormData();
          Object.keys(element).forEach((key) => {
            body.append(key, element[key]);
          });

          await fetch({
            headers: { 'content-type': 'multipart/form-data' },
            method: EMethod.PATCH,
            url: request_url,
            data: body
          }).then((res) => {
            console.log(res);
          });
        })
      ); */

      /** await Promise.all(
        deletedSlides.map(async (element) => {
          request_url = `/products/api/Currencyslides/${element.id}/`;
          await fetch({
            headers: { 'content-type': 'multipart/form-data' },
            method: EMethod.DELETE,
            url: request_url
          }).then((res) => {
            console.log(res);
          });
        })
      ); */

      return 1;
    } catch (error) {
      dispatch(failureCurrency(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return -1;
    }
  };
