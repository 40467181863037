import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { IVoucherBase } from 'interfaces/vouchers';
import { DialogContent, Typography, Divider } from '@material-ui/core';
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  voucher: IVoucherBase;
}

export default function UsersVoucherOrderModal(props: SimpleDialogProps) {
  const { onClose, open, voucher } = props;
  const [clients, setClients] = useState([]);
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    if (voucher) {
      setClients(voucher.client);
    }
  }, [voucher]);
  const mapClients = clients.map((client, idx) => (
    <div key={client.id} style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 10
        }}
      >
        <Typography>{client?.name}</Typography>
        <Typography>{client?.email}</Typography>
      </div>
      <Divider />
    </div>
  ));
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{voucher?.name}</DialogTitle>
      <DialogContent style={{ minWidth: '30vw' }}>
        <> {mapClients}</>
      </DialogContent>
    </Dialog>
  );
}
