import React, { useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductsGeneral } from 'redux/ducks/stock';
import IStore from 'interfaces/store';
import { createBanner } from 'redux/ducks/banner';
import Validator from 'validator';

interface IProps {
  onClose: () => void;
  open: boolean;
  reload: () => void;
}

const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto',
        maxWidth: '25vw'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

export default function CreateBanner(props: IProps) {
  const { onClose, open, reload } = props;
  const dispatch = useDispatch();
  const [clicked, setClicked] = React.useState(false);

  const { stock } = useSelector<IStore, IStore>((state) => state);
  const [placementTitle, setPlacementTitle] = React.useState('');
  const [placementTitleError, setPlacementError] = React.useState('');
  const [placementDesc, setPlacementDesc] = React.useState('');
  const [placementDescError, setPlacementDescError] = React.useState('');
  const [allEditions, setAllEditions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(['']);
  const [slidesError, setSlidesError] = React.useState([['', '', '', '', '']]);

  const [slides, setSlides] = React.useState([
    {
      image: '',
      video: '',
      external_url: '',
      description: '',
      hide_description: false,
      image_portrait: ''
    }
  ]);
  const [productError, setProductError] = React.useState(['']);
  const [product, setProduct] = React.useState([
    {
      id: 0,
      productName: '',
      value: 0
    }
  ]);

  useEffect(() => {
    try {
      if (allEditions.length === 0) {
        dispatch(fetchProductsGeneral());
      }
      if (stock && stock.products.length > 0) {
        const y = [];
        stock.products.forEach((element) => {
          const x = {
            id: element.product_id,
            productName: element.name,
            value: element.price
          };
          y.push(x);
        });
        setAllEditions(y);
      }
    } catch (e) {
      console.log(e);
    }
  }, [stock.products]);

  const checkFormError = () => {
    let entered = false;
    if (placementTitle === '') {
      setPlacementError('Please enter placement title');
      entered = true;
    }
    if (placementDesc === '') {
      setPlacementDescError('Please enter placement description');
      entered = true;
    }
    slides.map((element, index) => {
      if (element.description === '') {
        const x = [...slidesError];
        x[index][0] = 'Please enter description';
        setSlidesError(x);
        entered = true;
      }
      if (element.image === '') {
        const x = [...slidesError];
        x[index][1] = 'Please upload image';
        setSlidesError(x);
        entered = true;
      }
      if (element.image_portrait === '') {
        const x = [...slidesError];
        x[index][4] = 'Please upload image';
        setSlidesError(x);
        entered = true;
      }
      if (
        element.external_url &&
        element.external_url !== '' &&
        !Validator.isURL(element.external_url)
      ) {
        const x = [...slidesError];
        x[index][3] =
          'Please enter a valid external url (http(s)://something.something)';
        setSlidesError(x);
        entered = true;
      }
      console.log(element.external_url);
      if (
        product[index].productName !== null &&
        product[index].productName === '' &&
        element.external_url === ''
      ) {
        const x = [...productError];
        x[index] = 'Please enter a product';
        setProductError(x);
        entered = true;
      }
    });
    return entered;
  };
  const handleClose = () => {
    setClicked(false);

    setPlacementTitle('');
    setPlacementDesc('');
    setSlides([
      {
        image: '',
        video: '',
        external_url: '',
        description: '',
        hide_description: false,
        image_portrait: ''
      }
    ]);
    setProduct([
      {
        id: 0,
        productName: '',
        value: 0
      }
    ]);
    setPlacementError('');
    setPlacementDescError('');
    setSlidesError([['', '', '', '', '']]);
    setProductError(['']);
    onClose();
  };

  const handleSubmit = () => {
    if (!checkFormError()) {
      setClicked(true);
      const banner = {
        placement_title: placementTitle,
        placement_description: placementDesc,
        banner_slides: []
      };
      slides.map((element, index) => {
        const elementToBe = {
          image: element.image,
          image_portrait: element.image_portrait,
          video: element.video,
          external_url:
            element.external_url !== null ? element.external_url : '',
          description: element.description,
          hide_description: element.hide_description,
          internal_product: product[index].id
        };
        if (element !== null && element.external_url !== '') {
          delete elementToBe.internal_product;
        }
        banner.banner_slides.push(elementToBe);
      });
      dispatch(createBanner(banner)).then((result: number) => {
        if (result === 1) {
          reload();

          handleClose();
        } else {
          setClicked(false);
        }
      });
    }
  };

  const handlePlacementTitle = (value: any) => {
    setPlacementTitle(value);
    setPlacementError('');
  };
  const handlePlacementDesc = (value: any) => {
    setPlacementDesc(value);
    setPlacementDescError('');
  };
  const handleSlideDesc = (value: any, index: number) => {
    const x = [...slides];
    x[index].description = value;
    setSlides(x);
    const z = [...slidesError];
    z[index][0] = '';
    setSlidesError(z);
  };
  const handleHideDescription = (index: number) => {
    const x = [...slides];
    x[index].hide_description = !x[index].hide_description;
    setSlides(x);
  };
  const handleSlideExternalURL = (value: any, index: number) => {
    const x = [...slides];
    x[index].external_url = value;
    setSlides(x);
    const z = [...slidesError];
    z[index][3] = '';
    setSlidesError(z);
  };

  const addSlide = () => {
    const x = [...slides];
    x.push({
      image: '',
      video: '',
      external_url: '',
      description: '',
      hide_description: false,
      image_portrait: ''
    });
    setSlides(x);
    const y = [...product];
    y.push({
      id: 0,
      productName: '',
      value: 0
    });
    setProduct(y);
    const w = [...productError];
    w.push('');
    setProductError(w);
    const z = [...slidesError];
    z.push(['', '', '', '']);
    setSlidesError(z);
  };

  const deleteSlide = (index: number) => {
    const x = [...slides];
    x.splice(index, 1);
    setSlides(x);
    const y = [...product];
    y.splice(index, 1);
    setProduct(y);
  };

  const handleImage = (event: any, index) => {
    if (event.length > 0) {
      const x = [...slides];
      x[index].image = event[0];
      setSlides(x);
      const z = [...slidesError];
      z[index][1] = '';
      setSlidesError(z);
    }
  };
  const handleImagePortrait = (event: any, index) => {
    if (event.length > 0) {
      const x = [...slides];
      x[index].image_portrait = event[0];
      setSlides(x);
      const z = [...slidesError];
      z[index][4] = '';
      setSlidesError(z);
    }
  };

  const handleChangeProduct = (value: any, index: number) => {
    if (value !== null) {
      const y = [...productError];
      y[index] = '';
      setProductError(y);
      const x = [...product];
      x[index] = value;
      setProduct(x);
    } else {
      const x = [...product];
      x[index] = {
        id: 0,
        productName: '',
        value: 0
      };
      setProduct(x);
    }
  };

  const handleVideo = (event: any, index) => {
    if (event.length > 0) {
      const x = [...slides];
      x[index].video = event[0];
      setSlides(x);
      const z = [...slidesError];
      z[index][2] = '';
      setSlidesError(z);
    }
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px', marginTop: '1vw' }}
          color="secondary"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>Create Banner Container</div>
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSubmit}
                disabled={clicked}
              >
                Save
              </Button>
            </div>
          </div>
        </DialogTitle>
        <div style={{ padding: '2vw' }}>
          <Grid>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
              error={placementTitleError !== ''}
            >
              <InputLabel htmlFor="name-input">Placement Title</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={placementTitle}
                onChange={(e) => {
                  handlePlacementTitle(e.target.value);
                }}
              />
              <FormHelperText>{placementTitleError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl
              style={{
                marginTop: '0px',
                width: '20vw'
              }}
              fullWidth={true}
              margin={'normal'}
              error={placementDescError !== ''}
            >
              <InputLabel htmlFor="name-input">
                Placement Description
              </InputLabel>
              <Input
                id="name-input"
                type="text"
                value={placementDesc}
                onChange={(e) => {
                  handlePlacementDesc(e.target.value);
                }}
                multiline
                rows={3}
              />
              <FormHelperText>{placementDescError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid
            style={{
              fontSize: '0.9vw',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div style={{ marginRight: '1vw' }}>Slides</div>
            <IconButton onClick={addSlide}>
              <AddIcon></AddIcon>
            </IconButton>
          </Grid>
          {slides.map((element, index) => (
            <Grid
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Grid
                style={{
                  border: '1px solid black',
                  borderRadius: '1vw',
                  width: 'fit-content',
                  padding: '1vw',
                  marginTop: '1vw'
                }}
              >
                <Grid>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      width: '20vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                    error={slidesError[index][0] !== ''}
                  >
                    <InputLabel htmlFor="name-input">
                      Slide Description
                    </InputLabel>
                    <Input
                      id="name-input"
                      type="text"
                      value={element.description}
                      onChange={(e) => {
                        handleSlideDesc(e.target.value, index);
                      }}
                      multiline
                      rows={3}
                    />
                    <FormHelperText>{slidesError[index][0]}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid>
                  <FormControlLabel
                    style={{
                      marginLeft: 'unset'
                    }}
                    control={
                      <Checkbox
                        checked={element.hide_description}
                        onChange={(e) => {
                          handleHideDescription(index);
                        }}
                        name="hideDescriptionCheckbox"
                        color="primary"
                      />
                    }
                    label="Hide Description"
                  />
                </Grid>
                <Grid>
                  <FormControl
                    style={{
                      marginTop: '25px',
                      width: '20vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                    error={slidesError[index][3] !== ''}
                  >
                    <InputLabel htmlFor="name-input">External URL</InputLabel>
                    <Input
                      id="name-input"
                      type="text"
                      value={element.external_url}
                      onChange={(e) => {
                        handleSlideExternalURL(e.target.value, index);
                      }}
                    />
                    <FormHelperText>{slidesError[index][3]}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw',
                      width: '20vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                    error={productError[index] !== ''}
                    required
                  >
                    <Autocomplete
                      value={product[index]}
                      onChange={(event: any, newValue: string | null) => {
                        handleChangeProduct(newValue, index);
                      }}
                      inputValue={inputValue[index]}
                      onInputChange={(event, newInputValue) => {
                        const x = [...inputValue];
                        x[index] = newInputValue;
                        setInputValue(x);
                      }}
                      options={allEditions}
                      getOptionLabel={(option) => {
                        if (
                          option.productName !== undefined &&
                          option.id !== 0
                        ) {
                          return option.productName;
                        } else return '';
                      }}
                      renderOption={(option) =>
                        option.productName !== undefined
                          ? option.productName
                          : ''
                      }
                      loading={allEditions.length === 0}
                      loadingText={'Loading products'}
                      renderInput={(params) => (
                        <TextField {...params} label="Artwork" />
                      )}
                    />
                    <FormHelperText>{productError[index]}</FormHelperText>
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginLeft: '2vw',
                      width: '17.5vw'
                    }}
                    fullWidth
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="name-input">Value</InputLabel>
                    <Input
                      id="name-input"
                      type="text"
                      value={product[index].value}
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1vw',
                    flexDirection: 'column'
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <DropzoneArea
                      acceptedFiles={['image/*']}
                      dropzoneText={'Drag and drop the Slide image here'}
                      showPreviewsInDropzone={true}
                      onChange={(files) => handleImage(files, index)}
                      filesLimit={0}
                    />
                    <Typography variant="caption" style={{ color: '#fe309c' }}>
                      For better resolution insert images with 2500x1250
                    </Typography>
                    <br />
                  </ThemeProvider>
                  <FormHelperText style={{ color: 'red' }}>
                    {slidesError[index][1]}
                  </FormHelperText>
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1vw',
                    flexDirection: 'column'
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <DropzoneArea
                      acceptedFiles={['image/*']}
                      dropzoneText={
                        'Drag and drop the Slide image portrait here'
                      }
                      showPreviewsInDropzone={true}
                      onChange={(files) => handleImagePortrait(files, index)}
                      filesLimit={0}
                    />
                    <Typography variant="caption" style={{ color: '#fe309c' }}>
                      For better resolution insert images with 1080x1920
                    </Typography>
                    <br />
                  </ThemeProvider>
                  <FormHelperText style={{ color: 'red' }}>
                    {slidesError[index][4]}
                  </FormHelperText>
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1vw',
                    flexDirection: 'column'
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <DropzoneArea
                      acceptedFiles={['video/*']}
                      dropzoneText={'Drag and drop the Slide video here'}
                      showPreviewsInDropzone={true}
                      onChange={(files) => handleVideo(files, index)}
                      filesLimit={0}
                    />
                  </ThemeProvider>
                  <FormHelperText style={{ color: 'red' }}>
                    {slidesError[index][2]}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid>
                <IconButton
                  disabled={index === 0}
                  onClick={() => {
                    deleteSlide(index);
                  }}
                >
                  <DeleteIcon></DeleteIcon>
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </div>
      </div>
    </Modal>
  );
}
