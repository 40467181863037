import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { IHistory } from 'interfaces/history';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import PrintIcon from '@material-ui/icons/PrintOutlined';
import moment from 'moment';
import ModalCreateInvoice from './ModalCreateInvoice';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { editOrder } from 'redux/ducks/history';
import CurrencyFormat from 'react-currency-format';
// import { Box, Collapse, Input, Typography } from '@material-ui/core';
import { Input } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { getCustomerOrders } from 'redux/ducks/history';
import CancelOrderModal from './cancelOrderModal';
import DeleteOrderModal from './deleteOrderModal';

import { DataGrid, GridColDef, GridToolbar } from '@material-ui/data-grid';
import { toNumber } from 'lodash';
import { IKitchen } from 'interfaces/kitchen';
import OrderNotesModal from './OrderNotesModal';
import Axios from 'axios';
import { API_ENDPOINT } from 'settings';
import Pdf from './pdf'
import { formatCurrencyValues } from 'utils/format-functions/formatCurrencyValues';
import { pdf } from '@react-pdf/renderer';
import { sendPDF } from 'redux/ducks/kitchen';
import { getHistory } from 'redux/ducks/history';
import { fetch } from 'utils/request';
import { EMethod } from 'enums/method';
import EditOrderModal from './editOrderModal'
const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    maxWidth: '90vw',
    width: '90vw',
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'black',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: '#fe309c',
      backgroundColor: 'black'
    }
  },
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  }
});

interface IProps {
  orders: IHistory[];
  initialDate: Date | number;
  finalDate: Date;
  isFiltering: Boolean;
  filter: any;
  setInitialDate: any;
  setFinalDate: any;
  message: string;
  fetchData: () => void;
  query: any
}

export default function HistoryTable(props: IProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    orders,
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
    isFiltering,
    filter,
    message,
    fetchData,
    query
  } = props;

  const [invoiceOpen, setInvoiceOpen] = React.useState(false);
  const [cancelOrderModal, setCancelOrderModal] = React.useState(false);
  const [orderToCancel, setOrderToCancel] = useState<IKitchen>(null);
  const [deleteOrderModal, setDeleteOrderModal] = React.useState(false);
  const [orderToDelete, setOrderToDelete] = useState<IKitchen>(null);
  const [openEditOrder, setOpenEditOrder] = useState(false)
  const [selectedEditOrder, serSelectedEditOrder] = useState<any>({})

  const handleInitalDateChange = (date: Date) => {
    setInitialDate(date);
  };

  const handleFinalDateChange = (date: Date) => {
    setFinalDate(date);
  };

  const [invoiceReference, setInvoiceReference] = useState([]);
  const handleInvoiceReference = (orderId: number, value: string): void => {
    const updatedReferences = invoiceReference.filter(
      (e) => e.orderId !== orderId
    );
    updatedReferences.push({
      orderId: orderId,
      reference: value
    });
    setInvoiceReference(updatedReferences);

    return null;
  };
  const findInvoiceReferenceValue = (orderId: number): string => {
    if (!orderId) return '';

    const currentReference = invoiceReference.find(
      (e) => e.orderId === orderId
    );

    if (currentReference !== undefined) {
      return currentReference.reference;
    }

    return '';
  };

  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    if (orders) {
      const start = new Date(initialDate);
      const end = new Date(finalDate);

      const filtered = [];
      orders?.map((order) => {
        var date
        if (order.invoice_date) {
          date = new Date(order.invoice_date)
        } else {
          date = new Date(order.data_ordered)
        }
        if (
          date > start && date < end
        ) {
          filtered.push(order);
        }
      });

      setFilteredOrders(filtered);

      const newReferences = [];
      orders.map((order) => {
        newReferences.push({
          orderId: order.order_id,
          reference: order.invoice_reference ?? ''
        });
      });
      setInvoiceReference(newReferences);
    }
  }, [orders]);

  const submitOrder = (orderId: number): void => {
    if (!orderId) return;

    const currentReference = invoiceReference.find(
      (e) => e.orderId === orderId
    );

    if (currentReference.reference === '') return null;

    const order = orders.find((e) => e.order_id === orderId);
    const orderProducts = [];
    order.items.map((item) => {
      orderProducts.push({
        product_id: item.product.product_id,
        edition_id: item.edition.id,
        quantity: 1
      });
    });

    const formatedOrderToPatch = {
      infos: {
        type_of_order:
          order.type_of_order === 'O-C' ? 'Collection' : 'Delivery',
        store_id: 1,
        invoice_reference: currentReference.reference,
        insurance_requested: order.insurance_requested,
        invoice_date: order.invoice_date,

      },
      // address: {
      //   // address: deliveryAddress,
      //   street,
      //   street_number: streetNumber,
      //   city,
      //   country: country.code,
      //   state,
      //   postal_code: postalCode
      // },
      products: orderProducts,
      client: order.client || 1
      // type: 'invoice',
      // additional_costs: additionalCosts
    };

    dispatch(editOrder(formatedOrderToPatch, orderId)).then(
      (result) => {
        if (typeof result === "object") {
          generatePDF(result)
        }
        return;
      }
    );
  };

  const filterMessage = () => {
    const style = {
      color: '#233472',
      padding: '15px 0 0 0',
      fontWeight: 600
    };

    if (isFiltering) {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
        </div>
      );
    }
  };
  const onClose = () => {
    setInvoiceOpen(false);
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogSelectedOrder, setDialogSelectedOrder] = React.useState('');
  const [customerModal, setCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [orderNotesModal, setOrderNotesModal] = useState(false);
  const handleDialogOpen = (order: any) => {
    setDialogOpen(true);
    setDialogSelectedOrder(order);
  };
  const handleOrderNotesModalOpen = (order: any) => {
    setOrderNotesModal(true);
    setDialogSelectedOrder(order);
  };
  const handleCancelModalOpen = (order: any) => {

    setCancelOrderModal(true);
    setOrderToCancel(order);
  };

  const handleDeleteModalOpen = (order: any) => {
    setDeleteOrderModal(true);
    setOrderToDelete(order);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogSelectedOrder('');
  };
  const handleOrderNotesClose = () => {
    setOrderNotesModal(false);
    setDialogSelectedOrder('');
  };
  const editionPrice = (
    edition: {
      price_eur: number;
      base_price: number;
      price_gbp: number;
      price_chf: number;
      price_usd: number;
    },
    currency,
    operation
  ) => {
    var value;
    if (currency === 'EUR') {
      value = edition?.price_eur;
    }
    if (currency === 'USD') {

      value = edition?.price_usd;
    }
    if (currency === 'GBP') {

      value = edition?.price_gbp;
    }
    if (currency === 'CHF') {
      value = edition?.price_chf;
    }
    return value;
  };
  const submitPDF = (file, order) => {
    dispatch(sendPDF(file, order?.order_id)).then((result) => {
      if (result) {
        dispatch(getHistory(query))
      }
    })
  };
  const calcCartTotal = (order: IHistory) => {
    const items = order?.items;
    const cartTotal = items.reduce(
      (a, b) => a + editionPrice(b?.edition, order?.currency, 'cartTotal') - b.orderitem_discount,
      0
    );
    return formatCurrencyValues(cartTotal, order?.currency);
  };

  const getBillingAddress = async (email) => {
    return new Promise<string>((resolve, reject) => {
      fetch({
        method: EMethod.GET,
        url: `users/api/clients/?email=${email}`
      }).then((response) => {
        response = response.results[0].billing_address
        resolve(response.street + " " + response.street_number + ", " + response.city + ", " + response.postal_code + ", " + response.country)
      })
    })

  }
  const cleanAddress = (address: string): string => {
    var _address = address
      ?.substring(
        address.indexOf("'") + 1,
        address.lastIndexOf("'")
      )
    if (_address === "") {
      return address
    }
    return _address
  }
  const generatePDF = async (order: IHistory) => {
    var _names = []
    const replaceAt = (index: any, replacement: string, str: string) => {
      return str.substring(0, index) + replacement + str.substring(index + replacement.length);
    }

    function includesSpecialChars(str: string, idx) {
      const chars = ["ć", "č", "đ"]
      const utfChars = {
        ć: "c",
        č: "c",
        đ: "d"
      }
      var selectedChar = ""
      if (chars.some(v => {
        if (str.includes(v)) {
          selectedChar = v
        }
        return str.includes(v)
      })) {
        includesSpecialChars(replaceAt(str.indexOf(selectedChar), utfChars[selectedChar], str,), idx)
      } else {

        _names.length = order.items.length
        _names[idx] = str
      }
    }
    const _response = await fetch({
      url: `/orders/api/orders/${order.order_id}/get_order/`,
      method: 'GET'
    });
    const fillNames = () => {
      order.items.forEach((product, idx) => {
        includesSpecialChars(product.product.artist.name, idx)
      })
    }
    fillNames()
    getBillingAddress(order.owner.email).then(async (response) => {
      const doc = (
        <Pdf
          type_of_order={order.type_of_order}
          additional_cost={_response.additional_costs}
          order_notes={order.final_comment}
          reference={order.invoice_reference}
          billingAddress={response}
          name={order?.owner?.name ?? order?.client?.name}
          phone={order?.owner?.phone_number}
          currency={order?.currency}
          email={order?.owner?.email}
          voucher_code={order?.voucher_code}
          discount={formatCurrencyValues(
            order?.discount,
            order?.currency
          )}
          ownerId={order?.owner?.profile_id}
          address={order.type_of_order === "O-D" ? cleanAddress(order.delivery_address) : response}
          date={handleDateOrdered(order)}
          products={order.items.map((item, idx) => {
            return {
              product_id: item.product?.product_id,
              artist: _names[idx],
              name: item.product.name,
              hs_code: item.product?.hs_code,
              width: item.product.width,
              height: item.product.height,
              weight: item.product.weight,
              length: item.product.length,
              quantity: item.quantity,
              edition_number: item.edition?.edition_number,
              price: formatCurrencyValues(
                editionPrice(item.edition, order?.currency, 'cartTotal'),
                order?.currency
              ),
              orderitem_discount: formatCurrencyValues(item.orderitem_discount, order?.currency),
              year: item.product.year,
              edition: item.edition.edition_number
            };
          })}
          cartTotal={calcCartTotal(order)}
          vat={formatCurrencyValues(order?.vat, order?.currency)}
          total={formatCurrencyValues(order?.total_order, order?.currency)}
          shippxng={formatCurrencyValues(
            order?.delivery_fee || order?.delivery_fee == 0 ? order?.delivery_fee : order?.dhl_price,
            order?.currency
          )}
          insuranceFee={
            order?.insurance_requested
              ? formatCurrencyValues(
                order?.insurance_value_cents,
                order?.currency
              )
              : ''
          }
          order_no={order?.order_code}
        />
      );

      await pdf(doc)
        .toBlob()
        .then((blob) => {
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            submitPDF(base64data, order);
          };
        })
        .catch((e) => {
          console.log(e);
        });
    })

  };

  const parseOrderStatus = (orderStatus: string) => {
    switch (orderStatus) {
      case 'A fazer':
        return 'Pending';
      case 'Fazendo':
        return 'Accepted';
      case 'Feito':
        return 'Preparing';
      case 'Depot':
        return 'Ready';
      case 'Completed':
        return 'Completed';
      case 'Canceled':
        return 'Canceled';
      case 'Transit':
        return 'On Route';
      case 'Delivered':
        return 'Delivered';
      default:
        return 'Undefined';
    }
  };

  function CustomerDialog(props) {
    const { onClose, selectedCustomer, open } = props;
    const customer = selectedCustomer;
    // var customerOrders;
    getCustomerOrders(selectedCustomer.profile_id);
    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open} className={classes.dialog}>
        <DialogTitle> orders from {customer.name} </DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
    );
  }

  function SimpleDialog(props) {
    const { onClose, selectedOrder, open } = props;
    const order = selectedOrder;
    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open} className={classes.dialog}>
        <DialogTitle>Time Averages for Order: {order.order_code} </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span style={{ fontWeight: 'bold' }}>Pending:</span>{' '}
            {order.date_ordered
              ? moment(new Date(order.date_ordered)).format('ll')
              : ''}
          </DialogContentText>
          <DialogContentText>
            <span style={{ fontWeight: 'bold' }}>Accepted:</span>{' '}
            {order.doing_at
              ? moment(new Date(order.doing_at)).format('ll')
              : ''}
          </DialogContentText>
          <DialogContentText>
            <span style={{ fontWeight: 'bold' }}>Preparing:</span>{' '}
            {order.done_at ? moment(new Date(order.done_at)).format('ll') : ''}
          </DialogContentText>
          <DialogContentText>
            <span style={{ fontWeight: 'bold' }}>Ready:</span>{' '}
            {order.depot_at
              ? moment(new Date(order.depot_at)).format('ll')
              : ''}
          </DialogContentText>
          <DialogContentText>
            <span style={{ fontWeight: 'bold' }}>On Route:</span>{' '}
            {order.transit_at
              ? moment(new Date(order.transit_at)).format('ll')
              : ''}
          </DialogContentText>
          <DialogContentText>
            <span style={{ fontWeight: 'bold' }}>Delivered:</span>{' '}
            {order.delivered_at
              ? moment(new Date(order.delivered_at)).format('ll')
              : ''}
          </DialogContentText>
          <DialogContentText>
            <span style={{ fontWeight: 'bold' }}>Completed:</span>{' '}
            {order.finished_at
              ? moment(new Date(order.finished_at)).format('ll')
              : ''}
          </DialogContentText>
          <DialogContentText>
            <span style={{ fontWeight: 'bold' }}>Canceled:</span>{' '}
            {order.canceled_at
              ? moment(new Date(order.canceled_at)).format('ll')
              : ''}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Order Id',
      width: 150,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        return orderIdColumn(params.row.id);
      }
    },
    {
      field: 'editOrder',
      headerName: 'Edit',
      width: 150,
      headerAlign: 'left',
      align: 'left',
      disableExport: true,
      renderCell: (params) => {
        return editOrderColumn(params.row.id);
      }
    },
    {
      field: 'client',
      headerName: 'Client',
      width: 180,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        return customerOrders(params.row.id);
      }
    },
    {
      field: 'created',
      headerName: 'Created',
      width: 150,
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'invoiceNumber',
      headerName: 'Invoice Number',
      width: 180,
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params) => {
        return renderInvoiceNumberColumn(params.row.id);
      },
      renderCell: (params) => {
        return invoiceNumberColumn(params.row.id);
      }
    },

    {
      field: 'value',
      headerName: 'Value',
      width: 150,
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params) => {
        return renderValueColumn(params.row.id);
      },
      renderCell: (params) => {
        return valueColumn(params.row.id);
      }
    },
    {
      field: 'payment',
      headerName: 'Payment',
      width: 150,
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'euroMonthly',
      headerName: 'Sales EUR',
      width: 180,
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'stripeId',
      headerName: 'Stripe Id',
      width: 180,
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'paid',
      headerName: 'Paid?',
      width: 130,
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'orderNotes',
      headerName: 'Order notes',
      width: 180,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        return orderNotesColumn(params.row.id);
      }
    },
    {
      field: 'timeAverages',
      headerName: 'Time Averages',
      width: 180,
      headerAlign: 'left',
      align: 'left',
      disableExport: true,
      renderCell: (params) => {
        return timeAveragesColumn(params.row.id);
      }
    },
    {
      field: 'cancelButton',
      headerName: 'Cancel order',
      width: 130,
      headerAlign: 'left',
      align: 'center',
      disableExport: true,
      renderCell: (params) => {
        return cancelOrderColumn(params.row.id);
      }
    },
    {
      field: 'deleteButton',
      headerName: 'Delete order',
      width: 130,
      headerAlign: 'left',
      align: 'center',
      disableExport: true,
      renderCell: (params) => {
        return deleteOrderColumn(params.row.id);
      }
    }
  ];

  const getPDF = async (order) => {
    try {
      var pdfURL = order.invoice_pdf_decoded.substring(1);
      await Axios({
        method: 'GET',
        url: `${API_ENDPOINT}${pdfURL}`,
        responseType: 'blob'
      }).then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    } catch (error) {
      console.log('error ', error);
    }
  };
  const orderIdColumn = (orderId: number) => {
    const order = orders.find((order) => order.order_id === orderId);
    if (order && order?.invoice_pdf_decoded) {
      return (
        <Button onClick={() => getPDF(order)}>
          <div >{order.order_code}</div>
        </Button>
      );
    }
    return (
      <Button onClick={() => generatePDF(order)}>
        <div >Save invoice</div>
      </Button>
    )
  };

  const invoiceNumberColumn = (orderId: number) => {
    const order = orders.find((order) => order.order_id === orderId);

    if (order) {
      return (
        <Input
          id="name-input"
          type="text"
          value={findInvoiceReferenceValue(order.order_id)}
          onChange={(e) => {
            handleInvoiceReference(order.order_id, e.target.value);
          }}
          // onBlur={() => submitOrder(order.order_id)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => submitOrder(order.order_id)}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <EditIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      );
    }
    return null;
  };


  const editOrderColumn = (orderId: number) => {
    const order = orders.find((order) => order.order_id === orderId);

    if (order) {
      return (
        <Button onClick={() => {
          serSelectedEditOrder(order)
          setOpenEditOrder(true)
        }}>
          Edit order
        </Button>
      );
    }
    return null;
  };


  const renderInvoiceNumberColumn = (orderId: number) => {
    const order = orders.find((order) => order.order_id === orderId);

    if (order) {
      return findInvoiceReferenceValue(order.order_id);
    }
    return null;
  };

  const valueColumn = (orderId: number) => {
    const order = orders.find((order) => order.order_id === orderId);

    if (order) {
      return (
        <div id={`orderValue-${orderId}`} style={{ display: 'flex' }}>
          <CurrencyFormat
            value={order.total_order}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale
          />{' '}
          <span style={{ marginLeft: '7px' }}>{order.currency}</span>
        </div>
      );
    }
    return null;
  };

  const renderValueColumn = (orderId: number) => {
    const element = document.getElementById(`orderValue-${orderId}`);
    if (element) {
      return element.textContent;
    } else {
      return null;
    }
  };

  const customerOrders = (orderId: any) => {
    const order = orders.find((order) => order?.order_id === orderId);

    return (
      <div
        onClick={() => {
          setSelectedCustomer(order.owner);
          setCustomerModal(true);
        }}
      >
        {' '}
        {order?.owner?.name}
      </div>
    );
  };
  const timeAveragesColumn = (orderId: number) => {
    const order = orders.find((order) => order.order_id === orderId);

    return (
      <Button variant="outlined" onClick={() => handleDialogOpen(order)}>
        {parseOrderStatus(order?.status)}
      </Button>
    );
  };
  const orderNotesColumn = (orderId: number) => {
    const order = orders.find((order) => order.order_id === orderId);
    if (!order?.final_comment) {
      return null;
    }
    return (
      <Button
        variant="outlined"
        onClick={() => handleOrderNotesModalOpen(order)}
      >
        {order.final_comment.substring(0, 5)}...
      </Button>
    );
  };
  const cancelOrderColumn = (orderId: number) => {
    const order = orders.find((order) => order.order_id === orderId);

    return (
      <Button variant="outlined" onClick={() => handleCancelModalOpen(order)}>
        Cancel
      </Button>
    );
  };
  const deleteOrderColumn = (orderId: number) => {
    const order = orders.find((order) => order.order_id === orderId);

    return (
      <Button variant="outlined" onClick={() => handleDeleteModalOpen(order)}>
        Delete
      </Button>
    );
  };

  let rows: Array<any>;

  const handleDateOrdered = (order) => {
    if (order.direct_ecomm === "direct") {
      return order.invoice_date
        ? moment(order.invoice_date.substring(0, 10)).format('ll')
        : '';
    } else {
      return order.date_ordered
        ? moment(order.date_ordered.substring(0, 10)).format('ll')
        : '';
    }
  };

  if (filteredOrders) {
    rows = filteredOrders?.map((order, index) => ({
      id: order.order_id,
      editOrder: index,
      client: order.owner ? order.owner.name : '',
      invoiceNumber: order.invoice_reference,
      created: handleDateOrdered(order),
      value: '',
      payment: order.payment,
      euroMonthly: toNumber(order.total_order_eur_month).toLocaleString(
        'en-US',
        {
          style: 'currency',
          currency: 'EUR'
        }
      ),
      stripeId: '',
      paid: order.is_paid ? 'Yes' : 'No',
      orderNotes: order.final_comment,
      timeAverages: index,
      cancelButton: index,
      deleteButton: index
    }));
  } else {
    rows = [
      {
        id: 1,
        editOrder: '',
        invoiceNumber: '',
        client: '',
        payment: '',
        value: '',
        euroMonthly: '',
        stripeId: '',
        paid: '',
        created: '',
        timeAverages: '',
        cancelButton: '',
        deleteButton: ''
      }
    ];
  }

  return (
    <>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: 15,
            padding: 15,
            backgroundColor: '#f5f5f5',
            borderRadius: 5
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'flex-end'
                // width: '45vw'
              }}
            >
              <Grid
                style={{
                  alignItems: 'center',
                  display: 'inline-flex'
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Intial Date</span>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-initial"
                      value={initialDate}
                      placeholder="                ---------------"
                      onChange={handleInitalDateChange}
                      autoOk={true}
                      style={{ width: 189, marginRight: 5 }}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Final Date</span>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-final"
                      margin="none"
                      value={finalDate}
                      onChange={handleFinalDateChange}
                      autoOk={true}
                      style={{ width: 189 }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <div
                  style={{
                    display: 'inline-flex',
                    marginLeft: 5
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      marginLeft: 5,
                      fontSize: '0.8em',
                      padding: '5px 16px'
                    }}
                    onClick={filter}
                  >
                    Filter
                  </Button>

                </div>
                {/* <PrintIcon
                  onClick={() => {
                    const _initialDate = initialDate
                      ? moment(initialDate).format('YYYY-MM-DD')
                      : null;
                    const _finalDate = finalDate
                      ? moment(finalDate).format('YYYY-MM-DD')
                      : null;
                    window.open(
                      `https://shnonco.gorealtime.io/waiter/generate_csv_of_orders_for_between_dates/?starts=${_initialDate}&ends=${_finalDate}`
                    );
                  }}
                /> */}
              </Grid>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                // width: '20vw',
                alignItems: 'center'
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{
                  width: 'fit-content'
                }}
                className={classes.activeButton}
                onClick={() => setInvoiceOpen(true)}
              >
                Add Invoice
              </Button>
            </div>
          </div>
          {filterMessage()}
        </div>

        <SimpleDialog
          selectedOrder={dialogSelectedOrder}
          open={dialogOpen}
          onClose={handleDialogClose}
        />
        <CustomerDialog
          selectedCustomer={selectedCustomer}
          open={customerModal}
          onClose={() => {
            setCustomerModal(false);
            setSelectedCustomer({});
          }}
        />
        <ModalCreateInvoice
          initialDate={initialDate}
          finalDate={finalDate}
          open={invoiceOpen}
          onClose={onClose}
        />
        <CancelOrderModal
          order={orderToCancel}
          open={cancelOrderModal}
          successFunction={() => {
            fetchData();
          }}
          onClose={() => setCancelOrderModal(false)}
        />
        <DeleteOrderModal
          order={orderToDelete}
          open={deleteOrderModal}
          successFunction={() => {
            fetchData();
          }}
          onClose={() => setDeleteOrderModal(false)}
        />
        <EditOrderModal
          open={openEditOrder}
          order={selectedEditOrder}
          onClose={() => {
            setOpenEditOrder(false)
            serSelectedEditOrder({})
          }}
          generatePDF={(_order) => {
            generatePDF(_order)
          }}
        />
        <OrderNotesModal
          order={dialogSelectedOrder}
          open={orderNotesModal}
          onClose={handleOrderNotesClose}
        />
      </TableContainer>
      <div style={{ height: '80vh', width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar
          }}
        />
      </div>
    </>
  );
}
