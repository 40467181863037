import React, { useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
import { Button, InputLabel, Input, IconButton } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { editCurrency } from 'redux/ducks/currency';
import { Icurrency } from 'interfaces/currency';
import '../Stock/styles.css';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  currency: Icurrency;
  getData: () => void;
}

export default function ModalEditMenu(props: SimpleDialogProps) {
  const { onClose, open, currency, getData } = props;
  const [name, setName] = React.useState('');
  const [rate, setRate] = React.useState(0);
  const dispatch = useDispatch();

  const handleClose = () => {
    setName('');
    setRate(0);
    onClose();
  };
  useEffect(() => {
    if (currency) {
      setName(currency.name);
      setRate(currency.rate);
    }
  }, [currency]);

  const handleRate = (event: any) => {
    setRate(event.target.value);
  };
  const handleName = (event: any) => {
    setName(event.target.value);
  };

  const submitEdit = async () => {
    dispatch(
      editCurrency(currency.id, {
        name: name,
        rate: rate
      })
    ).then((result: boolean) => {
      if (result) {
        getData();
        handleClose();
      }
    });
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="simple-dialog-title">
          Edit Currency {currency?.name}
        </DialogTitle>

        <FormControl style={{ margin: '0px 10px' }}>
          <InputLabel htmlFor="name-input">Name</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={name}
            onChange={handleName}
          />
        </FormControl>
        <FormControl style={{ margin: '0px 10px' }}>
          <InputLabel htmlFor="name-input">Currency Rate</InputLabel>
          <Input
            id="name-input"
            type="number"
            value={rate}
            onChange={handleRate}
          />
        </FormControl>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={submitEdit} color="primary">
            {' '}
            Save{' '}
          </Button>
          <Button onClick={handleClose}> Cancel </Button>
        </div>
      </div>
    </Modal>
  );
}
