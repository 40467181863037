import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';
import { Product } from './stock';

export interface Banner {
  placement_title: string;
  placement_description: string;
  id: number;
  banner_slides: BannerSlides[];
}
export interface BannerSlides {
  sort_key: number;
  image: '';
  image_portrait: '';
  video: '';
  internal_product: Product;
  external_url: '';
  description: string;
  hide_description: boolean;
  id: number;
}

export interface IBannerState {
  fetch: boolean;
  count: number;
  next?: string;
  previous?: string;
  results: Banner[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesBanner {
  FETCH = 'BANNER/FETCH',
  SUCCESS = 'BANNER/SUCCESS',
  FIRSTLOAD = 'BANNER/FIRSTLOAD',
  FAILURE = 'BANNER/FAILURE',
  SEARCH = 'BANNER/SEARCH'
}

export interface IFetchAction extends IBaseAction<ETypesBanner, null> {
  type: ETypesBanner.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesBanner, IBannerState> {
  type: ETypesBanner.SUCCESS;
  payload: IBannerState;
}

export interface IFirstLoadAction
  extends IBaseAction<ETypesBanner, IBannerState> {
  type: ETypesBanner.FIRSTLOAD;
  payload: IBannerState;
}

export interface IFailureAction extends IBaseAction<ETypesBanner, string> {
  type: ETypesBanner.FAILURE;
  payload: string;
}

export type IBannerAction =
  | IFetchAction
  | ISuccessAction
  | IFirstLoadAction
  | IFailureAction;

export type GetState = () => any;

export type PromiseAction = Promise<IBannerAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IBannerAction
    | ThunkAction
    | PromiseAction
    | Array<IBannerAction>
    | IAlertAction
) => any;
