import React from 'react';

import { fetch } from 'utils/request';

import { Page, Text, View, Document, } from '@react-pdf/renderer';
import { useEffect } from 'react';
interface IProps {
  type_of_order: string;
  name: string;
  date: string;
  phone: string;
  email: string;
  products: {
    edition_number: string;
    product_id: number;
    artist: string;
    name: string;
    hs_code: string;
    width: number;
    height: number;
    length: number;
    weight: number;
    quantity: number;
    price: string;
    orderitem_discount: string;
    year: string;
    edition: string;
  }[];
  cartTotal: string;
  vat: string;
  shippxng: string;
  address: string;
  order_no: string;
  insuranceFee: string;
  total: string;
  ownerId: number;
  currency: string;
  discount: string;
  voucher_code: string;
  additional_cost: {
    "title": string,
    "description": string,
    "cost": number
  }[],
  order_notes: string;
  reference: string;
  billingAddress: string;
}

export default function Receipt(props: IProps): JSX.Element {
  const {
    type_of_order,
    name,
    date,
    email,
    products,
    cartTotal,
    shippxng,
    order_no,
    insuranceFee,
    total,
    phone,
    address,
    vat,
    currency,
    discount,
    voucher_code,
    additional_cost,
    order_notes,
    reference,
    billingAddress
  } = props;
  console.log("props ", props)
  const [technique, setTechnique] = React.useState('');
  const [template, setTemplate] = React.useState('');

  const getData = async () => {
    try {
      if (products) {
        await Promise.all(
          products.map(async (product) => {
            const response = await fetch({
              url: `/products/api/products/${product.product_id}/`,
              method: 'GET'
            });
            setTechnique(response.technique.name);
            setTemplate(response.template);
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const right = () => {
    return (
      <View
        style={{
          width: '30%',
          paddingTop: '15pt'
        }}
      >
        <View
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.212cm',
            paddingRight: '1.27cm'
          }}
        >
          <Text style={{ letterSpacing: '0.02cm', fontSize: '9.8pt' }}>
            ArtXX AG {'\n'}
            Aegeristrasse 50 {'\n'}
            6300 Zug {'\n'}
            Switzerland
          </Text>
        </View>
        <View
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.212cm',
            paddingRight: '1.27cm',
            letterSpacing: '0.02cm'
          }}
        >
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            VAT NUMBER:
          </Text>
          <Text style={{ fontSize: '9.8pt', letterSpacing: '0.02cm' }}>
            CH-391.058.957 MWST
          </Text>
        </View>
        <View
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.212cm',
            paddingRight: '1.27cm'
          }}
        >
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            CONTACTS:
          </Text>
          <Text style={{ fontSize: '9.8pt', letterSpacing: '0.02cm' }}>
            info@wengcontemporary.com {'\n'}
            +41 (0)43 229 31 28
          </Text>
        </View>
        <View
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.127cm',
            marginBottom: '0.127cm'
          }}
        ></View>
        <View
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.212cm',
            paddingRight: '1.27cm'
          }}
        >
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            GENERAL TERMS & CONDITIONS
          </Text>
          <Text
            style={{
              fontSize: '8.3pt',
              marginTop: '0.127cm',
              letterSpacing: '0.02cm'
            }}
          >
            The title of ownership of the work is transferred from ArtXX AG up
            on complete payment of the work. All sales are final up on receipt
            of payment. Unless indicated above, any applicable shipping, packing
            and/or insurance charges will be billed separately. The payment of
            any applicable duties, tax, customs, and other fees are the
            responsibility of the customer.
          </Text>
        </View>
        <View
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.127cm',
            marginBottom: '0.127cm',
            letterSpacing: '0.02cm'
          }}
        ></View>
        <View
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.212cm',
            paddingRight: '1.27cm',
            letterSpacing: '0.02cm'
          }}
        >
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            WIRE TRANSFER INSTRUCTION
          </Text>
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            (IN {currency})
          </Text>
        </View>
        <View
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.212cm',
            paddingRight: '1.27cm'
          }}
        >
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            ACCOUNT NAME:
          </Text>
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            ArtXX AG
          </Text>
        </View>
        <View
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.212cm',
            paddingRight: '1.27cm'
          }}
        >
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            BANK DETAILS:
          </Text>
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            {currency === 'USD'
              ? 'Commerzbank Branch Zurich'
              : currency === 'EUR'
                ? 'Deutsche Bank Krefeld'
                : 'Commerzbank Branch Zurich'}
          </Text>
        </View>
        <View
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.212cm',
            paddingRight: '1.27cm'
          }}
        >
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            IBAN:
          </Text>
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            {currency === 'USD'
              ? 'CH03 0883 6261 1275 0000 1'
              : currency === 'EUR'
                ? 'DE95320700800080039100'
                : currency === 'CHF'
                  ? 'CH62 0883 6261 1275 0000 6'
                  : 'CH19 0883 6261 1275 0000 4'}
          </Text>
        </View>
        <View
          style={{
            marginBottom: '1vh',
            paddingLeft: '0.212cm',
            paddingRight: '1.27cm'
          }}
        >
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            BIC/SWIFT CODE:
          </Text>
          <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm' }}>
            {currency === 'USD'
              ? 'COBA CH ZH XXX'
              : currency === 'EUR'
                ? 'DEUTDEDD320'
                : currency === 'CHF'
                  ? 'COBA CH ZH XXX'
                  : 'COBA CH ZH XXX'}
          </Text>
        </View>
      </View>
    );
  };
  const left = () => {
    return (
      <View
        style={{
          width: '50%',
          marginLeft: '20%',
          position: 'relative',
          paddingTop: '75pt'
        }}
      >
        <View
          style={{
            marginBottom: '8vh',
            maxWidth: '65%',
            paddingLeft: '0.127cm'
          }}
        >
          <Text style={{ fontSize: '0.423cm', letterSpacing: '0.02cm' }}>
            {name} {'\n'}
          </Text>
          {type_of_order === "O-D" ?
            <>
              <Text style={{ fontSize: '0.423cm', letterSpacing: '0.02cm', paddingTop: "0.3cm" }}>Delivery address:{"\n"}{address}</Text>
              <Text style={{ fontSize: '0.423cm', letterSpacing: '0.02cm', paddingTop: "0.3cm" }}>Billing address:{"\n"}{billingAddress}</Text>
            </> : <Text style={{ fontSize: '0.423cm', letterSpacing: '0.02cm' }}>{address}</Text>}

          {'\n'}
          <Text style={{ fontSize: '0.423cm', letterSpacing: '0.02cm' }}>{email.slice(0, 2) === "qa" ? "" : email}</Text>
          {'\n'}
          <Text style={{ fontSize: '0.423cm', letterSpacing: '0.02cm' }}>{phone.includes("12345") ? "" : phone}</Text>
        </View>
        <View
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.127cm',
            marginBottom: '0.127cm'
          }}
        ></View>
        <View style={{ paddingLeft: '0.127cm', paddingBottom: '0.212cm' }}>
          <Text style={{ fontSize: '0.423cm', letterSpacing: '0.02cm' }}>
            {date}
          </Text>
        </View>
        <View
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.127cm',
            marginBottom: '0.127cm'
          }}
        ></View>
        {
          products.map((product, index) => (
            <View>
              {product.quantity > 0 ? <View wrap={false} key={index} style={{ marginTop: index !== 0 ? '1vw' : '0vw' }}>
                <View style={{ paddingLeft: '0.127cm' }}>
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      paddingRight: '0.212cm'
                    }}
                  >
                    <Text style={{ fontSize: '0.423cm', letterSpacing: '0.02cm' }}>
                      {product.artist}
                    </Text>
                    <Text
                      style={{ fontSize: '0.423cm', letterSpacing: '0.02cm' }}
                    >{`${product.price} ${currency}`}</Text>
                  </View>
                  <Text
                    style={{
                      maxWidth: '50%',
                      fontSize: '0.423cm',
                      width: '50%',
                      letterSpacing: '0.02cm'
                    }}
                  >
                    <Text style={{ fontStyle: 'italic', letterSpacing: '0.02cm' }}>
                      {product.name}
                    </Text>
                    ,<Text> {product.year}</Text>
                  </Text>
                  <Text
                    style={{
                      maxWidth: '50%',
                      fontSize: '0.423cm',
                      width: '50%',
                      letterSpacing: '0.02cm'
                    }}
                  >
                    <Text style={{ letterSpacing: '0.02cm' }}>
                      Edition: {product.edition}
                    </Text>

                  </Text>
                  <Text
                    style={{
                      width: '50%',
                      fontSize: '0.423cm',
                      letterSpacing: '0.02cm'
                    }}
                  >
                    {technique}
                  </Text>
                  <Text style={{ letterSpacing: '0.02cm' }}>{template}</Text>
                  <Text
                    style={{
                      fontSize: '0.423cm',
                    }}
                  >
                    {template === 'SCULPTURE'
                      ? `${product.width ? `${product?.width?.toFixed(2)} x` : null
                      } ${product.height ? `${product?.height?.toFixed(2)} x` : null
                      } ${product.length
                        ? `${product?.length?.toFixed(2)} cm`
                        : null
                      } ${product.width
                        ? `(${(product?.width * 0.393701).toFixed(2)} x`
                        : null
                      } ${product.height
                        ? `${(product?.height * 0.393701).toFixed(2)} x`
                        : null
                      } ${product.length
                        ? `${(product?.length * 0.393701).toFixed(2)} in.`
                        : null
                      })`
                      : `${product.height ? `${product?.height?.toFixed(2)} x` : null
                      } ${product.length
                        ? `${product?.length?.toFixed(2)} cm`
                        : null
                      } (${product.height
                        ? `${(product?.height * 0.393701).toFixed(2)} x`
                        : null
                      } ${product.length
                        ? `${(product?.length * 0.393701).toFixed(2)} in.`
                        : null
                      })`}
                  </Text>
                  <Text
                    style={{
                      maxWidth: '50%',
                      fontSize: '0.423cm',
                      letterSpacing: '0.02cm'
                    }}
                  >
                    HSCODE: {product?.hs_code}
                  </Text>
                  {product.orderitem_discount && product.orderitem_discount !== "0.00" && !voucher_code ? (
                    <View
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingRight: '0.212cm'
                      }}
                    >
                      <Text
                        style={{
                          maxWidth: '50%',
                          fontSize: '0.423cm',
                          letterSpacing: '0.02cm'
                        }}
                      >
                        Product discount
                      </Text>
                      <Text
                        style={{
                          maxWidth: '50%',
                          fontSize: '0.423cm',
                          letterSpacing: '0.02cm'
                        }}
                      >
                        {`- ${product.orderitem_discount}${product.orderitem_discount.toString().includes('.')
                          ? ''
                          : '.00'
                          } ${currency}`}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View> : null}

            </View>
          ))
        }
        <View
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.339cm',
            marginBottom: '0.127cm'
          }}
        ></View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: '0.212cm',
            paddingLeft: '0.127cm',
            paddingBottom: '0.212cm'
          }}
        >
          <Text
            style={{
              fontSize: '0.423cm',
              letterSpacing: '0.02cm'
            }}
          >
            Products Subtotal:
          </Text>
          <Text
            style={{
              fontSize: '0.423cm',
              letterSpacing: '0.02cm'
            }}
          >{`${cartTotal} ${currency}`}</Text>
        </View>
        <View
          style={{
            width: '100%',
            height: '0.1vw',
            backgroundColor: 'black',
            marginTop: '0.127cm',
            marginBottom: '0.127cm'
          }}
        ></View>

        {
          type_of_order === 'O-D' ? (
            <>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingRight: '0.212cm',
                  paddingLeft: '0.127cm',
                  paddingBottom: '0.212cm'
                }}
              >
                <Text
                  style={{
                    fontSize: '0.423cm',
                    letterSpacing: '0.02cm'
                  }}
                >
                  Shipping:
                </Text>
                <Text
                  style={{
                    fontSize: '0.423cm',
                    letterSpacing: '0.02cm'
                  }}
                >{`${shippxng} ${currency}`}</Text>
              </View>
              <View
                style={{
                  width: '100%',
                  height: '0.1vw',
                  backgroundColor: 'black',
                  marginTop: '0.127cm',
                  marginBottom: '0.127cm'
                }}
              ></View>
              {insuranceFee && (
                <>
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '0.212cm 0.212cm 0.127cm 0.127cm'
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '0.423cm',
                        letterSpacing: '0.02cm'
                      }}
                    >
                      Insurance Fee:
                    </Text>
                    <Text
                      style={{
                        fontSize: '0.423cm',
                        letterSpacing: '0.02cm'
                      }}
                    >{`${insuranceFee} ${currency}`}</Text>
                  </View>
                  {
                    <View
                      style={{
                        width: '100%',
                        height: '0.1vw',
                        backgroundColor: 'black',
                        marginTop: '0.127cm',
                        marginBottom: '0.127cm'
                      }}
                    ></View>
                  }
                </>
              )}
            </>
          ) : null
        }
        {
          voucher_code && discount ? (
            <>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingRight: '0.212cm',
                  paddingLeft: '0.127cm',
                  paddingBottom: '0.212cm'
                }}
              >
                <Text
                  style={{
                    fontSize: '0.423cm',
                    letterSpacing: '0.02cm'
                  }}
                >
                  Voucher discount:
                </Text>
                <Text
                  style={{
                    fontSize: '0.423cm',
                    letterSpacing: '0.02cm'
                  }}
                >
                  {`-${discount}${discount.toString().includes('.') ? '' : '.00'
                    } ${currency}`}
                </Text>
              </View>

              <View
                style={{
                  width: '100%',
                  height: '0.1vw',
                  backgroundColor: 'black',
                  marginTop: '0.127cm',
                  marginBottom: '0.127cm'
                }}
              ></View>
            </>
          ) : null
        }
        {
          !voucher_code && discount && discount !== "0.00" ? (
            <>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingRight: '0.212cm',
                  paddingLeft: '0.127cm',
                  paddingBottom: '0.212cm'
                }}
              >
                <Text
                  style={{
                    fontSize: '0.423cm',
                    letterSpacing: '0.02cm'
                  }}
                >
                  Total discount:
                </Text>
                <Text
                  style={{
                    fontSize: '0.423cm',
                    letterSpacing: '0.02cm'
                  }}
                >
                  {`-${discount} ${currency}`}
                </Text>
              </View>

              <View
                style={{
                  width: '100%',
                  height: '0.1vw',
                  backgroundColor: 'black',
                  marginTop: '0.127cm',
                  marginBottom: '0.127cm'
                }}
              ></View>
            </>
          ) : null
        }
        {
          additional_cost &&
          additional_cost.map((cost, idx) => (
            <>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingRight: '0.212cm',
                  paddingLeft: '0.127cm',
                  paddingBottom: '0.212cm'
                }}
              >
                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '0.423cm',
                      letterSpacing: '0.02cm'
                    }}
                  >
                    {cost.title}:
                  </Text>
                  <Text
                    style={{
                      fontSize: '0.423cm',
                      letterSpacing: '0.02cm'
                    }}
                  >
                    {`${cost.cost.toFixed(2)} ${currency}`}
                  </Text>
                </View>
                <Text
                  style={{
                    fontSize: '0.423cm',
                    letterSpacing: '0.02cm',
                    width: '50%',
                  }}
                >
                  {cost.description}
                </Text>
              </View>

              <View
                style={{
                  width: '100%',
                  height: '0.1vw',
                  backgroundColor: 'black',
                  marginTop: '0.127cm',
                  marginBottom: '0.127cm'
                }}
              ></View>
            </>
          ))
        }
        {
          vat !== '0.00' ? (
            <>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingRight: '0.212cm',
                  paddingLeft: '0.127cm',
                  paddingBottom: '0.212cm'
                }}
              >
                <Text
                  style={{
                    fontSize: '0.423cm',
                    letterSpacing: '0.02cm'
                  }}
                >
                  VAT:
                </Text>
                <Text
                  style={{
                    fontSize: '0.423cm',
                    letterSpacing: '0.02cm'
                  }}
                >{`${vat} ${currency}`}</Text>
              </View>
              <View
                style={{
                  width: '100%',
                  height: '0.1vw',
                  backgroundColor: 'black',
                  marginTop: '0.127cm',
                  marginBottom: '0.127cm'
                }}
              ></View>
            </>
          ) : null
        }

        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: '0.212cm',
            paddingLeft: '0.127cm',
            paddingBottom: '0.212cm'
          }}
        >
          <Text
            style={{
              fontSize: '0.423cm',
              letterSpacing: '0.02cm'
            }}
          >
            Total:
          </Text>
          <Text
            style={{
              fontSize: '0.423cm',
              letterSpacing: '0.02cm'
            }}
          >{`${total} ${currency}`}</Text>
        </View>
        {
          order_notes ? <View wrap={true}
            style={{
              marginTop: '1cm',
              paddingLeft: '0.127cm', width: "100%", display: "flex", flexWrap: "wrap"
            }}
          >
            <Text style={{ fontSize: '8.3pt', letterSpacing: '0.02cm', display: "flex", width: "100%" }}>
              Order notes: {order_notes}
            </Text>
          </View> : null
        }
        <View
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            color: '#fe309c',
            marginTop: "2cm"

          }}
        >
          <Text
            style={{
              fontSize: '0.423cm',
              marginBottom: '0.846cm',
              letterSpacing: '0.02cm'
            }}
          >
            Thank you
          </Text>
        </View>
      </View >
    );
  };
  return (
    <Document>
      {products && (
        <Page wrap>
          <View style={{ position: 'relative' }}>
            <View
              style={{
                height: '7.5vh',
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '30%',
                alignItems: 'center'
              }}
            >
              <Text
                style={{
                  letterSpacing: '0.06cm',
                  color: '#222',
                  fontSize: '8.3pt',
                  textTransform: 'uppercase',
                  textDecoration: 'none',
                  width: '375pt',
                  textAlign: 'center',
                }}
              >
                WENG CONTEMPORARY.COM
              </Text>
              <View style={{
                display: "flex",
                width: "10cm",
              }}>
                <Text
                  style={{
                    letterSpacing: '0.06cm',
                    color: '#222',
                    fontSize: '8.3pt',
                    textTransform: 'uppercase',
                    textDecoration: 'none',
                    width: '100%',
                    textAlign: 'center',
                    paddingTop: '3pt',
                    marginLeft: '-5pt'
                  }}
                >
                  Invoice Nr. {order_no}
                </Text>
                <Text
                  style={{
                    letterSpacing: '0.06cm',
                    color: '#222',
                    fontSize: '8.3pt',
                    textTransform: 'uppercase',
                    textDecoration: 'none',
                    width: '100%',
                    textAlign: 'center',
                    paddingTop: '3pt',
                    marginLeft: '-5pt'
                  }}
                >
                  {reference}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              {left()}
              <View
                style={{
                  width: '0.1vw',
                  height: '100%',
                  backgroundColor: 'black'
                }}
              ></View>
              {right()}
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
}

Receipt.defaultProps = {
  disabled: false
};
