import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export enum EFilters {
  PRODUCTS = 'products',
  INGREDIENTS = 'ingredients'
}

export enum ETypesartist {
  FETCH = 'ARTIST/FETCH',
  SUCCESS = 'ARTIST/SUCCESS',
  FAILURE = 'ARTIST/FAILURE',
  RESET = 'ARTIST/RESET'
}

export interface Artist {
  name: string;
  short_name: string;
  image: string;
  short_description: string;
  long_description: string;
  slug: string;
  firstname: boolean;
  artist_active: boolean;
  tag: number;
  quote: string;
  id: number;
  surname_orderonly: string;
}

export interface IartistState {
  fetch?: boolean;
  artists: Artist[];
}

export interface IFetchAction extends IBaseAction<ETypesartist, null> {
  type: ETypesartist.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesartist, IartistState> {
  type: ETypesartist.SUCCESS;
  payload: IartistState;
}

export interface IFailureAction extends IBaseAction<ETypesartist, string> {
  type: ETypesartist.FAILURE;
  payload: string;
}

export interface IResetAction extends IBaseAction<ETypesartist, null> {
  type: ETypesartist.RESET;
}

export type IManagerAction =
  | IFetchAction
  | ISuccessAction
  //   | ICreateAction
  //   | IUpdateAction
  //   | IDeleteAction
  | IFailureAction
  | IResetAction;

export type GetState = () => any;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action: IManagerAction | ThunkAction | Array<IManagerAction> | IAlertAction
) => any;
