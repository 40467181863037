import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { Container, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { fetchProductById } from 'redux/ducks/stock';
import { editOrder } from 'redux/ducks/history';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'interfaces/store';
import { IKitchen, IOrderItems } from 'interfaces/kitchen';

// import { sendAlert } from 'redux/ducks/alert';
// import { EAlertVariant } from 'interfaces/alert';

// import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
  onClose: () => void;
  open: boolean;
  order: IKitchen;
  orderItem: IOrderItems;
}

export default function ModalChooseProductEdition(props: IProps) {
  const { onClose, open, order, orderItem } = props;
  const { stock } = useSelector<IStore, IStore>((state) => state);
  const dispatch = useDispatch();
  const [editions, setEditions] = useState<any>();
  const [selectedEditionId, setSelectedEditionId] = useState<number>();

  useEffect(() => {
    if (orderItem) {
      dispatch(fetchProductById(orderItem?.product?.product_id));
      setSelectedEditionId(orderItem.edition.id);
    }
  }, [orderItem]);

  useEffect(() => {
    if (stock) {
      if (stock.products?.length > 0) {
        for (const [key, value] of Object.entries(stock.products)) {
          if (key === 'editions') {
            setEditions(value);
          }
        }
      }
    }
  }, [stock]);

  const typeOfOrder = () => {
    switch (order.type_of_order) {
      case 'O-C':
        return 'Collection';
      case 'O-D':
        return 'Delivery';
      default:
        return 'Delivery';
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = (event.target as HTMLInputElement).value;
    setSelectedEditionId(parseInt(id));

    dispatch(editOrder(updatedOrderData(id), order.order_id));
  };
  const updatedOrderData = (id: string) => {
    const selectedEdition = editions.find(
      (edition) => edition.id.toString() === id
    );

    const orderProducts = [];
    order.items.map((item) => {
      orderProducts.push({
        product_id: item.product.product_id,
        edition_id: selectedEdition.id,
        quantity: 1
      });
    });

    return {
      infos: {
        type_of_order: typeOfOrder(),
        insurance_requested: order.insurance_requested
      },
      products: orderProducts
    };
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={() => onClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            {`Sold edition of order ${order?.order_code}`}
          </div>
        </DialogTitle>
        <Container
          maxWidth="md"
          style={{
            paddingLeft: '37px',
            paddingTop: '20px'
          }}
        >
          {editions && (
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Order Item: {orderItem?.product?.name}
              </FormLabel>
              <RadioGroup
                aria-label="edition"
                name="edition"
                value={selectedEditionId}
                onChange={handleChange}
              >
                {editions.map((edition) => (
                  <FormControlLabel
                    value={edition.id}
                    disabled={edition.stock_status === 'Sold'}
                    control={<Radio />}
                    label={`${edition.edition_number} - ${edition.name} - ${edition.stock_status}`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </Container>
      </div>
    </Modal>
  );
}
