import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export enum EFilters {
  PRODUCTS = 'products',
  INGREDIENTS = 'ingredients'
}

export enum ETypesStock {
  FETCH = 'STOCK/FETCH',
  SUCCESS = 'STOCK/SUCCESS',
  CREATE_PRODUCT = 'STOCK/CREATE_PRODUCT',
  UPDATE_PRODUCT = 'STOCK/UPDATE_PRODUCT',
  DELETE_PRODUCT = 'STOCK/DELETE_PRODUCT',
  FAILURE = 'STOCK/FAILURE',
  RESET = 'STOCK/RESET'
}

export interface IStockState {
  fetch?: boolean;
  ingredients: Ingredients[];
  products: Product[];
  productsForStockPage: Product[];
  hasMore: boolean;
}

export interface Ingredients {
  name: string;
  ingredient_id: number;
  measure: string;
  quantity: number;
  cost: number;
}

export interface Tags {
  id: number;
  name: string;
  background: string;
  color: string;
}

export interface Editions {
  id: number;
  name: string;
  edition_number: string;
  cost: number;
  slug: string;
  stock_status: string;
  base_price: number;
  tax_status: string;
  condition_notes: string;
  current_location: string;
  location_details: string;
  location_date: string;
  location_notes: string;
  location_history: string;
  store_owner: { id: number; name: string };
  consignor_owner: { id: number; name: string };
  third_party_owner: number;
  last_examined_by: { id: number; username: string; name: string };
  active: boolean;
  internal_condition_notes: string;
  product: number;
  location_history_logs: location_history_logs[];
  store_owner_id: number;
  consignor_owner_id: number;
  is_old: boolean;
  consignment_date: string;
  return_consignment_date: string;
  consignment_terms: string;
  third_party_base_price: number;
  consignment_documents: string;
  certificate: string;
  consignment_notes: string;
  consigned_to: { id: number; name: string };
  acquired_from: string;
  acquisition_date: string;
  price_eur: number;
  price_gbp: number;
  price_chf: number;
}

export interface location_history_logs {
  what: string;
  before: string;
  after: string;
  created_at: string;
}

export interface Product {
  active: boolean;
  bad_stock: number;
  content_sheet: File;
  index: string;
  page_tag: string;
  category: string;
  category_2: string;
  quotation: string;
  p_quotation_signature: string;
  cost: number;
  define_category: string;
  description: string;
  extras: any[];
  image: string | null;
  base_price: number;
  dimensions_additional: {
    artwork: string;
    length: number;
    width: number;
    height: number;
    weight: number;
    id: number;
  }[];
  ingredients: Ingredients[];
  product_images: { id: number; image: string; sort_key: number }[];
  name: string;
  year: string;
  hs_code: string;
  price: number;
  width: number;
  height: number;
  length: number;
  weight: number;
  product_id: number;
  signature_type: string;
  master_medium: string;
  edition_size: string;
  sold: number;
  reserved: number;
  allergy_notes: string;
  stock: number;
  video: File;
  preview_video: File;
  video_description: string;
  description_video: string;
  youtube_video: string;
  time: number;
  technique: { id: number; name: string };
  template: string;
  slug: string;
  editions: Editions[];
  artist: {
    id: number;
    name: string;
  };
  next_days: [
    {
      date: string;
      weekday: string;
      value: number;
    }
  ];
  day_history?: [
    {
      quantity: number;
      created_at: string;
      description: string;
      item_id: number;
      type: string;
      before: number;
      after: number;
    }
  ];
  colli_number: number;
}

export interface IFetchAction extends IBaseAction<ETypesStock, null> {
  type: ETypesStock.FETCH;
}

export interface ISuccessAction extends IBaseAction<ETypesStock, IStockState> {
  type: ETypesStock.SUCCESS;
  payload: IStockState;
}

export interface ICreateAction extends IBaseAction<ETypesStock, Product> {
  type: ETypesStock.CREATE_PRODUCT;
  payload: Product;
}

export interface IUpdateAction extends IBaseAction<ETypesStock, Product> {
  type: ETypesStock.UPDATE_PRODUCT;
  payload: Product;
}

export interface IDeleteAction extends IBaseAction<ETypesStock, number> {
  type: ETypesStock.DELETE_PRODUCT;
  payload: number;
}

export interface IFailureAction extends IBaseAction<ETypesStock, string> {
  type: ETypesStock.FAILURE;
  payload: string;
}

export interface IResetAction extends IBaseAction<ETypesStock, null> {
  type: ETypesStock.RESET;
}

export type IManagerAction =
  | IFetchAction
  | ISuccessAction
  | ICreateAction
  | IUpdateAction
  | IDeleteAction
  | IFailureAction
  | IResetAction;

export type GetState = () => any;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action: IManagerAction | ThunkAction | Array<IManagerAction> | IAlertAction
) => any;
