import React, { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  withStyles,
  Grid,
  TextField
} from '@material-ui/core';
import COUNTRIES from 'components/Global/Countries';
import Autocomplete from '@material-ui/lab/Autocomplete';

export interface SimpleDialogProps {
  open: boolean;
  onClose: (address: any) => void;
  addressType: string;
}

const GlobalCss = withStyles({
  '@global': {
    '.MuiDialog-paperWidthSm': {
      minWidth: '30vw'
    }
  }
})(() => null);

export default function ModalCreateClient(props: SimpleDialogProps) {
  const { onClose, open, addressType } = props;
  const [billing, setBilling] = useState({
    city: '',
    country: { code: '', name: '' },
    postal_code: '',
    street: '',
    street_number: '',
    address_id: 0,
    created: true
  });
  const [billingError, setBillingError] = useState({
    city: '',
    country: '',
    postal_code: '',
    street: '',
    street_number: ''
  });
  const [inputValueCountries, setInputValueCountries] = useState('');

  const handleClose = () => {
    setBilling({
      city: '',
      country: { code: '', name: '' },
      postal_code: '',
      street: '',
      street_number: '',
      address_id: 0,
      created: true
    });
    onClose(billing);
  };

  const checkForm = () => {
    var entered = false;
    for (let index = 0; index < Object.keys(billingError).length; index++) {
      const element = Object.keys(billingError)[index];
      if (billing[element] === '') {
        entered = true;
        setBillingError({ ...billingError, city: 'This field is mandatory' });
      }
    }

    return entered;
  };

  const submitCreate = async () => {
    if (!checkForm()) {
      handleClose();
    }
  };

  return (
    <div>
      <GlobalCss />
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        style={{ minWidth: '30vw' }}
      >
        <DialogTitle id="simple-dialog-title">
          Create a new {addressType} address
        </DialogTitle>
        <Grid container style={{ padding: '10px' }}>
          <Grid
            style={{
              marginBottom: '1vw',
              marginRight: '1rem'
            }}
            lg={5}
          >
            <FormControl
              style={{
                marginTop: '0px'
              }}
              margin={'normal'}
              fullWidth
              error={billingError.street !== ''}
              required
            >
              <InputLabel htmlFor="name-input">Street</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={billing.street}
                onChange={(e) => {
                  setBilling({ ...billing, street: e.target.value });
                }}
              />
              <FormHelperText>{billingError.street}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid lg={5}>
            <FormControl
              style={{
                marginTop: '0px',
                marginRight: '5px'
              }}
              margin={'normal'}
              fullWidth
              error={billingError.city !== ''}
              required
            >
              <InputLabel htmlFor="street-input">City</InputLabel>
              <Input
                id="street-input"
                type="text"
                value={billing.city}
                onChange={(e) => {
                  setBilling({ ...billing, city: e.target.value });
                }}
              />
              <FormHelperText>{billingError.city}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid
            style={{
              marginBottom: '1vw',
              marginRight: '1rem'
            }}
            lg={5}
          >
            <FormControl
              style={{
                marginTop: '0px'
              }}
              margin={'normal'}
              fullWidth
              error={billingError.street_number !== ''}
              required
            >
              <InputLabel htmlFor="street-input">Door number</InputLabel>
              <Input
                id="street-input"
                type="text"
                value={billing.street_number}
                onChange={(e) => {
                  setBilling({
                    ...billing,
                    street_number: e.target.value
                  });
                }}
              />
              <FormHelperText>{billingError.street_number}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid lg={5}>
            <FormControl
              style={{
                marginTop: '0px'
              }}
              margin={'normal'}
              fullWidth
              error={billingError.postal_code !== ''}
              required
            >
              <InputLabel htmlFor="street-input">Postal Code</InputLabel>
              <Input
                id="postal-input"
                type="text"
                value={billing.postal_code}
                onChange={(e) => {
                  setBilling({
                    ...billing,
                    postal_code: e.target.value
                  });
                }}
              />
              <FormHelperText>{billingError.postal_code}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid lg={5}>
            <FormControl
              style={{
                marginTop: '-15px'
              }}
              margin={'normal'}
              fullWidth
              error={billingError.country !== ''}
              required
            >
              <Autocomplete
                value={billing.country}
                onChange={(event: any, newValue: any | null) => {
                  setBilling({ ...billing, country: newValue });
                }}
                inputValue={inputValueCountries}
                onInputChange={(event, newInputValue) => {
                  setInputValueCountries(newInputValue);
                }}
                id="country-autocomplete"
                options={COUNTRIES}
                getOptionLabel={(option) => {
                  if (option.name !== undefined) {
                    return option.name;
                  } else return '';
                }}
                renderOption={(option) =>
                  option.name !== undefined ? option.name : ''
                }
                loadingText={'Loading countries'}
                renderInput={(params) => (
                  <TextField {...params} label="Country" />
                )}
              />

              <FormHelperText>{billingError.country}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={submitCreate} color="primary">
            Create
          </Button>
          <Button onClick={() => onClose('canceled')}> Cancel </Button>
        </div>
      </Dialog>
    </div>
  );
}
