import { useStyles } from './styles';
// import { IKitchen } from 'interfaces/kitchen';
import Animate from 'components/Common/Animate';
import { Grid, Button, Typography } from '@material-ui/core';
import Timeline from './Timeline';
import { EBalconyStatus } from 'enums/order';
import React, { useState } from 'react';
import ArrowUpIcon from '@material-ui/icons/ArrowUpwardOutlined';
import ArrowDownIcon from '@material-ui/icons/ArrowDownwardOutlined';
import { IProduction } from 'interfaces/production';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  orders: IProduction[];
}

export default function Production(props: IProps): JSX.Element {
  const { orders } = props;
  const [orderID, setOrderID] = useState(0);
  const [visible, setVisible] = useState(false);
  const classes = useStyles();

  function toggleOrder(id: number): void {
    setOrderID(id);
    if (visible && id === orderID) {
      setVisible(!visible);
    } else {
      setVisible(true);
    }
  }

  return (
    <>
      {orders.map((order) => (
        <Animate key={uuidv4()}>
          <Grid container>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid container justifyContent="flex-start">
                <Button
                  onClick={() => toggleOrder(order.id)}
                  style={{ marginTop: -8 }}
                >
                  {visible && orderID === order.id ? (
                    <ArrowUpIcon />
                  ) : (
                    <ArrowDownIcon />
                  )}
                </Button>
                <Typography
                  variant="h5"
                  onClick={() => toggleOrder(order.id)}
                  style={{ marginBottom: 10, cursor: 'pointer' }}
                >
                  {order.order_code}
                </Typography>
              </Grid>
            </Grid>

            {visible && orderID === order.id && (
              <Animate>
                <Grid
                  container
                  justifyContent="center"
                  className={`${classes.card}`}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    className={classes.info}
                  >
                    <Grid container direction="column">
                      <Typography
                        variant="overline"
                        style={{ fontWeight: 'bold' }}
                      >
                        ORDER SUMMARY
                      </Typography>
                      <Typography variant="overline">
                        {order.order_type === 'Product'
                          ? `${order.quantity} x ${order.item.name}`
                          : `${order.quantity}${order.measure || 'x'} ${
                              order.item
                            }`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    className={classes.panel}
                  >
                    <Timeline
                      status={EBalconyStatus.TODO}
                      date={order.date_ordered}
                      color={classes.todoPanel}
                    />
                    <Timeline
                      status={EBalconyStatus.DOING}
                      date={order.doing_at}
                      color={classes.doingPanel}
                    />
                    <Timeline
                      status={EBalconyStatus.DONE}
                      date={order.done_at}
                      color={classes.donePanel}
                    />
                    <Timeline
                      status={EBalconyStatus.COMPLETED}
                      date={order.finished_at}
                      color={classes.completedPanel}
                    />
                  </Grid>
                </Grid>
              </Animate>
            )}
          </Grid>
        </Animate>
      ))}
    </>
  );
}
