import {
  IProfileState,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IProfileAction,
  Dispatch,
  ThunkAction,
  ETypesProfile,
  IFirstLoadAction,
  GetState,
  User
} from 'interfaces/profile';

import { EAlertVariant } from 'interfaces/alert';

import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Profile State. */
const initialState: IProfileState = {
  fetch: false,
  count: 0,
  results: [],
  next: '',
  previous: '',
  error: ''
};

/* Profile Reducer. */
export default (
  state: IProfileState = initialState,
  action: IProfileAction
): IProfileState => {
  console.log(state);
  switch (action.type) {
    case ETypesProfile.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesProfile.FIRSTLOAD:
      return {
        ...state,
        fetch: false,
        count: action.payload.count,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesProfile.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesProfile.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* Profile Action Creators Functions. */
export const fetchProfile = (): IFetchAction => ({
  type: ETypesProfile.FETCH
});

export const successProfile = (payload: IProfileState): ISuccessAction => ({
  type: ETypesProfile.SUCCESS,
  payload
});

export const firstLoadProfile = (payload: IProfileState): IFirstLoadAction => ({
  type: ETypesProfile.FIRSTLOAD,
  payload
});

export const failureProfile = (payload: string): IFailureAction => ({
  type: ETypesProfile.FAILURE,
  payload
});

/* Profile Side Effects Functions. */
export const getProfile =
  (): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<User> => {
    try {
      const { profile } = getState();
      console.log(getState());
      let request_url;
      dispatch(fetchProfile());
      request_url = `/users/api/clients/me/`;
      const response = await fetch({
        method: EMethod.GET,
        url: request_url
      });
      console.log(response);
      let results: User;
      results = response.user;
      dispatch(successProfile({ ...profile, results: results }));
      return results;
    } catch (error) {
      console.log(error);
      dispatch(failureProfile(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return error;
    }
  };
export const editProfile =
  (data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<User> => {
    try {
      const { profile } = getState();
      console.log(getState());
      let request_url;
      dispatch(fetchProfile());
      request_url = `/users/api/clients/me/`;
      const response = await fetch({
        method: EMethod.PATCH,
        url: request_url,
        data
      });
      console.log(response);
      let results: User;
      results = response.user;
      dispatch(sendAlert('Updated Successfuly', EAlertVariant.SUCCESS));
      dispatch(successProfile({ ...profile, results: results }));
      return results;
    } catch (error) {
      console.log(error);
      dispatch(failureProfile(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return error;
    }
  };
export const editBillingAddress =
  (data: {} = undefined, id: number): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let request_url;
      dispatch(fetchProfile());
      request_url = `/users/api/address/${id}/`;
      const response = await fetch({
        method: EMethod.PATCH,
        url: request_url,
        data
      });
      console.log(response);

      dispatch(sendAlert('Updated Successfuly', EAlertVariant.SUCCESS));
    } catch (error) {
      console.log(error);
      dispatch(failureProfile(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return error;
    }
  };
export const addCardAPI =
  (data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      console.log(getState());
      let request_url;
      request_url = `/payment/api/credit_card/`;
      const response = await fetch({
        method: EMethod.POST,
        url: request_url,
        data
      });
      console.log(response);
      dispatch(sendAlert('Updated Successfuly', EAlertVariant.SUCCESS));
    } catch (error) {
      console.log(error);
      dispatch(failureProfile(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return error;
    }
  };
export const deleteCardAPI =
  (id: number = undefined): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      console.log(getState());
      let request_url;
      dispatch(fetchProfile());
      request_url = `/payment/api/credit_card/${id}/`;
      const response = await fetch({
        method: EMethod.DELETE,
        url: request_url
      });
      console.log(response);
      dispatch(sendAlert('Updated Successfuly', EAlertVariant.SUCCESS));
    } catch (error) {
      console.log(error);
      dispatch(failureProfile(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return error;
    }
  };
