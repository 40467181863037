/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/interface-name-prefix */
import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  FormControl,
  //   FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
  TextField,
  Switch
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import { fetchProductsGeneral } from 'redux/ducks/stock';
import IStore from 'interfaces/store';
import { EAlertVariant } from 'interfaces/alert';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetch } from './../../utils/request';
import { createOrder } from 'redux/ducks/history';
import { sendAlert } from '../../redux/ducks/alert';

import ModalCreateClient from './ModalCreateClient';

import COUNTRIES from 'components/Global/Countries';

import moment from 'moment';
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  initialDate: Date | number;
  finalDate: Date;
}

export default function ModalCreateInvoice(props: SimpleDialogProps) {
  const { onClose, open, initialDate, finalDate } = props;
  const dispatch = useDispatch();
  const { stock } = useSelector<IStore, IStore>((state) => state);
  const [allEditions, setAllEditions] = useState([]);
  const [from, setFrom] = useState('');
  // const [orderId, setOrderId] = useState('');
  const [client, setClient] = useState({
    addresses: [],
    billing_address: {},
    cards: '',
    email: '',
    first_name: '',
    id: 153,
    last_name: '',
    profile: {
      profile_id: 0
    },
    subscriptions: [],
    username: null
  });
  const [clientError, setClientError] = useState('');
  const [currency, setCurrency] = useState('USD');
  // const [deliveryAddress, setDeliveryAddress] = useState('');
  // const [deliveryAddressError, setDeliveryAddressError] = useState('');
  const [postalCode, setpostalCode] = useState('');
  const [postalCodeError, setpostalCodeError] = useState('');
  const [street, setstreet] = useState('');
  const [streetError, setstreetError] = useState('');
  const [streetNumber, setstreetNumber] = useState('');
  const [streetNumberError, setstreetNumberError] = useState('');
  const [city, setcity] = useState('');
  const [cityError, setcityError] = useState('');
  const [country, setcountry] = useState<any>('');
  const [countryError, setcountryError] = useState('');
  const [billingPostalCode, setBillingpostalCode] = useState('');
  const [billingPostalCodeError, setBillingpostalCodeError] = useState('');
  const [billingStreet, setBillingStreet] = useState('');
  const [billingStreetError, setBillingstreetError] = useState('');
  const [billingStreetNumber, setBillingstreetNumber] = useState('');
  const [billingStreetNumberError, setBillingstreetNumberError] = useState('');
  const [billingCity, setBillingcity] = useState('');
  const [billingCityError, setBillingcityError] = useState('');
  const [billingCountry, setBillingcountry] = useState<any>('');
  const [billingCountryError, setBillingcountryError] = useState('');
  const [isBillingChanged, setIsBillingChanged] = useState(false);
  // const [billingID, setBillingID] = useState(null);

  const [inputValue, setInputValue] = useState(['']);
  const [inputValueClients, setInputValueClients] = useState('');
  const [inputValueCountries, setInputValueCountries] = useState('');
  const [billingInputValueCountries, setBillingInputValueCountries] =
    useState('');
  const [prohibitedEmail, setProhibitedEmail] = useState(false);
  const [product, setProduct] = useState([
    {
      id: 0,
      name: '',
      productId: 0,
      productName: '',
      value: 0,
      extras: []
    }
  ]);
  const [additionalCosts, setAdditionalCosts] = useState([]);
  const [extras, setExtras] = useState([[]]);
  const [extrasQuantity, setExtrasQuantity] = useState([[]]);
  const [productError, setProductError] = useState(['']);
  const [productValue, setProductValue] = useState([0]);
  const [productInventoryValue, setProductInventoryValue] = useState([0]);
  const [productInventoryValueChanged, setProductInventoryValueChanged] =
    useState([false]);
  const [productDiscount, setProductDiscount] = useState([0]);
  const [deliveryValue, setDeliveryValue] = useState(0);
  const [paymentType, setPaymentType] = useState('');
  const [paymentTypeError, setPaymentTypeError] = useState('');
  const [paymentStatusError, setPaymentStatusError] = useState('');

  const [deliveryType, setDeliveryType] = useState('');
  const [deliveryTypeError, setDeliveryTypeError] = useState('');
  const [invoiceReference, setInvoiceReference] = useState('');
  const [discount, setDiscount] = useState(0);
  const [orderNotes, setOrderNotes] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [productObservations, setproductObservations] = useState(['']);
  const [addressChecked, setAddressChecked] = useState(false);
  const [freeShipping, setFreeShipping] = useState(false)
  const [billingAddress, setBillingAddress] = useState({
    address: '',
    address_id: 1,
    city: '',
    complement: '',
    country: '',
    name: '',
    postal_code: '',
    state: '',
    street: '',
    street_number: '',
    update_address: ''
  });
  const [clients, setClients] = useState([]);
  const [insuranceRequest, setInsuranceRequest] = useState(false);
  const [insuranceFee, setInsuranceFee] = useState(0);
  const [preferredDate, setPreferredDate] = useState('');
  const [preferredTime, setPreferredTime] = useState('');
  const totalProductsDiscounts = () => {
    var totalDiscount = 0;
    productDiscount.forEach((el) => {
      totalDiscount += el;
    });
    return totalDiscount;
  };
  useEffect(() => {
    const productsTotal = productInventoryValue.reduce((a, b) => a + b);
    setInsuranceFee((productsTotal - totalProductsDiscounts()) * 0.01);
  }, [productInventoryValue, productDiscount]);
  const _getClients = async () => {
    try {
      let response;
      const results = [];
      let url = '/users/api/clients/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setClients(response.results);
    } catch (error) {
      console.log('error fetching clients');
    }
  };
  useEffect(() => {
    if (allEditions.length === 0) {
      dispatch(fetchProductsGeneral());
    }
    setFrom('WENG CONTEMPORARY, SWITZERLAND, ZURICH');
    // setOrderId('');
    if (clients.length === 0) {
      _getClients();
    }
    if (stock && stock.products && stock.products.length > 0) {
      const y = [];
      stock.products.forEach((element) => {
        element.editions.forEach((edition) => {
          const x = {
            name: edition.name,
            id: edition.id,
            productId: edition.product,
            productName: element.name,
            value: edition.base_price,
            extras: element.extras,
            number: edition.edition_number,
            stock_status: edition.stock_status,
            price_eur: edition.price_eur,
            price_gbp: edition.price_gbp,
            price_chf: edition.price_chf
          };
          y.push(x);
        });
      });
      setAllEditions(y);
    }
  }, [stock.products]);
  const checkForm = () => {
    let entered = false;
    if (client === null || (client !== null && client.first_name === '')) {
      setClientError('Please select a client');
      entered = true;
    }
    if (invoiceDate === '') {
      setInvoiceDateError('Please enter the invoice date');
      entered = true;
    }
    if (paymentType === '') {
      setPaymentTypeError('Please enter payment type');
      entered = true;
    }
    if (paymentStatus === '') {
      setPaymentStatusError('Please enter payment status');
      entered = true;
    }
    // if (deliveryAddress === '') {
    //   setDeliveryAddressError('Please enter first line of address');
    //   entered = true;
    // }
    if (deliveryType === 'Delivery') {
      if (postalCode === '') {
        setpostalCodeError('Please enter postal code');
        entered = true;
      }
      if (street === '') {
        setstreetError('Please enter the street');
        entered = true;
      }
      if (streetNumber === '') {
        setstreetNumberError('Please enter door number');
        entered = true;
      }
      if (city === '') {
        setcityError('Please enter city');
        entered = true;
      }

      if (country === '' || country === null) {
        setcountryError('Please enter country');
        entered = true;
      }
    }
    if (billingPostalCode === '') {
      setBillingpostalCodeError('Please enter postal code');
      entered = true;
    }
    if (billingStreet === '') {
      setBillingstreetError('Please enter the street');
      entered = true;
    }
    if (billingStreetNumber === '') {
      setBillingstreetNumberError('Please enter door number');
      entered = true;
    }
    if (billingCity === '') {
      setBillingcityError('Please enter city');
      entered = true;
    }

    if (
      billingCountry === '' ||
      billingCountry?.code === '' ||
      billingCountry === null
    ) {
      setBillingcountryError('Please enter country');
      entered = true;
    }
    product.map((element, index) => {
      if (element === null || (element !== null && element.name === '')) {
        const error = [...productError];
        error[index] = 'Please enter this artwork';
        setProductError(error);
        entered = true;
      }
    });

    if (deliveryType === '') {
      setDeliveryTypeError('Please enter delivery type');
      entered = true;
    }
    return entered;
  };
  const handleSubmit = () => {
    if (!checkForm()) {
      if (deliveryValue === 0) {
        setFreeShipping(true)
      }
      let extrasToBeSubmitted = [];
      let alreadyAddedIngredients = [];
      let rejected = false;
      const productsToBeSubmitted = [];
      product.map((element, index) => {
        if (productInventoryValueChanged[index]) {
          productsToBeSubmitted.push({
            product_id: element.productId,
            edition_id: element.id,
            quantity: 1,
            commment: productObservations[index],
            orderitem_discount: productDiscount[index],
            value: productInventoryValue[index],
            changedPrice: true
          });
        } else {
          productsToBeSubmitted.push({
            product_id: element.productId,
            edition_id: element.id,
            quantity: 1,
            commment: productObservations[index],
            orderitem_discount: productDiscount[index],
            value: productValue[index]
          });
        }
      });
      extras.map((item, index) => {
        item.map((element, index1) => {
          if (element !== '' || element !== null) {
            if (alreadyAddedIngredients.includes(element)) {
              dispatch(
                sendAlert('Cannot have duplicate extras', EAlertVariant.ERROR)
              );
              rejected = true;
            } else {
              extrasToBeSubmitted.push({
                ingredient: parseInt(element),
                quantity:
                  Number.isNaN(parseInt(extrasQuantity[index][index1]))
                    ? 1
                    : parseInt(extrasQuantity[index][index1])
              });
              alreadyAddedIngredients.push(element);
              if (index1 === item.length - 1) {
                productsToBeSubmitted[index].extras = extrasToBeSubmitted;
                extrasToBeSubmitted = [];
                alreadyAddedIngredients = [];
              }
            }
          }
        });
      });
      if (!rejected) {
        var totalOrder = calculateTotalValue();
        if (country?.code === 'CH') {
          totalOrder += totalOrder * 0.077;
        }
        let data = {
          infos: {
            currency: currency,
            type_of_order: deliveryType,
            payment: paymentType !== 'card' ? 'invoice' : paymentType,
            final_comment: orderNotes,
            discount_total_invoice: discount,
            delivery_fee: deliveryValue,
            direct_ecomm: "direct",
            is_paid: paymentStatus === 'paid' ? true : false,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            //total_order: totalOrder,
            store_id: 1,
            invoice_reference: invoiceReference,
            invoice_date: invoiceDate !== '' ? invoiceDate : null,
            send_email: !prohibitedEmail,
            collection_order_date: '',
            collection_order_time: '',
            free_delivery: deliveryValue === 0 ? true : freeShipping,
          },
          address: {
            // address: deliveryAddress,
            street,
            street_number: streetNumber,
            city,
            country: country.code,
            neighborhood: city,
            postal_code: postalCode,
            user_id: client?.id,
            active: true
          },
          neighbourhood: city,

          products: productsToBeSubmitted,
          client: client?.profile?.profile_id,
          type: 'invoice',
          additional_costs: additionalCosts
        };
        if (isBillingChanged) {
          data['billing'] = {
            street: billingStreet,
            street_number: billingStreetNumber,
            city: billingCity,
            country: billingCountry?.code,
            neighborhood: billingCity,
            postal_code: billingPostalCode,
            user_id: client?.id,
            active: true
          };
        }
        if (deliveryType === 'Collection') {
          delete data.address;
          if (preferredDate != '') {
            data.infos.collection_order_date =
              moment(preferredDate).format('YYYY-MM-DD');
          } else {
            delete data.infos.collection_order_date;
          }
          if (preferredTime != '') {
            data.infos.collection_order_time = preferredTime;
          } else {
            delete data.infos.collection_order_time;
          }
        } else {
          delete data.infos.collection_order_date;
          delete data.infos.collection_order_time;
        }
        //   if (country.name === 'Switzerland' || deliveryType === 'Collection') {
        //     data.infos['vat'] = (calculateTotalValue() * 0.077).toFixed(2);
        //   }
        if (insuranceRequest) {
          data.infos['insurance_requested'] = true;
          data.infos['insurance_product_id'] = 1;
          data.infos['insurance_value_cents'] = Math.ceil(insuranceFee);
          data.infos['insurance_premium_type'] = 'yearly';
          data.infos['insurance_premium_amount_cents'] =
            Math.ceil(insuranceFee);
        }
        const _initialDate = initialDate
          ? moment(initialDate).format('YYYY-MM-DD')
          : null;
        const _finalDate = finalDate
          ? moment(finalDate).format('YYYY-MM-DD')
          : null;
        dispatch(
          createOrder(data, {
            starts: _initialDate,
            ends: _finalDate
          })
        ).then(() => {
          handleClose();
        }).catch((e) => {
          console.log("error")
        })
      }
    } else {
      dispatch(sendAlert('Please check the errors below', EAlertVariant.ERROR));
    }
  };
  const handleInsuranceRequest = () => {
    setInsuranceRequest(!insuranceRequest);
  };
  const returnCountry = (code) => {
    var data = {
      code: '',
      name: ''
    };
    COUNTRIES.forEach((country) => {
      if (code === country.code) {
        data = country;
      }
    });
    return data;
  };
  const handleChangeClient = (value: any) => {
    setClient(value);
    /*var billing_address = value.adresses.filter((address) => {
      address.active === true;
    }); */
    if (value !== null && value.billing_address) {
      setBillingAddress(value.billing_address);
      // setBillingID(value.address.address_id);
      setBillingcity(
        value.billing_address.city !== null ? value.billing_address.city : ''
      );
      setBillingpostalCode(
        value.billing_address.postal_code !== null
          ? value.billing_address.postal_code
          : ''
      );

      setBillingcountry(
        value.billing_address.country !== null
          ? returnCountry(value.billing_address.country)
          : ''
      );
      setBillingStreet(
        value.billing_address.street !== null
          ? value.billing_address.street
          : ''
      );
      setBillingstreetNumber(
        value.billing_address.street_number !== null
          ? value.billing_address.street_number
          : ''
      );
      if (addressChecked) {
        setcity(
          value.billing_address.city !== null ? value.billing_address.city : ''
        );
        setpostalCode(
          value.billing_address.postal_code !== null
            ? value.billing_address.postal_code
            : ''
        );
        setcountry(
          value.billing_address.country !== null
            ? value.billing_address.country
            : ''
        );
        setstreet(
          value.billing_address.street !== null
            ? value.billing_address.street
            : ''
        );
        setstreetNumber(
          value.billing_address.street_number !== null
            ? value.billing_address.street_number
            : ''
        );
        // setDeliveryAddressError('');
        setcountryError('');
        setcityError('');
        setpostalCodeError('');
        setstreetError('');
        setstreetNumberError('');
      }
    }
    if (!value?.billing_address) {
      setBillingAddress({
        address: '',
        address_id: 1,
        city: '',
        complement: '',
        country: '',
        name: '',
        postal_code: '',
        state: '',
        street: '',
        street_number: '',
        update_address: ''
      });
    }
    setClientError('');
  };

  const handleCurrency = (value: any) => {
    setCurrency(value);
  };

  // const handleChangeDeliveryAddress = (value: any) => {
  //   setDeliveryAddress(value);
  //   setDeliveryAddressError('');
  // };
  const handleChangePostalCode = (value: any) => {
    setpostalCode(value);
    setpostalCodeError('');
  };
  const handleChangeStreet = (value: any) => {
    setstreet(value);
    setstreetError('');
  };
  const handleChangeStreetNo = (value: any) => {
    setstreetNumber(value);
    setstreetNumberError('');
  };

  const handleChangeCity = (value: any) => {
    setcity(value);
    setcityError('');
  };
  const handleChangeCountry = (value: any) => {
    setcountry(value);
    setcountryError('');
  };

  const handleChangePaymentType = (value: any) => {
    setPaymentType(value);
    setPaymentTypeError('');
  };
  const handleChangeDeliveryType = (value: any) => {
    setDeliveryType(value);
    setDeliveryTypeError('');
  };
  const handleInvoiceReference = (value: any) => {
    setInvoiceReference(value);
  };
  const handleDeliveryValue = (value: any) => {
    setDeliveryValue(value !== '' ? parseInt(value) : value);
  };
  const handlePaymentStatus = (value: any) => {
    setPaymentStatus(value);
  };
  const handleDiscount = (value: any) => {
    setDiscount(value !== '' ? value : 0);
  };
  const handleProductObs = (value: any, index: number) => {
    const x = [...productObservations];
    x[index] = value;
    setproductObservations(x);
  };
  const handleProductDiscount = (value: number, index: number) => {
    const x = [...productDiscount];
    x[index] = value;
    setProductDiscount(x);
  };
  const handleOrderNotes = (value: any) => {
    setOrderNotes(value);
  };
  const handleChangeProduct = (value: any, index: number) => {
    const x = [...product];
    x[index] = value;
    setProduct(x);
    if (value !== null && value.value !== undefined) {
      const y = [...productValue];
      y[index] = value.value;
      const z = [...productInventoryValue];
      switch (currency) {
        case 'EUR':
          z[index] = value.price_eur;
          break;
        case 'USD':
          z[index] = value.value;
          break;
        case 'GBP':
          z[index] = value.price_gbp;
          break;
        case 'CHF':
          z[index] = value.price_chf;
          break;
        default:
          z[index] = value.value;
          break;
      }
      setProductValue(y);
      setProductInventoryValue(z);
    }
    const q = [...extras];
    q[index] = [];
    setExtras(q);
    const z = [...extrasQuantity];
    z[index] = [];
    setExtrasQuantity(z);

    const error = [...productError];
    error[index] = '';
    setProductError(error);
  };
  const handleChangeProductPrice = (value: number, index: number) => {
    const y = [...productInventoryValue];
    const z = [...productInventoryValueChanged];
    y[index] = value;
    z[index] = true;
    setProductInventoryValue(y);
    setProductInventoryValueChanged(z);
  };
  const handleAddProduct = () => {
    const x = [...product];
    x.push({
      id: 0,
      name: '',
      productId: 0,
      productName: '',
      value: 0,
      extras: []
    });
    setProduct(x);
    const y = [...productValue];
    y.push(0);
    setProductValue(y);

    const productsDiscount = [...productDiscount];
    productsDiscount.push(0);
    setProductDiscount(productsDiscount);

    const z = [...productObservations];
    z.push('');
    setproductObservations(z);
    const w = [...extras];
    w.push([]);
    setExtras(w);
    const t = [...extrasQuantity];
    t.push([]);
    setExtrasQuantity(t);
  };

  const handleAddAdditionalCost = () => {
    const costs = [...additionalCosts];
    costs.push({
      title: '',
      cost: 0,
      description: ''
    });
    setAdditionalCosts(costs);
  };

  const handleChangeAdditionalCost = (
    value: any,
    index: number,
    field: string
  ) => {
    const costs = [...additionalCosts];
    if (field === 'title') {
      costs[index].title = value;
    }
    if (field === 'cost') {
      costs[index].cost = value;
    }
    if (field === 'description') {
      costs[index].description = value;
    }
    setAdditionalCosts(costs);
  };

  const removeAdditionalCost = (index: number) => {
    const costs = [...additionalCosts];
    costs.splice(index, 1);
    setAdditionalCosts(costs);
  };

  const removeProducts = (index: number) => {
    const x = [...product];
    x.splice(index, 1);
    setProduct(x);
    const y = [...extras];
    y.splice(index, 1);
    setExtras(y);
    const z = [...extrasQuantity];
    z.splice(index, 1);
    setExtrasQuantity(z);
    const w = [...productObservations];
    w.splice(index, 1);
    setproductObservations(w);
    const q = [...productValue];
    q.splice(index, 1);
    setProductValue(q);
    const discounts = [...productDiscount];
    discounts.splice(index, 1);
    setProductDiscount(discounts);
  };

  const handleDuplicateAddress = () => {
    // setDeliveryAddress(
    //   billingAddress.address !== null ? billingAddress.address : ''
    // );
    setcity(billingCity);
    setpostalCode(billingPostalCode);
    setcountry(billingCountry);
    setstreet(billingStreet);
    setstreetNumber(billingStreetNumber);
    // setDeliveryAddressError('');
    setcountryError('');
    setcityError('');
    setpostalCodeError('');
    setstreetError('');
    setstreetNumberError('');
  };
  const handleUnDuplicateAddress = () => {
    // setDeliveryAddress('');
    setcountry('');
    setcity('');
    setpostalCode('');
    setstreet('');
    setstreetNumber('');
  };
  const displayTotalValue = () => {
    let x = calculateTotalValue();
    if (country?.code === 'CH' || deliveryType === 'Collection') {
      x += x * 0.077;
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    })
      .format(x)
      .replace(/[a-z]{3}/i, '')
      .trim();
  };
  const calculateVAT = () => {
    return calculateTotalValue() * 0.077;
  };
  const calculateTotalValue = () => {
    let x = 0;

    let totalValue = 0;
    productInventoryValue.map((element) => {
      totalValue += element;
    });

    productDiscount.map((element) => {
      totalValue -= element;
    });
    x += totalValue;
    if (deliveryType === 'Delivery') {
      x += deliveryValue;
    }

    let aditionalCostSum = 0;
    if (additionalCosts.length > 0) {
      additionalCosts.map((item) => {
        aditionalCostSum += parseFloat(item.cost);
      });
    }

    x += aditionalCostSum;

    x -= discount;
    if (insuranceRequest) {
      x += insuranceFee;
    }

    return x;
  };

  const [openCreateClient, setOpenClient] = useState(false);

  const [invoiceDate, setInvoiceDate] = useState('');
  const [invoiceDateError, setInvoiceDateError] = useState('');

  const handleInvoiceDate = (date: string): void => {
    setInvoiceDateError('');
    setInvoiceDate(date);
    return null;
  };

  const handleClose = () => {
    //  setAllEditions([]);
    // setFrom('');
    setFreeShipping(false)
    setClient({
      addresses: [],
      billing_address: {},
      cards: '',
      email: '',
      first_name: '',
      id: 153,
      last_name: '',
      profile: {
        profile_id: 0
      },
      subscriptions: [],
      username: null
    });
    setProductInventoryValue([0])
    setInvoiceDate("")
    setClientError('');
    setCurrency('USD');

    setpostalCode('');
    setpostalCodeError('');
    setstreet('');
    setstreetError('');
    setstreetNumber('');
    setstreetNumberError('');
    setcity('');
    setcityError('');
    setcountry('');
    setcountryError('');
    setInputValue(['']);
    setInputValueClients('');
    setInputValueCountries('');
    setProhibitedEmail(false);
    setProduct([
      {
        id: 0,
        name: '',
        productId: 0,
        productName: '',
        value: 0,
        extras: []
      }
    ]);
    setAdditionalCosts([]);
    setExtras([[]]);
    setExtrasQuantity([[]]);
    setProductError(['']);
    setProductValue([0]);
    setProductDiscount([0]);
    setDeliveryValue(0);
    setPaymentType('');
    setPaymentTypeError('');
    setDeliveryType('');
    setDeliveryTypeError('');
    setInvoiceReference('');
    setDiscount(0);
    setOrderNotes('');
    setPaymentStatus('');
    setproductObservations(['']);
    setAddressChecked(false);
    setBillingAddress({
      address: '',
      address_id: 1,
      city: '',
      complement: '',
      country: '',
      name: '',
      postal_code: '',
      state: '',
      street: '',
      street_number: '',
      update_address: ''
    });
    // setClients([]);
    setInsuranceRequest(false);
    setInsuranceFee(0);

    onClose();
  };
  const handleChangeSelect = (event) => {
    setPreferredTime(event.target.value);
  };
  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>

        <ModalCreateClient
          open={openCreateClient}
          onClose={(number: number) => {
            if (number === 1) {
              _getClients();
            }
            setOpenClient(false);
          }}
        />

        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>Add invoice</div>
            <div>
              <Button variant="outlined" color="primary" onClick={handleSubmit}>
                Create
              </Button>
            </div>
          </div>
        </DialogTitle>
        <div>
          <Grid
            item
            xs={12}
            sm={8}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginLeft: '2vw',
              marginBottom: '3vw'
            }}
          >
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '1vw'
              }}
            >
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth
                margin={'normal'}

              // error={nameError !== ''}
              >
                <InputLabel htmlFor="name-input">From</InputLabel>
                <Input
                  id="name-input"
                  type="text"
                  value={from}
                  disabled
                  multiline
                  rows={2}
                />
              </FormControl>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '1vw'
              }}
            >
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                margin={'normal'}
                fullWidth
              >
                <InputLabel htmlFor="name-input">Invoice Number</InputLabel>
                <Input
                  id="name-input"
                  type="text"
                  value={invoiceReference}
                  onChange={(e) => {
                    handleInvoiceReference(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '17.5vw',
                  marginLeft: '1rem'
                }}
                fullWidth
                margin={'normal'}
                error={invoiceDateError !== ''}
              >
                <TextField
                  id="datetime-local"
                  label="Invoice Date"
                  required
                  type="date"
                  error={invoiceDateError !== ''}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={invoiceDate}
                  onChange={(e) => {
                    handleInvoiceDate(e.target.value);
                  }}
                />
                <FormHelperText>{invoiceDateError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '1vw'
              }}
            >
              <FormControl
                style={{
                  marginTop: '0px'
                }}
                fullWidth
                margin={'normal'}
                error={clientError !== ''}
                required
              >
                <Autocomplete
                  value={client}
                  onChange={(event: any, newValue: string | null) => {
                    handleChangeClient(newValue);
                  }}
                  inputValue={inputValueClients}
                  onInputChange={(event, newInputValue) => {
                    setInputValueClients(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={clients}
                  getOptionLabel={(option) => {
                    if (option?.first_name !== undefined) {
                      return option?.first_name + ' ' + option?.last_name;
                    } else return '';
                  }}
                  renderOption={(option) =>
                    option?.first_name !== undefined
                      ? option?.first_name + ' ' + option?.last_name
                      : ''
                  }
                  loading={clients.length === 0}
                  loadingText={'Loading clients'}
                  renderInput={(params) => (
                    <TextField {...params} label="Client" />
                  )}
                />
                <FormHelperText>{clientError}</FormHelperText>
              </FormControl>
              <IconButton
                style={{ marginTop: '1vw' }}
                onClick={() => {
                  setOpenClient(true);
                }}
              >
                <AddIcon></AddIcon>
              </IconButton>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 13
              }}
            >
              {client?.email && (
                <Grid>
                  <Typography>{client?.email}</Typography>
                </Grid>
              )}
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prohibitedEmail}
                      onChange={() => {
                        setProhibitedEmail(!prohibitedEmail);
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Don't send emails"
                />
              </Grid>
            </Grid>
            {client?.email && (
              <div>
                {' '}
                <Grid style={{ fontWeight: 'lighter', fontSize: '1vw' }}>
                  Billing Address
                </Grid>
                <Grid
                  style={{
                    border: '1px solid grey',
                    padding: '1vw',
                    borderRadius: '1vw',
                    marginBottom: '1vw'
                  }}
                >
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <FormControl
                      style={{
                        marginTop: '0px'
                      }}
                      margin={'normal'}
                      fullWidth
                      error={billingStreetError !== ''}
                      required
                    >
                      <InputLabel htmlFor="name-input">Street</InputLabel>
                      <Input
                        id="name-input"
                        type="text"
                        value={billingStreet}
                        onChange={(e) => {
                          setBillingStreet(e.target.value);
                          setIsBillingChanged(true);
                          if (addressChecked) {
                            setstreet(e.target.value);
                          }
                        }}
                      />
                      <FormHelperText>{billingStreetError}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <FormControl
                      style={{
                        marginTop: '0px'
                      }}
                      margin={'normal'}
                      fullWidth
                      error={billingCityError !== ''}
                      required
                    >
                      <InputLabel htmlFor="street-input">City</InputLabel>
                      <Input
                        id="street-input"
                        type="text"
                        value={billingCity}
                        onChange={(e) => {
                          setBillingcity(e.target.value);
                          setIsBillingChanged(true);
                          if (addressChecked) {
                            setcity(e.target.value);
                          }
                        }}
                      />
                      <FormHelperText>{billingCityError}</FormHelperText>
                    </FormControl>

                    <FormControl
                      style={{
                        marginTop: '0px',
                        width: '17.5vw',
                        marginLeft: '2vw'
                      }}
                      margin={'normal'}
                      fullWidth
                      error={billingStreetError !== ''}
                      required
                    >
                      <InputLabel htmlFor="street-input">
                        Door number
                      </InputLabel>
                      <Input
                        id="street-input"
                        type="text"
                        value={billingStreetNumber}
                        onChange={(e) => {
                          setBillingstreetNumber(e.target.value);
                          setIsBillingChanged(true);
                          if (addressChecked) {
                            setstreetNumber(e.target.value);
                          }
                        }}
                      />
                      <FormHelperText>
                        {billingStreetNumberError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <FormControl
                      style={{
                        marginTop: '0px'
                      }}
                      margin={'normal'}
                      fullWidth
                      error={billingPostalCodeError !== ''}
                      required
                    >
                      <InputLabel htmlFor="street-input">
                        Postal Code
                      </InputLabel>
                      <Input
                        id="postal-input"
                        type="text"
                        value={billingPostalCode}
                        onChange={(e) => {
                          setBillingpostalCode(e.target.value);
                          setIsBillingChanged(true);
                          if (addressChecked) {
                            setpostalCode(e.target.value);
                          }
                        }}
                      />
                      <FormHelperText>{billingPostalCodeError}</FormHelperText>
                    </FormControl>
                    <FormControl
                      style={{
                        marginTop: '0px',
                        marginLeft: '2vw'
                      }}
                      margin={'normal'}
                      fullWidth
                      error={billingCountryError !== ''}
                      required
                    >
                      <Autocomplete
                        value={billingCountry}
                        onChange={(event: any, newValue: string | null) => {
                          setBillingcountry(newValue);
                          setIsBillingChanged(true);
                          if (addressChecked) {
                            setcountry(newValue);
                          }
                        }}
                        inputValue={billingInputValueCountries}
                        onInputChange={(event, newInputValue) => {
                          setBillingInputValueCountries(newInputValue);
                        }}
                        id="country-autocomplete"
                        options={COUNTRIES}
                        getOptionLabel={(option) => {
                          if (option.name !== undefined) {
                            return option.name;
                          } else return '';
                        }}
                        renderOption={(option) =>
                          option.name !== undefined ? option.name : ''
                        }
                        loading={clients.length === 0}
                        loadingText={'Loading countries'}
                        renderInput={(params) => (
                          <TextField {...params} label="Country" />
                        )}
                      />

                      <FormHelperText>{billingCountryError}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}
            <Grid style={{ marginBottom: '1vw' }}></Grid>
            <Grid style={{ marginBottom: '1vw' }}>
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '17.5vw'
                }}
                fullWidth={true}
                margin={'normal'}
                required
              >
                <InputLabel htmlFor="name-input">Currency</InputLabel>
                <Select
                  labelId="label"
                  id="currency-input"
                  value={currency}
                  onChange={(e) => {
                    handleCurrency(e.target.value);
                  }}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                  <MenuItem value="CHF">CHF</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              style={{
                marginBottom: '1vw',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '17.5vw'
                }}
                margin={'normal'}
                error={deliveryTypeError !== ''}
                required
              >
                <InputLabel htmlFor="name-input">
                  Delivery or Collection
                </InputLabel>
                <Select
                  labelId="label"
                  id="current-location-input"
                  value={deliveryType}
                  onChange={(e) => {
                    handleChangeDeliveryType(e.target.value);
                  }}
                >
                  <MenuItem value="Collection">Collection</MenuItem>
                  <MenuItem value="Delivery">Delivery</MenuItem>
                </Select>
                <FormHelperText>{deliveryTypeError}</FormHelperText>
              </FormControl>
              {deliveryType === 'Delivery' && (
                <FormControl
                  style={{
                    marginTop: '0px',
                    width: '17.5vw',
                    marginLeft: '2vw'
                  }}
                  margin={'normal'}
                >
                  <InputLabel htmlFor="name-input">Delivery Value</InputLabel>
                  <Input
                    id="name-input"
                    type="number"
                    disabled={freeShipping}
                    value={deliveryValue}
                    onChange={(e) => {
                      handleDeliveryValue(e.target.value);
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={freeShipping}
                        onChange={(e) => {
                          setFreeShipping(e.target.checked)
                          if (freeShipping) {
                            setDeliveryValue(0)
                          }
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Free shipping"
                  />
                </FormControl>
              )}
            </Grid>
            {deliveryType === 'Collection' && (
              <>
                <Grid style={{ marginBottom: '1vw' }}>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      width: '17.5vw',
                      marginRight: '15px'
                    }}
                    fullWidth
                    margin={'normal'}
                  >
                    <TextField
                      id="datetime-local"
                      label="Preferred date"
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={preferredDate}
                      onChange={(e) => {
                        setPreferredDate(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      width: '17.5vw',
                      marginTop: '0px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="name-input">Preferred time</InputLabel>
                    <Select
                      labelId="label"
                      id="preferred-time-input"
                      value={preferredTime}
                      onChange={(e) => {
                        handleChangeSelect(e);
                      }}
                    >
                      <MenuItem value="10:00:00">Morning (10AM-13PM)</MenuItem>
                      <MenuItem value="14:00:00">Afternoon (2PM–6PM)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {deliveryType === 'Delivery' && (
              <>
                {' '}
                <Grid style={{ fontWeight: 'lighter', fontSize: '1vw' }}>
                  Shipping Address
                </Grid>
                <Grid>
                  <FormControlLabel
                    disabled={billingAddress === null}
                    control={
                      <Checkbox
                        checked={addressChecked}
                        onChange={() => {
                          setAddressChecked(!addressChecked);
                          if (!addressChecked) {
                            handleDuplicateAddress();
                          } else {
                            handleUnDuplicateAddress();
                          }
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Same as billing address"
                  />
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    margin={'normal'}
                    fullWidth
                    error={streetError !== ''}
                    required
                  >
                    <InputLabel htmlFor="name-input">Street</InputLabel>
                    <Input
                      id="name-input"
                      type="text"
                      value={street}
                      onChange={(e) => {
                        handleChangeStreet(e.target.value);
                      }}
                    />
                    <FormHelperText>{streetError}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    margin={'normal'}
                    fullWidth
                    error={cityError !== ''}
                    required
                  >
                    <InputLabel htmlFor="street-input">City</InputLabel>
                    <Input
                      id="street-input"
                      type="text"
                      value={city}
                      onChange={(e) => {
                        handleChangeCity(e.target.value);
                      }}
                    />
                    <FormHelperText>{cityError}</FormHelperText>
                  </FormControl>

                  <FormControl
                    style={{
                      marginTop: '0px',
                      width: '17.5vw',
                      marginLeft: '2vw'
                    }}
                    margin={'normal'}
                    fullWidth
                    error={streetNumberError !== ''}
                    required
                  >
                    <InputLabel htmlFor="street-input">Door number</InputLabel>
                    <Input
                      id="street-input"
                      type="text"
                      value={streetNumber}
                      onChange={(e) => {
                        handleChangeStreetNo(e.target.value);
                      }}
                    />
                    <FormHelperText>{streetNumberError}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    margin={'normal'}
                    fullWidth
                    error={postalCodeError !== ''}
                    required
                  >
                    <InputLabel htmlFor="street-input">Postal Code</InputLabel>
                    <Input
                      id="postal-input"
                      type="text"
                      value={postalCode}
                      onChange={(e) => {
                        handleChangePostalCode(e.target.value);
                      }}
                    />
                    <FormHelperText>{postalCodeError}</FormHelperText>
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginLeft: '2vw'
                    }}
                    margin={'normal'}
                    fullWidth
                    error={countryError !== ''}
                    required
                  >
                    <Autocomplete
                      value={country}
                      onChange={(event: any, newValue: string | null) => {
                        handleChangeCountry(newValue);
                      }}
                      inputValue={inputValueCountries}
                      onInputChange={(event, newInputValue) => {
                        setInputValueCountries(newInputValue);
                      }}
                      id="country-autocomplete"
                      options={COUNTRIES}
                      getOptionLabel={(option) => {
                        if (option.name !== undefined) {
                          return option.name;
                        } else return '';
                      }}
                      renderOption={(option) =>
                        option.name !== undefined ? option.name : ''
                      }
                      loading={clients.length === 0}
                      loadingText={'Loading countries'}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" />
                      )}
                    />

                    <FormHelperText>{countryError}</FormHelperText>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid
              style={{
                fontWeight: 'lighter',
                fontSize: '1.1vw',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div>Artwork(s)</div>
              <div style={{ marginLeft: '2vw' }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={handleAddProduct}
                >
                  <AddIcon></AddIcon>
                </IconButton>
              </div>
            </Grid>
            {product.map((product, index) => (
              <Grid
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Grid
                  style={{
                    border: '1px solid grey',
                    padding: '1vw',
                    borderRadius: '1vw',
                    marginBottom: '1vw'
                  }}
                >
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <FormControl
                      style={{
                        marginTop: '0px',
                        marginBottom: '2vw'
                        // width: '17.5vw'
                      }}
                      fullWidth={true}
                      margin={'normal'}
                      error={productError[index] !== ''}
                      required
                    >
                      <Autocomplete
                        value={product}
                        onChange={(event: any, newValue: string | null) => {
                          handleChangeProduct(newValue, index);
                        }}
                        inputValue={inputValue[index]}
                        onInputChange={(event, newInputValue) => {
                          const x = [...inputValue];
                          x[index] = newInputValue;
                          setInputValue(x);
                        }}
                        id="controllable-states-demo"
                        options={allEditions}
                        getOptionLabel={(option) => {
                          if (option.productName !== '') {
                            return (
                              option.productName +
                              ': ' +
                              option.name +
                              '; ' +
                              option.number +
                              '; ' +
                              option.stock_status
                            );
                          } else return '';
                        }}
                        renderOption={(option) =>
                          option.productName !== ''
                            ? option.productName +
                            ': ' +
                            option.name +
                            '; ' +
                            option.number +
                            '; ' +
                            option.stock_status
                            : ''
                        }
                        loading={allEditions.length === 0}
                        loadingText={'Loading artworks'}
                        renderInput={(params) => (
                          <TextField {...params} label="Artwork" />
                        )}
                      />
                      <FormHelperText>{productError[index]}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <FormControl
                      style={{
                        marginTop: '0px'
                      }}
                      fullWidth
                      margin={'normal'}
                    >
                      <InputLabel htmlFor="name-input">
                        Inventory value
                      </InputLabel>
                      <Input
                        id="name-input"
                        type="number"
                        value={productInventoryValue[index]}
                        onChange={(e) =>
                          handleChangeProductPrice(
                            parseFloat(e.target.value),
                            index
                          )
                        }
                      />
                    </FormControl>
                    <FormControl
                      style={{
                        marginTop: '0px',
                        marginLeft: '2vw'
                      }}
                      fullWidth
                      margin={'normal'}
                    >
                      <InputLabel htmlFor="name-input">Value ($)</InputLabel>
                      <Input
                        id="name-input"
                        type="text"
                        disabled
                        value={productValue[index].toFixed(2)}
                      />
                    </FormControl>
                    <FormControl
                      style={{
                        marginTop: '0px',
                        marginLeft: '2vw'
                      }}
                      fullWidth
                      margin={'normal'}
                    >
                      <InputLabel htmlFor="name-input">Discount</InputLabel>
                      <Input
                        id="name-input"
                        type="number"
                        value={productDiscount[index]}
                        onChange={(e) =>
                          handleProductDiscount(
                            parseFloat(e.target.value),
                            index
                          )
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid style={{ marginBottom: '2vw' }}>
                    <FormControl fullWidth={true} margin={'normal'}>
                      <InputLabel htmlFor="description-input">
                        Artwork observations
                      </InputLabel>
                      <Input
                        id="description-input"
                        type="string"
                        value={productObservations[index]}
                        onChange={(e) =>
                          handleProductObs(e.target.value, index)
                        }
                        rows={4}
                        multiline={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid>
                  <IconButton
                    disabled={index === 0}
                    aria-label="expand row"
                    size="small"
                    onClick={() => removeProducts(index)}
                    style={{ marginTop: '0.5vw', marginLeft: '1vw' }}
                  >
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid
              style={{
                marginTop: '2rem',
                fontWeight: 'lighter',
                fontSize: '1.1vw',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div>Additional Cost(s)</div>
              <div style={{ marginLeft: '2vw' }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={handleAddAdditionalCost}
                >
                  <AddIcon></AddIcon>
                </IconButton>
              </div>
            </Grid>
            {additionalCosts.map((cost, index) => (
              <Grid
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Grid
                  style={{
                    border: '1px solid grey',
                    padding: '1vw',
                    borderRadius: '1vw',
                    marginBottom: '1vw'
                  }}
                >
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <FormControl
                      style={{
                        marginTop: '0px',
                        width: '17.5vw'
                      }}
                      fullWidth
                      margin={'normal'}
                    >
                      <InputLabel htmlFor="name-input">Title</InputLabel>
                      <Input
                        id="name-input"
                        type="string"
                        value={additionalCosts[index].title}
                        onChange={(e) => {
                          handleChangeAdditionalCost(
                            e.target.value,
                            index,
                            'title'
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <FormControl
                      style={{
                        marginTop: '0px',
                        width: '17.5vw'
                      }}
                      fullWidth
                      margin={'normal'}
                    >
                      <InputLabel htmlFor="name-input">Cost</InputLabel>
                      <Input
                        id="name-input"
                        type="number"
                        value={additionalCosts[index].value}
                        onChange={(e) => {
                          handleChangeAdditionalCost(
                            e.target.value,
                            index,
                            'cost'
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    style={{
                      fontWeight: 'lighter',
                      fontSize: '0.9vw',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  ></Grid>

                  <Grid style={{ marginBottom: '2vw' }}>
                    <FormControl fullWidth={true} margin={'normal'}>
                      <InputLabel htmlFor="description-input">
                        Description
                      </InputLabel>
                      <Input
                        id="description-input"
                        type="string"
                        value={additionalCosts[index].observations}
                        onChange={(e) => {
                          handleChangeAdditionalCost(
                            e.target.value,
                            index,
                            'description'
                          );
                        }}
                        rows={4}
                        multiline={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => removeAdditionalCost(index)}
                    style={{ marginTop: '0.5vw', marginLeft: '1vw' }}
                  >
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            <Grid style={{ margin: '3vw 0 1vw 0' }}>
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '17.5vw'
                }}
                fullWidth={true}
                margin={'normal'}
                error={paymentTypeError !== ''}
                required
              >
                <InputLabel htmlFor="name-input">Payment Type</InputLabel>
                <Select
                  required
                  labelId="label"
                  id="current-location-input"
                  value={paymentType}
                  error={paymentTypeError !== ''}
                  onChange={(e) => {
                    handleChangePaymentType(e.target.value);
                  }}
                >
                  <MenuItem value="transfer">Transfer</MenuItem>
                  <MenuItem value="cash">Cash</MenuItem>
                  <MenuItem value="card">Card in Store</MenuItem>
                  <MenuItem value="bitcoin">Bitcoin</MenuItem>
                  <MenuItem value="etherium">Etherium</MenuItem>
                </Select>
                <FormHelperText>{paymentTypeError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid style={{ marginBottom: '1vw' }}>
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '17.5vw'
                }}
                margin={'normal'}
              >
                <InputLabel htmlFor="name-input">Discount</InputLabel>
                <Input
                  id="name-input"
                  type="number"
                  value={discount}
                  onChange={(e) => {
                    handleDiscount(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>

            {deliveryType === 'Delivery' && (
              <>
                <Grid>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={insuranceRequest}
                        onChange={() => {
                          handleInsuranceRequest();
                        }}
                      />
                    }
                    label={`Request Insurance: ${new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: currency
                    })
                      .format(insuranceFee)
                      .replace(/[a-z]{3}/i, '')
                      .trim()}`}
                  />
                </Grid>
                <Typography variant="caption">
                  (Represents 1% of total products)
                </Typography>
              </>
            )}
            {country?.code === 'CH' ||
              (deliveryType === 'Collection') ? (
              <Grid>
                <br />
                <Typography>
                  VAT (7.7%):{' '}
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currency
                  })
                    .format(calculateVAT())
                    .replace(/[a-z]{3}/i, '')
                    .trim()}
                </Typography>
              </Grid>
            ) : null}
            <Grid style={{ margin: '22px 0' }}>
              <Typography variant="h5">Total: {displayTotalValue()}</Typography>
            </Grid>

            <Grid>
              <FormControl
                style={{
                  marginTop: '0px',
                  width: '17.5vw'
                }}
                margin={'normal'}
                error={paymentTypeError !== ''}
                required
              >
                <InputLabel htmlFor="name-input">Payment Status</InputLabel>

                <Select
                  labelId="label"
                  required
                  id="current-location-input"
                  value={paymentStatus}
                  onChange={(e) => {
                    handlePaymentStatus(e.target.value);
                  }}
                >
                  <MenuItem value="pending">Pending Payment</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                </Select>
                <FormHelperText>{paymentStatusError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid>
              <FormControl fullWidth={true} margin={'normal'}>
                <InputLabel htmlFor="description-input">Order notes</InputLabel>
                <Input
                  id="description-input"
                  type="string"
                  value={orderNotes}
                  onChange={(e) => handleOrderNotes(e.target.value)}
                  rows={4}
                  multiline={true}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  );
}
