import React, { useState, useEffect } from 'react';
import { IKitchen } from 'interfaces/kitchen';
import formatDate from 'date-fns/format';
import { enUS } from 'date-fns/locale';
import { v4 as uuidv4 } from 'uuid';

import { getDateByStatus, hideButtonByDate } from 'helpers/kitchen';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
import { getPaymentType } from 'helpers/common';
import { getDeliveryType } from 'helpers/kitchen';
import { useStyles } from './styles';
import LeftIcon from '@material-ui/icons/ArrowBackIosOutlined';
import RightIcon from '@material-ui/icons/ArrowForwardIosOutlined';

import Animate from 'components/Common/Animate';
import Receipt from './Receipt';

interface IProps {
  bodyStyle: string;
  order: IKitchen;
  showOKBtn?: boolean;
  showBackBtn?: boolean;
  showIsPaid?: boolean;
  showPrintBtn?: boolean;
  showExtraInfo?: boolean;
  currentOrderID: number;
  setKitchenStatus: () => void;
  setRevertKitchenStatus: () => void;
  onClick: () => void;
}

const Order: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    bodyStyle,
    order,
    showOKBtn,
    showBackBtn,
    showIsPaid,
    showPrintBtn,
    showExtraInfo,
    // currentOrderID,
    setKitchenStatus,
    setRevertKitchenStatus
  } = props;
  const classes = useStyles();

  const [disableButtons, setDisableButtons] = useState(hideButtonByDate(order));

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisableButtons(hideButtonByDate(order));
    }, 35000);
    return () => clearTimeout(timer);
  }, [order]);

  let found = null;
  if (order.delivery_address !== null) {
    const hyphenSplitted = order.delivery_address.split('-');
    if (hyphenSplitted.length > 0) {
      found = hyphenSplitted[2]?.split(' ')[1];
    }
  }
  const bairro = found != null ? found : null;

  const renderProductName = (item: any) => {
    if (item?.product?.half && !item.product?.double) {
      return `${item?.quantity + 'x' ?? ''} ${item.flavors.length > 0
          ? item.flavors
            .map((flavor: { name: string }) => flavor.name)
            .join('/')
          : item?.product?.name ?? 'Indisponível'
        }`;
    }
    return `${item?.quantity + 'x' ?? ''} ${item.product.name}`;
  };

  const background_type = (type: string) => {
    const values = {
      D: '#ccf6c8',
      C: '#f6d6ad',
      M: '#a6f6f1'
    };

    return values[type];
  };

  return (
    <Animate key={order.order_id}>
      <Card className={bodyStyle}>
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: '20px' }}
          >
            <Typography // Order Code
              variant="overline"
              className={classes.overlineLineHeight}
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                lineHeight: 1,
                backgroundColor: background_type(
                  order.type_of_order.split('-')[1] || ''
                )
              }}
            >
              {order?.order_code}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography // Date
                className={`${classes.pos} ${classes.overlineLineHeight}`}
                variant="overline"
                style={{
                  margin: 0,
                  lineHeight: 1,
                  marginBottom: '5px'
                }}
              >
                {formatDate(
                  new Date(getDateByStatus(order.status, order)),
                  'EEE - dd/MM/yy - HH:mm',
                  { locale: enUS }
                )}
              </Typography>
              {order.date_delivery && (
                <Typography // Date Delivery
                  className={`${classes.pos} ${classes.overlineLineHeight}`}
                  variant="overline"
                  style={{
                    margin: 0,
                    lineHeight: 1,
                    marginBottom: '5px'
                  }}
                >
                  {formatDate(
                    new Date(order.date_delivery),
                    'EEE - dd/MM/yy - HH:mm',
                    { locale: enUS }
                  )}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: '20px' }}
          >
            <Grid>
              <Typography // Order Client
                style={{
                  lineHeight: 1,
                  fontWeight: 'bold'
                }}
              >
                {order?.client?.name ? order?.client?.name : order?.owner?.name}
              </Typography>
              {showIsPaid && (
                <Typography
                  style={{
                    color: 'green'
                  }}
                >
                  {order?.is_paid && 'PAID'}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" alignItems="center">
            {order?.items?.map((item) => (
              item.quantity > 0 && <Grid
                key={uuidv4()}
                style={{
                  backgroundColor: '#f5f5f5',
                  marginBottom: 10,
                  paddingLeft: 8,
                  paddingRight: 8,
                  borderRadius: 10,
                  width: '100%'
                }}
              >
                <Typography // Product Name
                  variant="h6"
                  className={classes.productInfo}
                >
                  {renderProductName(item)}
                </Typography>
                {item?.product?.double &&
                  item?.flavors?.length > 0 &&
                  item?.flavors?.map((flavor, index) => (
                    <Typography
                      key={index}
                      variant="overline"
                      className={classes.overlineLineHeight}
                      paragraph
                      style={{ marginBottom: -5 }}
                    >
                      Stack {index + 1}: {flavor.name}
                    </Typography>
                  ))}
                {item.comment && (
                  <Grid className={classes.comment}>
                    <Typography
                      variant="overline"
                      className={classes.overlineLineHeight}
                      style={{ fontWeight: 'bold' }}
                    >
                      OBSERVATION:
                    </Typography>
                    <Typography
                      paragraph
                      variant="overline"
                      className={classes.overlineLineHeight}
                    >
                      {item.comment}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
          {order.delivery_address && bairro && (
            <Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
              >
                DISTRICT:
              </Typography>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
                style={{ fontWeight: 'bold' }}
              >
                {bairro}
              </Typography>
            </Grid>
          )}
          {order?.payment && (
            <Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
              >
                PAYMENT:
              </Typography>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
                style={{ fontWeight: 'bold' }}
              >
                {order?.payment && getPaymentType(order?.payment)}
              </Typography>
            </Grid>
          )}
          {order.final_comment && (
            <>
              <Grid>
                <Typography
                  variant="overline"
                  className={classes.overlineLineHeight}
                  style={{ fontWeight: 'bold' }}
                >
                  COMMENT
                </Typography>
              </Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
              >
                {`${order.final_comment}`}
              </Typography>
            </>
          )}
          {showExtraInfo && (
            <>
              <Grid>
                <Typography
                  variant="overline"
                  className={classes.overlineLineHeight}
                >
                  Type:{' '}
                </Typography>
                <Typography
                  variant="overline"
                  className={classes.overlineLineHeight}
                  style={{ fontWeight: 'bold' }}
                >
                  {`${getDeliveryType(order?.type_of_order)}`}
                </Typography>
              </Grid>
            </>
          )}
        </CardContent>

        <CardActions>
          <Grid container justifyContent="space-between">
            {showBackBtn ? (
              disableButtons ? (
                <Button
                  size="small"
                  // disabled={currentOrderID === order.order_id}
                  onClick={setRevertKitchenStatus}
                >
                  <LeftIcon />
                </Button>
              ) : (
                <Button disabled>{''}</Button>
              )
            ) : (
              <Button disabled>{''}</Button>
            )}
            {showPrintBtn && (
              <Receipt
                name={order?.owner?.name ?? order?.client?.name}
                phone={order?.owner?.phone_number}
                currency={order?.currency}
                email={order?.owner?.email}
                ownerId={order?.owner?.profile_id}
                address={order?.delivery_address}
                date={new Date(order?.date_ordered)}
                products={order.items.map((item) => {
                  return {
                    product_id: item.product?.product_id,
                    artist: item.product.artist?.name,
                    name: item.product.name,
                    width: item.product.width,
                    height: item.product.height,
                    weight: item.product.weight,
                    length: item.product.length,
                    quantity: item.quantity,
                    edition_number: item.edition?.edition_number,
                    price: item.edition?.base_price
                      ? Number(item.edition?.base_price.toFixed(2))
                      : 0
                  };
                })}
                subtotal={
                  Number(order?.pre_tax_order_value?.toFixed(2)) -
                  Number(order?.delivery_fee)
                }
                vat={order?.vat ? Number(order?.vat?.toFixed(2)) : 0}
                total={Number(order?.total_order?.toFixed(2))}
                shipping={order?.delivery_fee}
                order_no={order?.order_code}
              />
            )}
            {showOKBtn ? (
              <Button
                size="small"
                // disabled={currentOrderID === order.order_id}
                onClick={setKitchenStatus}
              >
                <RightIcon />
              </Button>
            ) : (
              <Button disabled>{''}</Button>
            )}
          </Grid>
        </CardActions>
      </Card>
    </Animate>
  );
};

export default Order;
