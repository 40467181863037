import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Product } from 'interfaces/stock';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'black',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: '#fe309c',
      backgroundColor: 'black'
    }
  }
});

interface IProps {
  menu_products?: Product[];
  products: Product[];
  selectedProducts?: Product[];
  setSelectedProducts?: any;
}

export default function EditProductsTable(props: IProps) {
  const classes = useStyles();
  const { products, selectedProducts, setSelectedProducts } = props;

  const handleProductOnPress = (selectedProduct: Product) => {
    if (
      selectedProducts
        .map((productToAdd) => productToAdd.product_id)
        .includes(selectedProduct.product_id)
    ) {
      return setSelectedProducts(
        selectedProducts.filter(
          (prod) => prod.product_id !== selectedProduct.product_id
        )
      );
    }
    return setSelectedProducts([selectedProduct, ...selectedProducts]);
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: '5px' }}>Selected</TableCell>
            <TableCell style={{ padding: '5px' }}>Id</TableCell>
            <TableCell style={{ padding: '5px' }}>Name</TableCell>
            <TableCell style={{ padding: '5px' }}>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products?.map((product) => (
            <TableRow key={product.product_id}>
              <TableCell component="th" scope="row" style={{ padding: '5px' }}>
                {selectedProducts.some(
                  (menu_product) =>
                    menu_product.product_id === product.product_id
                ) ? (
                  <CheckBoxIcon onClick={() => handleProductOnPress(product)} />
                ) : (
                  <CheckBoxOutlineBlankIcon
                    onClick={() => handleProductOnPress(product)}
                  />
                )}
              </TableCell>
              <TableCell component="th" scope="row" style={{ padding: '5px' }}>
                {product.product_id}
              </TableCell>
              <TableCell style={{ padding: '5px' }}>{product.name}</TableCell>
              <TableCell style={{ padding: '5px' }}>{product.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
