import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export enum EFilters {
  TODAY = 'today',
  LAST_WEEK = 'week',
  LAST_MONTH = 'month'
}

export enum ETypesManager {
  FETCH = 'MANAGER/FETCH',
  SUCCESS = 'MANAGER/SUCCESS',
  FAILURE = 'MANAGER/FAILURE',
  RESET = 'MANAGER/RESET'
}

export interface IManagerState {
  fetch?: boolean;
  balance: {
    revenue_d_c: {
      delivery: number;
      collection: number;
      nft: number;
    },
    revenue: {
      invoice: number;
      card: number;
      abandoned: number;
    };
    came_from: {
      waiter: number;
      app: number;
    };
    type_of_client: {
      b2b_card: number;
      b2b_invoice: number;
      b2c_card: number;
      b2c_invoice: number;
      undeclared: number;
    };
    revenue_count: {
      invoice: number;
      card: number;
      abandoned: number;
    };
    came_from_count: {
      waiter: number;
      app: number;
    };
    where_go: {
      delivery: number;
      collection: number;
    };
    where_go_count: {
      delivery: number;
      collection: number;
    };
    cost: {
      bills: number;
      general: number;
      drivers: number;
      discount: number;
      dhl: number;
      bookvalue_card: number;
      bookvalue_invoice: number;
      insurance_card: number;
      insurance_invoice: number;
    };
    store: {
      artxx: number;
      wfa: number;
    };
    timings: {
      doing_done: string;
      doing_depot: string;
      depot_transit: string;
      transit_delivered: string;
      doing_completed: string;
    };
  };
  orders: {
    open: number;
    closed: number;
    total_pizzas: number;
    news: Array<{
      name: string;
      amount: number;
    }>;
    average: Array<{
      name: string;
      amount: number;
    }>;
    total_average: number;
  };
  users: {
    total: number;
    news: number;
  };
  ranks: {
    products: Product[];
    total_products?: number;
    total_products_value?: number;
    countries: Country[];
    total_country?: number;
    total_country_value?: number;
    artists: Artist[];
    total_artist?: number;
    total_artist_value?: number
  };
}

export interface Product {
  name: string;
  amount: number; // Products Sold
  total_sold?: number;
}

export interface Country {
  name: string;
  amount: number; // Orders Made
  total_sold?: number;
}

export interface Artist {
  name: string;
  amount: number; // Orders Made
  total_sold?: number;
}



export interface IFetchAction extends IBaseAction<ETypesManager, null> {
  type: ETypesManager.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesManager, IManagerState> {
  type: ETypesManager.SUCCESS;
  payload: IManagerState;
}

export interface IFailureAction extends IBaseAction<ETypesManager, string> {
  type: ETypesManager.FAILURE;
  payload: string;
}

export interface IResetAction extends IBaseAction<ETypesManager, null> {
  type: ETypesManager.RESET;
}

export type IManagerAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction
  | IResetAction;

export type GetState = () => any;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action: IManagerAction | ThunkAction | Array<IManagerAction> | IAlertAction
) => any;
