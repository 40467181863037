import { Homepage } from 'interfaces/homepage';
import React from 'react';
import CreateHomepage from './CreateHomepage';
import { Banner } from 'interfaces/banner';

interface IProps {
  homepages: Homepage;
  reload: () => void;
  banners: Banner;
}

export default function MediaTable(props: IProps) {
  const { reload, homepages, banners } = props;
  const [open, setOpen] = React.useState(false);

  const createHomepageCloseModal = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        padding: '0px 50px 0px 50px'
      }}
    >
      <CreateHomepage
        open={open}
        banner={banners}
        homepage={homepages}
        onClose={() => createHomepageCloseModal()}
        reload={reload}
      />
    </div>
  );
}
