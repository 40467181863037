import { combineReducers } from 'redux';

import Alert from 'redux/ducks/alert';
import Authentication from 'redux/ducks/authentication';
import Balcony from 'redux/ducks/balcony';
import Kitchen from 'redux/ducks/kitchen';
import Production from 'redux/ducks/production';
import Home from 'redux/ducks/home';
import Package from 'redux/ducks/package';
import Manager from 'redux/ducks/manager';
import Stock from 'redux/ducks/stock';
import CustomersAndSellers from 'redux/ducks/customersAndSellers';
import Store from 'redux/ducks/store';
import Menu from 'redux/ducks/menu';
import History from 'redux/ducks/history';
import Notification from 'redux/ducks/notification';
import artists from './artists';
import latest from './latest';
import banner from './banner';
import currency from './currency';
import voucher from './vouchers';
export default combineReducers({
  alert: Alert,
  authentication: Authentication,
  balcony: Balcony,
  kitchen: Kitchen,
  production: Production,
  home: Home,
  package: Package,
  manager: Manager,
  history: History,
  stock: Stock,
  customersAndSellers: CustomersAndSellers,
  store: Store,
  menu: Menu,
  notification: Notification,
  voucher,
  artists,
  latest,
  banner,
  currency
});
