import {
  IKitchen,
  IKitchenState,
  IFetchAction,
  ISuccessAction,
  ICurrentOrderIDAction,
  ISetAction,
  IUnsetAction,
  IFailureAction,
  IKitchenAction,
  Dispatch,
  ThunkAction,
  ETypesKitchen,
  IUpdateAction,
  GetState
} from 'interfaces/kitchen';

import { EAlertVariant } from 'interfaces/alert';

import { EKitchenStatus } from 'enums/order';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { checkOrderStatus, unsetOldOrder, setNewOrder } from 'helpers/kitchen';

import UIfx from 'uifx';

import NotificationSound from 'assets/sounds/to-the-point.mp3';
import { mountQueryURL } from '../../utils/query';

/* Kitchen State. */
const initialState: IKitchenState = {
  fetch: false,
  count: 0,
  currentOrderID: 0,
  results: [],
  todo: [],
  doing: [],
  done: [],
  baking: [],
  depot: [],
  transit: [],
  delivered: [],
  next: '',
  previous: '',
  error: ''
};

/* Kitchen Reducer. */
export default (
  state: IKitchenState = initialState,
  action: IKitchenAction
): IKitchenState => {
  switch (action.type) {
    case ETypesKitchen.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesKitchen.SUCCESS:
      return {
        ...state,
        fetch: false,
        count: action.payload.count,
        results: action.payload.results,
        todo: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.TODO
        ),
        doing: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.DOING
        ),
        done: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.DONE
        ),
        depot: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.DEPOT
        ),
        baking: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.BAKING
        ),
        transit: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.TRANSIT
        ),
        delivered: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.DELIVERED
        ),
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesKitchen.UPDATE_ORDER:
      const updatedResults = state.results.map((order) => {
        if (order.order_id === action.payload.order_id) {
          return action.payload;
        }
        return order;
      });
      return {
        ...state,
        results: updatedResults,
        todo: updatedResults.filter(
          (order) => order.status === EKitchenStatus.TODO
        ),
        doing: updatedResults.filter(
          (order) => order.status === EKitchenStatus.DOING
        ),
        done: updatedResults.filter(
          (order) => order.status === EKitchenStatus.DONE
        ),
        depot: updatedResults.filter(
          (order) => order.status === EKitchenStatus.DEPOT
        ),
        baking: updatedResults.filter(
          (order) => order.status === EKitchenStatus.BAKING
        ),
        transit: updatedResults.filter(
          (order) => order.status === EKitchenStatus.TRANSIT
        ),
        delivered: updatedResults.filter(
          (order) => order.status === EKitchenStatus.DELIVERED
        )
      };
    case ETypesKitchen.CURRENT_ORDER_ID:
      return {
        ...state,
        currentOrderID: action.payload
      };
    case ETypesKitchen.SET:
      return {
        ...state,
        results: [...state.results, action.payload],
        currentOrderID: 0,
        [checkOrderStatus(action.payload.status)]: setNewOrder(
          action.payload,
          state[checkOrderStatus(action.payload.status)]
            ? state[checkOrderStatus(action.payload.status)]
            : []
        )
      };
    case ETypesKitchen.UNSET:
      return {
        ...state,
        [checkOrderStatus(action.payload.last_status)]: unsetOldOrder(
          action.payload,
          state[checkOrderStatus(action.payload.last_status)]
        )
      };
    case ETypesKitchen.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* Kitchen Action Creators Functions. */
export const fetchKitchen = (): IFetchAction => ({
  type: ETypesKitchen.FETCH
});

export const successKitchen = (payload: IKitchenState): ISuccessAction => ({
  type: ETypesKitchen.SUCCESS,
  payload
});

export const currentOrderIDKitchen = (
  payload: number
): ICurrentOrderIDAction => ({
  type: ETypesKitchen.CURRENT_ORDER_ID,
  payload
});

export const setKitchen = (payload: IKitchen): ISetAction => ({
  type: ETypesKitchen.SET,
  payload
});

export const unsetKitchen = (payload: IKitchen): IUnsetAction => ({
  type: ETypesKitchen.UNSET,
  payload
});

export const failureKitchen = (payload: string): IFailureAction => ({
  type: ETypesKitchen.FAILURE,
  payload
});

export const updateOrderOnKitchen = (payload: IKitchen): IUpdateAction => ({
  type: ETypesKitchen.UPDATE_ORDER,
  payload
});

/* Kitchen Side Effects Functions. */
export const getKitchen =
  (params: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchKitchen());

      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = '';

      let url = `/orders/api/orders/${query_url}`;
      let response;
      const results: IKitchen[] = [];
      do {
        response = await fetch({
          method: EMethod.GET,
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      dispatch(successKitchen(response));
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const setKitchenStatus =
  (order: IKitchen): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${order.order_id}/change_status/`
      });
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const skipAll =
  (order: IKitchen): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      await fetch({
        method: EMethod.PATCH,
        url: `/orders/api/orders/${order.order_id}/status_skip_all/`
      });
      dispatch(
        sendAlert(
          'Order succesfully marked as completed',
          EAlertVariant.SUCCESS
        )
      );
      dispatch(getKitchen());
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };
export const setCanceledStatus =
  (order: IKitchen, data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${order.order_id}/cancel_order/`,
        data
      });
      dispatch(sendAlert('Order successfully canceled', EAlertVariant.SUCCESS));

      return true;
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const deleteOrder =
  (order: IKitchen, data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      await fetch({
        method: EMethod.DELETE,
        url: `/orders/api/orders/${order.order_id}/`,
        data
      });
      dispatch(sendAlert('Order successfully deleted', EAlertVariant.SUCCESS));

      return true;
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const createInsuranceContract =
  (order: IKitchen): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      var data = {
        infos: {
          insurance_requested: true
        },
        products: order.items.map((item) => item.product.product_id)
      };
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${order.order_id}/create_insurance_contract/`,
        data: data
      });
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const createDHLShipment =
  (orderId: number, date: string): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch(currentOrderIDKitchen(orderId));
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${orderId}/create_dhl_shipment/`,
        data: { dhl_planned_shipping_date: date }
      });
      await dispatch(
        sendAlert('The DHL label was created.', EAlertVariant.SUCCESS)
      );
      return true;
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
    //Should to improve the error response, in time of handlerError task
  };

export const updateDhlShipmentDate =
  (order: IKitchen, data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      await fetch({
        method: EMethod.PATCH,
        url: `/orders/api/orders/${order.order_id}/`,
        data
      });
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
    //Should to improve the error response, in time of handlerError task
    return true;
  };

export const setKitchenRevertStatus =
  (order: IKitchen): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${order.order_id}/revert_status/`
      });
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const setOrderIsPaid =
  (order: IKitchen, data: {}): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${order.order_id}/manual_payment/`,
        data
      });
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const updateKitchen =
  (socket: any): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { kitchen } = getState();
      const { message } = socket;
      const newOrder: IKitchen = message;
      const checkOrders: IKitchen[] = kitchen.results.filter(
        (order: IKitchen) => {
          return order.order_id === newOrder.order_id;
        }
      );

      const status = ['todo', 'doing', 'done', 'depot'];
      const checkOrdersByStatus = status
        .map((stats) =>
          kitchen[stats].filter((order: IKitchen) => {
            if (order) {
              return order?.order_id === newOrder.order_id;
            }
          })
        )
        .filter((ordersByStatus) => ordersByStatus.length > 0);
      if (checkOrders.length > 0 || checkOrdersByStatus.length > 0) {
        return dispatch(updateOrderOnKitchen(newOrder));
      }
      if (!newOrder.last_status && newOrder.status === EKitchenStatus.TODO) {
        const beep = new UIfx(NotificationSound);
        beep.play();
      }
      if (newOrder.last_status) dispatch(unsetKitchen(newOrder));
      if (newOrder.status === EKitchenStatus.COMPLETED) return null;
      dispatch(setKitchen(newOrder));
    } catch (error) {
      console.log('Error ----', error);
      dispatch(failureKitchen(error));
      dispatch(sendAlert('Something went wrong', EAlertVariant.ERROR));
    }
  };

export const sendPDF =
  (file: any, id: number): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      var new_file = new File([file], 'invoice');
      const pdfFIle = new FormData();
      pdfFIle.append('invoice_pdf', new_file);
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${id}/add_invoice_pdf/`,
        data: pdfFIle
      });
      dispatch(sendAlert('Invoice saved successfuly', EAlertVariant.SUCCESS));
      //window.location.reload();
      return 1;
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };
