import React from 'react';

interface IProps {
  children: React.ReactElement;
  ref: any;
}

const Print = React.forwardRef((props: IProps): JSX.Element => {
  const { children, ref } = props;

  return (
    <>
      <div ref={ref}>{children}</div>
    </>
  );
});

export default Print;
