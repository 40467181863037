import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  IartistState,
  ETypesartist,
  IManagerAction,
  GetState
} from 'interfaces/artist';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Authentication State. */
const initialState: IartistState = {
  fetch: false,
  artists: []
};

/* Authentication Reducer. */
export default (
  state: IartistState = initialState,
  action: IManagerAction
): IartistState => {
  switch (action.type) {
    case ETypesartist.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesartist.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };

    case ETypesartist.FAILURE:
      return {
        ...state,
        fetch: false
      };
    case ETypesartist.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchartist = (): IFetchAction => ({
  type: ETypesartist.FETCH
});

export const successFetchartist = (payload: IartistState): ISuccessAction => ({
  type: ETypesartist.SUCCESS,
  payload
});

export const failureManagerDashboard = (payload: string): IFailureAction => ({
  type: ETypesartist.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesartist.RESET
});

/* Authentication Side Effects Functions. */

export const fetchArtists = (): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { artist } = getState();
    dispatch(fetchartist());
    let url = `products/api/artists/`;
    const results = [];
    while (url !== null) {
      const response = await fetch({
        method: EMethod.GET,
        url
      });
      url = response.next;
      const r = response.results;
      r.map((element) => {
        results.push(element);
      });
    }
    dispatch(successFetchartist({ ...artist, artists: results }));
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};

export const createArtist = (data: {}): ThunkAction => async (
  dispatch: Dispatch
): Promise<number> => {
  try {
    const body = new FormData();
    Object.keys(data).forEach((key) => {
      body.append(key, data[key]);
    });
    await fetch({
      method: EMethod.POST,
      headers: { 'content-type': 'multipart/form-data' },
      url: 'products/api/artists/',
      data: body
    });
    dispatch(sendAlert('Artist Created successfuly', EAlertVariant.SUCCESS));
    return 1;
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return 0;
  }
};

export const editArtist = (id: number, data: {}): ThunkAction => async (
  dispatch: Dispatch
): Promise<number> => {
  try {
    const body = new FormData();
    Object.keys(data).forEach((key) => {
      body.append(key, data[key]);
    });
    await fetch({
      method: EMethod.PATCH,
      headers: { 'content-type': 'multipart/form-data' },
      url: 'products/api/artists/' + id + '/',
      data: body
    });
    dispatch(sendAlert('Artist Edited successfuly', EAlertVariant.SUCCESS));
    return 1;
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return 0;
  }
};
export const deleteArtist = (id: number): ThunkAction => async (
  dispatch: Dispatch
): Promise<number> => {
  try {
    await fetch({
      method: EMethod.DELETE,
      url: 'products/api/artists/' + id + '/'
    });
    dispatch(sendAlert('Artist deleted successfuly', EAlertVariant.SUCCESS));
    return 1;
  } catch (error) {
    dispatch(failureManagerDashboard(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return 0;
  }
};
