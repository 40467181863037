import { Grid } from '@material-ui/core';
import Loading from 'components/Common/Loading';
import { Homepage } from 'interfaces/homepage';
import IStore from 'interfaces/store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHomepages } from 'redux/ducks/homepage';
import { getBanners } from 'redux/ducks/banner';
import { Banner } from 'interfaces/banner';
import HomepagesTable from './HomepagesTable';
//import ModalDeleteHomepage from './ModalDeleteHomepage';
export default function Index() {
  const dispatch = useDispatch();
  const [homepages, setHomepages] = React.useState([]);
  const [banners, setBanners] = React.useState([]);

  const { banner } = useSelector<IStore, IStore>((state) => state);
  const fetch = async () => {
    const x: Homepage[] = await dispatch(getHomepages());
    setHomepages(x);
  };
  useEffect(() => {
    fetch();
  }, []);
  const getBannerss = async () => {
    const x: Banner[] = await dispatch(getBanners());
    setBanners(x);
  };
  useEffect(() => {
    getBannerss();
  }, []);
  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-home-page');
    if (d.item(0)) {
      d.item(0).style.color = '#fe309c';
      d.item(1).style.color = '#fe309c';
    }
    const dText = document.querySelectorAll<HTMLElement>('.d-home-page-text');

    if (dText.item(0)) {
      dText.item(0).style.color = '#fe309c';
    }
  });

  return (
    <>
      <Loading show={banner.fetch} />

      <Grid container spacing={3}>
        <HomepagesTable
          banners={banners[0]}
          homepages={homepages[0]}
          reload={() => getHomepages()}
        />
      </Grid>
    </>
  );
}
