import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  Checkbox,
  Container,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Artist } from 'interfaces/artist';
import { DropzoneArea } from 'material-ui-dropzone';
import { createTheme } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { editArtist, fetchArtists } from 'redux/ducks/artists';
import { sendAlert } from 'redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
interface IProps {
  onClose: () => void;
  open: boolean;
  artist: Artist;
}

const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});
const ckEditorToolbarConfig = [
  'Heading',
  '|',
  'bold',
  'italic',
  '|',
  'BulletedList',
  'NumberedList',
  '|',
  'Indent',
  '|',
  'BlockQuote',
  'undo',
  'redo'
];
export default function ModalEditArtist(props: IProps) {
  const { onClose, open, artist } = props;
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [quote, setQuote] = useState('');
  const [slug, setSlug] = useState('');
  const [slugError, setSlugError] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [longDesc, setLongDesc] = useState('');
  const [shortName, setShortName] = useState('');
  const [firstName, setFirstName] = useState(false);
  const [active, setActive] = useState(false);
  const [imageSource, setImageSource] = useState<File>();
  const [imageSourceError, setImageSourceError] = useState('');
  const [clicked, setClicked] = useState(false);
  const [surnameOrderOnly, setSurnameOrderOnly] = useState<string>('');
  const [surnameOrderOnlyError, setSurnameOrderOnlyError] = useState<string>('');

  useEffect(() => {
    if (artist) {
      setName(artist.name);
      setSlug(artist.slug);
      setShortDesc(artist.short_description);
      setLongDesc(artist.long_description);
      setShortName(artist.short_name);
      setFirstName(artist.firstname);
      setActive(artist.artist_active);
      setQuote(artist.quote);
      setSurnameOrderOnly(artist.surname_orderonly);
    }
  }, [artist]);

  const handleClose = (number: number) => {
    if (number === 1) {
      dispatch(fetchArtists());
    }
    setName('');
    setNameError('');
    setSlug('');
    setSlugError('');
    setShortDesc('');
    setLongDesc('');
    setShortName('');
    setFirstName(false);
    setActive(false);
    setQuote('');
    setImageSource(null);
    setClicked(false);
    onClose();
    setSurnameOrderOnly('');
    setSlugError('');
  };

  const handleImage = (event: any) => {
    if (event.length > 0) {
      setImageSource(event[0]);
    }
  };
  const checkForm = () => {
    var entered = false;
    if (name === '') {
      setNameError('Please enter name');
      dispatch(sendAlert('Please check the errors below', EAlertVariant.ERROR));
      entered = true;
    }
    if (!imageSource && !artist?.image) {
      setImageSourceError('Please add the artist image');
      dispatch(sendAlert('Please check the errors below', EAlertVariant.ERROR));
      entered = true;
    }
    if (surnameOrderOnly === '') {
      setSurnameOrderOnlyError('Please add the surname');
      dispatch(sendAlert('Please check the errors below', EAlertVariant.ERROR));
      entered = true;
    }
    if (slug === '') {
      setSlugError('Please enter web url');
      dispatch(sendAlert('Please check the errors below', EAlertVariant.ERROR));
      entered = true;
    }
    return entered;
  };
  const submitCreation = () => {
    if (!checkForm()) {
      setClicked(true);
      dispatch(
        editArtist(
          artist.id,
          imageSource !== null && imageSource !== undefined
            ? {
              name,
              short_name: shortName,
              short_description: shortDesc,
              image: imageSource,
              slug,
              firstname: firstName,
              long_description: longDesc,
              artist_active: active,
              quote,
              surname_orderonly: surnameOrderOnly
            }
            : {
              name,
              short_name: shortName,
              short_description: shortDesc,
              slug,
              firstname: firstName,
              long_description: longDesc,
              artist_active: active,
              quote,
              surname_orderonly: surnameOrderOnly
            }
        )
      ).then((result: number) => {
        if (result === 1) {
          handleClose(1);
        } else {
          setClicked(false);
        }
      });
    }
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={() => handleClose(0)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => handleClose(0)}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>Edit Artist</div>
            <div>
              <Button
                onClick={submitCreation}
                variant="outlined"
                color="primary"
                disabled={clicked}
              >
                Save
              </Button>
            </div>
          </div>
        </DialogTitle>
        <Container maxWidth="md">
          <Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={() => {
                    setActive(!active);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
            error={nameError !== ''}
          >
            <InputLabel htmlFor="name-input">Name</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError('');
              }}
            />
            <FormHelperText>{nameError}</FormHelperText>
          </FormControl>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            <InputLabel htmlFor="name-input">Short Name</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={shortName}
              onChange={(e) => {
                setShortName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
            error={surnameOrderOnlyError !== ''}

          >
            <InputLabel htmlFor="name-input">Surname Orderonly</InputLabel>
            <Input
              id="surnameOrderOnly-input"
              type="text"
              value={surnameOrderOnly}
              onChange={(e) => {
                setSurnameOrderOnly(e.target.value);
              }}
            />
            <FormHelperText>{surnameOrderOnlyError}</FormHelperText>
          </FormControl>
          <Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={firstName}
                  onChange={() => {
                    setFirstName(!firstName);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="First Name"
            />
          </Grid>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            <div
              style={{
                fontWeight: 'lighter',
                fontSize: '1.1vw',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '1vw'
              }}
            >
              Short Description
            </div>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: ckEditorToolbarConfig
              }}
              data={shortDesc}
              onChange={(event, editor) => {
                const data = editor.getData();
                setShortDesc(data);
              }}
            />
          </FormControl>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            <div
              style={{
                fontWeight: 'lighter',
                fontSize: '1.1vw',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '1vw'
              }}
            >
              Long Description
            </div>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: ckEditorToolbarConfig
              }}
              data={longDesc}
              onChange={(event, editor) => {
                const data = editor.getData();
                setLongDesc(data);
              }}
            />
          </FormControl>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
            error={slugError !== ''}
          >
            <InputLabel htmlFor="name-input">Web URL</InputLabel>
            <Input
              id="name-input"
              type="text"
              value={slug}
              onChange={(e) => {
                setSlug(e.target.value);
                setSlugError('');
              }}
            />
            <FormHelperText>{slugError}</FormHelperText>
          </FormControl>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            <div
              style={{
                fontWeight: 'lighter',
                fontSize: '1.1vw',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '1vw'
              }}
            >
              Quote
            </div>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: ckEditorToolbarConfig
              }}
              data={quote}
              onChange={(event, editor) => {
                const data = editor.getData();
                setQuote(data);
              }}
            />
          </FormControl>
          <ThemeProvider theme={theme}>
            <DropzoneArea
              acceptedFiles={['image/*']}
              dropzoneText={'Drag and drop the artist image here'}
              onChange={(files) => handleImage(files)}
              filesLimit={1}
            />
            <Typography variant="caption" style={{ color: '#fe309c' }}>
              For better resolution insert images with 1000x1000
            </Typography>
          </ThemeProvider>
          <Typography variant="caption" style={{ color: 'red' }}>
            {imageSourceError}
          </Typography>
          <div>
            {(imageSource === null || imageSource === undefined) &&
              artist?.image && (
                <div style={{ width: '4vw', height: '4vw', marginTop: '2vw' }}>
                  <img
                    src={artist.image}
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              )}
          </div>
        </Container>
      </div>
    </Modal>
  );
}
