import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ManagerDashboard from './ManagerDashboard';
import useStyles from './styles';

export default function Home(): JSX.Element {
  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-manager');

    d.item(0).style.color = '#fe309c';

    d.item(1).style.color = '#fe309c';
    const dText = document.querySelectorAll<HTMLElement>('.d-manager-text');
    dText.item(0).style.color = '#fe309c';
  });
  const classes = useStyles();

  return (
    <Grid container className={classes.fullView} justifyContent="center">
      <ManagerDashboard />
    </Grid>
  );
}
