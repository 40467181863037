import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { getHistory } from 'redux/ducks/history';
import { connect } from 'utils/socket';
import { SOCKET_NOTIFICATION } from 'settings';
import { updateKitchen } from 'redux/ducks/kitchen';
import { ESocketStatus } from 'enums/socket';

// import {
// MuiPickersUtilsProvider,
// KeyboardDatePicker
// } from '@material-ui/pickers';
import IStore from 'interfaces/store';
import Loading from 'components/Common/Loading';
import HistoryTable from './HistoryTable';
// import DateFnsUtils from '@date-io/date-fns';
// import SearchIcon from '@material-ui/icons/Search';
// import useStyles from './styles';
// import formatDate from 'date-fns/format';
import { IHistoryState } from 'interfaces/history';
import moment from 'moment';

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const [initialDate, setInitialDate] = useState(
    new Date().setDate(new Date().getDate() - 30)
  );
  const [online, setOnline] = useState(false);
  const [finalDate, setFinalDate] = useState(new Date());
  const [isFiltering, setIsFiltering] = useState(false);
  const [orders, setOrders] = useState([])
  const [message, setMessage] = useState(
    'Showing all orders of the last 30 days.'
  );
  const { results, fetch } = useSelector<IStore, IHistoryState>((state) => state.history);
  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = 'black';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-history');
    d.item(0).style.color = '#fe309c';

    d.item(1).style.color = '#fe309c';
    const dText = document.querySelectorAll<HTMLElement>('.d-history-text');
    dText.item(0).style.color = '#fe309c';
  });

  useEffect(() => {
    const _initialDate = initialDate
      ? moment(initialDate).format('YYYY-MM-DD')
      : null;
    const _finalDate = finalDate
      ? moment(finalDate).format('YYYY-MM-DD')
      : null;
    dispatch(
      getHistory({
        starts: _initialDate,
        ends: _finalDate
      })
    );
  }, [dispatch]);
  useEffect(() => {
    setOrders(results)
  }, [results])
  useEffect(() => {
    let kitchenSocket = connect(SOCKET_NOTIFICATION);

    kitchenSocket.onopen = () => {
      setOnline(true);
    };

    kitchenSocket.onmessage = (event) => {
      try {
        let new_data = JSON.parse(event.data);
        if (new_data.message.order_id) {
          if (new_data) {
            dispatch(updateKitchen(JSON.parse(event.data)));
          }
        }
      } catch (error) {
        console.log('error ', error);
      }
    };

    kitchenSocket.onerror = (e) => {
      console.log('error ', e);
      kitchenSocket.close();
    };

    kitchenSocket.onclose = () => kitchenSocket.close();

    const heartbeat = setInterval(() => {
      if (kitchenSocket.readyState === ESocketStatus.OPEN) setOnline(true);

      if (kitchenSocket.readyState !== ESocketStatus.OPEN) setOnline(false);

      if (!online) kitchenSocket = connect(SOCKET_NOTIFICATION);
    }, 5000);

    return () => {
      kitchenSocket.close();
      clearInterval(heartbeat);
    };
  }, [online, dispatch]);
  const filter = () => {
    const _initialDate = initialDate
      ? moment(initialDate).format('YYYY-MM-DD')
      : null;
    const _finalDate = finalDate
      ? moment(finalDate).format('YYYY-MM-DD')
      : null;


    dispatch(
      getHistory({
        starts: _initialDate,
        ends: _finalDate
      })
    );
    setIsFiltering(true);
    const _message = `Showing orders from ${initialDate ? moment(initialDate).format('ll') : 'to'
      } to ${moment(finalDate).format('ll')}`;
    setMessage(_message);
  };
  return (
    <>
      <Loading show={fetch} />
      <Grid container spacing={3}>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        ></Grid>

        <HistoryTable
          initialDate={initialDate}
          query={{
            starts: moment(initialDate).format('YYYY-MM-DD'),
            ends: moment(finalDate).format('YYYY-MM-DD')
          }}
          setInitialDate={setInitialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
          isFiltering={isFiltering}
          filter={filter}
          message={message}
          orders={orders}
          fetchData={() => {
            const _initialDate = initialDate
              ? moment(initialDate).format('YYYY-MM-DD')
              : null;
            const _finalDate = finalDate
              ? moment(finalDate).format('YYYY-MM-DD')
              : null;
            dispatch(
              getHistory({
                starts: _initialDate,
                ends: _finalDate
              })
            );
          }}
        />
      </Grid>
    </>
  );
}
