import React, { useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Editions } from 'interfaces/stock';
import { editEdtion } from 'redux/ducks/stock';
import { useDispatch } from 'react-redux';
import { fetch } from './../../utils/request';
import moment from 'moment';
import { sendAlert } from 'redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  edition: Editions;
}

export default function ModalEditEdition(props: SimpleDialogProps) {
  const { onClose, open, edition } = props;
  const dispatch = useDispatch();
  const [editions, setEditions] = React.useState({
    id: 0,
    name: '',
    edition_number: '',
    slug: '',
    stock_status: 'Available',
    base_price: 0,
    cost: 0,
    active: false,
    tax_status: 'Standard Rate',
    condition_notes: '',
    internal_condition_notes: '',
    current_location: '',
    location_details: '',
    location_date: '',
    location_notes: '',
    consignment_date: '',
    return_consignment_date: '',
    consignment_terms: '',
    third_party_base_price: 0,
    consignment_documents: '',
    certificate: '',
    consignment_notes: '',
    location_history: '',
    store_owner: { id: 0, name: '' },
    consignor_owner: { id: 0, name: '' },
    last_examined_by: { id: 0, username: '' },
    consigned_to: { id: 0, name: '' }
  });
  const [stores, setStores] = React.useState([]);
  const [nameError, setNameError] = React.useState('');
  const [slugError, setSlugError] = React.useState('');
  const [storeError, setStoreError] = React.useState('');
  const [consignorError, setConsignorError] = React.useState('');
  const [users, setUsers] = React.useState([]);
  const [waiters, setWaiters] = React.useState([]);
  const [inputValueConsignorOwner, setInputValueConsignorOwner] =
    React.useState('');
  const [inputValueConsignedTo, setInputValueConsignedTo] = React.useState('');

  const [inputValueLastExaminedBy, setInputValueLastExaminedBy] =
    React.useState('');

  const _getStores = async () => {
    try {
      let response;
      let results = [];
      let url = '/products/api/stores/';
      do {
        response = await fetch({
          method: 'GET',
          url: url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setStores(response.results);
    } catch (error) {
      console.log('error fetching stores');
    }
  };
  const _getUsers = async () => {
    try {
      let response;
      let results = [];
      let url = '/users/api/clients/';
      do {
        response = await fetch({
          method: 'GET',
          url: url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setUsers(response.results);
      //   const x = [];
      //   response.results.forEach((element) => {
      //     x.push(element.first_name + '' + element.last_name);
      //   });
      //   setUsersOptions(x);
    } catch (error) {
      console.log('error fetching users');
    }
  };
  const _getWaiters = async () => {
    try {
      let response;
      let results = [];
      let url = '/waiter/api/waiter/';
      do {
        response = await fetch({
          method: 'GET',
          url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      const waitersTo = response.results.map((element) => {
        return { id: element.id, name: element.username };
      });
      setWaiters(waitersTo);
      //   const x = [];
      //   response.results.forEach((element) => {
      //     x.push(element.first_name + '' + element.last_name);
      //   });
      //   setUsersOptions(x);
    } catch (error) {
      console.log('error fetching users');
    }
  };

  useEffect(() => {
    if (edition && open) {
      const editionsToBeEditted = edition;
      console.log(editionsToBeEditted);
      if (editionsToBeEditted.last_examined_by.id) {
        editionsToBeEditted.last_examined_by.username =
          editionsToBeEditted.last_examined_by.name;
        delete editionsToBeEditted.last_examined_by.name;
      }
      if (editionsToBeEditted.location_date) {
        editionsToBeEditted.location_date = moment(
          new Date(editionsToBeEditted.location_date)
        )
          .format('YYYY-MM-DD')
          .split('T')[0];
      }
      if (editionsToBeEditted.consignment_date) {
        editionsToBeEditted.consignment_date = moment(
          new Date(editionsToBeEditted.consignment_date)
        ).format('YYYY-MM-DD');
      }
      if (editionsToBeEditted.return_consignment_date) {
        editionsToBeEditted.return_consignment_date = moment(
          new Date(editionsToBeEditted.return_consignment_date)
        ).format('YYYY-MM-DD');
      }

      setEditions(editionsToBeEditted);
    }

    if (stores.length === 0) _getStores();
    if (users.length === 0) _getUsers();
    if (waiters.length === 0) _getWaiters();
  }, [props, users]);

  const handleClose = () => {
    setNameError('');
    setSlugError('');
    setStoreError('');
    setConsignorError('');
    onClose();
  };

  const handleChangeNameEdition = (value: any) => {
    const item = { ...editions };
    item.name = value;
    setEditions(item);
    setNameError('');
  };

  const handleChangeBasePriceEdition = (value: any) => {
    const item = { ...editions };
    item.base_price = value;
    setEditions(item);
  };

  const handleChangeCost = (value: any) => {
    const item = { ...editions };
    item.cost = value;
    setEditions(item);
  };

  const handleChangeEditionNumberEdition = (value: any) => {
    const item = { ...editions };
    item.edition_number = value;
    setEditions(item);
  };

  const handleChangeStockStatus = (value: any) => {
    const item = { ...editions };
    item.stock_status = value;
    setEditions(item);
  };
  const handleChangeActive = (value: any) => {
    const item = { ...editions };
    item.active = value;
    setEditions(item);
  };

  const handleChangeCurrentLocation = (value: any) => {
    const item = { ...editions };
    item.current_location = value;
    setEditions(item);
  };

  //   const handleChangeLocationDetail = (value: any) => {
  //     const item = { ...editions };
  //     item.location_details = value;
  //     setEditions(item);
  //   };

  const handleChangeLocationHistory = (value: any) => {
    const item = { ...editions };
    item.location_history = value;
    setEditions(item);
  };

  const handleChangeLocationDate = (value: any) => {
    const item = { ...editions };
    item.location_date = value;
    setEditions(item);
  };

  const handleChangeConditionNotes = (value: any) => {
    const item = { ...editions };
    item.condition_notes = value;
    setEditions(item);
  };
  const handleChangeLocationNotes = (value: any) => {
    const item = { ...editions };
    item.location_notes = value;
    setEditions(item);
  };
  const handleChangeInternalConditionNotes = (value: any) => {
    const item = { ...editions };
    item.internal_condition_notes = value;
    setEditions(item);
  };

  const handleChangeSlugEdition = (value: any) => {
    const item = { ...editions };
    item.slug = value;
    setEditions(item);
    setSlugError('');
  };
  const handleChangeConsignmentDate = (value: any) => {
    const item = { ...editions };
    item.consignment_date = value;
    setEditions(item);
  };
  const handleChangeConsignmentDocuments = (value: any) => {
    const item = { ...editions };
    item.consignment_documents = value.target.files[0];
    setEditions(item);
  };
  const handleChangeConsignmentTerms = (value: any) => {
    const item = { ...editions };
    item.consignment_terms = value;
    setEditions(item);
  };
  const handleChangeConsignmentNotes = (value: any) => {
    const item = { ...editions };
    item.consignment_notes = value;
    setEditions(item);
  };
  const handleChangeCertificate = (value: any) => {
    const item = { ...editions };
    item.certificate = value.target.files[0];
    setEditions(item);
  };
  const handleChangeReturnConsignmentDate = (value: any) => {
    const item = { ...editions };
    item.return_consignment_date = value;
    setEditions(item);
  };
  const handleChange3rdBasePrice = (value: any) => {
    const item = { ...editions };
    item.third_party_base_price = value;
    setEditions(item);
  };
  const handleChangeStore = (value: any) => {
    const item = { ...editions };
    item.store_owner.id = value;
    Object.keys(stores).forEach((key) => {
      if (stores[key].id === value) {
        item.store_owner.name = stores[key].name;
      }
    });
    setEditions(item);
    setStoreError('');
  };
  const handleChangeConsignor = (value: any) => {
    const item = { ...editions };
    item.consignor_owner.id = value.id;
    item.consignor_owner.name = value.first_name + ' ' + value.last_name;
    setEditions(item);
    setConsignorError('');
  };
  const handleChangeConsignedTo = (value: any) => {
    const item = { ...editions };
    item.consigned_to.id = value.id;
    item.consigned_to.name = value.first_name + ' ' + value.last_name;
    setEditions(item);
  };

  const handleChangeLastExamined = (value: any) => {
    const item = { ...editions };
    item.last_examined_by = value;
    setEditions(item);
  };

  const checkFormError = () => {
    let entered = false;
    if (editions.name === '') {
      setNameError('Please add title');
      entered = true;
    }
    if (editions.slug === '') {
      setSlugError('Please add slug');
      entered = true;
    }
    if (editions.store_owner.name === '') {
      setStoreError('Please add store owner');
      entered = true;
    }
    if (editions.consignor_owner.name === '') {
      setConsignorError('Please add consignor owner');
      entered = true;
    }
    if (entered) {
      dispatch(
        sendAlert('Please fill the missing fields', EAlertVariant.ERROR)
      );
    }
    return entered;
  };

  const handleEdit = async () => {
    if (!checkFormError()) {
      const editionsToSubmit = {
        id: 0,
        name: '',
        edition_number: 1,
        slug: '',
        stock_status: 'Available',
        base_price: 0,
        cost: 0,
        active: false,
        tax_status: 'Standard Rate',
        condition_notes: '',
        internal_condition_notes: '',
        current_location: '',
        location_details: '',
        location_date: '',
        location_notes: '',
        consignment_date: '',
        return_consignment_date: '',
        consignment_terms: '',
        third_party_base_price: 0,
        consignment_documents: '',
        certificate: '',
        consignment_notes: '',
        location_history: '',
        store_owner: { id: 0, name: '' },
        store_owner_id: 0,
        consignor_owner: { id: 0, name: '' },
        consignor_owner_id: 0,
        last_examined_by: { id: 0, name: '' },
        last_examined_by_id: 0,
        consigned_to: { id: 0, name: '' },
        consigned_to_id: 0
      };

      Object.keys(editions).forEach((key) => {
        if (
          key === 'location_date' ||
          key === 'return_consignment_date' ||
          key === 'consignment_date'
        ) {
          editionsToSubmit[key] = moment(
            new Date(editions.location_date)
          ).format('YYYY-MM-DD');
        } else {
          if (key === 'consigned_to') {
            editionsToSubmit['consigned_to_id'] = editions['consigned_to'].id;
          }
          if (key === 'consignor_owner') {
            editionsToSubmit['consignor_owner_id'] =
              editions['consignor_owner'].id;
          }
          if (key === 'last_examined_by') {
            editionsToSubmit['last_examined_by_id'] =
              editions['last_examined_by'].id;
          }
          if (key === 'store_owner') {
            editionsToSubmit['store_owner_id'] = editions['store_owner'].id;
          }
          editionsToSubmit[key] = editions[key];
        }
      });
      if (
        editionsToSubmit.consignment_documents === '' ||
        editionsToSubmit.consignment_documents === null
      ) {
        delete editionsToSubmit.consignment_documents;
      }
      if (
        editionsToSubmit.certificate === '' ||
        editionsToSubmit.certificate === null
      ) {
        delete editionsToSubmit.certificate;
      }
      if (
        editionsToSubmit.consigned_to.id === 0 ||
        editionsToSubmit.consigned_to === null
      ) {
        delete editionsToSubmit.consigned_to;
        delete editionsToSubmit.consigned_to_id;
      }
      if (
        editionsToSubmit.consignor_owner.id === 0 ||
        editionsToSubmit.consignor_owner === null
      ) {
        delete editionsToSubmit.consignor_owner;
        delete editionsToSubmit.consignor_owner_id;
      }
      if (
        editionsToSubmit.last_examined_by.id === 0 ||
        editionsToSubmit.last_examined_by === null
      ) {
        delete editionsToSubmit.last_examined_by;
        delete editionsToSubmit.last_examined_by_id;
      }
      if (
        editionsToSubmit.store_owner.id === 0 ||
        editionsToSubmit.store_owner === null
      ) {
        delete editionsToSubmit.store_owner;
        delete editionsToSubmit.store_owner_id;
      }

      dispatch(editEdtion(editions.id, editionsToSubmit)).then(
        (result: number) => {
          if (result) {
            handleClose();
          }
        }
      );
    }
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <IconButton
          style={{ width: '50px' }}
          color="secondary"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>Editing {edition ? edition.name : ''}</div>
            <div>
              <Button variant="outlined" color="primary" onClick={handleEdit}>
                Save
              </Button>
            </div>
          </div>
        </DialogTitle>
        <div>
          <div style={{ position: 'relative' }}>
            <Grid
              item
              xs={12}
              sm={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginLeft: '2vw'
              }}
            >
              <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                <Grid item style={{ flexDirection: 'column' }}>
                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                    error={nameError !== ''}
                  >
                    <InputLabel htmlFor="name-input">Title</InputLabel>
                    <Input
                      id="name-input"
                      type="text"
                      value={editions.name}
                      onChange={(e) => {
                        handleChangeNameEdition(e.target.value);
                      }}
                    />
                    <FormHelperText>{nameError}</FormHelperText>
                  </FormControl>
                  <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControl
                      style={{
                        marginTop: '0px'
                      }}
                      fullWidth={true}
                      margin={'normal'}
                    >
                      <InputLabel htmlFor="base-price-input">
                        Website Price
                      </InputLabel>
                      <Input
                        id="base-price-input"
                        type="number"
                        value={editions.base_price}
                        onChange={(e) => {
                          handleChangeBasePriceEdition(e.target.value);
                        }}
                      />
                    </FormControl>
                    <FormControl
                      style={{
                        marginTop: '0px',
                        marginLeft: '0.5vw'
                      }}
                      fullWidth={true}
                      margin={'normal'}
                    >
                      <InputLabel htmlFor="cost-input">Buying Price</InputLabel>
                      <Input
                        id="cost-input"
                        type="number"
                        value={editions.cost}
                        onChange={(e) => {
                          handleChangeCost(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="internal-condition-notes-input">
                      Third Party Base Price
                    </InputLabel>
                    <Input
                      id="internal-condition-notes-input"
                      type="number"
                      value={editions.third_party_base_price}
                      onChange={(e) => {
                        handleChange3rdBasePrice(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="edition-number-input">
                      Edition Number
                    </InputLabel>
                    <Input
                      id="edition-number-input"
                      type="string"
                      value={editions.edition_number}
                      onChange={(e) => {
                        handleChangeEditionNumberEdition(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="current-location-input">
                      Current Location
                    </InputLabel>
                    <Select
                      labelId="label"
                      id="current-location-input"
                      value={editions.current_location}
                      onChange={(e) => {
                        handleChangeCurrentLocation(e.target.value);
                      }}
                    >
                      <MenuItem value="Warehouse">Warehouse</MenuItem>
                      <MenuItem value="Consigners location">
                        Consigners location
                      </MenuItem>
                      <MenuItem value="3rd Party">3rd Party</MenuItem>
                      <MenuItem value="Blockchain">Blockchain</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="internal-condition-notes-input">
                      Sub-Location Notes
                    </InputLabel>
                    <Input
                      id="internal-condition-notes-input"
                      type="text"
                      value={editions.location_notes}
                      onChange={(e) => {
                        handleChangeLocationNotes(e.target.value);
                      }}
                      rows={4}
                      multiline={true}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="location-history-input">
                      Location History
                    </InputLabel>
                    <Input
                      id="location-history-input"
                      type="text"
                      value={editions.location_history}
                      onChange={(e) => {
                        handleChangeLocationHistory(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <TextField
                      id="date"
                      label="Location Date"
                      type="date"
                      defaultValue={new Date()}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={editions.location_date}
                      onChange={(e) => {
                        handleChangeLocationDate(e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="status-input">Stock Status</InputLabel>
                    <Select
                      labelId="label"
                      id="status-input"
                      value={editions.stock_status}
                      onChange={(e) => {
                        handleChangeStockStatus(e.target.value);
                      }}
                    >
                      <MenuItem value="Available">Available</MenuItem>
                      <MenuItem value="Consigned Out">Consigned out</MenuItem>
                      <MenuItem value="Under offer">Under offer</MenuItem>
                      <MenuItem value="Sold">Sold</MenuItem>
                      <MenuItem value="Damaged">Damaged</MenuItem>
                      <MenuItem value="Unavailable">Unavailable</MenuItem>
                      <MenuItem value="Not for sale">Not for sale</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    {/* <FormLabel component="legend">Active</FormLabel> */}
                    <FormControlLabel
                      control={
                        <Switch
                          value={editions.active}
                          onChange={(e) => handleChangeActive(!editions.active)}
                          name="active"
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="condition-notes-input">
                      Website Condition Notes / Blockchain Category
                    </InputLabel>
                    <Input
                      id="condition-notes-input"
                      type="text"
                      value={editions.condition_notes}
                      onChange={(e) => {
                        handleChangeConditionNotes(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="internal-condition-notes-input">
                      Internal Condition Notes
                    </InputLabel>
                    <Input
                      id="internal-condition-notes-input"
                      type="text"
                      value={editions.internal_condition_notes}
                      onChange={(e) => {
                        handleChangeInternalConditionNotes(e.target.value);
                      }}
                      rows={4}
                      multiline={true}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                    error={slugError !== ''}
                  >
                    <InputLabel htmlFor="edition-number-input">
                      Web URL
                    </InputLabel>
                    <Input
                      id="edition-number-input"
                      type="text"
                      value={editions.slug}
                      onChange={(e) => {
                        handleChangeSlugEdition(e.target.value);
                      }}
                    />
                    <FormHelperText>{slugError}</FormHelperText>
                  </FormControl>
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                    error={storeError !== ''}
                  >
                    <InputLabel htmlFor="edition-number-input">
                      Store Owner
                    </InputLabel>
                    <Select
                      labelId="label"
                      id="status-input"
                      value={editions.store_owner.id}
                      onChange={(e) => {
                        handleChangeStore(e.target.value);
                      }}
                    >
                      {stores.map((element, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <MenuItem key={index} value={element.id}>
                          {element.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{storeError}</FormHelperText>
                  </FormControl>
                  {editions.stock_status === 'Consigned Out' && (
                    <Grid>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          marginBottom: '2vw'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                        error={consignorError !== ''}
                      >
                        <Autocomplete
                          value={editions.consignor_owner}
                          onChange={(event: any, newValue: string | null) => {
                            handleChangeConsignor(newValue);
                          }}
                          inputValue={inputValueConsignorOwner}
                          onInputChange={(event, newInputValue) => {
                            setInputValueConsignorOwner(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={users}
                          getOptionLabel={(option) => {
                            if (
                              option.first_name === undefined ||
                              option.last_name === undefined
                            ) {
                              return option.name;
                            } else
                              return option.first_name + ' ' + option.last_name;
                          }}
                          renderOption={(option) =>
                            option.name === undefined
                              ? option.first_name + ' ' + option.last_name
                              : option.name
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Consignor/Third Party Owner"
                            />
                          )}
                        />
                        <FormHelperText>{consignorError}</FormHelperText>
                      </FormControl>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          marginBottom: '2vw'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                      >
                        <Autocomplete
                          value={editions.consigned_to}
                          onChange={(event: any, newValue: string | null) => {
                            handleChangeConsignedTo(newValue);
                          }}
                          inputValue={inputValueConsignedTo}
                          onInputChange={(event, newInputValue) => {
                            setInputValueConsignedTo(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={users}
                          getOptionLabel={(option) => {
                            if (
                              option.first_name === undefined ||
                              option.last_name === undefined
                            ) {
                              return option.name;
                            } else
                              return option.first_name + ' ' + option.last_name;
                          }}
                          renderOption={(option) =>
                            option.name === undefined
                              ? option.first_name + ' ' + option.last_name
                              : option.name
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Consigned To" />
                          )}
                        />
                      </FormControl>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          marginBottom: '2vw'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                      >
                        <TextField
                          id="date"
                          label="Consignment Date"
                          type="date"
                          defaultValue={new Date()}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={editions.consignment_date}
                          onChange={(e) => {
                            handleChangeConsignmentDate(e.target.value);
                          }}
                        />
                      </FormControl>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          marginBottom: '2vw'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                      >
                        <TextField
                          id="date"
                          label="Return Consignment Date"
                          type="date"
                          defaultValue={new Date()}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={editions.return_consignment_date}
                          onChange={(e) => {
                            handleChangeReturnConsignmentDate(e.target.value);
                          }}
                        />
                      </FormControl>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          marginBottom: '2vw'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="artist-input">
                          Consignment Terms
                        </InputLabel>
                        <Select
                          labelId="label"
                          id="select"
                          value={editions.consignment_terms}
                          onChange={(e) => {
                            handleChangeConsignmentTerms(e.target.value);
                          }}
                        >
                          <MenuItem value={'Percentage'}>Percentage</MenuItem>
                          <MenuItem value={'Net Value'}>Net Value</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          marginBottom: '2vw'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="internal-condition-notes-input">
                          Consignment Notes
                        </InputLabel>
                        <Input
                          id="internal-condition-notes-input"
                          type="text"
                          value={editions.consignment_notes}
                          onChange={(e) => {
                            handleChangeConsignmentNotes(e.target.value);
                          }}
                          rows={4}
                          multiline={true}
                        />
                      </FormControl>
                      <FormControl
                        style={{
                          marginTop: '0px',
                          marginBottom: '2vw'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                      >
                        <InputLabel htmlFor="btn-upload-doc">
                          Consignment Documents
                        </InputLabel>
                        <label htmlFor="btn-upload" style={{ color: 'white' }}>
                          Select file
                        </label>

                        <Input
                          id="btn-upload-doc"
                          name="btn-upload-doc"
                          style={{ display: '' }}
                          type="file"
                          onChange={(e) => {
                            handleChangeConsignmentDocuments(e);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <InputLabel htmlFor="btn-upload">Certificate</InputLabel>
                    <label htmlFor="btn-upload" style={{ color: 'white' }}>
                      Select file
                    </label>

                    <Input
                      id="btn-upload"
                      name="btn-upload"
                      style={{ display: '' }}
                      type="file"
                      onChange={(e) => {
                        handleChangeCertificate(e);
                      }}
                    />
                  </FormControl>

                  <FormControl
                    style={{
                      marginTop: '0px',
                      marginBottom: '2vw'
                    }}
                    fullWidth={true}
                    margin={'normal'}
                  >
                    <Autocomplete
                      value={editions.last_examined_by}
                      onChange={(event: any, newValue: string | null) => {
                        handleChangeLastExamined(newValue);
                      }}
                      inputValue={inputValueLastExaminedBy}
                      onInputChange={(event, newInputValue) => {
                        setInputValueLastExaminedBy(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={waiters}
                      getOptionLabel={(option) => {
                        if (
                          option.first_name === undefined ||
                          option.last_name === undefined
                        ) {
                          return option.username;
                        } else
                          return option.first_name + ' ' + option.last_name;
                      }}
                      renderOption={(option) =>
                        option.username === undefined
                          ? option.first_name + ' ' + option.last_name
                          : option.username
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Last Examined By" />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Modal>
  );
}
