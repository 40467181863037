import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  ICreateAction,
  IUpdateAction,
  IDeleteAction,
  IFailureAction,
  IResetAction,
  IStockState,
  Product,
  Editions,
  ETypesStock,
  IManagerAction,
  GetState
} from 'interfaces/stock';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { mountQueryURL } from '../../utils/query';

/* Authentication State. */
const initialState: IStockState = {
  fetch: false,
  ingredients: [],
  products: [],
  productsForStockPage: [],
  hasMore: true
};

/* Authentication Reducer. */
export default (
  state: IStockState = initialState,
  action: IManagerAction
): IStockState => {
  switch (action.type) {
    case ETypesStock.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesStock.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesStock.CREATE_PRODUCT:
      const createdResults = state.products.map((product) => {
        return product;
      });
      createdResults.push(action.payload);
      return {
        ...state,
        products: createdResults
      };
    case ETypesStock.UPDATE_PRODUCT:
      const updatedResults = state.products.map((product) => {
        if (product.product_id === action.payload.product_id) {
          return action.payload;
        }
        return product;
      });
      return {
        ...state,
        products: updatedResults
      };
    case ETypesStock.DELETE_PRODUCT:
      const deletedResults = state.products.filter(
        (product) => product.product_id !== action.payload
      );
      return {
        ...state,
        products: deletedResults
      };
    case ETypesStock.FAILURE:
      return {
        ...state,
        fetch: false
      };
    case ETypesStock.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchStock = (): IFetchAction => ({
  type: ETypesStock.FETCH
});

export const successFetchStock = (payload: IStockState): ISuccessAction => ({
  type: ETypesStock.SUCCESS,
  payload
});

export const createProductOnProducts = (payload: Product): ICreateAction => ({
  type: ETypesStock.CREATE_PRODUCT,
  payload
});

export const editProductOnProducts = (payload: Product): IUpdateAction => ({
  type: ETypesStock.UPDATE_PRODUCT,
  payload
});

export const deleteProductOnProducts = (payload: number): IDeleteAction => ({
  type: ETypesStock.DELETE_PRODUCT,
  payload
});

export const failureManagerDashboard = (payload: string): IFailureAction => ({
  type: ETypesStock.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesStock.RESET
});

/* Authentication Side Effects Functions. */

export const fetchProductsGeneral =
  (params: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { stock } = getState();
      dispatch(fetchStock());
      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = '';
      let url = `products/api/products/calculate_stock_products/${query_url}`;
      const results = [];
      while (url !== null) {
        const response = await fetch({
          method: EMethod.GET,
          url
        });
        url = response.next;
        const r = response.results;
        r.map((element) => {
          results.push(element);
        });
      }
      dispatch(successFetchStock({ ...stock, products: results }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const fetchAllProducts = async () => {
  try {
    let url = `products/api/products/calculate_stock_products/`;
    const results = [];
    while (url !== null) {
      const response = await fetch({
        method: EMethod.GET,
        url
      });
      url = response.next;
      const r = response.results;
      r.map((element) => {
        results.push(element);
      });
    }
    return results;
  } catch (error) {
    return [];
  }
};

export const fetchProductById =
  (productId: number): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { stock } = getState();
      dispatch(fetchStock());

      let url = `products/api/products/${productId}`;

      const response = await fetch({
        method: EMethod.GET,
        url
      });

      dispatch(successFetchStock({ ...stock, products: response }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const fetchProductsPagination =
  (params: {} = undefined, reset = false): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { stock } = getState();
      dispatch(fetchStock());
      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = '';
      let url = `products/api/products/calculate_stock_products/${query_url}`;
      const results = [];
      const response = await fetch({
        method: EMethod.GET,
        url
      });
      url = response.next;
      const r = response.results;
      r.map((element) => {
        results.push(element);
      });
      dispatch(
        successFetchStock({
          ...stock,
          productsForStockPage: reset
            ? results
            : stock.productsForStockPage
            ? stock.productsForStockPage.concat(results)
            : results,
          hasMore: url !== null
        })
      );
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const fetchProducts =
  (): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { stock } = getState();
      dispatch(fetchStock());
      const response = await fetch({
        method: EMethod.GET,
        url: 'products/api/products/'
      });
      dispatch(successFetchStock({ ...stock, products: response.results }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const createProduct =
  (data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      let product_images = [];
      const body = new FormData();
      let bodyForExtras = [];
      let files = [];
      let additional_dimension = [];
      //  var tags: any;

      Object.keys(data).forEach((key) => {
        if (key === 'allFiles') {
          files = data[key];
        }
        if (key === 'product_images') {
          product_images = data[key];
          delete data[key];
        }
        if (key === 'dimensions_additional') {
          additional_dimension = data[key];
        }
        if (key === 'image') {
          if (data[key]) {
            body.append(key, data[key]);
          } else {
            return null;
          }
        } else {
          if (key === 'extras') {
            bodyForExtras = data[key];
          } else {
            if (key !== 'ingredients') {
              //   if (key === 'tags') {
              //   tags = data[key];
              // } else {
              body.append(key, data[key]);
              //}
            }
          }
        }
      });

      let id = 0;
      const editionsIds = [];
      await fetch({
        method: EMethod.POST,
        headers: { 'content-type': 'multipart/form-data' },
        url: 'products/api/products/',
        data: body
      }).then((res) => {
        id = res.product_id;
        res.editions?.map((element) => {
          editionsIds.push(element.id);
        });
      });

      additional_dimension.forEach(async (element) => {
        const bodyForDimensions = new FormData();
        bodyForDimensions.append('artwork', element.artwork);
        bodyForDimensions.append('weight', element.weight);
        bodyForDimensions.append('length', element.length);
        bodyForDimensions.append('width', element.width);
        bodyForDimensions.append('height', element.height);
        await fetch({
          method: EMethod.POST,
          headers: { 'content-type': 'multipart/form-data' },
          url: `/products/api/products/${id}/add_dimension_to_product/`,
          data: bodyForDimensions
        });
      });
      product_images.forEach(async (element, index) => {
        const bodyForImages = new FormData();
        bodyForImages.append('sort_key', `${index + 1}`);
        bodyForImages.append('image', element);
        await fetch({
          method: EMethod.POST,
          headers: { 'content-type': 'multipart/form-data' },
          url: `/products/api/products/${id}/add_image_to_product/`,
          data: bodyForImages
        });
      });
      bodyForExtras.forEach(async (element) => {
        await fetch({
          method: EMethod.POST,
          headers: { 'content-type': 'application/json' },
          url: `/products/api/products/${id}/add_extra_to_product/`,
          data: element
        });
      });
      editionsIds.forEach(async (element, index) => {
        const bodyForFiles = new FormData();
        bodyForFiles.append(
          'consignment_documents',
          files[index].consignment_documents
        );
        bodyForFiles.append('certificate', files[index].certificate);
        bodyForFiles.append('product', `${id}`);
        await fetch({
          method: EMethod.PUT,
          headers: { 'content-type': 'multipart/form-data' },
          url: `/products/api/editions/${element}/`,
          data: bodyForFiles
        });
      });

      dispatch(
        sendAlert(
          'Cloudinary is processing your upload(s) come back in a minute to arrange them in order.',
          EAlertVariant.SUCCESS
        )
      );
      return id;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return -1;
    }
  };

// export const addImageToProduct =
// (data: {} = undefined): ThunkAction =>
// async (dispatch: Dispatch): Promise<number> => {
//   try {
//     let product_images = [];
//     // const body = new FormData();
//     // let files = [];

//     Object.keys(data).forEach((key) => {
//       if (key === 'product_images') {
//         product_images = data[key];
//         delete data[key];
//       }
//     });
//     console.log("data ", data)
//     product_images.forEach(async (element, index) => {
//       const bodyForImages = new FormData();
//       bodyForImages.append('sort_key', `${index + 1}`);
//       bodyForImages.append('image', element);
//       await fetch({
//         method: EMethod.POST,
//         headers: { 'content-type': 'multipart/form-data' },
//         url: `/products/api/products/${id}/add_image_to_product/`,
//         data: bodyForImages
//       });
//     });

//     dispatch(
//       sendAlert('Artwork created successfully', EAlertVariant.SUCCESS)
//     );
//     return null;
//   } catch (error) {
//     dispatch(failureManagerDashboard(error));
//     dispatch(sendAlert(error, EAlertVariant.ERROR));
//     return -1;
//   }
// };

export const editProduct =
  (
    product_id: number = undefined,
    data: {} = undefined,
    orderedImages: any,
    dimensions_additional: {
      artwork: string;
      width: any;
      length: any;
      height: any;
      weight: any;
    }[] = undefined,
    deleteContentSheet: boolean
  ): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      const product_images = [];
      let deletedImages = [];
      let bodyForExtras = [];
      let bodyForOldExtras = [];
      let filesNew = [];
      let filesOld = [];
      let editionsIdsOld = [];
      let editionsIdsNew = [];
      let deletedEditions = [];
      let additionalDimensionsToBeDeleted = [];
      let additionalDimensionsToBeUpdated = [];
      const additional_dimension = dimensions_additional;

      let editions: Editions[] = [];
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === 'allFilesOld') {
          filesOld = data[key];
        }
        if (key === 'allFilesNew') {
          filesNew = data[key];
        }
        if (key === 'editionIdsOld') {
          editionsIdsOld = data[key];
        }
        if (key === 'editionIdsNew') {
          editionsIdsNew = data[key];
        }

        if (key === 'extrasToBeSubmitted') {
          bodyForExtras = data[key];
        }
        if (key === 'oldExtrasToBeSubmitted') {
          bodyForOldExtras = data[key];
        }
        if (key === 'deletedImages') {
          deletedImages = data[key];
          delete data[key];
        }
        if (key === 'deletedEditions') {
          deletedEditions = data[key];
          delete data[key];
        }
        if (key === 'additionalDimensionsToBeUpdated') {
          additionalDimensionsToBeUpdated = data[key];
          delete data[key];
        }
        if (key === 'additionalDimensionsToBeDeleted') {
          additionalDimensionsToBeDeleted = data[key];
          delete data[key];
        }
        if (key === 'image') {
          if (data[key]) {
            body.append(key, data[key]);
          } else {
            return null;
          }
        } else {
          if (key === 'oldEditions') {
            editions = data[key];
          } else if (key === 'newEditions') {
            body.append('editions', data[key]);
          } else {
            body.append(key, data[key]);
          }
        }
      });
      if (orderedImages.length > 0) {
        body.append('product_images', orderedImages);
      }

      await fetch({
        method: EMethod.PATCH,
        headers: { 'content-type': 'multipart/form-data' },
        url: `products/api/products/${product_id}/`,
        data: body
      });
      additionalDimensionsToBeDeleted.forEach(async (element) => {
        await fetch({
          method: EMethod.DELETE,
          url: `/products/api/dimensionsadditional/${element}/`
        });
      });
      additionalDimensionsToBeUpdated.forEach(async (element) => {
        const bodyForDimensions = new FormData();
        bodyForDimensions.append('artwork', element.artwork);
        bodyForDimensions.append('weight', element.weight);
        bodyForDimensions.append('length', element.length);
        bodyForDimensions.append('width', element.width);
        bodyForDimensions.append('height', element.height);
        await fetch({
          method: EMethod.PATCH,
          headers: { 'content-type': 'multipart/form-data' },
          url: `/products/api/dimensionsadditional/${element.id}/`,
          data: bodyForDimensions
        });
      });
      additional_dimension.forEach(async (element) => {
        const bodyForDimensions = new FormData();
        bodyForDimensions.append('artwork', element.artwork);
        bodyForDimensions.append('weight', element.weight);
        bodyForDimensions.append('length', element.length);
        bodyForDimensions.append('width', element.width);
        bodyForDimensions.append('height', element.height);
        await fetch({
          method: EMethod.POST,
          headers: { 'content-type': 'multipart/form-data' },
          url: `/products/api/products/${product_id}/add_dimension_to_product/`,
          data: bodyForDimensions
        });
      });
      product_images.forEach(async (element, index) => {
        // console.log('product_images: ', element);
        const bodyForImages = new FormData();
        bodyForImages.append('sort_key', `${index + 1}`);
        bodyForImages.append('image', element);
        await fetch({
          method: EMethod.POST,
          headers: { 'content-type': 'multipart/form-data' },
          url: `/products/api/products/${product_id}/add_image_to_product/`,
          data: bodyForImages
        });
      });
      deletedImages.forEach(async (element) => {
        await fetch({
          method: EMethod.DELETE,
          // headers: { 'content-type': 'multipart/form-data' },
          url: `/products/api/productimages/${element}/`
        });
      });
      bodyForExtras.forEach(async (element) => {
        await fetch({
          method: EMethod.POST,
          headers: { 'content-type': 'application/json' },
          url: `/products/api/products/${product_id}/add_extra_to_product/`,
          data: element
        });
      });
      bodyForOldExtras.forEach(async (element) => {
        await fetch({
          method: EMethod.PATCH,
          headers: { 'content-type': 'application/json' },
          url: `/products/api/extras/${element.ingredient_id}/`,
          data: element
        });
      });

      for (let index = 0; index < editions.length; index++) {
        const element = editions[index];
        var editionBody = {};
        var editionKey = 0;
        Object.keys(element).forEach((key) => {
          if (key !== 'id') {
            editionBody[key] = element[key];
          } else {
            editionKey = element[key];
          }
        });

        await fetch({
          method: EMethod.PATCH,
          headers: {
            'content-type': 'application/json'
          },
          url: `products/api/editions/${editionKey}/`,
          data: editionBody
        });
      }
      editionsIdsOld.forEach(async (element, index) => {
        const bodyForFiles = new FormData();
        filesOld[index].consignment_documents &&
          bodyForFiles.append(
            'consignment_documents',
            filesOld[index].consignment_documents
          );
        filesOld[index].certificate &&
          bodyForFiles.append('certificate', filesOld[index].certificate);
        bodyForFiles.append('product', `${product_id}`);
        await fetch({
          method: EMethod.PUT,
          headers: { 'content-type': 'multipart/form-data' },
          url: `/products/api/editions/${element}/`,
          data: bodyForFiles
        });
      });
      editionsIdsNew.forEach(async (element, index) => {
        const bodyForFiles = new FormData();
        filesNew[index].consignment_documents &&
          bodyForFiles.append(
            'consignment_documents',
            filesNew[index].consignment_documents
          );
        filesNew[index].certificate &&
          bodyForFiles.append('certificate', filesNew[index].certificate);
        bodyForFiles.append('product', `${product_id}`);
        await fetch({
          method: EMethod.PUT,
          headers: { 'content-type': 'multipart/form-data' },
          url: `/products/api/editions/${element}/`,
          data: bodyForFiles
        });
      });
      deletedEditions.forEach(async (element) => {
        await fetch({
          method: EMethod.DELETE,
          url: `/products/api/editions/${element}/`
        });
      });
      if (deleteContentSheet) {
        fetch({
          method: EMethod.GET,
          url: `products/api/products/${product_id}/remove_content_sheet/`
        });
      }
      dispatch(sendAlert('Artwork edited successfully', EAlertVariant.SUCCESS));
      return true;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };
export const editEdtion =
  (edition_id: number = undefined, data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === 'store_owner') {
          if (data[key].id > 0) {
            body.append('store_owner_id', data[key].id);
          }
        }
        if (key === 'consignor_owner') {
          if (data[key].id > 0) {
            body.append('consignor_owner_id', data[key].id);
          }
        }
        if (key === 'last_examined_by') {
          if (data[key] > 0) {
            body.append('last_examined_by', data[key]);
          }
        } else body.append(key, data[key]);
      });
      await fetch({
        method: EMethod.PATCH,
        headers: {
          'content-type': 'application/json'
        },
        url: `products/api/editions/${edition_id}/`,
        data: body
      });
      dispatch(sendAlert('Edition edited successfully', EAlertVariant.SUCCESS));
      return true;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const deleteProduct =
  (product_id: number = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      await fetch({
        method: EMethod.DELETE,
        url: `products/api/products/${product_id}/`
      });
      dispatch(deleteProductOnProducts(product_id));
      dispatch(
        sendAlert('Product deleted successfully', EAlertVariant.SUCCESS)
      );
      return true;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };
export const deleteEdition =
  (edition_id: number = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      await fetch({
        method: EMethod.DELETE,
        url: `products/api/editions/${edition_id}/`
      });
      dispatch(deleteProductOnProducts(edition_id));
      dispatch(
        sendAlert('Edition deleted successfully', EAlertVariant.SUCCESS)
      );
      return true;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const fetchIngredients =
  (): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { stock } = getState();
      dispatch(fetchStock());
      const response = await fetch({
        method: EMethod.GET,
        url: 'products/api/ingredients/'
      });
      dispatch(successFetchStock({ ...stock, ingredients: response.results }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };
export const createTechnique =
  (data: {}): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      await fetch({
        method: EMethod.POST,
        url: 'products/api/techniques/',
        data
      });
      dispatch(
        sendAlert('Technique Created successfuly', EAlertVariant.SUCCESS)
      );
      return 1;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };
