import {
  IHomepageState,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IHomepageAction,
  Dispatch,
  ThunkAction,
  ETypesHomepage,
  IFirstLoadAction,
  GetState,
  Homepage
} from 'interfaces/homepage';

import { EAlertVariant } from 'interfaces/alert';

import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

/* Homepage State. */
const initialState: IHomepageState = {
  fetch: false,
  count: 0,
  results: [],
  next: '',
  previous: '',
  error: ''
};

/* Homepage Reducer. */
export default (
  state: IHomepageState = initialState,
  action: IHomepageAction
): IHomepageState => {
  switch (action.type) {
    case ETypesHomepage.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesHomepage.FIRSTLOAD:
      return {
        ...state,
        fetch: false,
        count: action.payload.count,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesHomepage.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesHomepage.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const fetchHomepage = (): IFetchAction => ({
  type: ETypesHomepage.FETCH
});

export const successHomepage = (payload: IHomepageState): ISuccessAction => ({
  type: ETypesHomepage.SUCCESS,
  payload
});

export const firstLoadHomepage = (
  payload: IHomepageState
): IFirstLoadAction => ({
  type: ETypesHomepage.FIRSTLOAD,
  payload
});

export const failureHomepage = (payload: string): IFailureAction => ({
  type: ETypesHomepage.FAILURE,
  payload
});

/* Homepage Side Effects Functions. */

export const createHomepage =
  (data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        body.append(key, data[key]);
      });
      let request_url = `/content/api/homepage/`;
      await fetch({
        method: EMethod.POST,
        url: request_url,
        data: body
      }).then((res) => {
        return 1;
      });

      dispatch(
        sendAlert('Home page created successfully', EAlertVariant.SUCCESS)
      );

      return 1;
    } catch (error) {
      dispatch(failureHomepage(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };

export const getHomepages =
  (): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<Homepage[]> => {
    try {
      const { Homepage } = getState();
      dispatch(fetchHomepage());
      let request_url = `/content/api/homepage/`;
      const response = await fetch({
        method: EMethod.GET,
        url: request_url
      });
      let results: Homepage[] = [];
      results.push(...response.results);
      dispatch(successHomepage({ ...Homepage, results }));
      return results;
    } catch (error) {
      dispatch(failureHomepage(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return [];
    }
  };
export const deleteHomepage =
  (id: number): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      let request_url;
      request_url = `/content/api/homepage/${id}/`;
      await fetch({
        method: EMethod.DELETE,
        url: request_url
      });
      dispatch(
        sendAlert('Home page deleted successfully', EAlertVariant.SUCCESS)
      );
      return 0;
    } catch (error) {
      dispatch(failureHomepage(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return -1;
    }
  };

export const patchHomepage =
  (data: {} = undefined, imagesToPatch: any, id: number): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      let request_url = `/content/api/homepage/${id}/`;
      await fetch({
        method: EMethod.PATCH,
        url: request_url,
        data
      }).then(async (res) => {
        if (Object.keys(imagesToPatch).length > 0) {
          const body = new FormData();
          Object.keys(imagesToPatch).forEach((key) => {
            body.append(key, imagesToPatch[key]);
          });
          await fetch({
            method: EMethod.PATCH,
            url: request_url,
            data: body
          });
          return 1;
        }
        return 1;
      });

      dispatch(
        sendAlert('Home page edited successfully', EAlertVariant.SUCCESS)
      );

      return 1;
    } catch (error) {
      dispatch(failureHomepage(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };
