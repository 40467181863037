import React from 'react';
import { IBalcony } from 'interfaces/balcony';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Order from './Order';
import CancelIcon from '@material-ui/icons/Cancel';

interface IProps {
  orders: IBalcony[];
  initialDate: Date;
  finalDate: Date;
  isFiltering: Boolean;
  filter: any;
  setInitialDate: any;
  setFinalDate: any;
  clearFilter: any;
  message: string;
}

export default function Panel(props: IProps): JSX.Element {
  const {
    orders,
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
    isFiltering,
    filter,
    clearFilter,
    message
  } = props;

  const handleInitalDateChange = (date: Date) => {
    setInitialDate(date);
  };

  const handleFinalDateChange = (date: Date) => {
    setFinalDate(date);
  };

  const filterMessage = () => {
    const style = {
      color: '#233472',
      padding: '15px 0 0 0',
      fontWeight: 600
    };

    if (isFiltering) {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: 5, fontSize: '0.6em', padding: '1x 16px' }}
            onClick={clearFilter}
          >
            <CancelIcon fontSize="small" style={{ marginRight: 5 }} />
            Clean filter
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
        </div>
      );
    }
  };

  return (
    <Grid item container xs={12} sm={12} md={12} lg={12} direction="column">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: 15,
          padding: 15,
          backgroundColor: '#f5f5f5',
          borderRadius: 5
        }}
      >
        <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
          <Grid
            style={{
              alignItems: 'center',
              display: 'inline-flex'
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Initial Date</span>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-initial"
                  value={initialDate}
                  placeholder="                ---------------"
                  onChange={handleInitalDateChange}
                  autoOk={true}
                  style={{ width: 189, marginRight: 5 }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Final Date</span>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-final"
                  margin="none"
                  value={finalDate}
                  onChange={handleFinalDateChange}
                  autoOk={true}
                  style={{ width: 189 }}
                />
              </div>
            </MuiPickersUtilsProvider>
          </Grid>

          <div style={{ display: 'inline-flex', marginLeft: 5 }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginLeft: 5, fontSize: '0.8em', padding: '5px 16px' }}
              onClick={filter}
            >
              Filter
            </Button>
          </div>
        </div>
        {filterMessage()}
      </div>
      <Order orders={orders} />
    </Grid>
  );
}
