import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';
import { Button } from '@material-ui/core';
import EditProductsTable from './EditProductsTable';
import { Menu } from 'interfaces/menu';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductsGeneral } from 'redux/ducks/stock';
import { editMenuProducts } from 'redux/ducks/menu';
import IStore from 'interfaces/store';
import '../Stock/styles.css';
import CustomizableLoading from 'components/Common/CustomizableLoading';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  menu: Menu;
}

export default function ModalEditMenu(props: SimpleDialogProps) {
  const dispatch = useDispatch();
  const { onClose, open, menu } = props;

  const [selectedProducts, setSelectedProducts] = useState([]);

  const { stock } = useSelector<IStore, IStore>((state) => state);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setSelectedProducts(menu?.products);
    if (menu) {
      dispatch(fetchProductsGeneral());
    }
  }, [menu]);

  const submitEdit = async () => {
    dispatch(
      editMenuProducts(menu?.menu_id, {
        products: selectedProducts
      })
    ).then((result: boolean) => {
      if (result) handleClose();
    });
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <DialogTitle id="simple-dialog-title">
          Edit Menu {menu?.name} Artworks
        </DialogTitle>
        {menu ? (
          <div style={{position: "relative"}}>
          <EditProductsTable
            products={stock.products}
            menu_products={menu?.products}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
          <CustomizableLoading show={stock.fetch} styles={{position: "absolute", width: "100%", left: 0}} />
          </div>
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={submitEdit} color="primary">
            {' '}
            Save{' '}
          </Button>
          <Button onClick={handleClose}> Close </Button>
        </div>
      </div>
    </Modal>
  );
}
