import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export interface IOrderItems {
  quantity: number;
  flavors: [
    {
      id: number;
      name: string;
    }
  ];
  edition: {
    id: number;
    edition_number: string;
    base_price: number;
    slug: string;
    name: string;
    price_eur: number;
    price_gbp: number;
    price_chf: number;
    price_usd: number;
  };
  orderitem_discount: number;
  product: {
    double?: boolean;
    width: number;
    height: number;
    length: number;
    weight: number;
    half?: boolean;
    product_id: number;
    year: string;
    artist: {
      id: number;
      name: string;
    };
    name: string;
    hs_code: string;
    price: number;
    ingredients: [
      {
        ingredient_id: number;
        name: string;
        quantity: string;
        cost: number;
        measure: string;
      }
    ];
    image?: string;
    extras?: [
      {
        id: number;
        ingredient: string;
        limit: number;
        price: string;
      }
    ];
  };
  comment: string;
  extra_orders?: [
    {
      id: number;
      ingredient: string;
      quantity: number;
      price: number;
      measure: string;
      measure_quantity: number;
    }
  ];
  dhl_label: string;
}
export interface IKitchen {
  discount: number;
  order_id: number;
  direct_ecomm: string;
  type_of_order: string;
  table_code: string;
  data_ordered: string;
  invoice_date: string;
  date_created: string;
  insurance_value_cents: number;
  dhl_planned_shipping_date: string;
  collection_order_contact: string;
  collection_order_date: string;
  collection_order_location: string;
  collection_order_notes: string;
  collection_order_person_name: string;
  invoice_pdf: string;
  invoice_pdf_decoded: string;
  collection_order_time: string;
  insurance_requested: boolean;
  insurance_contract_id: number;
  insurance_premium_amount_cents: number;
  voucher_code: string;
  voucher_code_message: string;
  currency: string;
  items: IOrderItems[];
  order_code: string;
  status: string;
  last_status?: string;
  payment: string;
  cash_change: string;
  cash_paid: string;
  is_paid: boolean;
  delivery_address: string;
  pre_tax_order_value: number;
  vat: number;
  driver: {
    id: number;
    name: string;
    phone_number: string;
  };
  gomotoboy_driver_name: string;
  final_comment: string;
  client: {
    id: number;
    address: {
      address_id: number;
      name?: string;
      street: string;
      street_number: string;
      neighborhood: string;
      city?: string;
      state?: string;
      zipcode?: string;
      active: boolean;
    };
    name: string;
    email: string;
    phone: string;
    cpf: string;
  };
  owner: {
    user: any;
    profile_id: number;
    name: string;
    phone_number: string;
    cpf: string;
    email: string;
    billing_address: number;
    delivery_address: number;
  };
  date_ordered: string;
  date_delivery: string;
  doing_at: string;
  done_at: string;
  depot_at: string;
  transit_at?: string;
  delivered_at?: string;
  pagarme_id?: string;
  finished_at: string;
  canceled_at: string;
  delivery_fee: number;
  total_order: number;
  set_driver_qr: string;
  dhl_price: number;
  invoice_reference: string;
  additional_costs: {
    title: string;
    description: string;
    cost: number;
  }[];
}

export interface IKitchenState {
  fetch: boolean;
  count: number;
  next?: string;
  currentOrderID: number;
  previous?: string;
  results: IKitchen[];
  todo: IKitchen[];
  doing: IKitchen[];
  done: IKitchen[];
  baking: IKitchen[];
  depot: IKitchen[];
  transit: IKitchen[];
  delivered: IKitchen[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesKitchen {
  FETCH = 'KITCHEN/FETCH',
  SUCCESS = 'KITCHEN/SUCCESS',
  FAILURE = 'KITCHEN/FAILURE',
  SET = 'KITCHEN/SET',
  UNSET = 'KITCHEN/UNSET',
  CURRENT_ORDER_ID = 'KITCHEN/CURRENT_ORDER_ID',
  UPDATE_ORDER = 'KITCHEN/UPDATE_ORDER'
}

export interface IFetchAction extends IBaseAction<ETypesKitchen, null> {
  type: ETypesKitchen.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesKitchen, IKitchenState> {
  type: ETypesKitchen.SUCCESS;
  payload: IKitchenState;
}

export interface ICurrentOrderIDAction
  extends IBaseAction<ETypesKitchen, number> {
  type: ETypesKitchen.CURRENT_ORDER_ID;
  payload: number;
}

export interface ISetAction extends IBaseAction<ETypesKitchen, IKitchen> {
  type: ETypesKitchen.SET;
  payload: IKitchen;
}

export interface IUnsetAction extends IBaseAction<ETypesKitchen, IKitchen> {
  type: ETypesKitchen.UNSET;
  payload: IKitchen;
}

export interface IFailureAction extends IBaseAction<ETypesKitchen, string> {
  type: ETypesKitchen.FAILURE;
  payload: string;
}

export interface IUpdateAction extends IBaseAction<ETypesKitchen, IKitchen> {
  type: ETypesKitchen.UPDATE_ORDER;
  payload: IKitchen;
}

export type IKitchenAction =
  | IFetchAction
  | ISuccessAction
  | ICurrentOrderIDAction
  | ISetAction
  | IUnsetAction
  | IFailureAction
  | IUpdateAction;

export type GetState = () => any;

export type PromiseAction = Promise<IKitchenAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IKitchenAction
    | ThunkAction
    | PromiseAction
    | Array<IKitchenAction>
    | IAlertAction
) => any;
