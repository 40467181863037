import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { Icurrency } from 'interfaces/currency';
import { useDispatch } from 'react-redux';
import { deleteCurrency } from 'redux/ducks/currency';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  currency: Icurrency;
  getData: any;
}

export default function ModalEditCurrency(props: SimpleDialogProps) {
  const dispatch = useDispatch();
  const { onClose, open, currency, getData } = props;

  const handleClose = () => {
    onClose();
  };

  const submitDelete = async () => {
    dispatch(deleteCurrency(currency?.id)).then((result: boolean) => {
      if (result) {
        console.log("veio aqui")
        handleClose();
        getData();

      }
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Delete currency {currency?.name}?
      </DialogTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={submitDelete} color="primary">
          {' '}
          Delete{' '}
        </Button>
        <Button onClick={handleClose}> Close </Button>
      </div>
    </Dialog>
  );
}
