import React from 'react';
import { Typography } from '@material-ui/core';
import { CardWithValue, CardBody, CardLabel } from './styles';
import NumberFormat from 'react-number-format';

interface Props {
  value: number;
  label: string;
  header?: number;
  percentage?: number;
  marginRight?: boolean;
}

const ExpensesCard: React.FC<Props> = (props: Props): JSX.Element => {
  const { value, label, header, percentage, marginRight } = props;
  return (
    <CardWithValue
      style={{
        marginRight: marginRight ? '2rem' : 'unset',
        alignItems: header ? 'center' : 'flex-start'
      }}
    >
      <CardBody>
        <CardLabel paragraph align="center">
          {label.toUpperCase()}
        </CardLabel>

        <Typography
          variant="h4"
          style={{ color: value >= 0 ? '#3FA336' : '#DE1C1C' }}
        >
          <NumberFormat
            value={value.toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'€'}
          />
        </Typography>
        {header ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h5" style={{ color: '#333399' }}>
              <NumberFormat
                value={header}
                displayType={'text'}
                thousandSeparator={true}
              />
            </Typography>
            <Typography variant="h5" style={{ color: '#FFA500' }}>
              <NumberFormat
                value={percentage.toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                suffix={'%'}
              />
            </Typography>
          </div>
        ) : null}
      </CardBody>
    </CardWithValue>
  );
};

export default ExpensesCard;
